// MessageModel.js
class MessageModel {
  constructor({
    createdOn,
    isRead = [],
    message,
    messageType,
    userId,
    block = [],
    isDeleted = [],
  }) {
    this.createdOn = createdOn;
    this.isRead = isRead;
    this.message = message;
    this.messageType = messageType;
    this.userId = userId;
    this.block = block;
    this.isDeleted = isDeleted;
  }
}
// Define the message type enumeration if needed
const MessageType = {
  IMAGE: "image",
  TEXT: "text",
  // Add other message types as needed
};
export { MessageModel, MessageType };
