import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  .modal-title {
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-family: inter;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #000000;
  }
`;
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .accountDetails-section,
  .accountDetails-data,
  .accountDetails-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 425px) {
    .accountDetails-section,
    .accountDetails-data,
    .accountDetails-title {
      flex-direction: column;
    }
  }
  .accountDetails-data {
    width: 547px;
    height: 56px;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 0px 20px;
    cursor: pointer;
  }
  @media (max-width: 425px) {
    .accountDetails-data {
      width: 100%;
      height: auto;
      padding: 14px;
      margin-bottom: 10px;
    }
  }
  .accountDetails-data.active {
    border: 2px solid #ffbb02;
    cursor: pointer;
  }
  .accountDetails-title {
    flex-basis: 50%;
    gap: 25px;
  }
  @media (max-width: 425px) {
    .accountDetails-title {
      gap: 0px;
    }
  }
  .accountDetails-section {
    justify-content: space-between;
  }

  .accountDetailsItems:active {
    border: 2px solid #ffbb02;
  }
  .accountNames {
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    line-height: 25.6px;
    color: #1c1c27;
    text-align: start;
  }
  .sectionButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }
  @media (max-width: 425px) {
    .sectionButton {
      flex-wrap: wrap;
    }
  }

  #addNewButton,
  #selectButton {
    width: 302px;
    height: 56px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: inter;
    font-weight: 700;
    line-height: 25.6px;
    text-align: center;
    color: #ffffff;
  }
  #addNewButton {
    background-color: #ffbb02;
  }
  #selectButton {
    background-color: #156a3d;
  }
  .error-message {
    color: red;
    font-family: inter;
  }
`;
