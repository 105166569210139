import { AuthAPI } from "libs/http-service/api/auth.api";

const useHooks = () => {
  const getProfile = async () => {
    try {
      const response = await AuthAPI.getProfile();
      return response
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const fetchUserReviews = async (params) => {
    try {
      const response = await AuthAPI.getuserReviews(params);
      console.log(response, "hello");
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };

  return { getProfile, fetchUserReviews };
};

export default useHooks;
