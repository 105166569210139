import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background: var(--all-background);
  height: -webkit-fill-available;

  .name {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.41px;
    color: #1c1c27;
    padding-bottom: 28px;
  }
  .prdct-img-text {
    font-family: inter;
    font-weight: 500;
    size: 16px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .details {
    font-family: inter;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .main-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 0px 10px 0px; */
  }
  .published-flex {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .ios-button {
    display: flex;
    align-items: center;
    /* padding-inline: 15px; */
  }
  .icon-width {
    width: 38px;
    height: 38px;
  }
  .published {
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #0c3a2d;
  }
  /* .padding-text {
    padding: 15px 0px 0px 0px;
  } */
`;
export const Card = styled.div`
  .image-size {
    width: 73px;
    height: 73px;
    border-radius: 20px;
  }
  .card-main-flex {
    display: flex;
    gap: 30px;
  }
  @media (max-width: 1280px) {
    .card-main-flex {
      flex-wrap: wrap;
    }
  }
  .categories-flex {
    display: flex;
    gap: 100px;
    /* padding: 40px 0px 0px 0px; */
  }
  .categories-flex-2 {
    display: flex;
    gap: 70px;
  }
  /* .padding {
    padding-inline: 10px;
  } */
  .star-width {
    width: 16px;
    height: 14px;
  }
  /* .padding-rows {
    padding: 8px 50px 3px 95px;
  }
  .padding-rows1 {
    padding: 0px 50px 0px 30px;
  }
  .padding-rows2 {
    padding: 0px 0px 0px 40px;
    width: 342px;
  }
  .padding-table {
    padding: 25px 0px 0px 0px;
  } */
  .table-heading {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #808d9e;
    width: 50%;
  }
  @media (max-width: 425px) {
    .table-heading {
      white-space: nowrap;
    }
  }
  .table-data {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.41px;
    color: #1c1c27;
    width: 50%;
  }
  @media (max-width: 1038px) {
    .table-data {
      width: 100%;
    }
  }

  /* .padding-color {
    padding: 0px 92px 0px 67px;
  } */
  .color {
    border-radius: 15px;
    border: 2px solid #e2e8f0;
    cursor: pointer;
  }
  .description {
    font-family: Plus Jakarta Sans;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #1c1c27;
  }
  @media (max-width: 1038px) {
    .description {
      text-align: justify;
    }
  }
  .seeAll {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
    cursor: pointer;
  }
  .comment-pic {
    width: 45px;
    height: 45px;
  }
  .review-flex {
    display: flex;
    align-items: center;
    /* padding: 0px 10px; */
  }
  .client-name {
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    padding: 0px 10px;
    color: #333333;
  }
  .report {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #e00000;
  }
  .comment-rating {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .comment-img-flex {
    display: flex;
    gap: 10px;
    /* padding-top: 10px; */
  }
  .date {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #64748b;
  }
  .comment {
    font-family: roboto;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }

  .parent {
    width: 100%;
  }
  .div1 {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  @media (max-width: 1038px) {
    .div1 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .div2 {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  @media (max-width: 1038px) {
    .div2 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .div3 {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  @media (max-width: 1038px) {
    .div3 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .div4 {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  @media (max-width: 1038px) {
    .div4 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .sub-parent {
    display: flex;
    gap: 30px;
    align-items: center;
  }
  .dimention {
    display: flex;
    width: 100%;
  }

  @media (max-width: 1038px) {
    .dimention {
      flex-wrap: wrap;
      gap: 13px;
      padding: 9px 9px;
    }
  }
  @media (max-width: 1280px) {
    .dimention {
      gap: 82px;
    }
  }
  @media (max-width: 1038px) {
    .dimention {
      gap: 20px;
    }
  }
  .dimention-flex {
    display: flex;
    width: 100%;
    gap: 27px;
  }
  @media (max-width: 1280px) {
    .dimention-flex {
      gap: 24px;
    }
  }
  .dimnetion-parent {
    display: flex;
    width: 100%;
    gap: 12em;
  }
  @media (max-width: 1038px) {
    .dimnetion-parent {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  @media (max-width: 1280px) {
    .dimnetion-parent {
      gap: 2em;
    }
  }
  .table-headings {
    width: 168px;
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #808d9e;
  }
  .product-img {
    width: 133px;
    height: 136px;
    margin-top: 15px;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  @media (max-width: 1038px) {
    .product-img {
      width: 100%;
    }
  }

  .parent-flex {
    width: 100%;
    display: flex;
    gap: 44px;
    margin-top: 15px;
  }
  @media (max-width: 2200px) {
    .parent-flex {
      flex-wrap: wrap;
      gap: 12px;
    }
  }
  .product-img-flex {
    display: flex;
    gap: 10px;
  }
  .product-flex {
    display: flex;
    gap: 44px;
    margin-top: 14px;
  }
  @media (max-width: 1038px) {
    .product-flex {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
`;
