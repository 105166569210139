import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHooks = () => {
    const catalogspost = async (formData) => { 
      try {
        const response = await AuthAPI.catalogspost(formData);
        // console.log(formData)
        return response;
      } catch (error) {
        console.error("Error in collectionPost:", error);
        throw error;
      }
    };
    const imagePost = async (file) => {
      try {
          if (!file) {
              throw new Error("No file provided");
          }
          const reader = new FileReader();
          const fileAsDataURL = new Promise((resolve, reject) => {
              reader.onload = () => resolve(reader.result);
              reader.onerror = reject;
          });
          reader.readAsDataURL(file);
          await fileAsDataURL;  // Wait for the file to be read as data URL
          const response = await AuthAPI.imagepost(
              { image: file },
              { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log("Image upload response", response);
          if (response?.path) {
              toastr.success("Image uploaded successfully");
              // alert('Success')
              console.log("response path",response.path)
              return response.path;
          } else {
              alert("Image upload failed");
              return null;
          }
      } catch (error) {
          console.error("Error uploading image:", error);
          alert("An error occurred during image upload");
          return null;
      }
  };
  const GetSinglecatalog = async (id) => { 
    console.log("Get single catalog ID",id)
    try {
      const response = await AuthAPI.getSingleCatalog(id);
      return response;
    } catch (error) {
      console.error("Error in catalog single:", error);
      throw error;
    }
  };
  const putCatalog = async (id, params) => {
    try {
      const response = await AuthAPI.catalogPut(id,params);
      console.log(response, 'Put Catalog Successfully');
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
    
    return { catalogspost,imagePost,GetSinglecatalog,putCatalog };
  };
  
  export default useHooks;

 
  