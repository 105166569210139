import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  height: max-content;

  .padding-inline {
    padding-inline: 45px;
  }
  .padding-inline2 {
    padding-inline: 28px;
  }
  .notifications {
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    padding-bottom: 20px;
  }
  .padding {
    padding: 7px 36px 7px 15px;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .notification-heading {
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
    color: #0c3a2d;
  }
  @media (max-width: 425px) {
    .notification-heading {
      font-size: 18px;
    }
  }

  .notification-subheading {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #808d9e;
  }
  .time {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #808d9e;
  }
  @media (max-width: 425px) {
    .time {
      display: none;
    }
  }
  .load-more {
    display: flex;
    justify-content: center;
    padding: 10px 35px;
    cursor: pointer;
  }
  .load {
    background-color: #0c3a2d;
    color: white;
    font-family: "Inter";
    padding: 5px 10px;
    border-radius: 3px;
  }
`;
