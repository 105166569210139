import { AuthAPI } from "libs/http-service/api/auth.api";

const useHook = () => {
  const GetNotifications = async (params) => {
    try {
      const response = await AuthAPI.GetNotifications(params);
      // if (response && response.data && response.data.results) {
      //   const notificationsLength = response.data.results.length;
      //   if (typeof notificationsLength === "number") {
      //     localStorage.setItem(
      //       "notificationsLength",
      //       notificationsLength.toString()
      //     );
      //     console.log("Number of notifications:", notificationsLength); // Log the length of the notifications array
      //   }
      // } 
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  const ReadNotifications = async () => {
    try {
      const response = await AuthAPI.ReadNotification();
 
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return { GetNotifications, ReadNotifications };
};

export default useHook;
