import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    // showEasing: "swing",
    // hideEasing: "linear",
    // showMethod: "fadeIn",
    // hideMethod: "fadeOut"
  };
const useHooks = () => {
    const getSubcatalogs = async (body)=> { 
        try {
          const response = await AuthAPI.getSubCatalog(body);  
          console.log('response is ',response)
          return response;
        } catch (error) {
          console.error(error);
          console.error("",error);
          return null;
        }
      }; 
      const SubcatalogsDelete = async (params) => { 
        try {
          const response = await AuthAPI.deleteSubCatalog(params);
          // console.log(formData)
          return response;
        } catch (error) {
          console.error("Error in collectionPost:", error);
          throw error;
        }
      };
      const getSingleMaincatalog = async (body)=> { 
        try {
          const response = await AuthAPI.getSingleCatalog(body);  
          console.log('Response Get Single Catalog is ',response)
          return response;
        } catch (error) {
          console.error(error);
          console.error("",error);
          return null;
        }
      }; 
      

  return { getSubcatalogs,SubcatalogsDelete,getSingleMaincatalog };
};

export default useHooks;