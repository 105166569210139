import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { ImgInput, Main } from "./style";
import BigArrow from "../../../assets/img/catalogs/Upload Big Arrow.png";
import useHooks from "./useHook";
import { IoCloseOutline } from "react-icons/io5";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 695,
  height: 512,
  bgcolor: "background.paper",
  border: "0px",
  boxShadow: 24,
  padding: 4,
  borderRadius: "30px",
  paddingTop: 1,
  "@media (max-width: 425px)": {
    width: "90%",
    height: "auto",
    padding: 2,
  },
};

export default function CollectionsModal({
  open,
  onClose,
  collectionSingle,
  imageid,
  setimageid,
}) {
  const { imagePost, collectionPost, putcollection } = useHooks();
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState("");
  // form validate
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const validateForm = () => {
    let isValid = true;

    // Validate name
    if (name.trim() === "") {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Validate selectedImage
    if (!selectedImage) {
      setImageError("Image is required");
      isValid = false;
    } else {
      setImageError("");
    }

    // Validate description
    if (description.trim() === "") {
      setDescriptionError("Description is required");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    return isValid;
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      const imagePath = await imagePost(file); // Ensure this is awaited
      console.log("FINAL: ", imagePath);
      if (imagePath) {
        setSelectedImage(imagePath);
        console.log("Uploaded Image URL:", imagePath);
      }
    } catch (error) {
      console.error("Error in handleFileInputChange: ", error);
      alert("An error occurred during image upload");
    }
  };
  const handleImagePickerClick = () => {
    document.getElementById("imagePicker").click();
  };
  const handleCreateCollection = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        name: name,
        image: selectedImage,
        description: description,
      };
      collectionPost(formData);
      onClose();
      setName("");
      setDescription("");
      setSelectedImage("");
    }
  };
  const [collectionid, setcollectionid] = useState("");
  // const showdata = () => {
  //   const response = collectionSingle;
  //   // console.log(response)
  //   setName(response.data?.name);
  //   setSelectedImage(response.data?.image);
  //   setDescription(response.data?.description)
  //   setcollectionid(response.data?.id)
  // }

  React.useEffect(() => {
    if (collectionSingle && collectionSingle.status) {
      setName(collectionSingle.data?.name);
      setSelectedImage(collectionSingle.data?.image);
      setDescription(collectionSingle.data?.description);
      setcollectionid(collectionSingle.data?.id);
    }
  }, [collectionSingle]);

  useEffect(() => {
    if (imageid === "" || imageid === null) {
      setName("");
      setSelectedImage("");
      setDescription("");
      setcollectionid("");
    }
  }, [imageid]);

  const updateMethod = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await putcollection(collectionid, {
        name,
        image: selectedImage,
        description,
      });
      onClose();
      setName("");
      setSelectedImage("");
      setDescription("");
      setcollectionid("");
    }
  };
  const handkeClose = () => {
    onClose();
    setName("");
    setSelectedImage("");
    setDescription("");
    setcollectionid("");
  };

  return (
    <Main>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography></Typography>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className="text-center"
              >
                {imageid ? (
                  <div>Update Collection</div>
                ) : (
                  <div>Create a new Collection</div>
                )}
              </Typography>
              <IoCloseOutline
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={handkeClose}
              />
            </div>

            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ImgInput>
                <form>
                  <label className="fw-bolder">Add Image</label>
                  <div className="add-img-input">
                    {!selectedImage ? (
                      <label
                        htmlFor="image-upload"
                        onClick={handleImagePickerClick}
                      >
                        <input
                          type="file"
                          id="imagePicker"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleImageChange(e);
                            setImageError(""); // Clear error when selecting image
                          }}
                        />
                        <img
                          src={BigArrow}
                          alt="big-arrow"
                          style={{ width: "25px" }}
                        />
                        <p style={{ padding: "5px 0px", margin: "0px" }}>
                          Upload Image
                        </p>
                        <p className="m-0">
                          (Only *.jpeg, *.webp and *.png images will be
                          accepted)
                        </p>
                      </label>
                    ) : (
                      <div
                        onClick={handleImagePickerClick}
                        style={{ cursor: "pointer" }}
                      >
                        {/* Hidden file input */}
                        <input
                          type="file"
                          id="imagePicker"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleImageChange(e);
                            setImageError(""); // Clear error when selecting image
                          }}
                        />
                        {/* Placeholder for the image */}
                        {imageError && (
                          <span className="error">{imageError}</span>
                        )}
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL + "/" + selectedImage
                          }
                          className="img-sizzing"
                        />
                      </div>
                    )}
                  </div>

                  <label className="mt-1 fw-bolder">Name</label>
                  <div>
                    <input
                      type="text"
                      className="name"
                      placeholder="Enter Collection Name"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError(""); // Clear error when typing
                      }}
                    />
                    {nameError && <span className="error">{nameError}</span>}
                  </div>

                  <label className="mt-1 fw-bolder">Description</label>
                  <div>
                    <textarea
                      className="text-area"
                      placeholder="Enter Collection Description"
                      rows={3}
                      name="description"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        setDescriptionError(""); // Clear error when typing description
                      }}
                    ></textarea>
                    {descriptionError && (
                      <span className="error">{descriptionError}</span>
                    )}
                  </div>

                  <div className="mt-2">
                    {imageid ? (
                      <button
                        type="submit"
                        className="create-btn"
                        onClick={updateMethod}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="create-btn"
                        onClick={handleCreateCollection}
                      >
                        Create
                      </button>
                    )}
                    {/* <button type="submit" className="create-btn">
                      Create
                    </button> */}
                  </div>
                </form>
              </ImgInput>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Main>
  );
}
