import React, { useEffect, useState } from "react";
import { Main } from "./style";
import useHooks from "./useHook";
import Spiner from "components/spinner";
import View from "../../../assets/img/orders/view.svg";
import PaginationRounded from "components/Pagination";
import ModalComponent from "components/modal/order-status-modal";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import Export from "assets/img/customers/export.png";
const Orders = () => {
  const { getOrders } = useHooks();
  const navigate = useNavigate();

  // Pagination states for different tabs
  const [customerName, setCustomerName] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pagination, setPagination] = useState({
    all: 1,
    pending: 1,
    processing: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    returned: 1,
    declined: 1,
  });

  const [totalPages, setTotalPages] = useState({
    all: 1,
    pending: 1,
    processing: 1,
    shipped: 1,
    delivered: 1,
    cancelled: 1,
    returned: 1,
    declined: 1,
  });
  const [activeTab, setActiveTab] = useState("all");

  const [loading, setLoading] = useState(true);
  const [orderlist, setOrderList] = useState([]);
  // for invoice
  const [productId, setproductId] = useState();

  // All order get
  const fetchOrders = async (status = activeTab, page = 1, perPage = 10) => {
    try {
      setLoading(true);
      const statusParam = status === "all" ? "" : status;
      console.log("current api status in params is====", status);
      const response = await getOrders({
        customerName,
        startDate,
        endDate,
        invoiceNumber,
        status: statusParam,
        page,
        perPage,
      });
      // console.log("order data", response.data.results)
      setOrderList(response.data.results);
      setTotalPages((prev) => ({
        ...prev,
        [status]: Math.ceil(response.data.counts / perPage),
      }));
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(activeTab, pagination[activeTab]);
  }, [activeTab, pagination]);

  const handlePageChange = (event, newPage) => {
    setPagination((prev) => ({ ...prev, [activeTab]: newPage }));
    localStorage.setItem(`pagination_${activeTab}`, newPage);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const handleView = (id) => {
    navigate(`/orders/${id}/order-invoice`, {
      state: {
        id: id,
        itemId: productId,
      },
    });
  };

  // useEffect(() => {
  //   const storedTab = localStorage.getItem('activeTab');
  //   if (storedTab && ['all', 'pending', 'processing', 'shipped', 'delivered', 'cancelled', 'returned', 'declined'].includes(storedTab)) {
  //     setActiveTab(storedTab);
  //     fetchOrders(storedTab, pagination[storedTab]);
  //   }
  // }, []);

  // useEffect(() => {
  //   const storedTab = localStorage.getItem('activeTab');
  //   if (
  //     storedTab &&
  //     ['all', 'pending', 'processing', 'shipped', 'delivered', 'cancelled', 'returned', 'declined'].includes(
  //       storedTab
  //     )
  //   ) {
  //     setActiveTab(storedTab);
  //     const storedPage = parseInt(localStorage.getItem(`pagination_${storedTab}`), 10);
  //     const initialPage = !isNaN(storedPage) ? storedPage : 1;
  //     setPagination((prev) => ({ ...prev, [storedTab]: initialPage }));
  //     fetchOrders(storedTab, initialPage);
  //   } else {
  //     fetchOrders(activeTab, pagination[activeTab]);
  //   }
  // }, []);
  // Function to handle tab click

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
    fetchOrders(tab);
  };
  const handleStartDateChange = (date) => {
    const selectedStartDate = new Date(date);

    if (endDate && selectedStartDate > new Date(endDate)) {
      setEndDate(null);
    }

    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const selectedEndDate = new Date(date);

    if (startDate && selectedEndDate < new Date(startDate)) {
      alert("End date cannot be earlier than start date");
      return;
    }

    setEndDate(date);
  };
  const handleReset = () => {
    setCustomerName("");
    setEndDate("");
    setStartDate("");
    setInvoiceNumber("");
  };

  // useEffect to trigger fetchOrders after state updates
  useEffect(() => {
    if (
      customerName === "" &&
      startDate === "" &&
      endDate === "" &&
      invoiceNumber === ""
    ) {
      fetchOrders();
    }
  }, [customerName, startDate, endDate, invoiceNumber]);
  const statusColors = {
    pending: "#FF8F501A",
    delivered: "#209A421A",
    declined: "#97f0f1",
    returned: "#89c8ff",
    cancelled: "#FF00001A",
    processing: "#00D1FF1A",
    shipped: "#ffdbbb",
  };
  const textColor = {
    pending: "#FF8F50",
    delivered: "#209A42",
    declined: "#149c9e",
    returned: "#002647",
    cancelled: "#FF0000",
    processing: "#00D1FF",
    shipped: "#FF8F50",
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingTabname, setPendingTabName] = useState("");

  const [orderID, setOrderId] = useState();
  const handleOpenOrder = (id, tabName) => {
    setModalOpen(true);
    setPendingTabName(tabName);
    setOrderId(id);
    console.log("activeTab", tabName);
  };

  useEffect(() => {
    if (!modalOpen) {
      fetchOrders();
    }
  }, [activeTab, pagination[activeTab]]);

  // for csv
  const headers = [
    { label: "SR. No", key: "sr" },
    { label: "Order Time", key: "createdAt" },
    { label: "Customer Name", key: "name" },
    { label: "Total Amount", key: "totalAmount" },
    { label: "Status", key: "status" },
  ];
  const exportData = orderlist.map((order, index) => ({
    sr: order.id,
    createdAt: formatDate(order.createdAt),
    name: order.user?.name,
    totalAmount: order.totalAmount,
    status: order.status,
  }));

  return (
    <Main>
      <div className="container-fluid">
        <div className="heading-flex">
          <p className="m-0 heading">Orders Overview</p>
          <CSVLink
            data={exportData}
            className="download-orders"
            headers={headers}
            target="_blank"
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecorationLine: "none",
            }}
            filename={"orders.csv"}
          >
            <p>Download All Orders</p>
          </CSVLink>
        </div>

        <div className="card border-0">
          <div className="card-body display-grid">
            <div>
              <input
                type="search"
                className="input me-2"
                placeholder="Search by Customer Name"
                onChange={(e) => setCustomerName(e.target.value)}
                value={customerName}
                style={{ marginTop: "24px" }}
              />
            </div>
            <div>
              <input
                type="search"
                className="input2 me-2"
                placeholder="Search by Invoice No"
                onChange={(e) => setInvoiceNumber(e.target.value)}
                value={invoiceNumber}
                style={{ marginTop: "24px" }}
              />
            </div>
            {/* <div>
              <select
                className="select1 me-2"
                // onChange={(e) => setStatus(e.target.value)}
                // value={status}
              >
                <option>Status</option>
                <option>Delivered</option>
                <option>Pending</option>
                <option>Processing</option>
                <option>Cancelled</option>
              </select>
            </div> */}
            {/* <div>
              {<select
                className="select2 me-2"
                // onChange={(e) => setlimit(e.target.value)}
                // value={limit}
              >
                <option>Order Limits</option>
                <option>Last 5 days orders</option>
                <option>Last 7 days orders</option>
                <option>Last 15 days orders</option>
                <option>Last 30 days orders</option>
              </select> 
            </div> */}
            <div>
              <label className="date">Start Date</label> <br />
              <input
                type="date"
                className="date-input"
                name=""
                id=""
                onChange={(e) => handleStartDateChange(e.target.value)}
                value={startDate || ""}
              />
            </div>
            <div>
              <label className="date">End Date</label> <br />
              <input
                type="date"
                className="date-input"
                name=""
                id=""
                onChange={(e) => handleEndDateChange(e.target.value)}
                value={endDate || ""}
              />
            </div>
            <div></div>
            <div className="head-button-flex">
              <div className=" w-100">
                <button
                  type="submit"
                  className="filter"
                  onClick={() => fetchOrders()}
                >
                  Filter
                </button>
              </div>
              <div className=" w-100">
                <button
                  type="button"
                  className="reset"
                  onClick={() => handleReset()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Status Modal Opening */}
        <ModalComponent
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          pendingTabname={pendingTabname}
          orderID={orderID}
          fetchOrders={fetchOrders}
        />
        <ul className="nav nav-tabs dispute-font" id="myTab" role="tablist">
          <li className="nav-item padding-dispute" role="presentation">
            <button
              className={`nav-link ${
                activeTab === "all" ? "active" : "custom-color"
              } dispute-font `}
              onClick={() => {
                handleTabClick("all");
              }}
            >
              All
            </button>
          </li>
          <li className="nav-item dispute-font " role="presentation">
            <button
              className={`nav-link ${
                activeTab === "pending" ? "active" : "custom-color"
              }`}
              onClick={() => {
                handleTabClick("pending");
              }}
            >
              Pending
            </button>
          </li>
          <li className="nav-item dispute-font " role="presentation">
            <button
              className={`nav-link ${
                activeTab === "processing" ? "active" : "custom-color"
              }`}
              onClick={() => {
                handleTabClick("processing");
              }}
            >
              Processing
            </button>
          </li>
          <li className="nav-item dispute-font " role="presentation">
            <button
              className={`nav-link ${
                activeTab === "shipped" ? "active" : "custom-color"
              }`}
              onClick={() => handleTabClick("shipped")}
            >
              Shipped
            </button>
          </li>
          <li className="nav-item dispute-font" role="presentation">
            <button
              className={`nav-link ${
                activeTab === "delivered" ? "active" : "custom-color"
              }`}
              onClick={() => handleTabClick("delivered")}
            >
              Delivered
            </button>
          </li>
          <li className="nav-item dispute-font " role="presentation">
            <button
              className={`nav-link ${
                activeTab === "cancelled" ? "active" : "custom-color"
              }`}
              onClick={() => handleTabClick("cancelled")}
            >
              Cancelled
            </button>
          </li>
          <li className="nav-item dispute-font " role="presentation">
            <button
              className={`nav-link ${
                activeTab === "returned" ? "active" : "custom-color"
              }`}
              onClick={() => handleTabClick("returned")}
            >
              Returned
            </button>
          </li>
          <li className="nav-item dispute-font" role="presentation">
            <button
              className={`nav-link ${
                activeTab === "declined" ? "active" : "custom-color"
              }`}
              onClick={() => handleTabClick("declined")}
            >
              Declined
            </button>
          </li>
        </ul>
        <div className="tab-content background" id="myTabContent">
          {/* All */}
          <div
            className={`tab-pane fade ${
              activeTab === "all" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {loading ? (
              <Spiner />
            ) : (
              <>
                {orderlist.length <= 0 ? (
                  <div className="no-record">No Record Found</div>
                ) : (
                  <div className="table-responsive">
                    <table className="table-width">
                      <thead>
                        <tr className="table-head">
                          <th>Invoice</th>
                          <th>Order Time</th>
                          <th>Customer Name</th>
                          <th style={{ width: "16%" }}>Amount</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderlist.map((items, index) => (
                          <tr className="table-row" key={index}>
                            <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                            <td style={{ paddingBlock: "13px" }}>
                              {formatDate(items.createdAt)}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {items.user?.name}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {items.totalAmount}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              <span
                                style={{
                                  backgroundColor:
                                    statusColors[items.status] || "transparent",
                                  color:
                                    textColor[items.status] || "transparent",
                                  padding: "3px",
                                  borderRadius: "10px",
                                  fontSize: "12px",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                {items.status}
                              </span>
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              <img
                                src={View}
                                onClick={() => handleView(items.id)}
                                className="view-icon"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <PaginationRounded
                      count={totalPages[activeTab]}
                      page={pagination[activeTab]}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          {/* pending */}
          <div
            className={`tab-pane fade ${
              activeTab === "pending" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {orderlist.length <= 0 ? (
              <div className="no-record">No Record Found</div>
            ) : (
              <div className="table-responsive">
                    <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th>Invoice</th>
                      <th>Order Time</th>
                      <th>Customer Name</th>
                      <th style={{ width: "16%" }}>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlist.map((items, index) => (
                      <tr className="table-row" key={index}>
                        <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                        <td style={{ paddingBlock: "13px" }}>
                          {formatDate(items.createdAt)}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.user?.name}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.totalAmount}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <span
                            style={{
                              backgroundColor:
                                statusColors[items.status] || "transparent",
                              color: textColor[items.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td
                          style={{
                            paddingBlock: "13px",
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <svg
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenOrder(items.id, activeTab)}
                            className="create-button"
                            fill="#00D1FF"
                            viewBox="0 0 16 16"
                            height="1.5em"
                            width="1.5em"
                          >
                            <path d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3a.5.5 0 011 0z" />
                          </svg>
                          <img
                            src={View}
                            onClick={() => handleView(items.id)}
                            className="view-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <PaginationRounded
                  count={totalPages[activeTab]}
                  page={pagination[activeTab]}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
          {/* processing */}
          <div
            className={`tab-pane fade ${
              activeTab === "processing" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {orderlist.length <= 0 ? (
              <div className="no-record">No Record Found</div>
            ) : (
              <div className="table-responsive">
                    <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th>Invoice</th>
                      <th>Order Time</th>
                      <th>Customer Name</th>
                      <th style={{ width: "16%" }}>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlist.map((items, index) => (
                      <tr className="table-row" key={index}>
                        <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                        <td style={{ paddingBlock: "13px" }}>
                          {formatDate(items.createdAt)}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.user?.name}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.totalAmount}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <span
                            style={{
                              backgroundColor:
                                statusColors[items.status] || "transparent",
                              color: textColor[items.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td
                          style={{
                            paddingBlock: "13px",
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <svg
                            style={{ cursor: "pointer" }}
                            //  onClick={() => handleOpen(item.id)}
                            className="create-button"
                            fill="#00D1FF"
                            viewBox="0 0 16 16"
                            height="1.5em"
                            width="1.5em"
                            onClick={() => handleOpenOrder(items.id, activeTab)}
                          >
                            <path d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3a.5.5 0 011 0z" />
                          </svg>
                          <img
                            src={View}
                            onClick={() => handleView(items.id)}
                            className="view-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PaginationRounded
                  count={totalPages[activeTab]}
                  page={pagination[activeTab]}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
          {/* shipped */}
          <div
            className={`tab-pane fade ${
              activeTab === "shipped" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {orderlist.length <= 0 ? (
              <div className="no-record">No Record Found</div>
            ) : (
              <div className="table-responsive">
                    <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th>Invoice</th>
                      <th>Order Time</th>
                      <th>Customer Name</th>
                      <th style={{ width: "16%" }}>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlist.map((items, index) => (
                      <tr className="table-row" key={index}>
                        <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                        <td style={{ paddingBlock: "13px" }}>
                          {formatDate(items.createdAt)}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.user?.name}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.totalAmount}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <span
                            style={{
                              backgroundColor:
                                statusColors[items.status] || "transparent",
                              color: textColor[items.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td
                          style={{
                            paddingBlock: "12px",
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <svg
                            style={{ cursor: "pointer" }}
                            //  onClick={() => handleOpen(item.id)}
                            className="create-button"
                            fill="#00D1FF"
                            viewBox="0 0 16 16"
                            height="1.5em"
                            width="1.5em"
                            onClick={() => handleOpenOrder(items.id, activeTab)}
                          >
                            <path d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3a.5.5 0 011 0z" />
                          </svg>
                          <img
                            src={View}
                            onClick={() => handleView(items.id)}
                            className="view-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PaginationRounded
                  count={totalPages[activeTab]}
                  page={pagination[activeTab]}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
          {/* delivered */}
          <div
            className={`tab-pane fade ${
              activeTab === "delivered" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {orderlist.length <= 0 ? (
              <div className="no-record">No Record Found</div>
            ) : (
              <div className="table-responsive">
                    <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th>Invoice</th>
                      <th>Order Time</th>
                      <th>Customer Name</th>
                      <th style={{ width: "16%" }}>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlist.map((items, index) => (
                      <tr className="table-row" key={index}>
                        <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                        <td style={{ paddingBlock: "13px" }}>
                          {formatDate(items.createdAt)}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.user?.name}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.totalAmount}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <span
                            style={{
                              backgroundColor:
                                statusColors[items.status] || "transparent",
                              color: textColor[items.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <img
                            src={View}
                            onClick={() => handleView(items.id)}
                            className="view-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PaginationRounded
                  count={totalPages[activeTab]}
                  page={pagination[activeTab]}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
          {/* cancelled */}
          <div
            className={`tab-pane fade ${
              activeTab === "cancelled" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {orderlist.length <= 0 ? (
              <div className="no-record">No Record Found</div>
            ) : (
              <div className="table-responsive">
                    <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th>Invoice</th>
                      <th>Order Time</th>
                      <th>Customer Name</th>
                      <th style={{ width: "16%" }}>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlist.map((items, index) => (
                      <tr className="table-row" key={index}>
                        <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                        <td style={{ paddingBlock: "13px" }}>
                          {formatDate(items.createdAt)}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.user?.name}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.totalAmount}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <span
                            style={{
                              backgroundColor:
                                statusColors[items.status] || "transparent",
                              color: textColor[items.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <img
                            src={View}
                            onClick={() => handleView(items.id)}
                            className="view-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PaginationRounded
                  count={totalPages[activeTab]}
                  page={pagination[activeTab]}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
          {/* returned */}
          <div
            className={`tab-pane fade ${
              activeTab === "returned" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {orderlist.length <= 0 ? (
              <div className="no-record">No Record Found</div>
            ) : (
              <div className="table-responsive">
                    <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th>Invoice</th>
                      <th>Order Time</th>
                      <th>Customer Name</th>
                      <th style={{ width: "16%" }}>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlist.map((items, index) => (
                      <tr className="table-row" key={index}>
                        <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                        <td style={{ paddingBlock: "13px" }}>
                          {formatDate(items.createdAt)}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.user?.name}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.totalAmount}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <span
                            style={{
                              backgroundColor:
                                statusColors[items.status] || "transparent",
                              color: textColor[items.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <img
                            src={View}
                            onClick={() => handleView(items.id)}
                            className="view-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <PaginationRounded
                  count={totalPages[activeTab]}
                  page={pagination[activeTab]}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
          {/* declined */}
          <div
            className={`tab-pane fade ${
              activeTab === "declined" ? "show active" : ""
            }`}
            id="all-tab-pane"
            role="tabpanel"
            style={{ marginTop: "10px" }}
          >
            {orderlist.length <= 0 ? (
              <div className="no-record">No Record Found</div>
            ) : (
              <div className="table-responsive">
                    <table className="table-width">
                  <thead>
                    <tr className="table-head">
                      <th>Invoice</th>
                      <th>Order Time</th>
                      <th>Customer Name</th>
                      <th style={{ width: "16%" }}>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderlist.map((items, index) => (
                      <tr className="table-row" key={index}>
                        <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                        <td style={{ paddingBlock: "13px" }}>
                          {formatDate(items.createdAt)}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.user?.name}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          {items.totalAmount}
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <span
                            style={{
                              backgroundColor:
                                statusColors[items.status] || "transparent",
                              color: textColor[items.status] || "transparent",
                              padding: "3px",
                              borderRadius: "10px",
                              fontSize: "12px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            {items.status}
                          </span>
                        </td>
                        <td style={{ paddingBlock: "13px" }}>
                          <img
                            src={View}
                            onClick={() => handleView(items.id)}
                            className="view-icon"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <PaginationRounded
                  count={totalPages[activeTab]}
                  page={pagination[activeTab]}
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Orders;
