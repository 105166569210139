import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  /* height: var(--height); */
  height: 130vh;
  .invoice {
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    margin: 0;
    padding-block: 20px;
  }
  .invoice2 {
    font-family: poppins;
    font-weight: 600;
    font-size: 18px;
    color: #252525;
  }
  .status {
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .process-button {
    width: 86px;
    height: 24px;
    border-radius: 31px;
    background-color: #06d36621;
    font-family: inter;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #156a3d;
    border: 0px;
  }
  .style-jenifer {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 20px;
    color: black;
  }
  .style-payment {
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
    color: black;
  }
  .style-address {
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: black;
  }
  .style-date {
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    color: black;
  }
  .style-pay {
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: black;
  }
  .center {
    text-align: center;
  }
  .style-table {
    border-bottom: 1px solid #f1f5f9;
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #0f172a;
  }
  .amount {
    color: #ff0000;
    font-weight: 700;
    font-size: 23px;
    letter-spacing: 0.2px;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #394b42;
  }
  .download-pdf {
    width: 20%;
    height: 56px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
  }
  @media (max-width: 1034px) {
    .download-pdf {
     width: 40%;
    }
  }
  .invoice-flex{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;

  }
  @media (max-width: 425px) {
    .invoice-flex {
     flex-wrap: wrap;
    }
  }


  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 750px;
    }
  }

  @media print {
    body * {
      visibility: hidden;
    }
    .printable,
    .printable * {
      visibility: visible;
    }

    .printable {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .button-flex{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  @media (max-width: 425px) {
    .button-flex {
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
    }
    .download-pdf{
      width: 100%;
    }
  }
`;
