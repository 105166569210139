// import React from "react";
// import img from "../../assets/img/blank-page/Frame.svg";

// const BlankPage = () => {
//   return (
//     <div
//       style={{
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         justifyContent: "center",
//         textAlign: "center",
//         width: "100%",
//         height: "100%",
//       }}
//     >
//       <div style={{ marginRight: "70px" }}>
//         <img src={img} alt="" />
//       </div>
//       <h1
//         style={{
//           fontFamily: "inter",
//           fontSize: "24px",
//           fontWeight: "600",
//           marginTop: "20px",
//         }}
//       >
//         You got an email to continue work as Vendor
//       </h1>
//     </div>
//   );
// };

// export default BlankPage;
import React, { useEffect } from "react";
import img from "../../assets/img/blank-page/Frame.svg";
import { useNavigate } from "react-router-dom";

const BlankPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear local storage when the component mounts
    localStorage.clear();

    // Prevent back navigation
    const unblock = window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
    };

    // Cleanup when the component is unmounted
    return () => {
      window.onpopstate = null;
    };
  }, [navigate]);

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
        height: "100%",
        padding: "0px 60px",
      }}
    >
      <div style={{ marginRight: "70px" }}>
        <img src={img} alt="Vendor Email Prompt" />
      </div>
      <h1
        style={{
          fontFamily: "inter",
          fontSize: "24px",
          fontWeight: "600",
          marginTop: "20px",
          color: "#1e5e3b",
        }}
      >
        Your request has been sent to the admin, and it is currently under
        review. Once it is accepted, you will receive an email notification.
      </h1>
    </div>
  );
};

export default BlankPage;
