import { Routes, Route } from "react-router-dom";
import Login from "./login";
import Forget from "./forget";
import VerifyEmail from "./verifyEmail";
import ResetPassword from "./resetPassword";
import ForcePasswordChange from "./force-password-change";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" Component={Login} />
      <Route path="/forget" Component={Forget} />
      <Route path="/verifyemail" Component={VerifyEmail} />
      <Route path="/resetpassword" Component={ResetPassword} />
      <Route path="/change-password" Component={ForcePasswordChange} />
    </Routes>
  );
};

export default AuthRoutes;
