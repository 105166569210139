import styled from "styled-components";

export const Main = styled.div`
    width: 100%;
    height: 100vh;
    background-color: var(--all-background);
letter-spacing: 0.2px;
    .nav-pills .nav-link.active, .edagDK .nav-pills .show>.nav-link {
        color: black;
        background-color: #F9F9F9 !important;
    } 

.dataTable-section{
 width: 100%;
  background-color: var(--white-background);
  border-radius: 10px;
  border-spacing: 0;
}
.dataTable-section-body{
    display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 35px 0px;
  padding:10px 35px 35px 35px;
}
`

export const Table = styled.table`
 width: inherit;

  th, td {
    padding: 20px 0px;
  }
  .table-head{
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2%;
    text-align: left;
    }
    .table-body{
        border-bottom:1px solid #E9E9E9;

    }
    .table-body tr:nth-child(odd) {
    background-color: #F9FBFD;
  }
.table-body td:nth-child(1){
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
}
.table-body td{
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2%;
    border-bottom: 1px solid #EDF2F7;
}

  .status-pending,
  .status-approved {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
  }

  .status-pending {
    background-color: #FF00001A;
    color: #FF8F50;
  }

  .status-approved {
    background-color: #209A421A;
    color: #209A42;
  }
  .pagination-section{

  }
`;
