import styled from "styled-components";

export const Main = styled.div`
  width: 100%;

  .yellow_border {
    border-bottom: 6px solid #ffbb02;
    padding: 10%;
    margin-inline: 10%;
  }
  .heading {
    font-family: "Plus Jakarta Sans";
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #0f172a;
  }
  @media (max-width: 380px) {
    .heading {
      font-size: 20px;
    }
  }
  .welcome_Content {
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-weight: 400;
    color: #64748b;
  }
  @media (max-width: 380px) {
    .welcome_Content {
      font-size: 10px;
    }
  }
  .input {
    width: 60%;
    height: 45px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    color: black;
    padding: 9px;
    outline: #ffbb02;
  }
  .input-password {
    width: 60%;
    height: 45px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    color: black;
    padding: 9px;
    outline: #ffbb02;
  }
  .eye-icon {
    position: absolute;
    margin-top: 10px;
    margin-left: -29px;
  }
  .margin {
    margin-top: 12%;
    margin-left: 20%;
  }
  .remember {
    display: flex;
    justify-content: space-between;
    margin: 3% 20%;
  }
  .remember_me {
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #252525;
  }
  @media (max-width: 425px) {
    .remember_me {
      display: none;
    }
  }
  .Forgot_Password {
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #ffbb02;
    font-weight: 500;
  }
  .SignIn_Button {
    background-color: #0c3a2d;
    color: white;
    width: 60%;
    border-radius: 10px;
    font-family: "Plus Jakarta Sans";
    height: 45px;
  }
`;
