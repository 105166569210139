// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import Sidebar from "components/sidebar";
// import DashboardRoutes from "../../../containers/dashboard";
// import Header from "components/header";
// import { Main } from "./style";

// const DashboardLayout = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <Main>
//       <div className="container-fluid p-0">
//         <div
//           style={{
//             width: "100%",
//             height: "100vh",
//             padding: "0px",
//             margin: "0px",
//           }}
//         >
//           <div className="width1 p-0 ">
//             <div className="fixed">
//               <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
//             </div>
//           </div>
//           <div className="cont p-0">
//             <div className="header">
//              <Header toggleSidebar={toggleSidebar} />
//             </div>
//             <div className="dashboard-routes">
//               <DashboardRoutes />
//             </div>
//           </div>
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default DashboardLayout;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardRoutes from "../../../containers/dashboard";
import Header from "components/header";
import { Main } from "./style";
import Sidebar from "components/sidebar";

const DashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const isBlankPage =
    location.pathname === "/blank-page" ||
    location.pathname === "/change-password" ||
    location.pathname === "/vendor-information";

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const isLoggedIn = localStorage.getItem("login");

  //   // Check if user is logged in or not
  //   if (isLoggedIn !== "true") {
  //     // If not logged in, redirect to /auth
  //     navigate("/auth/");
  //   }
  // }, [navigate]);

  return (
    <Main>
      <div className="container-fluid p-0">
        <div
          style={{
            width: "100%",
            height: "100vh",
            padding: "0px",
            margin: "0px",
          }}
        >
          {!isBlankPage && (
            <div className="width1 p-0 ">
              <div className="fixed">
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
              </div>
            </div>
          )}
          <div className="cont p-0">
            {!isBlankPage && (
              <div className="header">
                <Header toggleSidebar={toggleSidebar} />
              </div>
            )}
            <div className="dashboard-routes">
              <DashboardRoutes />
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default DashboardLayout;
