// import React, { useEffect, useRef, useState } from "react";
// import { Main } from "./style";
// import Ellipse from "../../assets/img/header/Ellipse.png";
// import Notification from "../../assets/img/header/notification-bing 1.svg";
// import Chat from "../../assets/img/header/search.svg";
// import { useNavigate } from "react-router-dom";
// import useHooks from "./usehook"; // Correct import for useHooks
// import { toast } from "react-toastify";
// import toastr from "toastr";

// const Header = () => {
//   const navigate = useNavigate();
//   const headerRef = useRef(null);

//   const [username, setUsername] = useState("");
//   const [profilepic, setProfilepic] = useState("");
//   const [count, setCount] = useState();
//   const hasAlertedRef = useRef(false);

//   useEffect(() => {
//     const storedUserName = localStorage.getItem("name");
//     const storedProfilepic = localStorage.getItem("profile_pic");
//     const storedid = localStorage.getItem("id");
//     const storedcount = localStorage.getItem("notificationsLength");

//     if (storedUserName) {
//       setUsername(storedUserName);
//     }
//     if (storedProfilepic) {
//       setProfilepic(storedProfilepic);
//     }
//     if (storedcount) {
//       setCount(storedcount);
//     } else if (!hasAlertedRef.current) {
//       hasAlertedRef.current = true;
//     }

//     // const handleScroll = () => {
//     //   if (headerRef.current) {
//     //     if (window.pageYOffset >= headerRef.current.offsetTop) {
//     //       headerRef.current.classList.add("sticky");
//     //     } else {
//     //       headerRef.current.classList.remove("sticky");
//     //     }
//     //   }
//     // };

//     // window.addEventListener("scroll", handleScroll);

//     // return () => {
//     //   window.removeEventListener("scroll", handleScroll);
//     // };
//   }, []);

//   const handleVendorProfileOpen = () => {
//     navigate("/vendor-profile");
//   };

//   const handleVendorSettings = () => {
//     navigate("/vendor-profile-settings");
//   };

//   const handleChatManagement = () => {
//     navigate("/chat-managment");
//   };

//   const handleLogout = () => {
//     let logout = localStorage.clear(false);
//     if (!logout) {
//       navigate("/auth");
//     } else {
//       alert("not logout");
//     }
//   };

//   const handleNotifications = () => {
//     navigate("/notifications");
//   };

//   const unread = localStorage.getItem("unreadCount");
//   console.log(unread);
//   return (
//     <Main ref={headerRef}>
//       <div className="width">
//         <div className="flex">
//           <div style={{ cursor: "pointer", position: "relative" }}>
//             <img
//               src={Chat}
//               alt="chat"
//               className="search"
//               onClick={handleChatManagement}
//             />
//             <p className="message">{unread}</p>
//             &nbsp; &nbsp;
//             <img
//               src={Notification}
//               alt="notification"
//               className="notification-bell"
//               onClick={handleNotifications}
//             />
//             <p className="counter">{unread}</p>
//           </div>
//           <div className="dropdown">
//             <div
//               className="rounded-2 bg-white h-auto padding dropdown-toggle"
//               data-bs-toggle="dropdown"
//               aria-expanded="false"
//               style={{ cursor: "pointer" }}
//             >
//               <img
//                 src={`${process.env.REACT_APP_BASE_URL}${profilepic}`}
//                 alt="Ellipse"
//                 className="image"
//               />
//               <span className="select">{username}</span>
//             </div>
//             <ul
//               className="dropdown-menu dropdown-menu-end"
//               style={{ width: "200px" }}
//             >
//               <li>
//                 <button
//                   className="dropdown-item"
//                   type="button"
//                   onClick={handleVendorProfileOpen}
//                 >
//                   Profile
//                 </button>
//               </li>
//               <li>
//                 <button
//                   className="dropdown-item"
//                   type="button"
//                   onClick={handleVendorSettings}
//                 >
//                   Settings
//                 </button>
//               </li>
//               <li>
//                 <button
//                   className="dropdown-item logoutButton"
//                   type="button"
//                   onClick={handleLogout}
//                 >
//                   Logout
//                 </button>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default Header;

import React, { useEffect, useRef, useState } from "react";
import { Main } from "./style";
import Ellipse from "../../assets/img/header/Ellipse.png";
import Notification from "../../assets/img/header/notification-bing 1.svg";
import Chat from "../../assets/img/header/search.svg";
import { useNavigate } from "react-router-dom";
import { FiAlignLeft } from "react-icons/fi"; // Import the burger icon
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import useHooks from "./usehook";

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const [username, setUsername] = useState("");
  const [profilepic, setProfilepic] = useState("");
  const [count, setCount] = useState();
  const hasAlertedRef = useRef(false);

  useEffect(() => {
    const storedUserName = localStorage.getItem("name");
    const storedProfilepic = localStorage.getItem("profile_pic");
    const storedid = localStorage.getItem("id");
    const storedcount = localStorage.getItem("notificationsLength");

    if (storedUserName) {
      setUsername(storedUserName);
    }
    if (storedProfilepic) {
      setProfilepic(storedProfilepic);
    }
    if (storedcount) {
      setCount(storedcount);
    } else if (!hasAlertedRef.current) {
      hasAlertedRef.current = true;
    }
  }, []);

  const handleVendorProfileOpen = () => {
    navigate("/vendor-profile");
  };

  const handleVendorSettings = () => {
    navigate("/vendor-profile-settings");
  };

  const handleChatManagement = () => {
    navigate("/chat-managment");
  };

  const handleLogout = () => {
    let logout = localStorage.clear(false);
    if (!logout) {
      navigate("/auth");
    } else {
      alert("not logout");
    }
  };

  const handleNotifications = () => {
    navigate("/notifications");
  };

  const unread = localStorage.getItem("unreadCount");
  // useEffect(() => {
  //   const updateMessageCount = () => {
  //     const unreadMessage = localStorage.getItem("unreadCounter");
  //     setMessageCount(Number(unreadMessage) || 0);
  //   };

  //   // Listen for changes to localStorage and update the state
  //   window.addEventListener("storage", updateMessageCount);

  //   // Call it initially to set the state
  //   updateMessageCount();

  //   return () => {
  //     // Stop listening to Firestore updates
  //     window.removeEventListener("storage", updateMessageCount);
  //   };
  // }, []);

  //  count
  const [customerData, setCustomerData] = useState([]);
  const [blockedData, setBlockedData] = useState([]);
  const [mssgCount, setMessageCount] = useState();
  const getFirebaseChats = () => {
    const vendorId = Number(localStorage.getItem("id"));
    if (!vendorId) {
      console.error("No vendor ID found in localStorage");
      return;
    }

    const chatCollection = collection(db, "chat");

    const unsubscribe = onSnapshot(chatCollection, (snapshot) => {
      const customers = [];
      const blocked = [];
      const messageCount = {};
      let unreadCounterTemp = 0;

      snapshot.forEach((doc) => {
        const data = doc.data();

        if (data.myInfo.id === vendorId || data.otherUser.id === vendorId) {
          if (data.lastMessage) {
            const latestMessage = data.lastMessage;
            const senderId = latestMessage.userId;
            const isMessageRead = latestMessage.isRead.includes(vendorId);

            // Debugging logs to verify message read status
            // console.log(`Message ID: ${doc.id}`);
            // console.log(`isRead: ${latestMessage.isRead}`);
            // console.log(`vendorId: ${vendorId}`);
            // console.log(`isMessageRead: ${isMessageRead}`);

            // Only increment unreadCounterTemp if vendorId is not in isRead
            if (!isMessageRead) {
              unreadCounterTemp++;
              // console.log(
              //   `Unread message from ${data.otherUser.name || "Unknown"}`
              // );
            }

            if (senderId in messageCount) {
              messageCount[senderId]++;
            } else {
              messageCount[senderId] = 1;
            }

            if (data.block?.block) {
              blocked.push({
                ...data,
                id: doc.id,
                latestMessageTime: latestMessage.createdOn,
                senderId,
                senderName:
                  data.myInfo.id === senderId
                    ? data.myInfo.name
                    : data.otherUser?.name || "Unknown",
                messageCount: messageCount[senderId],
              });
            } else {
              customers.push({
                ...data,
                id: doc.id,
                latestMessageTime: latestMessage.createdOn,
                senderId,
                senderName:
                  data.myInfo.id === senderId
                    ? data.myInfo.name
                    : data.otherUser?.name || "Unknown",
                messageCount: messageCount[senderId],
              });
            }
          } else {
            if (data.block?.block) {
              blocked.push({ ...data, id: doc.id });
            } else {
              customers.push({ ...data, id: doc.id });
            }
          }
        }
      });

      customers.sort((a, b) => b.latestMessageTime - a.latestMessageTime);

      setCustomerData(customers);
      setBlockedData(blocked);

      // Update and log unread counter only if greater than 0
      if (unreadCounterTemp > 0) {
        // console.log("Final unreadCounter:", unreadCounterTemp);
        setMessageCount(unreadCounterTemp);
      } else {
        // Optionally, you can set messageCount to 0 explicitly if desired
        setMessageCount("");
      }

      // Save unreadCounter in local storage
      localStorage.setItem("unreadCount", unreadCounterTemp);
    });

    return unsubscribe; // Return unsubscribe function to stop listening when needed
  };

  useEffect(() => {
    getFirebaseChats();
  }, []);

  // getprofie :

  const [vendorProfile, setVendorProfile] = useState({});
  const { getProfile } = useHooks();

  const handleProfile = async () => {
    try {
      const response = await getProfile();
      setVendorProfile(response.data);

      // Check if the user is not verified
      if (!response.data.isVerified) {
        // console.log("User is not verified, navigating to /auth");
        navigate("/auth");
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  useEffect(() => {
    handleProfile();
  }, []);

  return (
    <Main ref={headerRef}>
      <div className="width">
        <div className="flex">
          <div className="burger-div">
            <div>
              <FiAlignLeft
                className="burger-icon"
                size={30}
                onClick={toggleSidebar} // Use the toggleSidebar prop
                style={{ cursor: "pointer", marginRight: "20px" }}
              />
            </div>
            <div className="header-flex">
              <div className="relative">
                <img
                  src={Chat}
                  alt="chat"
                  className="search"
                  onClick={handleChatManagement}
                />
                <p className="message">{mssgCount}</p>
                &nbsp; &nbsp;
                <img
                  src={Notification}
                  alt="notification"
                  className="notification-bell"
                  onClick={handleNotifications}
                />
                <p className="counter">{unread}</p>
              </div>
              <div className="dropdown">
                <div
                  className="rounded-2 bg-white h-auto padding dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${
                      vendorProfile ? vendorProfile.profile_pic : "no img here"
                    }`}
                    alt="Ellipse"
                    className="image"
                  />
                  <span className="select">
                    {" "}
                    {vendorProfile ? vendorProfile.name : "Loading..."}
                  </span>
                </div>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  style={{ width: "200px" }}
                >
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={handleVendorProfileOpen}
                    >
                      Profile
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={handleVendorSettings}
                    >
                      Settings
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item logoutButton"
                      type="button"
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Header;
