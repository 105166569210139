import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  /* height: var(--height); */
  height: 200vh;
  /* @media (max-width:1460px) {
    
} */
  /* padding: 0px 18px; */

  .Overview {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #1c1c27;
    padding-block: 20px;
  }

  .overview-card {
    width: 100%;
    height: 168px;
    border-radius: 9px;
    border: 0px;
  }
  .overview-img {
    width: 40px;
    height: 40px;
  }
  .overview-cardbody {
    text-align: center;
    padding: 40px;
  }
  .overview-flex {
    display: flex;
    gap: 8px;
  }
  @media (max-width: 1270px) {
    .overview-flex {
      flex-direction: column;
    }
  }
  .orders {
    font-family: inter;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
  .orders-number {
    font-family: inter;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.2px;
    color: #ffffff;
  }
  .background-order {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }

  .img-width {
    width: 32px;
    height: 32px;
  }
  .flex {
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    @media (max-width: 660px) {
      display: flex;
      justify-content: center;
    }
  }
  .number {
    font-family: poppins;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0.5%;
    color: #252525;
    margin-top: 8px;
    float: right;
    padding: 12px 0px;
  }
  .para {
    font-family: poppins;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.5%;
    color: #9ca4ab;
    padding-top: 7%;
  }
  .card-width {
    border: 1px solid #edf2f7;
    width: 312px;
    height: 110px;
    border-radius: 12px;
    margin: 4% 0%;
    flex-wrap: wrap;
    @media (max-width: 660px) {
    }
  }
  .second-cards {
    width: 36px;
    height: 32px;
    flex-wrap: wrap;
  }
  .padding {
    padding-top: 15px;
  }
  .vendor-style {
    font-family: poppins;
    font-weight: 500;
    font-size: 14px;
    color: #9ca4ab;
  }
  .vendor-number {
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
  }
  .second-card {
    width: 214px;
    height: 130px;
    border-radius: 12px;
    border: 1px solid #edf2f7;

    @media (max-width: 660px) {
      width: 312px;
      margin-top: 4%;
    }
  }

  .third-card {
    width: 100%;
    height: 92px;
    border: 1px solid #edf2f7;
    /* margin-top: 1%; */
  }
  /* .order-img{
    width: 62px;
    height: 62px;
} */
  .third-flex {
    justify-content: start;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .margin {
    margin-left: 8%;
  }
  .order-para {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .order-number {
    font-family: Inter;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .order-flex {
    display: grid;
    margin-top: 3%;
    display: grid;
    grid: auto / auto auto auto auto;
    grid-gap: 10px;
  }
  @media (max-width: 1260px) {
    .order-flex {
      grid-template-columns: 1fr;
    }
  }
  .order-img {
    width: 32px;
    height: 32px;
  }
  .SalesStatistics {
    font-family: inter;
    font-weight: 500px;
    font-size: 18px;
    color: #000000;
  }
  .sales {
    width: 188px;
    height: 51px;
    border-radius: 5px;
    background-color: #0c3a2d;
    color: white;
    font-family: inter;
    font-weight: 400;
    letter-spacing: 0.2px;
    border: 0px;
  }
  .Orders {
    width: 188px;
    height: 51px;
    border-radius: 5px;
    background-color: #ffbb02;
    color: white;
    font-family: inter;
    font-weight: 400;
    letter-spacing: 0.2px;
    border: 0px;
  }
  .walet-back {
    background-color: #0c3a2d;
    padding: 5px;
    border-radius: 2px 0px 0px 2px;
  }
  .wallet-main {
    border: 1px solid black;
    border-radius: 5px 5px 5px 5px;
    width: 274px;
    height: 38px;
  }
  .wallet-input {
    border: 0;
    outline: none;
    width: 100%;
  }
  .to {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 16px;
    color: #808d9e;
  }
  .chart-container {
    width: 100%;
    height: 442.46px;
  }
  .sale-flex{
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 505px) {
    .sale-flex {
       flex-wrap: wrap;
    }
}
`;
