import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
const useHooks = () => {
  const customersOrderDetailGet = async (id) => {
    try {
      const response = await AuthAPI.customerOderDetailGet(id);
      return response;
    } catch (error) {
      console.error(error);
      console.log(error.message);
      return null;
    }
  };
  // const OrdersGet=async(id,params)=>{
  //     try{
  //         const response = await AuthAPI.OrdersGet(id,params);
  //         console.log('order get response',response)
  //         return response;
  //     }
  //     catch(error){
  //         console.error(error);
  //               alert(error.message);
  //               return null;
  //     }
  // }

  const OrdersGet = async (
    userId,
    invoiceNumber,
    startDate,
    endDate,
    status,
    page,
    perPage
  ) => {
    const params = {
      invoiceNumber: invoiceNumber,
      startDate: startDate,
      endDate: endDate,
      status: status,
      page: page,
      perPage: perPage,
    };

    try {
      const response = await AuthAPI.OrdersGet(userId, params);
      console.log("Order get response:", response);
      return response; // Assuming AuthAPI.OrdersGet returns the entire Axios response object
    } catch (error) {
      console.error("Error fetching orders:", error);
      alert(error.message); // Show an alert with the error message
      return null; // Return null or handle the error as needed in your component
    }
  };
  const orderGetID = async (id) => {
    try {
      const response = await AuthAPI.getOrders(id);
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };

  return { customersOrderDetailGet, OrdersGet, orderGetID };
};

export default useHooks;
