import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);
font-family: inter;
.nav-pills .nav-link.active, .edagDK .nav-pills .show>.nav-link {
    color: black;
    background-color: #F9F9F9 !important;
}
p{
    margin-bottom: 0px;
}

    .view-policies-action{    
     display: flex;
     gap: 0px 10px;
    }
h4{
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--black-color);
    margin-bottom: 30px;
}
    h5{
        font-size: 18px;
        font-weight: 600;
        color: var(--darkGray-color);
           }
              h6{
        font-size: 14px;
        font-weight: 700;
        color: var(--black-color);
        white-space: normal;
        }
h6 span{
    line-height: 21px;
    font-size: 14px;
        font-weight: 500;
        color: var(--black-color);
}
 .policy-details{
            line-height: 27px;
    letter-spacing: 0.2px;
 border-radius: 20px;
padding:35px 130px 35px 35px;
        }
    .view-text{
        line-height: 21px;
            letter-spacing: 0.2px;
       display: flex;
       /* align-items: center; */
       justify-content: start;
    }        
`