import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHook = () => {
  const GetwalletCards = async (body) => {
    try {
      const response = await AuthAPI.getWithDrawlCards(body);
      if (response) {
        // toastr.success('Collection get successfully');
        console.log(response);
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again Later");
      return null;
    }
  };

  const GetwalletStats = async (body) => {
    try {
      const response = await AuthAPI.getWithDrawlStats(body);
      if (response) {
        // toastr.success('Collection get successfully');
        console.log(response);
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again Later");
      return null;
    }
  };
  const GetallRequests = async (page = 1, perPage = 1000) => {
    try {
      const response = await AuthAPI.getallRequest({ page, perPage });
      if (response) {
        // toastr.success('Collection get successfully');
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again later");
      return null;
    }
  };
  const GetSettings = async () => {
    try {
      const response = await AuthAPI.getsettings();
      if (response) {
        // toastr.success('Collection get successfully');
        console.log(response);
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again Later");
      return null;
    }
  };

  return { GetwalletCards, GetwalletStats, GetallRequests,GetSettings };
};

export default useHook;
