import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };
const useHooks = () => {
    // const getcollections = async (params)=> { 
    //     try {
    //       const response = await AuthAPI.getcollections(params);  
    //       if (response) {
    //         // toastr.success('Collection get successfully');
    //         console.log(response);
    //       }
    //       console.log(response,'')
    //       return response;
    //     } catch (error) {
    //       console.error(error);
    //     //   toastr.error("Please try again Later");
    //       return null;
    //     }
    //   }; 
    //   const collectionDelete=async(id)=>{
    //     try {
    //       const response = await AuthAPI.deleteCollection(id);  
    //       // toastr.success('Collection delete successfully')
    //       return response;
    //     } catch (error) {
    //       console.error(error);
    //     //   toastr.error("Please try again later");
    //       return null;
    //     }
    //   }
    const singleCollectionGet = async (id) => {
        try {
            const response = await AuthAPI.getSingleCollection(id);
            // console.log(formData)
            return response;
        } catch (error) {
            console.error("Error in single collection Get:", error);
            throw error;
        }
    };
    const getproductlist = async (params) => {
        try {
            const response = await AuthAPI.getproducts(params);
            return response;
        } catch (error) {
            console.error("error getting product list", error)
        }
    }
    const productSwitchPut = async (id, isPublished) => {
        try {
            const response = await AuthAPI.putPublishproducts(id, { isPublished });
            toastr.success("Switch updated successfully");
            return response.data || [];
        } catch (error) {
            alert('There was an error posting the switch: ' + error.message);
            console.error('Error details:', error);
            return [];
        }
    };
    const productDelete = async (params) => { 
        try {
          const response = await AuthAPI.deleteproduct(params);
          // console.log(formData)
          return response;
        } catch (error) {
          console.error("Error in collectionPost:", error);
          throw error;
        }
      };

    //   return { getcollections,collectionDelete,singleCollectionGet,getproductlist };
    return { singleCollectionGet, getproductlist,productSwitchPut,productDelete };
};

export default useHooks;
