import React, { useState, useRef, useEffect } from "react";
import { Main, Section } from "./style";
// import Editor from 'components/text-editor';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { Main } from "./style";

import useHooks from "./useHook";
import {  useNavigate, useParams } from "react-router-dom";

 
const CreatePolicies = () => {
  const { policiesPost, policiesUpdate, Getpolicies } = useHooks();
  const [name, setValue] = useState("");
  const [description, setdescription] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
 
  const validateForm = () => {
    let errors = {};
    let isValid = true;
 
    if (!description.trim()) {
      errors.description = "Policy Description is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const { policyname, id } = useParams();

 

  const handlepostPolicies = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formdata = {
        name: policyname,
        description: description,
      };
 
      await policiesPost(formdata);
      navigate("/my-policies");
    }
  };
  const handleupdatePolicies = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // const formdata = {
      //   name: value,
      //   description: description,
      // };
 
      await policiesUpdate(id, { name: name, description: description });
      navigate("/my-policies");
    }
  };
  // const [singlePolicy, setSinglePolicy] = useState("");
  const FetchSingleCollection = async () => {
    try {
      const response = await Getpolicies(id);
      // setSinglePolicy(response.data);
      setValue(response.data.name);
      setdescription(response.data.description);
 
    } catch (error) {
      console.log("Error single collection get", error);
    }
  };

  useEffect(() => {
    FetchSingleCollection();
  }, []);
  // react quill description
  const reactQuillRef = useRef(null);
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      ["link"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
    ],
    // imageUploader: {
    //   upload: (file) => {
    //     return new Promise((resolve, reject) => {
    //       const formData = new FormData();
    //       formData.append("image", file);

    //       fetch(
    //         "https://api.imgbb.com/1/upload?key=334ecea9ec1213784db5cb9a14dac265",
    //         {
    //           method: "POST",
    //           body: formData
    //         }
    //       )
    //         .then((response) => response.json())
    //         .then((result) => {
    //           console.log(result);
    //           resolve(result.data.url);
    //         })
    //         .catch((error) => {
    //           reject("Upload failed");
    //           console.error("Error:", error);
    //         });
    //     });
    //   }
    // }
  };
  // const location = useLocation();
  // const { id } = location.state || { id: null, name: "" };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "image",
    "list",
    "bullet",
    "indent",
    "link",
    "align",
  ];

  return (
    <Main>
      <div className="container-fluid">
        <div className="heading-flex">
          <p className="heading">
            {id ? <span> Update Policy</span> : <span>Create Policy</span>}
          </p>
        </div>

        <Section>
          <div className="create-policies-section">
            {/* <div className="input-field-section w-100">
              <p className="policy-name">Policy Name</p>
              <input
                type="text"
                placeholder="Cancellation Policy"
                value={name}
                onChange={(e) => {
                  setValue(e.target.value);
                  // Clear error when user starts typing
                  setErrors({ ...errors, value: "" });
                }}
              />
              {errors.value && <p className="error-message">{errors.value}</p>}
            </div> */}
            {id ? (
              <button
              type="submit"
              className="category-btn"
              onClick={handleupdatePolicies}
            >
              Update
            </button>
            ) : (
             
               <button
               type="submit"
               className="category-btn"
               onClick={handlepostPolicies}
             >
               Create
             </button>
            )}
          </div>
          <div>
            <p className="policy-description">Policy Description</p>
            <div>
              <ReactQuill
                onChange={(value) => {
                  setdescription(value);
                  setErrors({ ...errors, description: "" });
                }}
                theme="snow"
                style={{
                  minHeight: "25vh",
                  height: "10px",
                }}
                modules={modules}
                formats={formats}
                value={description}
                ref={reactQuillRef}
              />
            </div>
          </div>
          {errors.description && (
            <p className="error-message2">{errors.description}</p>
          )}
        </Section>
      </div>
    </Main>
  );
};

export default CreatePolicies;
