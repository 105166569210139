import styled from "styled-components";

export const BusinessDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .styling-font {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    color: white;
  }
  .main-name {
    font-family: inter;
    font-size: 24px;
    font-weight: 600;
    color: #0c3a2d;
  }
  .ratting {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    color: #0c3a2d;
  }
  .main-width {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  .partition-width {
    width: 22%;
    padding: 0px 25px;
    margin-top: 20px;
  }
  .main-head {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    color: #808d9e;
    white-space: nowrap;
  }
  .sub-head {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    color: #1c1c27;
    white-space: nowrap;
  }
  .license-img {
    width: 68px;
    height: 44px;
    border-radius: 10px;
  }
  hr {
    margin: 2rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid #c9cfd8;
    opacity: 0.25;
  }
  .address-font {
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    color: #1c1c27;
    text-align: justify;
  }
  .close-icon {
    font-size: 25px;
    cursor: pointer;
    color: #0f0f0f;
  }
`;
