import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  height: auto;
`;
export const SearchCard = styled.div`
  .export-card {
    border: 0px;
    border-radius: 9px;
  }
  .input-width {
    width: 80%;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 15px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    outline: none;
  }
  @media (max-width: 491px) {
    .input-width {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .search-button {
    width: 19%;
    height: 56px;
    border-radius: 5px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    border: 0px;
    background-color: #156a3d;
  }
  @media (max-width: 491px) {
    .search-button {
      width: 100%;
    }
  }
  .input-flex {
    display: flex;
    gap: 10px;
  }
  @media (max-width: 491px) {
    .input-flex {
      flex-wrap: wrap;
    }
  }
`;

export const Tabs = styled.div`
  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: #808d9e;
    text-decoration: none;
    background: white;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    width: 131px;
    height: 48px;
    border-radius: 16px 16px 0px 0px;
    background-color: var(--all-background);
  }
  .dispute-font {
    font-family: inter;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    border-bottom: 0;
    color: #808d9e;
  }
  .padding-dispute {
    padding: 0px 3px;
  }
  .nav-tabs .nav-link.active {
    color: white;
    background-color: #0c3a2d;
    border-color: var(--bs-nav-tabs-link-active-border-color);
  }
  .dispute-content {
    background: white;
    margin: 0px 3px;
  }
  .card-border {
    border-radius: 0px 0px 7px 7px;
  }
 
`;
export const Table = styled.div`
  .table-heading {
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #0c3a2d;
  }
  .table-row {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #0f172a;
    border-bottom: 1px solid #f1f5f9;
  }
  .customer-item {
    /* General styling for customer items */
    padding: 10px;
    font-weight: normal;
    color: #333;
    transition: background-color 0.3s, font-weight 0.3s;
  }

  .customer-item.new-message {
    font-weight: bold; /* Make the font bold */
    background-color: #f5f5f5; /* Slightly darker background */
    color: #000; /* Darker text color */
  }

  /* Additional hover effect if desired */
  .customer-item.new-message:hover {
    background-color: #e0e0e0;
  }

  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }
`;
