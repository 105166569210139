import styled from "styled-components";
//
export const Main = styled.div`
  .heading {
    background-color: #0c3a2d;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
  .text-container {
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .filter {
    width: 100%;
    height: 56px;
    border-radius: 5px;
    background-color: #0c3a2d;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
  }
  .reset {
    width: 100%;
    height: 56px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808d9e;
    margin-left: 6px;
  }
  @media (max-width: 425px) {
    .reset{
    margin-left: 0px;
    margin-top: 10px;
    }
  }
  .css-d3wcwz-MuiTypography-root {
    margin: 0;
    font-family: poppins;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: -0.65em;
    text-align: center;
  }
  .btn-flex {
    display: flex;
    justify-content: space-between;
    margin-top: 13%;
  }
@media (max-width: 425px) {
    .btn-flex{
     flex-wrap: wrap;
    }
  }
`;
