import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--all-background);
  font-family: inter;
  letter-spacing: 0.2px;

  .nav-pills .nav-link.active,
  .edagDK .nav-pills .show > .nav-link {
    color: black;
    background-color: #f9f9f9 !important;
  }

  .create-policies-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--black-color);
  }
`;
export const Section = styled.section`
  height: 70vh;
  background-color: var(--white-background);
  padding: 20px;
  border-radius: 15px;
  margin: 12px 0px;
  .create-policies-section {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 20px;
  }
  @media (max-width: 425px) {
    .create-policies-section {
      flex-wrap: wrap;
    }
  }
  .policy-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 5px;
  }
  .policy-description {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin: 20px 0px 27px 0px;
  }

  .input-field-section > input {
    border-radius: 4px;
    background-color: var(--all-background);
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    padding: 20px;
  }
  input::placeholder {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--darkGray-color);
  }

  .create-policies-section > button {
    width: 218px;
    height: 48px;
    border-radius: 5px;
    background-color: var(--primaryGreen-button);
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
    text-align: center;
  }
  @media (max-width: 425px) {
    .create-policies-section > button {
      width: 100%;
    }
  }

  /* .quill >.ql-snow{
 border: none;
} */
  .quill > .ql-toolbar {
    border-bottom: 1px solid var(--darkGray-color);
  }
  .error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  .error-message2 {
    color: red;
    font-size: 0.8rem;
    margin-top: 50px;
  }
`;
