import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./home";
import Customers from "./cutomers";
import CustomerOverview from "./customer-overview";
import Products from "./products";
import AddProducts from "./add-products";
import ProductsDetails from "./products-details";
import Orders from "./orders";
import UserInvoice from "./user-invoice";
import OrderDispute from "./order-dispute";
import Catalogs from "./my-catalog";
import Collection from "./my-collection";
import Policies from "./my-policies";
import ViewPolicies from "./view-policies";
import CreatePolicies from "./create-policies";
import Wallet from "./my-wallet";
import WithDrawRequestTable from "./my-wallet-withDrawRequestTable";
import VendorProfile from "./vendor-profile";
import VendorProfileEdit from "./vendor-profile-edit";
import ChatManagment from "./chat-managment";
import Notifications from "./notifications";
import Chat from "./chats";
import VendorSetting from "./vendor-setting";
import UpdateProducts from "./update-products";
import MycatalogDetail from "./my-catalogs-detail";
import CollectionDetail from "./my-collection-detail";
import OrderInvoice from "./orders-invoice";
import DisputesDetails from "./disputes-details";
import VendorInformation from "./vendor-information";
import BlankPage from "components/blank-page";

const DashboardRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/customers" Component={Customers} />
        <Route path="/customers/overview/:id" Component={CustomerOverview} />
        <Route
          path="/customers/overview/:id/user-invoice"
          Component={UserInvoice}
        />
        <Route path="/products" Component={Products} />
        <Route path="/products/add" Component={AddProducts} />
        <Route path="/products/details/:id" Component={ProductsDetails} />
        <Route path="/products/update/:id" Component={UpdateProducts} />
        <Route path="/orders" Component={Orders} />
        <Route path="/orders/:id/order-invoice" Component={OrderInvoice} />
        <Route path="/orders/user-invoice" Component={UserInvoice} />
        <Route path="/order-dispute" Component={OrderDispute} />
        <Route
          path="/order-dispute/disputes-details/:id"
          Component={DisputesDetails}
        />
        <Route path="/my-catalogs" Component={Catalogs} />
        <Route path="/my-catalogs-detail/:id" Component={MycatalogDetail} />
        <Route path="/my-collection" Component={Collection} />
        <Route path="/my-collection-detail/:id" Component={CollectionDetail} />
        <Route path="/my-policies" Component={Policies} />
        <Route path="/my-policies/view-policies/:id" Component={ViewPolicies} />
        <Route
          path="/my-policies/create-policies/:id/:policyname"
          Component={CreatePolicies}
        />
        <Route
          path="/my-policies/create-policies/:policyname"
          Component={CreatePolicies}
        />

        <Route path="/my-wallet" Component={Wallet} />
        <Route
          path="/my-wallet/withdraw-request-data"
          Component={WithDrawRequestTable}
        />
        <Route path="/vendor-profile" Component={VendorProfile} />
        <Route path="/edit-vendor-profile" Component={VendorProfileEdit} />
        <Route path="/vendor-information" Component={VendorInformation} />
        <Route path="/vendor-profile-settings" Component={VendorSetting} />
        <Route path="/chat-managment" Component={ChatManagment} />
        <Route path="/notifications" Component={Notifications} />
        <Route path="/chat-managment/chat/:id" Component={Chat} />
        <Route path="/blank-page" Component={BlankPage} />
      </Routes>
    </>
  );
};

export default DashboardRoutes;
