import styled from "styled-components";
//
export const Main = styled.div`
  .main-container {
    width: 100%;
    margin-bottom: 20px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 272px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    color: #000000;
    border-radius: 12px 12px 0px 0px;
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 20px;
  }

  .close-icon {
    font-size: 25px;
    cursor: pointer;
    color: #0f0f0f;
  }

  .img-container {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-pic {
    width: 140px;
    height: 140px;
    border-radius: 104.05px;
    border: 4px solid #0c3a2d;
    padding: 2px;
  }
  .profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
    flex-wrap: wrap;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .lablel-text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #808d9e;
  }
  .user-detail {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #1c1c27;
  }

  .vendor-name {
    color: #0c3a2d;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    margin-block: 14px;
  }
`;
