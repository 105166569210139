import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: min-content;
  background-color: var(--all-background);
  letter-spacing: 0.2px;
  .nav-pills .nav-link.active,
  .edagDK .nav-pills .show > .nav-link {
    color: black;
    background-color: #f9f9f9 !important;
  }
  .wallet-headingSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 20px;
  }
  .wallet-headingName {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    text-align: justify;
    line-height: 27px;
    color: var(--black-color);
  }

  .wallet-headingButton {
    width: 223px;
    height: 56px;
    border-radius: 10px;
    background-color: #272525;
    font-family: Poppins;
    font-weight: 700;
    font-size: 14px;
    color: var(--white-textColor);
    border: 0px;
  }
  .wallet-cards {
    display: flex;
    flex-direction: row;
    gap: 0px 12px;
  }
  @media (max-width: 1160px) {
    .wallet-cards {
      gap: 12px 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  .cards-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white-background);
    border: 1px solid #edf2f7;
    width: 100%;
    height: 159px;
    border-radius: 12px;
    padding: 25px;
  }
  .card-body > h5 {
    font-family: inter;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: var(--black-color);
  }
  .card-cost {
    font-family: inter;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.5%;
  }
  .withdraw-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0px;
  }
  .withdraw-section-title {
    font-size: 18px;
    font-family: inter;
    font-weight: 600;
    line-height: 27px;
  }

  .withdraw-section a {
    font-size: 18px;
    font-family: inter;
    font-weight: 600;
    line-height: 27px;
    border: none;
    background-color: transparent;
    color: var(--darkGray-color);
    text-decoration: none;
  }
  .dataTable-section {
    width: 100%;
    background-color: var(--white-background);
    border-radius: 10px;
    border-spacing: 0;
  }
  .dataTable-section-body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 35px 0px;
    padding: 10px 35px 35px 35px;
  }
  /* .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }*/

  /* .tooltip-text {
    visibility: hidden;
    width: 180px; //Adjust width as needed
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the icon */
  /* left: 50%;
    margin-left: -90px; /* Center the tooltip */
  /* opacity: 0;
    transition: opacity 0.3s;
    white-space: normal; /* Allow wrapping to multiple lines */
  /* }  */

  /* .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  } */

  th,
  td {
    padding: 20px 0px;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #0c3a2d;
  }
  .table-body {
    border-bottom: 1px solid #e9e9e9;
  }
  /* .table-body tr:nth-child(odd) {
    background-color: #f9fbfd;
  } */
  .table-body td:nth-child(1) {
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
  }
  .table-body td {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2%;
    border-bottom: 1px solid #edf2f7;
  }

  .status-pending,
  .status-approved {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
  }

  .status-pending {
    background-color: #ff00001a;
    color: #ff8f50;
  }

  .status-approved {
    background-color: #209a421a;
    color: #209a42;
  }
  .pagination-section {
  }
  .image-width {
    width: 50px;
    border-radius: 5px;
  }
  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }
`;
