import styled from "styled-components";

export const Main = styled.div`
  .inputs-width {
    width: 673px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    padding: 13px;
    outline: none;
  }
  @media (max-width: 1280px) {
    .inputs-width {
      width: 100%;
    }
  }
  .validation-color {
    font-weight: 500;
    color: red;
    font-family: "Inter";
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 20px;
  }
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 673px;
  }
  .tag-background {
    background-color: #0c3a2d;
    color: white;
    border-radius: 6px;
    padding: 3px 7px;
    display: flex;
    align-items: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
  }
  
`;
