// import React, { useState, useRef, useEffect } from "react";
// import { Main } from "./style";
// import { RxCross2 } from "react-icons/rx";
// import { useForm } from "react-hook-form";

// const ProductsInputs = ({ onChange }) => {
//   const [tags, setTags] = useState([]);
//   const [inputValue, setInputValue] = useState("");
//   const inputRef = useRef(null);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   useEffect(() => {
//     onChange(tags);
//   }, [tags, onChange]);

//   const handleInputKeyPress = (event) => {
//     const { key } = event;
//     if (key === "Enter" || key === "," || key === " ") {
//       event.preventDefault();
//       const trimmedValue = inputValue?.trim();
//       if (trimmedValue && trimmedValue !== "") {
//         setTags([...tags, trimmedValue]);
//         setInputValue("");
//       }
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     setTags(tags.filter((tag) => tag !== tagToRemove));
//   };

//   const handleInputPaste = (event) => {
//     event.preventDefault();
//     const pasteData = event.clipboardData.getData("text/plain");
//     const pastedTags = pasteData.split(/[,\s]+/);
//     const newTags = [...new Set([...tags, ...pastedTags])];
//     setTags(newTags.filter((tag) => tag.trim() !== ""));
//   };

//   const handleInputKeyDown = (event) => {
//     if (event.key === "Backspace" && inputValue === "" && tags.length > 0) {
//       const updatedTags = [...tags];
//       updatedTags.pop();
//       setTags(updatedTags);
//     }
//   };

//   const handleTagClick = () => {
//     inputRef.current.focus();
//   };

//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm();
//   return (
//     <Main>
//       <div className="flex-wrap">
//         <input
//           type="text"
//           name="metas"
//           value={inputValue}
//           onChange={handleInputChange}
//           onKeyPress={handleInputKeyPress}
//           className="inputs-width"
//           placeholder="Product Keywords"

//         />
//         <div className="flex">
//           {tags.map((tag, index) => (
//             <>
//               <span key={index} className="  tag-background">
//                 {tag}

//                 <RxCross2
//                   onClick={() => removeTag(tag)}
//                   size={60}
//                   color="white"
//                   className="cross-border"
//                 />
//               </span>
//             </>
//           ))}
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default ProductsInputs;

import React, { useState, useRef, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { useForm } from "react-hook-form";
import { Main } from "./style";

const ProductsInputs = ({ onChange }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    onChange(tags); // Notify parent component of tag changes
  }, [tags, onChange]);

  const handleInputKeyPress = (event) => {
    const { key } = event;
    if (key === "Enter" || key === "," || key === " ") {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue && trimmedValue !== "") {
        setTags([...tags, trimmedValue]);
        setInputValue("");
      }
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleInputPaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");
    const pastedTags = pasteData.split(/[,\s]+/);
    const newTags = [...new Set([...tags, ...pastedTags])];
    setTags(newTags.filter((tag) => tag.trim() !== ""));
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Backspace" && inputValue === "" && tags.length > 0) {
      const updatedTags = [...tags];
      updatedTags.pop();
      setTags(updatedTags);
    }
  };

  const handleTagClick = () => {
    inputRef.current.focus();
  };

  return (
    <Main>
      <div className="flex-wrap">
        <input
          type="text"
          name="metas"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          onPaste={handleInputPaste}
          onKeyDown={handleInputKeyDown}
          className="inputs-width"
          placeholder="Product Keywords"
          ref={inputRef}
        />
        <div className="flex">
          {tags.map((tag, index) => (
            <span key={index} className="tag-background">
              {tag}
              <RxCross2
                onClick={() => removeTag(tag)}
                size={60}
                color="white"
                className="cross-border"
              />
            </span>
          ))}
        </div>
      </div>
    </Main>
  );
};

export default ProductsInputs;

// import React, { useState, useRef, useEffect } from "react";
// import { Main } from "./style";
// import { RxCross2 } from "react-icons/rx";

// const ProductsInputs = ({ onChange, initialTags = [] }) => {
//   const [tags, setTags] = useState(initialTags);
//   const [inputValue, setInputValue] = useState("");
//   const inputRef = useRef(null);

//   useEffect(() => {
//     setTags(initialTags);
//   }, [initialTags]);

//   useEffect(() => {
//     onChange(tags);
//   }, [tags, onChange]);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleInputKeyPress = (event) => {
//     const { key } = event;
//     if (key === "Enter" || key === "," || key === " ") {
//       event.preventDefault();
//       const trimmedValue = inputValue.trim();
//       if (trimmedValue && trimmedValue !== "") {
//         setTags([...tags, trimmedValue]);
//         setInputValue("");
//       }
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     setTags(tags.filter((tag) => tag !== tagToRemove));
//   };

//   const handleInputPaste = (event) => {
//     event.preventDefault();
//     const pasteData = event.clipboardData.getData("text/plain");
//     const pastedTags = pasteData.split(/[,\s]+/);
//     const newTags = [...new Set([...tags, ...pastedTags])];
//     setTags(newTags.filter((tag) => tag.trim() !== ""));
//   };

//   const handleInputKeyDown = (event) => {
//     if (event.key === "Backspace" && inputValue === "" && tags.length > 0) {
//       const updatedTags = [...tags];
//       updatedTags.pop();
//       setTags(updatedTags);
//     }
//   };

//   const handleTagClick = () => {
//     inputRef.current.focus();
//   };

//   return (
//     <Main>
//       <div className="flex-wrap">
//         <input
//           type="text"
//           name="metas"
//           value={inputValue}
//           onChange={handleInputChange}
//           onKeyPress={handleInputKeyPress}
//           onPaste={handleInputPaste}
//           onKeyDown={handleInputKeyDown}
//           className="inputs-width"
//           placeholder="Product Keywords"
//           ref={inputRef}
//         />
//         <div className="flex">
//           {tags.map((tag, index) => (
//             <span key={index} className="tag-background">
//               {tag}
//               <RxCross2
//                 onClick={() => removeTag(tag)}
//                 size={60}
//                 color="white"
//                 className="cross-border"
//               />
//             </span>
//           ))}
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default ProductsInputs;
// import React, { useState, useRef, useEffect } from "react";
// import { Main } from "./style";
// import { RxCross2 } from "react-icons/rx";

// const ProductsInputs = ({ onChange, initialTags = [] }) => {
//   const [tags, setTags] = useState(initialTags);
//   const [inputValue, setInputValue] = useState("");
//   const inputRef = useRef(null);

//   useEffect(() => {
//     setTags(initialTags);
//   }, [initialTags]);

//   useEffect(() => {
//     onChange(tags);
//   }, []);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleInputKeyPress = (event) => {
//     const { key } = event;
//     if (key === "Enter" || key === "," || key === " ") {
//       event.preventDefault();
//       const trimmedValue = inputValue.trim();
//       if (trimmedValue && trimmedValue !== "") {
//         setTags([...tags, trimmedValue]);
//         setInputValue("");
//       }
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     setTags(tags.filter((tag) => tag !== tagToRemove));
//   };

//   const handleInputPaste = (event) => {
//     event.preventDefault();
//     const pasteData = event.clipboardData.getData("text/plain");
//     const pastedTags = pasteData.split(/[,\s]+/);
//     const newTags = [...new Set([...tags, ...pastedTags])];
//     setTags(newTags.filter((tag) => tag.trim() !== ""));
//   };

//   const handleInputKeyDown = (event) => {
//     if (event.key === "Backspace" && inputValue === "" && tags.length > 0) {
//       const updatedTags = [...tags];
//       updatedTags.pop();
//       setTags(updatedTags);
//     }
//   };

//   const handleTagClick = () => {
//     inputRef.current.focus();
//   };

//   return (
//     <Main>
//       <div className="flex-wrap">
//         <input
//           type="text"
//           name="metas"
//           value={inputValue}
//           onChange={handleInputChange}
//           onKeyPress={handleInputKeyPress}
//           onPaste={handleInputPaste}
//           onKeyDown={handleInputKeyDown}
//           className="inputs-width"
//           placeholder="Product Keywords"
//           ref={inputRef}
//         />
//         <div className="flex">
//           {tags.map((tag, index) => (
//             <span key={index} className="tag-background">
//               {tag}
//               <RxCross2
//                 onClick={() => removeTag(tag)}
//                 size={60}
//                 color="white"
//                 className="cross-border"
//               />
//             </span>
//           ))}
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default ProductsInputs;
