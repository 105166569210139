// import React, { useState, useRef } from "react";
// import { Modal, Box, Typography } from "@mui/material";
// import { Main, TabContent, Tab, TabsWrapper, Send } from "./style";
// import { IoMdClose } from "react-icons/io";
// import VendorProfile from "assets/img/disputes-modal/Group 1707488248.svg";
// const CustomTabs = (disputesdetails) => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [completedTabs, setCompletedTabs] = useState([]);
//   const [selectedImages, setSelectedImages] = useState([]);
//   console.log("disputesdetails:", disputesdetails);
//   const fileInputRef = useRef(null);
//   const handleSend = () => {
//     if (!completedTabs.includes(activeTab)) {
//       setCompletedTabs([...completedTabs, activeTab]);
//     }
//     console.log(`Action performed on tab: ${activeTab}`);
//   };
//   const tabs = ["Comment", "Update Status", "Warning"];
//   const handleImageChange = (e) => {
//     const files = Array.from(e.target.files);
//     if (selectedImages.length + files.length > 5) {
//       alert("You can only upload up to 5 images.");
//       return;
//     }
//     setSelectedImages((prevImages) => [...prevImages, ...files]);
//   };
//   const triggerFileInput = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click(); // Trigger a click on the hidden file input
//     } else {
//       console.error("File input reference is null");
//     }
//   };

//   return (
//     <>
//       <TabsWrapper>
//         {tabs.map((tab, index) => (
//           <Tab
//             key={index}
//             active={activeTab === index}
//             onClick={() => setActiveTab(index)}
//           >
//             {tab}
//           </Tab>
//         ))}
//       </TabsWrapper>
//       <TabContent>
//         {activeTab === 0 && (
//           <div>
//             <div className="comment-container">
//               <span className="comment">Comment </span>
//               <textarea className="textarea"></textarea>
//               <div className="image-container gap-2">
//                 <div className="selected-images mt-2 gap-2">
//                   {selectedImages.map((image, index) => (
//                     <img
//                       key={index}
//                       src={URL.createObjectURL(image)}
//                       alt={`Selected ${index}`}
//                       className="thumbnail"
//                     />
//                   ))}
//                 </div>
//                 <div className="mt-2" onClick={triggerFileInput}>
//                   {/* <VendorProfile className="vendor-pic" /> */}
//                   <img
//                     src={VendorProfile}
//                     alt="VendorProfile"
//                     className="vendor-pic"
//                   />

//                   <input
//                     ref={fileInputRef} // Reference the file input
//                     id="file-upload"
//                     type="file"
//                     accept="image/*"
//                     onChange={handleImageChange}
//                     style={{ display: "none" }}
//                     multiple
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//         {activeTab === 1 && (
//           <div>
//             <div className="select-conatiner">
//               <select className="select-input">
//                 <option>1</option>
//                 <option>2</option>
//                 <option>3</option>
//               </select>
//             </div>
//           </div>
//         )}
//         {activeTab === 2 && (
//           <div>
//             <span className="comment">Warning</span>
//             <textarea className="textarea"></textarea>
//           </div>
//         )}
//         <div className="btn-container">
//           <Send onClick={handleSend}>Send</Send>
//         </div>
//       </TabContent>
//     </>
//   );
// };
import React, { useState, useRef } from "react";
import { Modal, Box, Typography } from "@mui/material";
import { Main, TabContent, Tab, TabsWrapper, Send } from "./style";
import { IoMdClose } from "react-icons/io";
import VendorProfile from "assets/img/disputes-modal/Group 1707488248.svg";
import usehook from "./usehook";
import toastr from "toastr";
const CustomTabs = ({ disputeId, handleClose, Disputes }) => {
  // Receive disputeId as a prop
  const { imagePost, disputeModalPost } = usehook();
  const [activeTab, setActiveTab] = useState(0);
  const [completedTabs, setCompletedTabs] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedImagePaths, setUploadedImagePaths] = useState([]);
  const [comment, setComment] = useState("");
  // const [warning, setWarning] = useState("");
  const [status, setStatus] = useState("");
  const fileInputRef = useRef(null);

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    if (selectedImages.length + files.length > 5) {
      toastr.error("You can only upload up to 5 images.");
      return;
    }
    const imagePromises = files.map(async (file) => {
      setSelectedImages((prevImages) => [...prevImages, file]);

      const uploadedImagePath = await imagePost(file);
      if (uploadedImagePath) {
        setUploadedImagePaths((prevPaths) => [...prevPaths, uploadedImagePath]);
      }
    });

    await Promise.all(imagePromises);
  };

  const id = disputeId;

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input reference is null");
    }
  };

  const handleSend = () => {
    if (!completedTabs.includes(activeTab)) {
      setCompletedTabs([...completedTabs, activeTab]);
    }

    if (!comment && !status && uploadedImagePaths.length === 0) {
      toastr.error("Please provide data in at least one field.");
      return;
    }

    const formData = {
      comment, 
      status: status, 
      images: uploadedImagePaths, 
    };

    console.log("Prepared API data:", formData);

    disputeModalPost(disputeId, formData) 
      .then((response) => {
        console.log("Form successfully submitted:", response);
        handleClose();
        Disputes();
      })
      .catch((error) => {
        console.error("Error submitting the form:", error);
      });
  };

  
  const tabs = ["Comment", "Update Status"];
  return (
    <>
      <TabsWrapper>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </Tab>
        ))}
      </TabsWrapper>
      <TabContent>
        {activeTab === 0 && (
          <div>
            <div className="comment-container">
              <span className="comment">Comment </span>
              <textarea
                className="textarea"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <div className="image-container gap-2">
                <div className="selected-images mt-2 gap-2">
                  {uploadedImagePaths.map((path, index) => (
                    <img
                      key={index}
                      src={`${process.env.REACT_APP_BASE_URL}${path}`}
                      alt={`Uploaded ${index}`}
                      className="thumbnail"
                    />
                  ))}
                </div>
                <div className="mt-2" onClick={triggerFileInput}>
                  <img
                    src={VendorProfile}
                    alt="VendorProfile"
                    className="vendor-pic"
                  />
                  <input
                    ref={fileInputRef}
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <div className="select-conatiner">
              <select
                className="select-input"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="initiated">Initiated</option>
                <option value="resolved">Resolved</option>
                <option value="admin_review">Admin Review</option>
                <option value="returned">Returned</option>
              </select>
            </div>
          </div>
        )}
        {/* {activeTab === 2 && (
          <div>
            <span className="comment">Warning</span>
            <textarea
              className="textarea"
              value={warning}
              onChange={(e) => setWarning(e.target.value)}
            />
          </div>
        )} */}
        <div className="btn-container">
          <Send onClick={handleSend}>Send</Send>
        </div>
      </TabContent>
    </>
  );
};

const OrderDisputeModal = ({
  open,
  handleClose,
  disputesdetails,
  Disputes,
}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            border: "none",
            borderRadius: "20px 20px 10px 10px",
          }}
        >
          <Main>
            <Typography className="header">
              <Typography variant="h6" component="h2">
                Order Dispute
              </Typography>
              <IoMdClose className="close-icon" onClick={handleClose} />
            </Typography>
            <div className="container-body">
              <div className="row1">
                <div className="direction1">
                  <label className="label-color">Dispute ID</label>
                  <span className="customerlabel">{disputesdetails.id}</span>
                </div>
                <div className="direction1">
                  <label className="label-color">Dispute Date</label>
                  <span className="customerlabel">
                    {disputesdetails?.createdAt
                      ? formatDate(disputesdetails.createdAt)
                      : "N/A"}
                  </span>
                </div>
                <div className="direction1">
                  <label className="label-color">Dispute Amount</label>
                  <span className="customerlabel">
                    ${disputesdetails?.orderProduct?.itemPrice}
                  </span>
                </div>
              </div>
              <div className="row2">
                <div className="direction1">
                  <label className="label-color">Customer</label>
                  <span className="customerlabel">
                    {" "}
                    {disputesdetails?.order?.user?.name}
                  </span>
                </div>
                <div className="direction1">
                  <label className="label-color">Vendor</label>
                  <span className="customerlabel">
                    {" "}
                    {disputesdetails?.order?.vendor?.name}
                  </span>
                </div>
                <div className="direction1">
                  <label className="label-color">Product Name</label>
                  <span className="customerlabel dot-property">
                    {" "}
                    {disputesdetails?.orderProduct?.product?.name}
                  </span>
                </div>
              </div>
              <div>
                <div className="reason-container">
                  <span className="reason">Reason</span>
                  <span className="reason-field">
                    {disputesdetails?.reason}
                  </span>
                </div>
              </div>
              <div>
                <CustomTabs
                  disputeId={disputesdetails.id}
                  Getalldispute={disputesdetails}
                  handleClose={handleClose}
                  Disputes={Disputes}
                />
              </div>
            </div>
          </Main>
        </Box>
      </Modal>
    </>
  );
};
export default OrderDisputeModal;
