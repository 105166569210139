import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHook = () => {
  const GetwalletCards = async (body) => {
    try {
      const response = await AuthAPI.getWithDrawlCards(body);
      if (response) {
        // toastr.success('Collection get successfully');
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again Later");
      return null;
    }
  };

  const WithDrawRequest = async (amount, accountId) => {
    try {
      const response = await AuthAPI.WithdrawRequest({ amount, accountId });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return { GetwalletCards, WithDrawRequest };
};

export default useHook;
