import AuthRoutes from "../../../containers/auth/index";
import { Main } from "./style";
import AdminImg from "../../../assets/img/auth/sign screens.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AuthLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const requireVerification =
      localStorage.getItem("requireVerification") === "true";

    if (token) {
      // Check if the user still needs verification
      if (!requireVerification) {
        navigate("/"); // Only navigate to home if no verification is required
      } else {
        navigate("/auth/change-password"); // Redirect to change password page if verification is required
      }
    }
  }, [navigate]);

  // useEffect(() => {
  //   const isLoggedIn = localStorage.getItem("login");

  //   // If user is already logged in, redirect to the dashboard
  //   if (isLoggedIn === "true") {
  //     navigate("/");
  //   }
  // }, [navigate]);
  return (
    <Main>
      {/* <h2>Auth layout</h2> */}
      <div className="container-fluid ">
        <div className="row">
          <div class="col-lg-6 d-none d-lg-block  p-0 h-100">
            <img
              src={AdminImg}
              alt="Login Image"
              className="w-100 "
              style={{ height: "100vh" }}
            />
          </div>
          <div class="col-lg-6 col-12 p-0">
            <AuthRoutes />
          </div>
        </div>
      </div>
    </Main>
  );
};

export default AuthLayout;
