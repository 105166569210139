// import React, { useState } from "react";
// import { Main } from "./style";
// import Verify from "../../../assets/img/auth/verify.png";
// import { useNavigate } from "react-router-dom";
// const VerifyEmail = () => {
//   const Home = (state) => {
//     Home_Navigate("/auth", { state });
//   };
//   const [otp, setOtp] = useState(["", "", "", ""]);
//   const [error, setError] = useState("");
//   const Home_Navigate = useNavigate();

//   const handleOtpChange = (e, index) => {
//     const { value } = e.target;
//     if (/^[0-9]$/.test(value) || value === "") {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);
//       // Auto-focus on the next input field
//       if (value !== "" && index < 3) {
//         document.getElementById(`otp-input-${index + 1}`).focus();
//       }
//     }
//   };
//   return (
//     <Main>
//       <div>
//         <p className="yellow_border"></p>
//       </div>
//       <div className="text-center">
//         <img src={Verify} alt="Verify Img" className="verifyImg" />
//         <h4 className="heading mt-4">Verify your Email</h4>
//         <p className="welcome_Content">
//           Thank you, check your email for instructions to reset your <br />{" "}
//           password
//         </p>
//         <div className="text-center mt-4 gap">
//           {otp.map((value, index) => (
//             <input
//               key={index}
//               id={`otp-input-${index}`}
//               type="text"
//               value={value}
//               onChange={(e) => handleOtpChange(e, index)}
//               className="otp-input"
//               maxLength="1"
//             />
//           ))}

//           {error && <p className="errorText">{error}</p>}
//         </div>

//         <div>
//           <input type="email" name="" id="" className="input" placeholder="Enter Email " />
//         </div>

//         <div>
//           <button
//             type="submit"
//             className="btn SignIn_Button"
//             onClick={() => Home()}
//           >
//             Skip Now
//           </button>
//           <p className="recieve">
//             Don’t receive an email?<span className="resend"> Resend</span>
//           </p>
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default VerifyEmail;

import React, { useEffect, useState } from "react";
import { Main } from "./style";
import Verify from "../../../assets/img/auth/verify.png";
import { useNavigate } from "react-router-dom";
import useHooks from "../useHooks";
import toastr from "toastr";

const VerifyEmail = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { VerifyOtp } = useHooks();

  useEffect(() => {
    // Get the email from local storage when the component mounts
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []); // Empty dependency array means this effect runs only once after the component mounts

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // Auto-focus on the next input field
      if (value !== "" && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = async () => {
    const otpCode = otp.join("");

    // Basic validation
    if (otpCode.length !== 4) {
      setError("Please enter a valid 4-digit OTP.");
      return;
    }

    setError(""); // Clear any existing error messages

    // API call to verify OTP
    try {
      const response = await VerifyOtp({ otp: otpCode, email });
      console.log("response", response);
      if (response.status === true) {
        const token = response.data.token;
        // Save the token in local storage
        localStorage.setItem("authToken", token);

        // Show success notification
        toastr.success("OTP verified successfully!");

        // Navigate to the desired page upon successful verification
        navigate("/auth/resetpassword");
      } else {
        toastr.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      toastr.error("Failed to verify OTP. Please try again.");
    }
  };
  return (
    <Main>
      <div>
        <p className="yellow_border"></p>
      </div>
      <div className="text-center">
        <img src={Verify} alt="Verify Img" className="verifyImg" />
        <h4 className="heading mt-4">Verify your Email</h4>
        <p className="welcome_Content">
          Thank you, check your email for instructions to reset your <br />
          password
        </p>
        <div className="text-center mt-4 gap">
          {otp.map((value, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              value={value}
              onChange={(e) => handleOtpChange(e, index)}
              className="otp-input"
              maxLength="1"
            />
          ))}
        </div>
        {error && <p className="error">{error}</p>}

        {/* <div style={{ paddingBottom: " 20px" }}>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="input"
            placeholder="Enter Email"
          />
        </div> */}

        <div>
          <button
            type="submit"
            className="btn SignIn_Button"
            onClick={handleSubmit}
          >
            Verify OTP
          </button>
          {/* <p className="recieve">
            Don’t receive an email?<span className="resend"> Resend</span>
          </p> */}
        </div>
      </div>
    </Main>
  );
};

export default VerifyEmail;
