import { AuthAPI } from "libs/http-service/api/auth.api";

const useHooks = () => {
  const getOrders = async (params) => {
    try {
      
      const response = await AuthAPI.getOrders(params);
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const ChangeStatus = async (id,params) => {
    try {
      const response = await AuthAPI.changeOrderStatus(id,params);
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  return { getOrders, ChangeStatus };
};

export default useHooks;
