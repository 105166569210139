import { AuthAPI } from "libs/http-service/api/auth.api";

const useHooks = () => {
  const getProducts = async (params) => {
    try {
      const response = await AuthAPI.getproducts(params);
      // console.log(response,'')
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const putPublishProducts = async (id, isPublished) => {
    try {
      const response = await AuthAPI.putPublishproducts(id, { isPublished });
      console.log(response, 'Products publish successfully');
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const getcategory = async (params) => { 
    try {
      const response = await AuthAPI.getcategory(params);  
    //   console.log(response,'Category')
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  }; 
  const deleteProducts = async (id) =>{
    try{
      const response = await AuthAPI.ProductsDelete(id);
      return response;
    } catch (error) {
      console.log(error)
      return null;
    }
  }
  return { getProducts, putPublishProducts,getcategory,deleteProducts };
};

export default useHooks;
