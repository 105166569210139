import styled from "styled-components";

export const Main = styled.div`
.inputs-width{
    width: 673px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    padding: 13px;
    outline: none;
}
@media (max-width: 1280px) {
    .inputs-width {
     width: 100%;
    }
    .inputs-flex {
     flex-wrap: wrap;
    }
  }
.flex{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 20px;
  
}
/* .flex-wrap{
    display: flex;
    flex-wrap: wrap;
    width: 673px;
} */
.tag-background{
    background-color: #0C3A2D;
    color: white;
    border-radius: 6px;
    padding: 3px 7px;
    display: flex;
    align-items: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
}

`