import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Form, Title } from "./style";
import closeIcon from '../../../assets/img/report-modal/closeIcon.svg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "406px",
  height: "auto",
  bgcolor: "#FFFFFF",
  p: "25px",
  borderRadius: "30px",
  outline: "none",
  overflowY: "auto",
};
const styles = {
  position: "fixed",
  zIndex: " 1300",
  right: "0,",
  bottom: "0",
  top: "0",
  left: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  outline: "none",
}
const ReportModal = ({ onOpen, onClose }) => {

  const handleSubmit = () => {
    console.log("Report Comments Submitted");
  };
  const handleModalClose = () => {
      onClose(false);
  };

  const Data = [
    "User engaged in unacceptable behavior, including the use of derogatory language.",
    "Encountered verbal abuse from user in comments.",
    "User comment contains offensive or disrespectful language",
    "Received inappropriate language or abusive behavior from user.",
    "User comment includes harassing or threatening language.",
    "Other",
  ];
  return (
    <>
      <Modal
        open={onOpen}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
        sx={styles}
      >
        <Fade in={onOpen} >

          <Box sx={style}>
            <Title className="modal-head">
              <p className="modal-title">Report Comment</p>
              <img src={closeIcon} alt="CloseButton" onClick={onClose} className="close" />
            </Title>
            <Form>
              {Data.map((items) => (
                <div className="report-section-background">
                  <div className="flex-checkbox-report-text">
                    <input type="checkbox" />
                    <p className="report-text">{items}</p>
                  </div>
                </div>
              ))}

              <textarea className="report-section-textarea" placeholder="Write Your issue here..." />
              <button
                type="submit"
                id="form-submitButton"
                onClick={handleSubmit}
              >
                Send
              </button>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ReportModal;
