import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHook = () => {
  const GetAccounts = async () => {
    try {
      const response = await AuthAPI.getAccount();
      if (response) {
        // toastr.success('Collection get successfully');
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again Later");
      return null;
    }
  };
  const DeleteAccount = async (id) => {
    try {
      const response = await AuthAPI.deleteBankAccount(id);
      if (response) {
        toastr.success("Delete successfully");
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again Later");
      return null;
    }
  };
  return { GetAccounts, DeleteAccount };
};

export default useHook;
