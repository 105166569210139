import styled from "styled-components";

export const Title = styled.div`
    display: flex;
    text-align: center;
    justify-content: space-between;
padding-bottom: 15px;
    .modal-title {
    flex: 1;  
    text-align: center;
    font-size: 16px;
    font-family: inter;
    line-height: 24px;
    font-weight: 600;
letter-spacing: 0.2px;
color: #000000;
    }

    .close{
        font-size: 14px;
        font-weight: 600;
        color: #FFBB02;
        font-family: Plus Jakarta Sans;
        cursor: pointer;
    }
    `
export const Form = styled.form`
   display: flex;
   flex-direction: column;
  gap: 12px;
.modal-head{
   position: relative;
}
.selectAccount{
display: flex;
justify-content: space-between;
align-items: center;
}
.selectAccount-title,
 .selectAmmount-title{
    font-family:inter;
    font-weight:600;
    font-size:16px;
    line-height:25.6px;
    color: #1C1C27;
}
.selectAccount-selectTitle,
.selectAmmount-selectTitle{
    font-family:inter;
    font-weight:600;
    font-size:16px;
    line-height:25.6px;
    color: #FBAF43;
    text-align: right;
}
.selectAccount-inputField,
.selectAmmount-inputField
{
    width: 616px;
    height: 56px;
    border-radius: 5px;
    background-color: #F9F9F9;
    border: none;
    outline: none;
    background-color: #F9F9F9;
    padding:15px;
}
input::placeholder{
    font-family:inter;
    font-weight:500;
    font-size:16px;
    line-height:25.6px;
    color: #808D9E;
}
#form-submitButton{
    background-color:#156A3D;
    width: 100%;
    height: 56px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: inter;
    font-weight:700;
    line-height: 25.6px;
    text-align: center;
    color:#FFFFFF;
}
.report-section-background{
    background-color: #F9F9F9;
    width: 100%;
    height: 56px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
}
.report-text{
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    margin-left: 9px;
}
.flex-checkbox-report-text{
    display: flex;
    align-items: center;
}
.report-section-textarea{
    background-color: #F9F9F9;
    width: 100%;
    height: 110px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    border: 0;
    outline: none;
    font-size: 12px;
    font-family: Plus Jakarta Sans;
    font-weight: 500;
    color: #808D9E;
}
textarea{
    resize: none;
}

.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 21px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #FFBB02;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
`
