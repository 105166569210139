import styled from "styled-components";
export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  height: -webkit-fill-available;
  .heading-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .nav-pills .nav-link.active,
  .edagDK .nav-pills .show > .nav-link {
    color: black;
    background-color: #f9f9f9 !important;
  }

  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: black;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
  .img {
    width: 100%;
    height: 186px;
    border-radius: 10px;
  }
  /* .card-width {
    width: 32%;
    border: 0px;
    border-radius: 15px;
  } */

  .card-width {
    width: 24%;
    /* Adjusts to 3 cards per row on larger screens */
    border: 0px;
    border-radius: 15px;
    /* height: 276px; */
  }
  @media (max-width: 1163px) {
    .card-width {
      width: 48%; /* Adjusts to 2 cards per row on medium screens */
    }
  }
  @media (max-width: 1175px) {
    .card-width {
      width: 100%; /* Adjusts to 2 cards per row on medium screens */
    }
  }

  .sub-heading {
    font-family: Plus Jakarta sans;
    font-weight: 600;
    font-size: 14px;
    color: #808d9e;
  }
  .card-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    /* justify-content: space-between; */
  }
  .third {
    width: 326px;
    height: 92px;
    border: 1px solid #edf2f7;
  }
  .catalog-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 20px;
  }
  .catalog {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .category-btn {
    width: 218px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: 0px;
  }
  .img-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img-flex-2 {
    display: flex;
    padding-top: 6px;
    gap: 3px;
  }
  .pagination {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .heading-card {
    font-family: Plus Jakarta sans;
    font-weight: 600;
    font-size: 16px;
    /* color: #1C1C27; */
    letter-spacing: 0.2px;
    padding: 0px 0px;
  }
`;
export const Product = styled.div`
  .product-name {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  .product-count {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 14px;
    color: #808d9e;
  }
  .product-para {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    color: #0c3a2d;
  }
  .styled-font {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
  }
  .status {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .edit {
    width: 31px;
    height: 32px;
  }
  /* .img {
    width: 359px;
    height: 223px;
    border-radius: 12px;
  } */
  .flex-1 {
    width: 100%;
    height: 100%;
  }
  .transitional {
    padding: 0px 12px;
    width: auto;
    height: 28px;
    border-radius: 5px;
    background-color: #0c3a2d;
    font-family: Plus Jakarta Sans;
    font-weight: 500;
    font-size: 14px;
    color: white;
    border: 0px;
  }
  .flex-4 {
    display: flex;
    padding-bottom: 15%;
    gap: 15px;
  }
  .heading-card2 {
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }
  .sub-heading2 {
    font-family: Plus Jakarta sans;
    font-weight: 400;
    font-size: 14px;
    color: #808d9e;
  }
  .img-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .category-btn {
    width: 218px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: 0px;
  }
  .single-description {
    display: flex;
    flex-direction: row;
  }
  .singleCatalog-Image {
    height: 100px;
    border-radius: 8px;
  }
  .description-container {
    margin-left: 10px;
  }
`;
