import styled from "styled-components";

export const Main = styled.div`
  .create-button {
    width: 218px;
    height: auto;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
`;
export const ImgInput = styled.div`
  .add-img-input {
    width: 616px;
    height: 120px;
    border-radius: 14px;
    text-align: center;
    background-color: #f9f9f9;
    padding: 0px 0px 36px 0px;
    position: relative;
  }
  @media (max-width: 425px) {
    .add-img-input {
      width: 100%;
    }
  }
  .name {
    width: 616px;
    height: 40px;
    border-radius: 5px;
    outline: none;
    border: 0px;
    background: #f9f9f9;
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    /* color: #808D9E; */
    color: black;
    padding: 0px 15px;
  }
  @media (max-width: 425px) {
    .name {
      width: 100%;
    }
  }
  .text-area {
    width: 616px;
    border-radius: 5px;
    outline: none;
    border: 0px;
    background: #f9f9f9;
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    /* color: #808D9E; */
    color: black;
    padding: 10px 15px;
    resize: none;
  }
  @media (max-width: 425px) {
    .text-area {
      width: 100%;
    }
  }
  .create-btn {
    width: 616px;
    height: 56px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  @media (max-width: 425px) {
    .create-btn {
      width: 100%;
    }
  }

  .img-sizzing {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 120px;
    border-radius: 10px;
  }
  .error {
    color: red;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }
`;
