import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHook = () => {
  const PostAccount = async (accountName, accountNumber) => {
    try {
      const response = await AuthAPI.postAccount({accountName, accountNumber});
      if (response) {
        console.log(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { PostAccount };
};

export default useHook;
