import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  height: 100vh;
  .customer-list {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #1c1c27;
    padding-block: 20px;
  }

  .export-card {
    border: 0px;
    border-radius: 9px;
  }
  /* @media (max-width: 1063px) {
    .export-card {
        overflow-x: auto;
    }
} */
  .export-width {
    width: 15px;
    height: 15px;
  }
  .export-text {
    border: 1px solid #e2e8f0;
    width: 110px;
    height: 48px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }
  .input-width {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 15px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    outline: none;
  }
  @media (max-width: 1171px) {
    .input-width {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .search-button {
    width: 19%;
    height: 48px;
    border-radius: 5px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    border: 0px;
    background-color: #156a3d;
  }
  @media (max-width: 1171px) {
    .search-button {
      width: 100%;
    }
  }
  .view-width {
    width: 23px;
    height: 23px;
    cursor: pointer;
  }
  .Block-width {
    width: 70px;
    height: 24px;
    cursor: pointer;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
    align-items: center;
    color: #0c3a2d;
  }
  .filter-flex {
    display: flex;
    gap: 10px;
  }
  @media (max-width: 1171px) {
    .filter-flex {
      flex-wrap: wrap;
    }
  }
  .table-body {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    align-items: center;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
  }
  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 750px;
    }
  }
`;
