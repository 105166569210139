import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 15px;
  .modal-title {
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-family: inter;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #000000;
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .modal-head {
    position: relative;
  }
  .selectAccount {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .selectAccount-title,
  .selectAmmount-title {
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
    color: #1c1c27;
  }

  .selectAccount-inputField,
  .selectAmmount-inputField {
    width: 616px;
    height: 56px;
    border-radius: 5px;
    background-color: #f9f9f9;
    border: none;
    outline: none;
    background-color: #f9f9f9;
    padding: 15px;
  }
  @media (max-width: 425px) {
    .selectAccount-inputField,
    .selectAmmount-inputField {
      width: 100%;
    }
  }
  input::placeholder {
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 25.6px;
    color: #808d9e;
  }

  .sectionButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
  }
  @media (max-width: 425px) {
    .sectionButton{
     flex-direction: column;
    }
  }

  #cancelButton,
  #saveButton {
    width: 302px;
    height: 56px;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: inter;
    font-weight: 700;
    line-height: 25.6px;
    text-align: center;
  }
  @media (max-width: 425px) {
    #cancelButton,
    #saveButton{
     width: 100%;
    }
  }
  
  #cancelButton {
    background-color: #f9f9f9;
    color: #808d9e;
  }
  #saveButton {
    background-color: #156a3d;
    color: #ffffff;
  }
  .error-message {
    color: red;
    font-family: inter;
  }
`;
