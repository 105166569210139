import React from "react";
import { Main } from "./style";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useHooks from "../useHooks";
import toastr from "toastr";

const Forget = () => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const Forget_Navigate = useNavigate();
  const { ForgetPassword } = useHooks();

  // const handleSubmit = async (values) => {
  //   if (!values.email) {
  //     // Email is not filled, show validation errors
  //     console.log("Invalid fields. Show validation errors.");
  //   } else {
  //     try {
  //       localStorage.setItem("email", values.email);
  //       // Call the API to send the reset link
  //       await ForgetPassword({ email: values.email });
  //       toastr.success("OTP send successfully to mail");
  //       Forget_Navigate("/auth/verifyemail");

  //     } catch (error) {
  //       console.log("Error sending email: ", error);
  //     }
  //   }
  // };

  const handleSubmit = async (values) => {
    if (!values.email) {
      // Email is not filled, show validation errors
      toastr.error("Email is required.");
    }
    const role = "vendor";

    try {
      // Call the API to send the reset link
      const response = await ForgetPassword({ email: values.email, role });

      // Check if the API response indicates success
      if (response.status === true) {
        localStorage.setItem("email", values.email);
        toastr.success("OTP sent successfully to mail");
        Forget_Navigate("/auth/verifyemail");
      } else {
        // Handle errors based on the API response
        const errorMessage =
          response.errors || "An error occurred. Please try again.";
        toastr.error(errorMessage);
      }
    } catch (errors) {
      // Handle unexpected errors
      toastr.error("Error sending email: " + errors?.message);
    }
  };

  // const handleSubmit = async (values) => {
  //   if (!values.email) {
  //     // Email is not filled, show validation errors
  //     toastr.error("Email is required.");
  //     return;
  //   }

  //   try {
  //     // Call the API to send the reset link
  //     const response = await ForgetPassword({ email: values.email });

  //     // Check response status or structure for success or failure
  //     if (response?.status === true) {
  //       toastr.success("OTP sent successfully to your email.");
  //       Forget_Navigate("/auth/verifyemail");
  //     } else {
  //       // Handle failure based on the response structure
  //       // Show specific error message if it exists
  //       const errorMessage =
  //         response.errors && response.errors.length > 0
  //           ? response.errors.join(", ")
  //           : response.message || "Failed to send OTP. Please try again.";
  //       toastr.error(errorMessage);
  //     }
  //   } catch (error) {
  //     // Show error if an exception occurs
  //     console.error("Error sending email: ", error); // Log the error for debugging
  //     toastr.error("An unexpected error occurred. Please try again.");
  //   }
  // };

  const navigate = useNavigate();
  const SignIn = (state) => {
    navigate("/", { state });
  };

  return (
    <Main>
      <div>
        <p className="yellow_border"></p>
      </div>

      <div className="margin">
        <h4 className="heading">Reset your password</h4>
        <p className="welcome_Content">
          Enter the email address associated with your account and we will send
          you a Otp to reset your password.
        </p>
      </div>

      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="text-center">
              <Field
                type="email"
                name="email"
                className="input"
                placeholder="Email"
              />
              {errors.email && touched.email ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "start",
                    marginLeft: "20%",
                  }}
                >
                  {errors.email}
                </div>
              ) : null}
            </div>

            <div className="text-center mt-4">
              <button type="submit" className="btn SignIn_Button">
                Continue
              </button>
              <button
                type="submit"
                className=" back-signin"
                onClick={() => SignIn()}
              >
                Back to Sign In
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Main>
  );
};

export default Forget;
