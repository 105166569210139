import { AuthAPI } from 'libs/http-service/api/auth.api';

const useHooks = () => {
  const getProfile = async () => {
    try {
      const response = await AuthAPI.getProfile();
      return response; // Return the response
    } catch (error) {
      console.error(error);
      // alert(error.message);
      return null;
    }
  };

  
  
  return { getProfile};
};

export default useHooks;