import React, { useCallback, useEffect, useState } from "react";
import { Main } from "./style";
import EmailPic from "../../../assets/img/user-management/Email.png";
import group from "../../../assets/img/user-management/Group.png";
import map from "../../../assets/img/user-management/Maps and Flags.png";
import total from "../../../assets/img/user-management/total.png";
import cancel from "../../../assets/img/user-management/cancel.png";
import turn from "../../../assets/img/user-management/return.png";
import view from "../../../assets/img/user-management/view.svg";
import Stack from "@mui/material/Stack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import useHooks from "./useHook";
import PaginationRounded from "components/Pagination";
import Customer from "assets/img/customers/customer.png";
import Spiner from "components/spinner";

const CustomerOverview = ({ chatID }) => {
  const { id } = useParams();
  const { customersOrderDetailGet, OrdersGet } = useHooks();
  console.log("This is my route id", id);
  const location = useLocation();
  const {
    name,
    email,
    phone,
    profile_pic,
    addresses,
    country,
    city,
    state,
    zipCode,
  } = location.state || {};
  const customerPic = process.env.REACT_APP_MEDIA_URL + profile_pic;
  console.log("overall customer pic", customerPic);
  const navigate = useNavigate();

  const ThirdCard = styled.div``;
  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    // Update select element's style
    event.target.style.backgroundColor = selectedOption.backgroundColor;
    event.target.style.color = selectedOption.color;
  };
  const options = [
    {
      value: "processing",
      label: "Processing",
      backgroundColor: "#00D1FF1A",
      color: "#00D1FF",
    },
    {
      value: "cancel",
      label: "Cancel",
      backgroundColor: "#FF00001A",
      color: "#FF0000",
    },
    {
      value: "delivered",
      label: "Delivered",
      backgroundColor: "#209A421A",
      color: "#209A42",
    },
    {
      value: "pending",
      label: "Pending",
      backgroundColor: "#FF8F501A",
      color: "#FF8F50",
    },
  ];

  // get order data
  const [orderlist, setorderlist] = useState(null);
  const fetchOrderNumberDetail = async () => {
    try {
      const response = await customersOrderDetailGet(id);
      const orderDetail = response.data;
      // console.log('data detail is', orderDetail)
      setorderlist(orderDetail);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchOrderNumberDetail();
  }, []);

  const [orderhistory, setOrderHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  // additional parameter
  const [invoiceNumber, setinvoiceNumber] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [status, setStatus] = useState(null);

  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [noDataFound, setNoDataFound] = useState(false);

  const getOrders = async (resetPage = false) => {
    const userId = parseInt(id);
    const page = resetPage ? 1 : currentPage;
    const perPage = 10;

    try {
      const response = await OrdersGet(
        userId,
        invoiceNumber,
        startDate,
        endDate,
        status,
        page,
        perPage
      );
      if (response.data.results.length === 0) {
        setNoDataFound(true); // Set noDataFound state if no results
      } else {
        setOrderHistory(response.data.results);
        setTotalPage(Math.ceil(response.data.counts / perPage));
        setNoDataFound(false); // Reset noDataFound state if results found
      }
    } catch (error) {
      // alert("Error fetching orders");
      setNoDataFound(false);
    } finally {
      setLoading(false);
    }

    console.log("invoice number", invoiceNumber);
    console.log("start Date", startDate);
    console.log("end date", endDate);
    console.log("Status", status);
  };

  useEffect(() => {
    getOrders();
  }, [currentPage]);

  const handleFilter = () => {
    setcurrentPage(1);
    getOrders(true);
  };
  // Function to handle reset button
  const handleReset = useCallback(() => {
    // e.preventDefault();
    setinvoiceNumber("");
    setStatus("");
    setstartDate("");
    setendDate("");

    setTimeout(() => {
      getOrders();
    }, 0);
  }, [setinvoiceNumber, setStatus, setstartDate, setendDate]);

  // data transfer to invoice
  const UserInvoice = (itemId) => {
    console.log("order history", orderhistory);
    navigate(`/customers/overview/${id}/user-invoice`, {
      state: {
        id: id,
        orderhistory: orderhistory,
        itemId: itemId,
      },
    });
  };
  const handleStartDateChange = (date) => {
    const selectedStartDate = new Date(date);

    if (endDate && selectedStartDate > new Date(endDate)) {
      setendDate(null);
    }

    setstartDate(date);
  };

  const handleEndDateChange = (date) => {
    const selectedEndDate = new Date(date);

    if (startDate && selectedEndDate < new Date(startDate)) {
      alert("End date cannot be earlier than start date");
      return;
    }

    setendDate(date);
  };

  // date conversion
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };

  const goToChat = () => {
    navigate(`/chat-managment`);
  };
  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="background-color">
          <div className="container-fluid">
            <div className="heading-flex">
              <p className="heading m-0">
                Customers Overview - Customer Details
              </p>
            </div>
            {/* REACT_APP_MEDIA_URL */}
            <div className="card border-0">
              <div className="card-body">
                <div className="items">
                  <div className="d-flex justify-content-start align-items-center">
                    <img
                      src={customerPic ? customerPic : Customer}
                      alt=""
                      className="img-width"
                    />
                    <div className="ms-3">
                      <p className="m-0 style-name">{name}</p>
                      <p className="m-0 style-font">
                        <img src={EmailPic} alt="email" />
                        {email}
                      </p>
                      <p className="m-0 style-font">
                        <img src={group} alt="" /> {phone}
                      </p>
                      <p className="m-0 style-font">
                        <img src={map} alt="map" />
                        {addresses},{city},{state},{country},{zipCode}
                      </p>
                    </div>
                  </div>
                  <div className="display">
                    <button
                      type="submit"
                      className="message"
                      onClick={() => goToChat(chatID)}
                    >
                      Message
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <p className="heading m-0">Order Details</p>
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <div className="nav-link p-0 me-2 active" aria-selected="true">
                  <ThirdCard>
                    <div className="cards">
                      <div className="card-body">
                        <div className="third-flex">
                          <img src={total} alt="img" className="total-img" />
                          <div className="margin">
                            <p className="m-0 order-para">Total Order</p>
                            <p className="m-0 order-number">
                              {orderlist ? orderlist.totalOrders : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ThirdCard>
                </div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link p-0 me-2 active" aria-selected="true">
                  <ThirdCard>
                    <div className="cards ">
                      <div className="card-body">
                        <div className="third-flex">
                          <img src={cancel} alt="img" className="cancel-img" />
                          <div className="margin">
                            <p className="m-0 order-para">Order Cancel</p>
                            <p className="m-0 order-number">
                              {orderlist ? orderlist.cancelledCount : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ThirdCard>
                </div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link p-0 active" aria-selected="true">
                  <ThirdCard>
                    <div className="cards ">
                      <div className="card-body">
                        <div className="third-flex">
                          <img src={turn} alt="img" className="return-img" />
                          <div className="margin">
                            <p className="m-0 order-para">Order Returns</p>
                            <p className="m-0 order-number">
                              {orderlist ? orderlist.returnedCount : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ThirdCard>
                </div>
              </li>
            </ul>

            <div className="tab-content" style={{ cursor: "pointer" }}>
              <div className="tab-pane fade show active">
                <div>
                  <p className="heading m-0">Order History</p>
                </div>
                <div className="card border-0 flex mb-3">
                  <div className="card-body">
                    <div className="filters-flex">
                      <div className="mt-4 width">
                        <input
                          type="number"
                          className="input2 me-2"
                          placeholder="Search by Id"
                          name="id"
                          value={invoiceNumber}
                          onChange={(e) => setinvoiceNumber(e.target.value)}
                        />
                      </div>
                      <div className="width">
                        <select
                          className="select1 me-2 mt-4"
                          name="status"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="">Status</option>
                          <option value="pending">Pending</option>
                          <option value="processing">Processing</option>
                          <option value="delivered">Delivered</option>
                          <option value="declined">Declined</option>
                          <option value="shipped">Shipped</option>
                          <option value="returned">Returned</option>
                          <option value="cancelled">Cancelled</option>
                        </select>
                      </div>
                      <div className="width">
                        <label className="date">Start Date</label> <br />
                        <input
                          type="date"
                          className="date-input"
                          id=""
                          name="startDate"
                          value={startDate || ""}
                          onChange={(e) =>
                            handleStartDateChange(e.target.value)
                          }
                        />
                      </div>
                      <div className="width">
                        <label className="date">End Date</label> <br />
                        <input
                          type="date"
                          className="date-input"
                          id=""
                          name="endDate"
                          value={endDate || ""}
                          onChange={(e) => handleEndDateChange(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="buttons-end">
                      <button
                        type="submit"
                        className="filter"
                        onClick={handleFilter}
                      >
                        Filter
                      </button>

                      <button
                        type="submit"
                        className="reset"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card border-0">
                  <div className="card-body">
                    {noDataFound ? (
                      <div className="no-record">No Record Found</div>
                    ) : (
                      <>
                        <div className="table-responsive">
                          <table className="table-width">
                            <thead>
                              <tr className="flex table-head">
                                <th>Invoice</th>
                                <th>Order Time</th>
                                {/* <th>Method</th> */}
                                <th>Amount</th>
                                <th>Rating</th>
                                <th>Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody className="waleed-margin">
                              {orderhistory.length === 0 ? (
                                <tr>
                                  <td
                                    colSpan="6"
                                    style={{
                                      textAlign: "center",
                                      padding: "15px",
                                    }}
                                  >
                                    No data found
                                  </td>
                                </tr>
                              ) : (
                                orderhistory.map((items) => (
                                  <tr
                                    className="mt-3 flex2 style-font2"
                                    style={{ paddingTop: "10%" }}
                                  >
                                    <td style={{ paddingBlock: "15px" }}>
                                      {items.id}
                                    </td>
                                    <td style={{ paddingBlock: "15px" }}>
                                      {formatDate(items.createdAt)}
                                    </td>
                                    {/* <td style={{ paddingBlock: '15px' }} >{items.Method}</td> */}
                                    <td style={{ paddingBlock: "15px" }}>
                                      {items.totalAmount}
                                    </td>
                                    <td style={{ paddingBlock: "15px" }}>
                                      <span className="rating-number">
                                        {" "}
                                        <img src={items.Rating} alt="" />{" "}
                                        {items.user.numberOfRattings}
                                      </span>
                                    </td>
                                    <td
                                      style={{
                                        paddingBlock: "15px",
                                        width: "10%",
                                      }}
                                    >
                                      <p
                                        className={`status-${
                                          items.status === "processing"
                                            ? "Processing"
                                            : items.status === "delivered"
                                            ? "delivered"
                                            : items.status === "cancelled"
                                            ? "cancel"
                                            : "pending"
                                        } m-0`}
                                      >
                                        {items.status}
                                      </p>
                                    </td>
                                    <td style={{ paddingBlock: "15px" }}>
                                      {" "}
                                      <img
                                        src={view}
                                        onClick={() => UserInvoice(items.id)}
                                        className="view-icon"
                                      />
                                      &nbsp;
                                      {/* <img
                                      src={copy}
                                      className="copy-icon"
                                    />{" "} */}
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <PaginationRounded
                            count={totalPage}
                            page={currentPage}
                            onChange={handlePageChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Main>
  );
};

export default CustomerOverview;
