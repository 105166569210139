import React, { useEffect, useState } from "react";
import { Main, SearchCard, Table, Tabs } from "./style";
import Img1 from "../../../assets/img/chat-mangment/img1.png";
import Img2 from "../../../assets/img/chat-mangment/img2.png";
import Img3 from "../../../assets/img/chat-mangment/img3.png";
import Img4 from "../../../assets/img/chat-mangment/img4.png";
import Block from "../../../assets/img/chat-mangment/block.png";
import View from "../../../assets/img/chat-mangment/view.png";
import Delete from "../../../assets/img/chat-mangment/delete.png";
import Unblock from "../../../assets/img/chat-mangment/unblock.png";
import PaginationRounded from "components/Pagination";
import { useNavigate } from "react-router-dom";
import {
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import toastr from "toastr";
import Spiner from "components/spinner";
const ChatManagment = () => {
  const navigate = useNavigate();

  const Chat = (customerId) => {
    navigate(`/chat-managment/chat/${customerId}`);
  };

  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [blockedData, setBlockedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userId = localStorage.getItem("id");

  // Function to fetch chat data

  // getFirebaseChats();
  // const getFirebaseChats = async () => {
  //   try {
  //     // console.log("Fetching chats...");
  //     const snapshot = await getDocs(collection(db, "chat"));
  //     // console.log(
  //     //   "Snapshot data:",
  //     //   snapshot.docs.map((doc) => doc.data())
  //     // );

  //     const customers = [];
  //     const blocked = [];
  //     const messageCount = {}; // To track message counts per customer

  //     snapshot.forEach((doc) => {
  //       const data = doc.data();

  //       if (data.lastMessage) {
  //         const latestMessage = data.lastMessage;
  //         const senderId = latestMessage.userId;

  //         // Increment message count for the senderId
  //         if (senderId in messageCount) {
  //           messageCount[senderId]++;
  //         } else {
  //           messageCount[senderId] = 1;
  //         }

  //         if (data.block?.block) {
  //           blocked.push({
  //             ...data,
  //             id: doc.id,
  //             latestMessageTime: latestMessage.createdOn,
  //             senderId,
  //             senderName:
  //               data.myInfo.id === senderId
  //                 ? data.myInfo.name
  //                 : data.otherUser?.name || "Unknown",
  //             messageCount: messageCount[senderId], // Add messageCount
  //           });
  //         } else {
  //           customers.push({
  //             ...data,
  //             id: doc.id,
  //             latestMessageTime: latestMessage.createdOn,
  //             senderId,
  //             senderName:
  //               data.myInfo.id === senderId
  //                 ? data.myInfo.name
  //                 : data.otherUser?.name || "Unknown",
  //             messageCount: messageCount[senderId], // Add messageCount
  //           });
  //         }
  //       } else {
  //         if (data.block?.block) {
  //           blocked.push({ ...data, id: doc.id });
  //         } else {
  //           customers.push({ ...data, id: doc.id });
  //         }
  //       }
  //     });

  //     customers.sort((a, b) => b.latestMessageTime - a.latestMessageTime);

  //     setCustomerData(customers);
  //     setBlockedData(blocked);

  //     // Log the number of messages per customer
  //     // console.log("Number of messages per customer:");
  //     for (const [senderId, count] of Object.entries(messageCount)) {
  //       // console.log(`Customer ID ${senderId}: ${count} messages`);
  //     }

  //     const userId = localStorage.getItem("id");
  //     // console.log("User ID:", userId);
  //   } catch (error) {
  //     console.error("Error fetching chats:", error);
  //   }
  // };

  // const getFirebaseChats = async () => {
  //   try {
  //     const vendorId = Number(localStorage.getItem("id")); // Ensure this is the vendor's ID
  //     if (!vendorId) {
  //       console.error("No vendor ID found in localStorage");
  //       return;
  //     }

  //     const snapshot = await getDocs(collection(db, "chat"));

  //     const customers = [];
  //     const blocked = [];
  //     const messageCount = {}; // To track message counts per customer

  //     snapshot.forEach((doc) => {
  //       const data = doc.data();

  //       // Check if the chat is relevant to the current vendor
  //       if (data.myInfo.id === vendorId || data.otherUser.id === vendorId) {
  //         if (data.lastMessage) {
  //           const latestMessage = data.lastMessage;
  //           const senderId = latestMessage.userId;

  //           // Increment message count for the senderId
  //           if (senderId in messageCount) {
  //             messageCount[senderId]++;
  //           } else {
  //             messageCount[senderId] = 1;
  //           }

  //           if (data.block?.block) {
  //             blocked.push({
  //               ...data,
  //               id: doc.id,
  //               latestMessageTime: latestMessage.createdOn,
  //               senderId,
  //               senderName:
  //                 data.myInfo.id === senderId
  //                   ? data.myInfo.name
  //                   : data.otherUser?.name || "Unknown",
  //               messageCount: messageCount[senderId], // Add messageCount
  //             });
  //           } else {
  //             customers.push({
  //               ...data,
  //               id: doc.id,
  //               latestMessageTime: latestMessage.createdOn,
  //               senderId,
  //               senderName:
  //                 data.myInfo.id === senderId
  //                   ? data.myInfo.name
  //                   : data.otherUser?.name || "Unknown",
  //               messageCount: messageCount[senderId], // Add messageCount
  //             });
  //           }
  //         } else {
  //           if (data.block?.block) {
  //             blocked.push({ ...data, id: doc.id });
  //           } else {
  //             customers.push({ ...data, id: doc.id });
  //           }
  //         }
  //       }
  //     });

  //     customers.sort((a, b) => b.latestMessageTime - a.latestMessageTime);

  //     setCustomerData(customers);
  //     setBlockedData(blocked);
  //   } catch (error) {
  //     console.error("Error fetching chats:", error);
  //   }
  // };

  // console.log(customerData);

  const getFirebaseChats = () => {
    const vendorId = Number(localStorage.getItem("id"));
    if (!vendorId) {
      console.error("No vendor ID found in localStorage");
      return;
    }

    const chatCollection = collection(db, "chat");

    const unsubscribe = onSnapshot(chatCollection, (snapshot) => {
      const customers = [];
      const blocked = [];
      const messageCount = {};
      let unreadCounterTemp = 0;

      snapshot.forEach((doc) => {
        const data = doc.data();

        if (data.myInfo.id === vendorId || data.otherUser.id === vendorId) {
          if (data.lastMessage) {
            const latestMessage = data.lastMessage;
            const senderId = latestMessage.userId;

            if (!latestMessage.isRead.includes(vendorId)) {
              unreadCounterTemp++;
              console.log(
                `Unread message from ${data.otherUser.name || "Unknown"}`
              );
            }

            if (senderId in messageCount) {
              messageCount[senderId]++;
            } else {
              messageCount[senderId] = 1;
            }

            if (data.block?.block) {
              blocked.push({
                ...data,
                id: doc.id,
                latestMessageTime: latestMessage.createdOn,
                senderId,
                senderName:
                  data.myInfo.id === senderId
                    ? data.myInfo.name
                    : data.otherUser?.name || "Unknown",
                messageCount: messageCount[senderId],
              });
            } else {
              customers.push({
                ...data,
                id: doc.id,
                latestMessageTime: latestMessage.createdOn,
                senderId,
                senderName:
                  data.myInfo.id === senderId
                    ? data.myInfo.name
                    : data.otherUser?.name || "Unknown",
                messageCount: messageCount[senderId],
              });
            }
          } else {
            if (data.block?.block) {
              blocked.push({ ...data, id: doc.id });
            } else {
              customers.push({ ...data, id: doc.id });
            }
          }
        }
      });

      customers.sort((a, b) => b.latestMessageTime - a.latestMessageTime);

      setCustomerData(customers);
      setBlockedData(blocked);

      console.log("Final unreadCounter:", unreadCounterTemp);
    });

    return unsubscribe; // Return unsubscribe function to stop listening when needed
  };

  // Export the function at the top level

  // filter

  // const handleSearch = () => {
  //   const searchLower = searchTerm.toLowerCase();
  //   console.log("Search Term:", searchLower); // Log the search term

  //   if (searchLower === "") {
  //     // If search term is empty, fetch all chat data
  //     getFirebaseChats();
  //     return; // Exit the function to avoid filtering
  //   }

  //   const filteredCustomers = customerData.filter((customer) => {
  //     // Using myInfo.name as displayed in your component
  //     const name = customer.myInfo?.name
  //       ? customer.myInfo.name.toLowerCase()
  //       : "";

  //     console.log("Customer Name:", name);

  //     return name.includes(searchLower);
  //   });
  //   // const vendorId = localStorage.getItem("id");
  //   // const filteredCustomers = customerData.filter((customer) => {
  //   //   // Ensure the displayed chat data matches the current vendor's context
  //   //   const isVendorRelated =
  //   //     customer.myInfo.id === vendorId || customer.otherUser.id === vendorId;
  //   //   return isVendorRelated;
  //   // });

  //   console.log("Filtered Customers:", filteredCustomers); // Log the filtered customers

  //   setCustomerData(filteredCustomers);
  // };
  // useEffect(() => {
  //   getFirebaseChats();
  // }, []);

  useEffect(() => {
    // Fetch all chats on component mount
    getFirebaseChats();
  }, []); // Run once on mount

  const handleSearch = () => {
    const searchLower = searchTerm.trim().toLowerCase(); // Trim to avoid spaces issue
    console.log("Search Term:", searchLower);

    if (searchLower === "") {
      console.log("Search term is empty, fetching all chats.");
      getFirebaseChats(); // Fetch all chats if the search term is empty
      return;
    }

    const filteredCustomers = customerData.filter((customer) => {
      const name = customer.myInfo?.name?.toLowerCase() || "";
      console.log("Customer Name:", name);
      return name.includes(searchLower);
    });

    console.log("Filtered Customers:", filteredCustomers);
    setCustomerData(filteredCustomers);
  };

  useEffect(() => {
    if (searchTerm === "") {
      getFirebaseChats();
    }
  }, [searchTerm]); // Run when searchTerm changes

  // Fetch chat data on component mount or when the user ID changes
  // useEffect(() => {
  //   if (!userId) {
  //     console.error("No vendorId found in localStorage");
  //     return;
  //   }

  //   setCustomerData([]);
  //   setBlockedData([]);
  //   getFirebaseChats();
  // }, [userId]);
  useEffect(() => {
    const vendorId = Number(localStorage.getItem("id"));
    if (vendorId) {
      setCustomerData([]);
      setBlockedData([]);
      getFirebaseChats();
    } else {
      console.error("No vendor ID found in localStorage");
    }
  }, [userId]); // Add vendor ID dependency if needed

  const markMessagesAsRead = async (chatId, userId) => {
    try {
      const chatDocRef = doc(db, "chat", chatId);
      const chatDoc = await getDoc(chatDocRef);

      if (chatDoc.exists()) {
        const data = chatDoc.data();
        const isReadList = Array.isArray(data.lastMessage.isRead)
          ? data.lastMessage.isRead
          : [];

        if (!isReadList.includes(userId)) {
          const updatedIsRead = [...isReadList, userId];

          await updateDoc(chatDocRef, {
            "lastMessage.isRead": updatedIsRead,
          });

          console.log(`Marked messages as read for chat ID ${chatId}`);
          console.log("Updated isRead:", updatedIsRead);
        } else {
          console.log(
            `User ${userId} has already marked messages as read for chat ID ${chatId}`
          );
        }
      } else {
        console.error(`Chat document with ID ${chatId} does not exist.`);
      }
    } catch (error) {
      console.error("Error marking messages as read:", error);
    }
  };

  const openChat = (chatId) => {
    const userId = Number(localStorage.getItem("id")); // Convert user ID to a number

    markMessagesAsRead(chatId, userId)
      .then(() => {
        getFirebaseChats(); // Re-fetch data to reflect the updated status
      })
      .catch((error) => {
        console.error("Error in openChat:", error);
      });

    // Your existing logic to open the chat
  };

  // Function to unblock a user
  const unblockUser = async (chatId) => {
    try {
      const chatRef = doc(db, "chat", chatId);
      await updateDoc(chatRef, {
        block: {
          block: false,
          id: parseInt(userId),
        },
        modifiedOn: Date.now(),
      });
      toastr.success("User unblocked successfully");
      return true;
    } catch (e) {
      console.error("Error unblocking user:", e.message);
      return false;
    }
  };

  // Handle unblock click and refresh chats
  const handleUnblockClick = async (chatId) => {
    const success = await unblockUser(chatId);
    if (success) {
      console.log("User unblocked successfully");
      await getFirebaseChats(); // Refresh chat data after unblock
    } else {
      console.error("Failed to unblock user");
    }
  };

  // Function to block a user
  const blockUser = async (chatId) => {
    try {
      const chatRef = doc(db, "chat", chatId);
      await updateDoc(chatRef, {
        block: {
          block: true,
          id: parseInt(userId),
        },
        modifiedOn: Date.now(),
      });
      toastr.success("User blocked successfully");
      return true;
    } catch (e) {
      console.error("Error blocking user:", e.message);
      return false;
    }
  };

  // Handle block click and refresh chats
  const handleClick = async (chatId) => {
    const success = await blockUser(chatId);
    if (success) {
      console.log("User blocked successfully");
      await getFirebaseChats(); // Refresh chat data after block
    } else {
      console.error("Failed to block user");
    }
  };

  const customerId = customerData[0]?.myInfo?.id;

  localStorage.setItem("customerId", customerId);

  ///////////////       DELETE MESSGAES       ///////////////////

  const deleteMessage = async (chatId, userId) => {
    const db = getFirestore();
    let result = false;

    if (typeof userId !== "number" && typeof userId !== "string") {
      console.error("Invalid userId:", userId);
      return result;
    }

    try {
      const messagesRef = collection(db, "chat", chatId, "messages");
      const snapshot = await getDocs(messagesRef);

      console.log(`Found ${snapshot.size} messages to process.`);

      for (const docSnapshot of snapshot.docs) {
        try {
          console.log(`Deleting document ${docSnapshot.id}`);
          setLoading(true);
          await deleteDoc(docSnapshot.ref); // Physically delete the document
          console.log(`Document ${docSnapshot.id} deleted.`);
          setLoading(true);
        } catch (e) {
          console.error(`Failed to delete document ${docSnapshot.id}:`, e);
        }
      }

      console.log(`Updating lastMessage document for chatId ${chatId}`);
      setLoading(true);
      await setDoc(
        doc(db, "chat", chatId),
        {
          lastMessage: {
            isDeleted: arrayUnion(userId),
          },
          modifiedOn: Date.now(),
        },
        { merge: true }
      );

      console.log(`Last message document for chatId ${chatId} updated.`);
      result = true;
    } catch (e) {
      console.error("Error deleting messages:", e);
    }

    return result;
  };

  // Usage in your component
  const handleDeleteClick = async (chatId, customerId) => {
    if (!customerId) {
      console.error("customerId is not defined");
      return;
    }

    // Convert customerId to a number if necessary
    customerId = Number(customerId);
    if (isNaN(customerId)) {
      console.error("customerId is not a valid number");
      return;
    }

    const success = await deleteMessage(chatId, customerId);
    try {
      if (success) {
        console.log("Messages deleted successfully");
      } else {
        console.error("Failed to delete messages");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid pb-5">
          <div className="heading-flex">
            <div className="heading">
              <p>Chat Management</p>
            </div>
          </div>
          <SearchCard>
            <div className="card export-card mt-3">
              <div className="card-body">
                <div className="input-flex">
                  <input
                    type="search"
                    placeholder="Search by Name"
                    className="input-width"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="search-button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </SearchCard>

          <Tabs>
            <ul
              className="nav nav-tabs mt-3 dispute-font"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item padding-dispute" role="presentation">
                <button
                  className="nav-link active dispute-font"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  Customers
                </button>
              </li>
              <li className="nav-item padding-dispute" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  Blocked
                </button>
              </li>
            </ul>
            <div className="tab-content dispute-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabIndex="0"
              >
                <div className="card border-0 card-border mb-5">
                  <div className="card-body">
                    <Table>
                      <div className="table-responsive">
                        <table className="table-width">
                          <thead>
                            <tr className="table-heading">
                              <th style={{ width: "10%" }}>Sr No.</th>
                              <th style={{ width: "18%" }}>Created On</th>
                              <th style={{ width: "12%" }}>Image</th>
                              <th style={{ width: "48%" }}>Customer Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customerData.length > 0 ? (
                              customerData.map((item, index) => (
                                <tr
                                  key={item.id}
                                  className="table-row"
                                  onClick={() => openChat(item.id)}
                                >
                                  <td
                                    style={{ paddingBlock: "13px" }}
                                    className={`customer-item ${
                                      item.latestMessageTime &&
                                      !item.lastMessage.isRead.includes(
                                        Number(localStorage.getItem("id"))
                                      ) // Convert to number for comparison
                                        ? "new-message"
                                        : ""
                                    }`}
                                    onClick={() => Chat(item.id)}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    style={{ paddingBlock: "13px" }}
                                    className={`customer-item ${
                                      item.latestMessageTime &&
                                      !item.lastMessage.isRead.includes(
                                        Number(localStorage.getItem("id"))
                                      ) // Convert to number for comparison
                                        ? "new-message"
                                        : ""
                                    }`}
                                    onClick={() => Chat(item.id)}
                                  >
                                    {new Date(
                                      item.createdOn
                                    ).toLocaleDateString()}
                                  </td>
                                  <td
                                    style={{ paddingBlock: "13px" }}
                                    className={`customer-item ${
                                      item.latestMessageTime &&
                                      !item.lastMessage.isRead.includes(
                                        Number(localStorage.getItem("id"))
                                      ) // Convert to number for comparison
                                        ? "new-message"
                                        : ""
                                    }`}
                                    onClick={() => Chat(item.id)}
                                  >
                                    <img
                                      src={`${item.myInfo.profile_pic || Img1}`}
                                      alt="profile"
                                      style={{
                                        width: "42px",
                                        height: "42px",
                                        borderRadius: "25px",
                                      }}
                                    />
                                  </td>

                                  {/* <td
                                  style={{ paddingBlock: "13px" }}
                                  className={`customer-item ${
                                    item.latestMessageTime ? "new-message" : ""
                                  }`}
                                >
                                  {item.myInfo ? item.myInfo.name : ""}{" "}
                                  {item.latestMessageTime && (
                                    <span>
                                      New Message from {item.senderName}
                                    </span>
                                  )}
                                </td> */}
                                  {/* <td
                                  style={{ paddingBlock: "13px" }}
                                  className={`customer-item ${
                                    item.latestMessageTime &&
                                    !item.lastMessage.isRead.includes(
                                      localStorage.getItem("id")
                                    )
                                      ? "new-message"
                                      : ""
                                  }`}
                                >
                                  {item.myInfo
                                    ? item.myInfo.name
                                    : "Unknown Customer"}
                                  {/* {item.latestMessageTime && (
                                    <span>
                                      {" "}
                                      - New Message from {item.senderName}
                                      {item.messageCount !== undefined &&
                                        item.messageCount > 0 && (
                                          <span>
                                            {" "}
                                            ({item.messageCount} messages)
                                          </span>
                                        )}
                                    </span>
                                  )} 
                                </td> */}
                                  <td
                                    style={{ paddingBlock: "13px" }}
                                    onClick={() => Chat(item.id)}
                                    className={`customer-item ${
                                      item.latestMessageTime &&
                                      !item.lastMessage.isRead.includes(
                                        Number(localStorage.getItem("id"))
                                      ) // Convert to number for comparison
                                        ? "new-message"
                                        : ""
                                    }`}
                                  >
                                    {item.myInfo
                                      ? item.myInfo.name.toLowerCase()
                                      : "Unknown Customer"}
                                  </td>

                                  <td style={{ paddingBlock: "13px" }}>
                                    <img
                                      src={View}
                                      alt="view"
                                      onClick={() => Chat(item.id)}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <img
                                      src={Delete}
                                      alt="delete"
                                      className="mx-2"
                                      onClick={() =>
                                        handleDeleteClick(item.id, customerId)
                                      }
                                      style={{ cursor: "pointer" }} // Optional: changes cursor to pointer on hover
                                    />
                                    <img
                                      src={Block}
                                      alt="block"
                                      onClick={() => handleClick(item.id)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <p>No customers found</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Table>
                    <div>{/* <PaginationRounded /> */}</div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabIndex="0"
              >
                <div className="card border-0 card-border mb-5">
                  <div className="card-body">
                    <Table>
                      <div className="table-responsive">
                        <table className="table-width">
                          <thead>
                            <tr className="table-heading">
                              <th style={{ width: "10%" }}>Sr No.</th>
                              <th style={{ width: "18%" }}>Created On</th>
                              <th style={{ width: "12%" }}>Image</th>
                              <th style={{ width: "48%" }}>Customer Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {blockedData.length > 0 ? (
                            blockedData.map((item, index) => (
                              <tbody>
                                <tr key={item.id} className="table-row">
                                  <td style={{ paddingBlock: "13px" }}>
                                    {index + 1}
                                  </td>
                                  <td style={{ paddingBlock: "13px" }}>
                                    {new Date(
                                      item.createdOn
                                    ).toLocaleDateString()}
                                  </td>
                                  <td style={{ paddingBlock: "13px" }}>
                                    <img
                                      src={`${item.myInfo.profile_pic || Img1}`}
                                      alt="profile"
                                      style={{
                                        width: "42px",
                                        height: "42px",
                                        borderRadius: "25px",
                                      }}
                                    />
                                  </td>
                                  <td style={{ paddingBlock: "13px" }}>
                                    {item.myInfo.name}
                                  </td>
                                  <td style={{ paddingBlock: "13px" }}>
                                    {/* <img
                                  src={View}
                                  alt="view"
                                  onClick={() => Chat(item.id)}
                                  style={{ cursor: "pointer" }}
                                /> */}
                                    {/* <img
                                  src={Delete}
                                  alt="delete"
                                  className="mx-2"
                                /> */}
                                    <img
                                      src={Unblock}
                                      alt="unblock"
                                      onClick={() =>
                                        handleUnblockClick(item.id)
                                      } // Call unblock function here
                                      style={{ cursor: "pointer" }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            ))
                          ) : (
                            <div> No blocked users found </div>
                          )}
                        </table>
                      </div>
                    </Table>
                    <div>{/* <PaginationRounded /> */}</div>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      )}
    </Main>
  );
};

export default ChatManagment;
