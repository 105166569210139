import styled from "styled-components";

export const Main = styled.div`
  .create-button {
    width: 218px;
    height: auto;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  .create-collection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const ImgInput = styled.div`
  .add-img-input {
    width: 616px;
    height: 197px;
    border-radius: 14px;
    text-align: center;
    background-color: #f9f9f9;
    padding: 36px 0px 36px 0px;
    position: relative;
  }
  @media (max-width: 425px) {
    .add-img-input {
      width: 100%;
    }
  }
  .name {
    width: 616px;
    height: 56px;
    border-radius: 5px;
    outline: none;
    border: 0px;
    background: #f9f9f9;
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    color: #808d9e;
    padding: 0px 22px;
  }
  @media (max-width: 425px) {
    .name {
      width: 100%;
    }
  }
  .text-area {
    width: 616px;
    border-radius: 5px;
    outline: none;
    border: 0px;
    background: #f9f9f9;
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    color: #808d9e;
    padding: 15px 22px;
    resize: none;
  }
  @media (max-width: 425px) {
    .text-area {
      width: 100%;
    }
  }
  .create-btn {
    width: 616px;
    height: 56px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  @media (max-width: 425px) {
    .create-btn {
      width: 100%;
    }
  }

  .img-sizzing {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 197px;
    border-radius: 14px;
  }

  .tag {
    background-color: #ffbb02;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
  }
  .background {
    width: 616px;
    height: 56px;
    overflow-y: auto;
    border-radius: 5px;
    outline: none;
    border: 0px;
    background: #f9f9f9;
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    color: #808d9e;
    padding: 6px 22px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .close {
    border: 0;
    background-color: #ffbb02;
    color: white;
    padding: 0px 0px 0px 10px;
  }
  .error {
    color: red;
    font-family: inter;
  }
`;
