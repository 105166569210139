// import React, { useState } from "react";
// import { Main } from "./style";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import { FaRegEye } from "react-icons/fa";
// import { FaRegEyeSlash } from "react-icons/fa";
// import { useForm } from "react-hook-form";
// const ResetPassword = () => {
//   const SignupSchema = Yup.object().shape({
//     firstName: Yup.string()
//       .min(2, "Too Short!")
//       .max(10, "Too Long!")
//       .required("Required"),
//     // email: Yup.string().email('Invalid ').required('Required'),
//   });
//   const [showpassword, setshowPassword] = useState(false);
//   const [showpassword2, setshowPassword2] = useState(false);

//   const authToken = localStorage.getItem("authToken");

//   const {
//     register,
//     formState: { errors },
//     handleSubmit,
//     watch,
//   } = useForm();

//   const onSubmit = (data) => {
//     const { password, confirmPassword } = data;
//     if (password === confirmPassword) {
//       //   loginNavigate();  // ROUTE TO NEXT PAGE
//     }
//   };

//   const watchPassword = watch("password", "");
//   return (
//     <Main>
//       <div>
//         <p className="yellow_border"></p>
//       </div>
//       <div className="margin">
//         <h4 className="heading">Reset your Password</h4>
//         <p className="welcome_Content">
//           Please enter your new password and confirm password
//         </p>
//       </div>

//       <Formik
//         initialValues={{
//           firstName: "",
//           email: "",
//         }}
//         validationSchema={SignupSchema}
//         onSubmit={(values) => {
//           console.log(values);
//         }}
//       >
//         {({ errors, touched }) => (
//           <Form onSubmit={handleSubmit(onSubmit)}>
//             <div className="text-center" style={{ position: "relative" }}>
//               <Field
//                 type={showpassword ? "text" : "password"}
//                 name="email"
//                 className="input my-3"
//                 placeholder="Password"
//               />
//               <span
//                 onClick={() => setshowPassword(!showpassword)}
//                 className="position_first"
//               >
//                 {showpassword ? (
//                   <FaRegEye fontSize={"20px"} />
//                 ) : (
//                   <FaRegEyeSlash fontSize={"20px"} />
//                 )}
//               </span>
//               {/* {errors.email && touched.email ? <div style={{ color: "red", marginTop: "-14px", textAlign: "start", marginLeft: "20%" }}>{errors.email}</div> : null} */}

//               <Field
//                 type={showpassword2 ? "text" : "password"}
//                 name="firstName"
//                 className="input"
//                 placeholder="Confirm Password"
//               />
//               <span
//                 onClick={() => setshowPassword2(!showpassword2)}
//                 className="position_second"
//               >
//                 {showpassword2 ? (
//                   <FaRegEye fontSize={"20px"} />
//                 ) : (
//                   <FaRegEyeSlash fontSize={"20px"} />
//                 )}
//               </span>
//             </div>
//             {errors.firstName && touched.firstName ? (
//               <div
//                 style={{ color: "red", textAlign: "start", marginLeft: "20%" }}
//               >
//                 {errors.firstName}
//               </div>
//             ) : null}

//             <div className="text-center">
//               <button type="submit" className="btn SignIn_Button mt-4">
//                 Confirm
//               </button>
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </Main>
//   );
// };

// export default ResetPassword;

import React, { useState } from "react";
import { Main } from "./style";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import useHooks from "../useHooks";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();
  const { ChangePassword } = useHooks();

  const authToken = localStorage.getItem("authToken");

  const SignupSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const onSubmit = async (values) => {
    const { password } = values;
    const body = {
      resetToken: authToken, // Token from local storage
      password,
    };

    try {
      const response = await ChangePassword(body);
      if (response?.status === true) {
        toastr.success("Password changed successfully.");
        navigate("/"); // Navigate to the login page or any other desired page
      }
      //else {
      //   toastr.error(response?.message || "Failed to change password.");
      // }
    } catch (error) {
      toastr.error("An error occurred. Please try again.");
    }
  };

  return (
    <Main>
      <div>
        <p className="yellow_border"></p>
      </div>
      <div className="margin">
        <h4 className="heading">Reset your Password</h4>
        <p className="welcome_Content">
          Please enter your new password and confirm password
        </p>
      </div>

      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="text-center">
              <div style={{ position: "relative" }}>
                <Field
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="input my-3"
                  placeholder="Password"
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="position_first"
                >
                  {showPassword ? (
                    <FaRegEye fontSize={"20px"} />
                  ) : (
                    <FaRegEyeSlash fontSize={"20px"} />
                  )}
                </span>
              </div>
              {errors.password && touched.password ? (
                <div
                  style={{
                    color: "red",
                    marginTop: "-14px",
                    textAlign: "start",
                    marginLeft: "20%",
                  }}
                >
                  {errors.password}
                </div>
              ) : null}
              <div style={{ position: "relative" }}>
                <Field
                  type={showPassword2 ? "text" : "password"}
                  name="confirmPassword"
                  className="input"
                  placeholder="Confirm Password"
                />
                <span
                  onClick={() => setShowPassword2(!showPassword2)}
                  className="position_second"
                >
                  {showPassword2 ? (
                    <FaRegEye fontSize={"20px"} />
                  ) : (
                    <FaRegEyeSlash fontSize={"20px"} />
                  )}
                </span>
              </div>
              {errors.confirmPassword && touched.confirmPassword ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "start",
                    marginLeft: "20%",
                  }}
                >
                  {errors.confirmPassword}
                </div>
              ) : null}
            </div>

            <div className="text-center">
              <button type="submit" className="btn SignIn_Button mt-4">
                Confirm
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Main>
  );
};

export default ResetPassword;
