import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  height: 170vh;
  .style-profile {
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    margin: 0;
    padding-block: 20px;
  }
  .message {
    width: 207px;
    height: 56px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    color: #ffffff;
    border: 0px;
  }
  @media (max-width: 981px) {
    .message {
      margin-top: 10px;
    }
  }
  .img-width {
    width: 99px;
    height: 99px;
    border-radius: 5px;
  }

  .style-font {
    font-family: inter;
    font-size: 14px;
    display: flex;
    gap: 10px;
    color: #808d9e;
    align-items: center;
  }
  @media (max-width: 368px) {
    .style-font {
    font-size: 10px;
    }
  }
  .style-font2 {
    font-family: inter;
    font-size: 14px;
    font-weight: 400;
    color: black;
    border-bottom: 1px solid #e2e8f0;
  }
  .style-name {
    font-family: inter;
    font-size: 18px;
    font-weight: 800;
    color: black;
  }
  .display {
    display: flex;
    gap: 0px;
  }
  .items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .img-width1 {
    width: 29px;
    height: 31px;
  }
  .font-style {
    font-family: poppins;
    font-weight: 600;
    font-size: 12px;
    color: #252525;
  }
  .order {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    color: #1c1c27;
    margin: 0;
    padding-block: 20px;
  }
  .order1 {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    color: #1c1c27;
  }
  .order-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
  }
  /* cards for filter */
  .status-Processing {
    width: 99px;
    height: 24px;
    border-radius: 5px;
    background-color: #00d1ff1a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #00d1ff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-delivered {
    width: 90px;
    height: 24px;
    border-radius: 5px;
    background-color: #209a421a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #209a42;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-pending {
    width: 82px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff8f501a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff8f50;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-cancel {
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  /*.custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
} */

  .custom-select option {
    background-color: #fff;
  }
  .options {
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
  }
  .pagination-flex {
    display: flex;
    justify-content: end;
  }
  .input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .input2 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .select1 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .select2 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .date {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #1c1c27;
  }
  .date-input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    gap: 88px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    padding: 10px;
    outline: none;
  }
  .filter {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
  }
  .reset {
    width: 200px;
    height: 48px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808d9e;
  }
  .display-grid {
    display: grid;
    grid: auto / auto auto auto auto;
    grid-gap: 10px;
  }
  .customer-input {
    width: 307px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    padding: 15px;
    outline: none;
  }
  .status {
    width: 208px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .order-limit {
    width: 242px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .method {
    width: 256px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .inputs-flex {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .calender {
    width: 300px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
    padding: 15px;
  }
  .label {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #1c1c27;
  }
  .filter {
    width: 200px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  @media (max-width: 455px) {
    .filter {
      width: 100%;
    }
    .reset {
      width: 100%;
    }
  }
  /* .reset {
    width: 167px;
    height: 48px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    color: #808d9e;
    border: 0px;
  } */
  .button-flex {
    display: flex;
    gap: 5px;
    padding-block: 24px;
  }
  .nav-pills .nav-link.active .cards,
  .nav-pills .nav-item.show .nav-link .cards {
    background-color: white;
    border: 0px;
    border-radius: 9px;
  }
  @media (max-width: 809px) {
    .nav-item {
      width: 100%;
    }
  }

  .nav-pills .nav-link:hover .cards,
  .nav-pills .nav-item.show .nav-link:hover .cards {
    background-color: white;
    border: 0px;
    border-radius: 9px;
  }

  .nav-pills {
    --bs-nav-pills-border-radius: var(--bs-border-radius);
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: white;
    gap: 10px;
  }
  /* .third-card:hover{
  background-color: white;
  border: 0px;
} */
  /* .third-card:hover,
.third-card:active {
  background-color: white;
  border: 0px;
} */
  .cards {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: var(--bs-border-radius);
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(
      var(--bs-border-radius) - (var(--bs-border-width))
    );
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 264px;
    height: 92px;
    color: var(--bs-body-color);
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid #e2e8f0;
    border-radius: 9px;
    /* background-color:#E2E8F0; */
    &:hover {
      border-radius: 9px;
    }
  }
  .third-flex {
    justify-content: start;
    display: flex;
    align-items: center;
  }
  .order-img {
    width: 62px;
    height: 62px;
  }
  .margin {
    margin-left: 8%;
  }
  .order-para {
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .order-number {
    font-family: Inter;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  /* .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color:white;
    background-color: #F9F9F9 !important;
} */
  .border1 {
    width: 81px;
    height: 30px;
    background-color: #00d1ff1a;
    color: #00d1ff;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
  }
  .border2 {
    width: 81px;
    height: 30px;
    background-color: #209a421a;
    color: #209a42;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
  }
  .border3 {
    width: 100px;
    height: 30px;
    background-color: #00d1ff1a;
    color: #00d1ff;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
  }
  .border4 {
    width: 100px;
    height: 30px;
    background-color: #209a421a;
    color: #209a42;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
  }
  .rating-number {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    color: black;
  }
  .cancel {
    background-color: #f084751a;
    color: #eb2a00;
    width: 73px;
    height: 24px;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
  }
  .status-return {
    background-color: #ffe9d2;
    color: #b36618;
    width: 73px;
    height: 24px;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
  }
  .total-img {
    width: 61px;
    height: 61px;
    background-color: #004e971a;
    padding: 10px;
    border-radius: 35px;
  }
  .cancel-img {
    width: 61px;
    height: 61px;
    background-color: #ff10101a;
    padding: 10px;
    border-radius: 35px;
  }
  .return-img {
    width: 61px;
    height: 61px;
    background-color: #8b56251a;
    padding: 10px;
    border-radius: 35px;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;

    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
    min-width: 48px;
    height: 48px;
    border-radius: 12px;
    padding: 0 6px;
    margin: 0 3px;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #0c3a2d;
    color: #ffbb02;
  }
  .pagination-items {
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    color: #0f172a;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    color: #0c3a2d;
  }
  .status-Processing {
    width: 99px;
    height: 24px;
    border-radius: 5px;
    background-color: #00d1ff1a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #00d1ff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-delivered {
    width: 90px;
    height: 24px;
    border-radius: 5px;
    background-color: #209a421a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #209a42;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-pending {
    width: 82px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff8f501a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff8f50;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-cancel {
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
  }
  .options {
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
  }
  .buttons-end {
    display: flex;
    justify-content: end;
    gap: 25px;
    margin-top: 10px;
  }
  @media (max-width: 630px) {
    .buttons-end {
      justify-content: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  .filters-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
  }
  @media (max-width: 630px) {
    .filters-flex {
      flex-direction: column;
    }
  }
  .width {
    width: 25%;
  }
  @media (max-width: 630px) {
    .width {
      width: 100%;
    }
  }

  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width{
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width{
      width: 750px;
  }
  }
`;
