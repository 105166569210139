// In this file, you will be putting the styling which you are going to use globally in your application which leads to the reusability of the styling

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    *{
        margin: 0 ;
        padding: 0;
        box-sizing: border-box;
        /* background: var(--all-background); */
        /* height: var(--height); */
        
    }
    p{
        margin-bottom: 0px;
    }

    :root{
        --primary: #41e0a3;
        --all-background:#F9F9F9;
        --white-background:#ffffff;
        --white-textColor:#ffffff;
        --darkGray-color:#808D9E;
        --black-color:#1C1C27;
        --primaryGreen-button:#156A3D;
        --height:100vh;
        //put your global colors here which you are using multiple times in your application.
        /*  You can replace the primary color with your main theme color */
    }
    .heading{
        font-family: inter;
    font-weight: 600;
    font-size: 18px;
   color: #1C1C27;
   letter-spacing: 0.2px;
   padding: 15px 0px;
    }
    .heading-flex{
        display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
    /* padding-block: 10px; */
    }
    @media (max-width: 375px) {
    .heading-flex {
      flex-wrap: wrap;
    }
  }
    .cross-border{
    border: 1px solid white;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 4px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
 
}
.no-record{
  width: 100%;
    background-color: white;
    padding: 12px;
    text-align: center;
    /* font-weight: 600; */
    font-size: 16px;
    color: red;
    font-family: 'Inter';
}
body{
    height: 100vh;
  scrollbar-gutter: stable both-edges;

}
/* Scrollbar styles for WebKit browsers */
::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Roundness of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #0c3a2d; /* Custom color of the thumb */
  border-radius: 10px; /* Roundness of the thumb */
  border: 3px solid #f1f1f1; /* Space around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #083020; /* Darker shade for the thumb on hover */
}

/* Scrollbar styles for Firefox */
html {
  scrollbar-width: thin; /* Thickness of the scrollbar */
  scrollbar-color: #0c3a2d #f1f1f1; /* Color of the thumb and track */
}


.view-icon {
    height: 24px;
    width: 23px;
    border-radius: 5px;
    background-color: #209a42;
    padding: 5px;
    cursor: pointer;
  }
  .copy-icon{
    height: 24px;
    width: 23px;
    border-radius: 5px;
    background-color: #00D1FF;
    padding: 5px;
    cursor: pointer;
  }
  .edit-icon{
    height: 24px;
    width: 23px;
    border-radius: 5px;
    background-color: #FF8F50;
    padding: 5px;
    cursor: pointer;
  }
  .delete-icon{
    height: 24px;
    width: 23px;
    border-radius: 5px;
    background-color: #FF0000;
    padding: 5px;
    cursor: pointer;
  }
  input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  

`;
