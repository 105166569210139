import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
const useHooks = () => {
  const policiesPost = async (body) => {
    try {
      const response = await AuthAPI.policiesPost(body);
      console.log(response);
      toastr.success("policies create successfully");
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const policiesUpdate = async (id, body) => {
    try {
      const response = await AuthAPI.PoliciesUpdate(id, body);
      console.log(response);
      toastr.success("policies update successfully");
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const Getpolicies = async (id) => {
    try {
      const response = await AuthAPI.Getpolicies(id);
      if (response) {
        // toastr.success('Collection get successfully');
        console.log(response);
      }
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Please try again Later");
      return null;
    }
  };
  return { Getpolicies, policiesUpdate, policiesPost };
  //   const updateProfile = async (profileData) => {
  //     try {
  //       const response = await AuthAPI.updateProfile(profileData);
  //       // console.log(response);
  //       return response;
  //     } catch (error) {
  //       console.error(error);
  //       alert(error.message);
  //       return null;
  //     }
  //   };
  //   const getProfile = async () => {
  //     try {
  //       const response = await AuthAPI.getProfile();
  //       console.log(response,'media')
  //       return response;
  //     } catch (error) {
  //       console.error(error);
  //       alert(error.message);
  //       return null;
  //     }
  //   };

  // return { policiesPost,policiesUpdate};
};

export default useHooks;
