import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Title, Section } from "./style";
import closeIcon from "../../../assets/img/my-wallet-modals/closeIcon.svg";
import HomeIcon from "../../../assets/img/my-wallet-modals/homeIcon.svg";
import DeleteIcon from "../../../assets/img/my-wallet-modals/deleteIcon.svg";
import useHook from "./useHook";
import { useEffect, useState } from "react";
import PaymentRequestModal from "../my-wallet-RequestPayment-modal";
import DeleteModal from "../delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "695px",
  height: "auto",
  bgcolor: "#FFFFFF",
  p: "25px 45px",
  borderRadius: "30px",
  "@media (max-width: 425px)": {
    width: "90%",
    padding: 2,
    borderRadius: "30px",
    height: "397px",
    overflowY: "auto",
  },
};

const SelectAccountModal = ({
  onCloseSelectAccountModal,
  onOpenSelectAccountModal,
  onOpenAddAccountModal,
  onOpenRequestModal,
  // Add prop for handling account selection
}) => {
  const handleModalClose = () => {
    onCloseSelectAccountModal();
  };

  const [activeId, setActiveId] = useState(null);
  const [accountId, setAccountId] = useState(0);

  const handleActive = (id, name, number) => {
    setActiveId(id);
    localStorage.setItem("accountId", id);
    localStorage.setItem("accountName", name);
    localStorage.setItem("accountNumber", number);
  };

  const handleSelect = () => {
    if (activeId !== null) {
      setAccountId(activeId); // Pass the selected ID to the parent component
      handleModalClose(); // Close the modal
    } else {
      console.log("No item selected");
    }
  };

  // API call
  const { GetAccounts, DeleteAccount } = useHook();
  const [data, setData] = useState([]);
  const [deleteID, setDeleteID] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const GetAccount = async () => {
    try {
      const res = await GetAccounts();
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAccount();
  }, []);

  // const accountLimitExceeded = data.length > 4;

  // API For Delete

  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async (id) => {
    if (id !== null) {
      try {
        await DeleteAccount(id);
        const newdata = data.filter((account) => account.id !== id);
        setData(newdata);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Error deleting account:", error);
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    GetAccount();
  }, [deleteModalOpen, currentPage]);

  const accountLimitExceeded = data.length > 4;
  return (
    <main>
      <PaymentRequestModal />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleCloseDeleteModal}
        handleDelete={() => handleConfirmDelete(deleteID)}
      />
      <Modal
        open={onOpenSelectAccountModal}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
      >
        <Fade in={onOpenSelectAccountModal}>
          <Box sx={style}>
            <Title className="modal-head">
              <p className="modal-title">Select Account</p>
              <figure onClick={handleModalClose}>
                <img
                  src={closeIcon}
                  alt="Close Button"
                  style={{ cursor: "pointer" }}
                />
              </figure>
            </Title>
            <Section>
              {data.map((item) => (
                <div key={item.id} className="accountDetails-section">
                  <div
                    className={`accountDetails-data ${
                      activeId === item.id ? "active" : ""
                    }`}
                    onClick={() =>
                      handleActive(
                        item.id,
                        item.accountName,
                        item.accountNumber
                      )
                    }
                  >
                    <div className="accountDetails-title">
                      <img src={HomeIcon} alt="Bank Icon" />
                      <p className="accountNames">{item.accountName}</p>
                    </div>
                    <div>
                      <p className="accountNames">{item.accountNumber}</p>
                    </div>
                  </div>
                  <img
                    src={DeleteIcon}
                    alt="Delete Items"
                    onClick={() => handleOpenDeleteModal(item.id)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))}

              <div className="sectionButton">
                <button
                  type="button"
                  id="addNewButton"
                  onClick={onOpenAddAccountModal}
                  disabled={accountLimitExceeded}
                >
                  Add New
                </button>
                <button type="button" id="selectButton" onClick={handleSelect}>
                  Select
                </button>
              </div>
              {accountLimitExceeded && (
                <p className="error-message">
                  You cannot add more than 5 accounts.
                </p>
              )}
            </Section>
          </Box>
        </Fade>
      </Modal>
    </main>
  );
};

export default SelectAccountModal;
