import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  .width {
    /* height: 88px; */
    width: 100%;
  }
  .flex {
    /* display: flex;
    justify-content: end; */
    padding: 20px 28px;
    align-items: center;
    gap: 16px;
    /* flex-wrap: wrap-reverse;
    width: 100%; */
  }
  @media (max-width: 354px) {
    .flex {
      padding: 20px 11px;
    }
  }

  .header-flex {
    display: flex;
    gap: 12px;
  }
  @media (max-width: 480px) {
    .header-flex {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  .select {
    font-size: 16px;
    font-family: inter;
    font-weight: 600;
    padding-right: 11px;
  }
  .boder {
    border: 1px solid;
    width: 21%;
    padding: 1%;
  }
  .padding {
    padding: 1px 8px;
    border: 1px solid #e2e8f0;
  }
  .image {
    width: 45px;
    border-radius: 27px;
    height: 44px;
    margin-right: 10px;
    padding: 4px 3px;
  }
  .icon-width {
    width: 49px;
    height: 48px;
    /* border: 1px solid #E2E8F0; */
    /* padding: 8px; */
    border-radius: 5px;
  }
  .dropdown-item {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
    padding: 10px 20px;
  }
  .dropdown-logoutbutton {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ff0000;
  }
  .logoutButton {
    color: #ff0000;
  }
  .notification-bell {
    width: 50px;
    border: 1px solid #e2e8f0;
    padding: 0px 11px;
    height: 48px;
    border-radius: 6px;
  }
  .search {
    width: 50px;
    border: 1px solid #e2e8f0;
    padding: 0px 11px;
    height: 48px;
    border-radius: 6px;
  }
  .counter {
    position: absolute;
    top: -2px;
    right: 0px;
    border: 1px solid;
    border-radius: 11px;
    font-size: 12px;
    width: 20px;
    font-family: inter;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffbb02;
    color: white;
  }
  .message {
    position: absolute;
    top: -2px;
    right: 64px;
    border: 1px solid;
    border-radius: 11px;
    font-size: 12px;
    width: 20px;
    font-family: inter;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffbb02;
    color: white;
  }
  .burger-icon {
    color: #808d9e;
    cursor: pointer;
    display: none;
    /* border: 1px solid; */
    width: 50px;
    border: 1px solid #e2e8f0;
    padding: 0px 11px;
    height: 48px;
    border-radius: 6px;
  }

  @media (max-width: 769px) {
    .background-color {
      width: ${({ isOpen }) => (isOpen ? "250px" : "60px")};
      position: fixed;
      z-index: 999;
      top: 0px;
    }

    .burger-icon {
      display: block;
    }

    .sidebar-content {
      display: ${({ isOpen }) => (isOpen ? "block" : "none")};
      transition: all ease-in-out 0.5s;
    }
  }
  .burger-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 480px) {
    .burger-div {
      align-items: normal;
    }
  }
  .relative{
    position: relative;
    cursor: pointer;
  }
  @media (max-width: 480px) {
    .relative {
      text-align: end;
    }
  }

`;
