import React from "react";
import { Main } from "./style";
import sofa from "../../assets/img/spinner/lotuspro.svg";
import circle from "../../assets/img/collection/Group 1597883371.png";
const Spiner = () => {
  return (
    <Main>
      <div className="spinner-adjust">
        <div className="spinner">
          <img src={sofa} alt="Sofa" className="sofa" />
          <img src={circle} alt="Circle" className="circle" />
        </div>
      </div>
    </Main>
  );
};

export default Spiner;
