import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHooks = () => {
  const login = async (body) => {
    try {
      const response = await AuthAPI.PostChangePassword(body);
      //   console.log("response", response);
      //   toastr.success("Password Updated successfull !!");
      if (response.status === true) {
        toastr.success("Password Updated successful");
      } else {
        // console.log("Login failed");
        toastr.error("Password Updated error");
      }
      return response;
    } catch (error) {
      toastr.error("Password Updated error:");
    }
  };
  const notification = async (body) => {
    try {
      const response = await AuthAPI.ToggleNotification(body);
      //   console.log("response", response);
      //   toastr.success("Password Updated successfull !!");
      if (response.status === true) {
        toastr.success("Notifications Updated successfull");
      } else {
        // console.log("Login failed");
        toastr.error("Password Updated error");
      }
    } catch (error) {
      toastr.error("Password Updated error:");
    }
  };
  const getProfile = async () => {
    try {
      const response = await AuthAPI.getProfile(); // Assuming AuthAPI.getProfile() makes the API call
      return response.data; // Assuming response.data contains the profile data
    } catch (error) {
      console.error("Error fetching profile:", error);
      alert("Error fetching profile: " + error.message);
      throw error; // Optionally re-throw the error for further handling
    }
  };

  return {
    login,
    notification,
    getProfile,
  };
};
export default useHooks;
