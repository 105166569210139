import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHooks = () => {
  const imagePost = async (file) => {
    try {
      if (!file) {
        throw new Error("No file provided");
      }
      const reader = new FileReader();
      const fileAsDataURL = new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
      reader.readAsDataURL(file);
      await fileAsDataURL; // Wait for the file to be read as data URL
      const response = await AuthAPI.imagepost(
        { image: file },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("Image upload response", response);
      if (response?.path) {
        toastr.success("Image uploaded successfully");
        // alert('seccess')
        console.log("response path", response.path);
        return response.path;
      } else {
        alert("Image upload failed");
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("An error occurred during image upload");
      return null;
    }
  };

  const PushNotifications = async (params) => {
    try {
      const response = await AuthAPI.sendNotification(params);
      console.log(response);

      if (response?.status) {
        toastr.success("Message sent successfully");
      } else {
        toastr.error("Message sending failed");
      }
    } catch (error) {
      toastr.error("Notification error:", error);
    }
  };
  const SaveFCMToken = async (deviceId, token) => {
    try {
      const response = await AuthAPI.SaveToken(deviceId, token);
      console.log(response);

      if (response?.status) {
        // toastr.success("Token Saved successfully");
      } else {
        toastr.error("Token Saved failed");
      }
    } catch (error) {
      toastr.error("Notification error:", error);
    }
  };

  return { imagePost, PushNotifications, SaveFCMToken };
};

export default useHooks;
