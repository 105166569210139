import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthAPI } from "libs/http-service/api/auth.api";
import { useNavigate } from "react-router-dom";
import toastr, { error } from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
const useHooks = () => {
  const navigate = useNavigate();

  const login = async (body) => {
    try {
      const response = await AuthAPI.login(body);
      console.log("response");
      // if (response?.data?.token) {
      //   localStorage.setItem("token", response.data.token);
      //   localStorage.setItem("name", response.data.user.name);
      //   localStorage.setItem("profile_pic", response.data.user.profile_pic);
        // localStorage.setItem("id", response.data.user.id);
      // }
      return response;
    } catch (error) {
      toastr.error("Login error:", error);
    }
  };
  const ForgetPassword = async (body) => {
    try {
      const response = await AuthAPI.forgetPassword(body);
      console.log("response");
      return response;
    } catch (error) {
      toastr.error("Login error:", error);
    }
  };
  const VerifyOtp = async (body) => {
    try {
      const response = await AuthAPI.verifyOtp(body);
      return response;
    } catch (error) {
      toastr.error("Login error:", error);
    }
  };
  const ChangePassword = async (body) => {
    try {
      const response = await AuthAPI.changePassword(body);
      return response;
    } catch (error) {
      toastr.error("Login error:", error);
    }
  };

  return {
    login,
    ForgetPassword,
    VerifyOtp,
    ChangePassword,
  };
};
export default useHooks;
