import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Main = styled.div`
  width: ${({ isOpen }) => (isOpen ? "17%" : "60px")};
  transition: width 0.3s ease-in-out;

  .background-color {
    background-color: #ffffff;
    height: 100vh;
    position: fixed;
    width: 240px;
  }

  .logo {
    width: 40px;
    height: 44px;
  }
  .logo-flex {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-top: 17px;
    gap: 5px;
  }

  .bottom-border {
    border-bottom: 1px solid #e8e8e9;
    margin: 12px 0px 22px 0px;
  }

  .burger-icon {
    color: black;
    cursor: pointer;
    display: none;
  }

  @media (max-width: 769px) {
    .background-color {
      width: ${({ isOpen }) => (isOpen ? "250px" : "60px")};
      position: fixed;
      z-index: 999;
      top: 0px;
    }

    .burger-icon {
      display: block;
    }

    .sidebar-content {
      display: ${({ isOpen }) => (isOpen ? "block" : "none")};
      transition: all ease-in-out 0.5s;
    }
  }

  .sidebar-content {
    overflow: hidden;
  }

  .sidebar-content.open {
    width: 100%;
  }

  .dashboard {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #808d9e;
  }

  .color {
    color: white;
    height: 14px;
    width: 14px;
  }

  .cross {
    color: white;
    padding: 15px 0 0 0;
    display: none;
  }

  @media (max-width: 768px) {
    .cross {
      display: block;
    }
  }

  .nav-link.active {
    background-color: #0c3a2d;
    color: white;
    border-radius: 5px;
  }
  .lotus {
    font-size: 30px;
    font-family: inter;
    font-weight: 400;
    margin-top: 14px;
    color: #0c3a2d;
  }
  .pro {
    font-size: 30px;
    font-family: inter;
    font-weight: 900;
    color: #0c3a2d;
  }
`;

export const NavLinkStyled = styled(NavLink)`
  padding: 8px 13px;
  cursor: pointer;

  .color {
    path {
      fill: #808d9e;
    }
  }

  &:hover {
    color: white;
    background-color: #0c3a2d;
    border-radius: 5px;

    .color {
      path {
        fill: white;
      }
    }
  }

  &.active {
    background-color: #0c3a2d;
    color: white;
    border-radius: 5px;

    .color {
      path {
        fill: white;
      }
    }
  }
`;
