import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { IoClose } from "react-icons/io5";
import { Modal } from "./style";
import { FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import useHooks from "./usehooks";
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
const ModalComponent = ({
  open,
  onClose,
  pendingTabname,
  orderID,
  fetchOrders,
}) => {
  const { ChangeStatus } = useHooks();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };
  // btn Pending case functionality
  const [pendingStatus, setPendingStatus] = useState("");
  const [declineReason, setDeclineReason] = useState("");
  const [errors, setErrors] = useState({});
  const handlePending = async () => {
    if (!validateForm()) {
      return;
    }
    if (pendingStatus === "processing") {
      try {
        const params = {
          status: pendingStatus,
        };
        const response = await ChangeStatus(orderID, params);
        console.log(response);
        toastr.success("User Product is in Processing");
        onClose();
        fetchOrders();
      } catch (error) {
        console.error(error.message);
        toastr.error("Error Occured. Please try again later");
      }
    } else {
      try {
        const params = {
          status: pendingStatus,
          declinedReason: declineReason,
          // "trackingId": "string",
          // "shippingTrackingUrl": "string",
          // "shippingCompany": "string",
        };
        const response = await ChangeStatus(orderID, params);
        // console.log("pending Status is", response);
        toastr.success("User Product is Declined Successfully");
        onClose();
        setDeclineReason("");
      } catch (error) {
        console.error(error.message);
        toastr.error("Error Occured. Please try again later");
      }
    }
  };

  const handlePendingOptionChange = (e) => {
    const value = e.target.value;
    setPendingStatus(() => {
      // console.log("Previous pending status:", prevStatus);
      console.log("New pending status:", value);
      return value;
    });
    setErrors((prev) => ({ ...prev, pendingStatus: "" }));
  };

  const handleDeclineReasonChange = (event) => {
    setDeclineReason(event.target.value);
    console.log(declineReason);
    setErrors((prev) => ({ ...prev, declineReason: "" }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!pendingStatus) {
      newErrors.pendingStatus = "Please select a pending status";
      valid = false;
    }

    if (pendingStatus === "declined" && !declineReason) {
      newErrors.declineReason = "Please provide a reason for declining";
      valid = false;
    }
    fetchOrders();

    setErrors(newErrors);
    return valid;
  };

  // btn processing case functionality
  const [processingStatus, setprocessingStatus] = useState("");
  const [ProcessigValidationError, setProcessingValidationError] = useState("");
  const [trackingId, setTrackingid] = useState("");
  const [trackingURL, setTrackingURL] = useState("");
  const [shipmentCompany, setShipmentCompany] = useState("");
  const [processingErrors, setProcessingErrors] = useState({
    PtrackingId: "",
    PshipmentCompany: "",
    PtrackingURL: "",
  });
  const [processingdeclineReason, setprocessingdeclineReason] = useState();
  const [processingDeclineErrors, setProcessingDeclineErrors] = useState("");
  const handleProcessing = async () => {
    if (!processingStatus) {
      setProcessingValidationError("Please select a status");
      return;
    }
    setProcessingValidationError("");
    let errors = {};

    if (processingStatus === "shipped") {
      if (!trackingId) errors.PtrackingId = "Tracking ID is required";
      if (!trackingURL) errors.PtrackingURL = "Tracking URL is required";
      if (!shipmentCompany)
        errors.PshipmentCompany = "Shipment Company is required";

      if (Object.keys(errors).length > 0) {
        setProcessingErrors(errors);
        return;
      }
    }

    if (processingStatus === "shipped") {
      try {
        const params = {
          status: processingStatus,
          trackingId: trackingId,
          shippingTrackingUrl: trackingURL,
          shippingCompany: shipmentCompany,
        };
        console.log("shipped testing processing", params);
        const response = await ChangeStatus(orderID, params);
        console.log("processing Status is", response);
        toastr.success("User Product is in shipped");
        onClose();
        setprocessingStatus("");
        setTrackingid("");
        setTrackingURL("");
        setShipmentCompany("");
        fetchOrders();
      } catch (error) {
        console.error(error.message);
        toastr.error("Error Occured. Please try again later");
      }
    } else {
      if (processingStatus === "declined") {
        if (!processingdeclineReason) {
          setProcessingDeclineErrors("Decline reason is required");
          return;
        }
        try {
          const params = {
            status: processingStatus,
            declinedReason: declineReason,
            // "trackingId": "string",
            // "shippingTrackingUrl": "string",
            // "shippingCompany": "string",
          };
          const response = await ChangeStatus(orderID, params);
          console.log("decline running", params);
          console.log("pending Status is", response);
          toastr.success("User Product is Declined Successfully");
          onClose();
          setDeclineReason("");
        } catch (error) {
          console.error(error.message);
          toastr.error("Error Occured. Please try again later");
        }
      }
    }
  };
  const handleProcessingOptionChange = (e) => {
    const value = e.target.value;
    setProcessingErrors({
      PtrackingId: "",
      PshipmentCompany: "",
      PtrackingURL: "",
    });
    setProcessingValidationError("");
    setprocessingStatus(() => {
      // console.log("Previous pending status:", prevStatus);
      console.log("New pending status:", value);
      return value;
    });
  };
  const ProcessinghandleDeclineReasonChange = (e) => {
    setprocessingdeclineReason(e.target.value);
  };

  // shipped modal
  const [shippedStatus, setShippedStatus] = useState("");
  const [ShipmentValidationError, setShipmentValidationError] = useState("");
  const handleShipedOptionChange = (e) => {
    const value = e.target.value;
    setShippedStatus(() => {
      // console.log("Previous pending status:", prevStatus);
      setShipmentValidationError("");
      console.log("New pending status:", value);
      return value;
    });
  };
  const handleShippedParcel = async () => {
    if (!shippedStatus) {
      setShipmentValidationError("Please select a status");
      return;
    }
    setShipmentValidationError("");
    const params = {
      status: shippedStatus,
    };
    try {
      const response = await ChangeStatus(orderID, params);
      console.log("processing Status is", response);
      toastr.success("User Product is in shipped");
      onClose();
      fetchOrders();
    } catch (error) {
      console.error(error.message);
      toastr.error("Error Occured. Please try again later");
    }
  };
  const statusColors = {
    pending: "#FF8F501A",
    delivered: "#209A421A",
    declined: "#97f0f1",
    returned: "#89c8ff",
    cancelled: "#FF00001A",
    processing: "#00D1FF1A",
    shipped: "#ffdbbb",
  };
  const textColor = {
    pending: "#FF8F50",
    delivered: "#209A42",
    declined: "#149c9e",
    returned: "#002647",
    cancelled: "#FF0000",
    processing: "#00D1FF",
    shipped: "rgb(255, 143, 80)",
  };
  const bgColor = statusColors[pendingTabname];
  const txtColor = textColor[pendingTabname];

  return (
    <>
      <Modal>
        <Backdrop
          sx={{
            color: "#1C1C27",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            boxShadow:
              "inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 5px 10px rgba(0, 0, 0, 0.2)",
          }}
          open={open}
          onClick={onClose}
        >
          <Box sx={style} onClick={(event) => event.stopPropagation()}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography id="modal-modal-title" variant="h5" component="h5">
                Update Status
              </Typography>

              <IoClose
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, display: "flex", justifyContent: "center" }}
              //   className={`status-${item.status.toLowerCase()} m-0`}
            >
              <span
                style={{
                  backgroundColor: bgColor,
                  color: txtColor,
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  borderRadius: "12px",
                }}
              >
                {" "}
                {pendingTabname}
              </span>
            </Typography>

            {/* Pending Tab */}
            {pendingTabname === "pending" && (
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InputLabel id="pending-select-label">Select</InputLabel>
                <Select
                  labelId="pending-select-label"
                  id="pending-select"
                  value={pendingStatus}
                  onChange={handlePendingOptionChange}
                  label="Select Status"
                >
                  <MenuItem value="processing">Processing</MenuItem>
                  <MenuItem value="declined">Declined</MenuItem>
                </Select>
                {errors.pendingStatus && (
                  <FormHelperText error>{errors.pendingStatus}</FormHelperText>
                )}
                {pendingStatus === "declined" && (
                  <>
                    <>
                      <TextField
                        label="Reason for Declining"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={declineReason}
                        onChange={handleDeclineReasonChange}
                        sx={{ mt: 2 }}
                        error={!!errors.declineReason}
                      />
                      {errors.declineReason && (
                        <FormHelperText error>
                          {errors.declineReason}
                        </FormHelperText>
                      )}
                    </>
                  </>
                )}
                {/* {pendingStatus} */}
                <Button
                  variant="contained"
                  style={{ marginTop: "16px" }}
                  onClick={handlePending}
                >
                  Confirm
                  {/* {pendingStatus ==='declined' ? <span>Confirm Decline</span>:<span>Confirm Processing</span>} */}
                </Button>
              </FormControl>
            )}

            {/* Processing Tab */}
            {pendingTabname === "processing" && (
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={processingStatus}
                    onChange={handleProcessingOptionChange}
                    label="Select"
                  >
                    <MenuItem value="shipped" className="options">
                      Shipped
                    </MenuItem>
                    <MenuItem value="declined" className="options">
                      Declined
                    </MenuItem>
                  </Select>
                  {ProcessigValidationError && (
                    <Typography
                      color="error"
                      variant="body2"
                      style={{ marginTop: "8px" }}
                    >
                      {ProcessigValidationError}
                    </Typography>
                  )}
                  {processingStatus === "declined" && (
                    <>
                      <TextField
                        label="Reason for Declining"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={1}
                        required
                        value={processingdeclineReason}
                        onChange={ProcessinghandleDeclineReasonChange}
                        sx={{ mt: 2 }}
                      />
                      {processingDeclineErrors && (
                        <Typography
                          color="error"
                          variant="body2"
                          style={{ marginTop: "10px" }}
                        >
                          {processingDeclineErrors}
                        </Typography>
                      )}
                    </>
                  )}

                  {processingStatus === "shipped" && (
                    <>
                      <TextField
                        label="Shippment Id or Invoice Number"
                        variant="outlined"
                        fullWidth
                        value={trackingId}
                        onChange={(e) => setTrackingid(e.target.value)}
                        sx={{ mt: 2 }}
                        error={!!processingErrors.PtrackingId}
                        helperText={processingErrors.PtrackingId}
                      />
                      <TextField
                        label="Shipment Company"
                        variant="outlined"
                        fullWidth
                        value={shipmentCompany}
                        onChange={(e) => setShipmentCompany(e.target.value)}
                        sx={{ mt: 2 }}
                        error={!!processingErrors.PshipmentCompany}
                        helperText={processingErrors.PshipmentCompany}
                      />
                      <TextField
                        label="Shipment Link"
                        variant="outlined"
                        fullWidth
                        value={trackingURL}
                        onChange={(e) => setTrackingURL(e.target.value)}
                        sx={{ mt: 2 }}
                        error={!!processingErrors.PtrackingURL}
                        helperText={processingErrors.PtrackingURL}
                      />
                    </>
                  )}
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleProcessing}
                    style={{ marginTop: "16px" }}
                  >
                    Confirm
                    {/* {processingStatus==="shipped"? <span>Confirm Shipment</span>: <span>Confirm Decline</span>} */}
                  </Button>
                </div>
              </FormControl>
            )}

            {/* Shipped */}
            {pendingTabname === "shipped" && (
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Select
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={shippedStatus}
                  onChange={handleShipedOptionChange}
                  label="Select"
                >
                  <MenuItem value="delivered" className="options">
                    Delivered
                  </MenuItem>
                </Select>
                {ShipmentValidationError && (
                  <Typography
                    color="error"
                    variant="body2"
                    style={{ marginTop: "8px" }}
                  >
                    {ShipmentValidationError}
                  </Typography>
                )}

                <Button
                  variant="contained"
                  style={{ marginTop: "16px" }}
                  onClick={handleShippedParcel}
                >
                  Confirm
                </Button>
              </FormControl>
            )}
          </Box>
        </Backdrop>
      </Modal>
    </>
  );
};

export default ModalComponent;
