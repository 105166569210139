import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);

  .overview {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #1c1c27;
    padding-block: 20px;
  }
  .export-width {
    width: 15px;
    height: 15px;
  }
  .export-text {
    border: 1px solid #e2e8f0;
    width: 110px;
    height: 48px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }
  .products-button {
    width: 198px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    border: 0px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
  }
  .dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    width: 159px;
  }
  .products-flex {
    display: flex;
    justify-content: space-between;
    gap: 0px;
  }
  @media (max-width: 382px) {
    .products-flex {
      gap: 10px;
    }
  }
  .search-input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    outline: none;
    padding: 15px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
  }
  .category-width {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    outline: none;
    padding: 13px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    cursor: pointer;
  }
  .price-width {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    outline: none;
    padding: 13px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    cursor: pointer;
  }
  .status-width {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    outline: none;
    padding: 13px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    cursor: pointer;
  }
  .filter-button {
    width: 167px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    color: white;
    border: 0px;
  }
  @media (max-width: 391px) {
    .filter-button {
      width: 100%;
    }
  }
  .filter-butons {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 16px;
  }
  @media (max-width: 391px) {
    .filter-butons {
      flex-wrap: wrap;
    }
  }
  .Reset-button {
    width: 167px;
    height: 48px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    color: #808d9e;
    border: 0px;
  }
  @media (max-width: 391px) {
    .Reset-button {
      width: 100%;
    }
  }
  .total-flex {
    display: flex;
    gap: 11px;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1024px) {
    .total-flex {
      flex-wrap: wrap;
    }
  }
  .arrow-flex {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .rating {
    display: flex;
    align-items: center;
  }
  .status-sold {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #ff00001a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff0000;
  }
  .status-stock {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #209a421a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #209a42;
  }
  .icon-flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #0c3a2d;
  }
  .table-body {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
  }
  .showing {
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #0f172a;
  }
  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }
`;
