import styled from "styled-components";

export const Modal = styled.div`
  .options {
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
  }

`