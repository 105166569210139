import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: min-content;
  /* background-color: red; */
  background-color: var(--all-background);

  .nav-pills .nav-link.active,
  .edagDK .nav-pills .show > .nav-link {
    color: black;
    background-color: #f9f9f9 !important;
  }
  /////////////////////////////

  ///////////////////////////////////////
  .dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    width: 159px;
  }
  .img {
    width: 100%;
    height: 186px;
    border-radius: 10px;
  }
  .card-width {
    width: 24%;
    border: 0px;
    border-radius: 15px;
  }
  @media (max-width: 1175px) {
    .card-width {
      width: 32%;
    }
  }
  @media (max-width: 1163px) {
    .card-width {
      width: 48%;
    }
  }
  @media (max-width: 585px) {
    .card-width {
      width: 100%;
    }
  }
  .mainHeading {
    font-family: Plus Jakarta sans;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
  }
  .sub-heading {
    font-family: Plus Jakarta sans;
    font-weight: 600;
    font-size: 14px;
    color: #808d9e;
  }
  .card-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  .third {
    width: 326px;
    height: 92px;
    border: 1px solid #edf2f7;
  }
  .category-btn {
    width: 218px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: 0px;
  }
  .img-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img-flex-2 {
    display: flex;
    gap: 3px;
    width: 84px;
    height: 24px;
    border-radius: 5px;
  }
  .pagination {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .collection-container {
    height: 100vh;
    overflow: hidden;
  }
`;
// export const Product = styled.div`
//   .product-name {
//     font-family: Plus Jakarta Sans;
//     font-weight: 600;
//     font-size: 14px;
//     color: #000000;
//   }
//   .product-count {
//     font-family: Plus Jakarta Sans;
//     font-weight: 600;
//     font-size: 14px;
//     color: #808d9e;
//   }
//   .product-para {
//     font-family: inter;
//     font-weight: 400;
//     font-size: 14px;
//     color: #1c1c27;
//   }
//   .styled-font {
//     font-family: inter;
//     font-weight: 400;
//     font-size: 14px;
//     color: #1c1c27;
//   }
//   .status {
//     width: 65px;
//     height: 28px;
//     border-radius: 5px;
//     background-color: #ff00001a;
//     font-family: inter;
//     font-weight: 400;
//     font-size: 12px;
//     color: #ff0000;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0px;
//   }

//   .edit {
//     width: 31px;
//     height: 32px;
//   }
//   .img {
//     width: 359px;
//     height: 223px;
//     border-radius: 12px;
//   }
//   .flex-1 {
//     width: 31%;
//     padding: 0px 10px;
//   }
//   .transitional {
//     padding: 0px 12px;
//     width: auto;
//     height: 28px;
//     border-radius: 5px;
//     background-color: #0c3a2d;
//     font-family: Plus Jakarta Sans;
//     font-weight: 500;
//     font-size: 14px;
//     color: white;
//     border: 0px;
//   }
//   .flex-4 {
//     display: flex;
//     flex-wrap: wrap;
//   }

//   .table-head {
//     font-family: inter;
//     font-weight: 700;
//     font-size: 14px;
//     letter-spacing: 0.2px;
//     color: #0c3a2d;
//   }
//   .table-body {
//     font-family: inter;
//     font-weight: 400;
//     font-size: 14px;
//     letter-spacing: 0.2px;
//     color: #1c1c27;
//     border-bottom: 1px solid #e2e8f0;
//   }
//   .status-sold {
//     width: 65px;
//     height: 28px;
//     border-radius: 5px;
//     background-color: #ff00001a;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-family: inter;
//     font-weight: 400;
//     font-size: 12px;
//     color: #ff0000;
//   }
//   .status-stock {
//     width: 65px;
//     height: 28px;
//     border-radius: 5px;
//     background-color: #209a421a;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-family: inter;
//     font-weight: 400;
//     font-size: 12px;
//     color: #209a42;
//   }
//   .status-width {
//     width: 328px;
//     height: 56px;
//     border-radius: 5px;
//     border: 1px solid #e2e8f0;
//     outline: none;
//     padding: 15px;
//     font-family: inter;
//     font-weight: 400;
//     font-size: 16px;
//     color: #808d9e;
//     cursor: pointer;
//   }
//   .rating {
//     display: flex;
//     align-items: center;
//   }
//   .icon-flex {
//     display: flex;
//     align-items: center;
//     gap: 5px;
//   }
//   .showing {
//     font-family: inter;
//     font-weight: 600;
//     font-size: 16px;
//     letter-spacing: 0.2px;
//     color: #0f172a;
//   }
// `;
