import React, { useState, useEffect, useCallback } from "react";
import { Main } from "./style";
import { RxCross2 } from "react-icons/rx";
import Dollar from "../../../assets/img/add-products/$.png";
import ProductsInputs from "components/products-inputs";
import MaterialTags from "components/product-material-tags";
import useHooks from "./useHook";
import { Controller, useForm } from "react-hook-form";
import toastr from "toastr";
import { Navigate, useNavigate } from "react-router-dom";
import Spiner from "components/spinner";
import PuffLoader from "components/puff-loader";
import PuffLoaderComponent from "components/puff-loader";
import { SketchPicker } from "react-color";
const AddProducts = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metas, setMetas] = useState([]);
  const [barcode, setBarcode] = useState("");
  const [productprice, setProductprice] = useState(0);
  const [saleprice, setSalePrice] = useState(0);
  const [quantity, setQuantity] = useState();
  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [unit, setUnit] = useState("");
  const [warrenty, setWarrenty] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [color, setColor] = useState("");
  const [colorData, setColorData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [material, setMaterial] = useState([]);
  const [materialId, setMaterialId] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]);
  const [collectionIds, setCollectionIds] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState([]);
  const [subCategoryValue, setSubCategoryValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const [warrantySelected, setWarrantySelected] = useState("no"); // Initial state set to 'no'
  // const [warrenty, setwarrenty] = useState("0"); // Assuming initial state for warranty years

  const handleCheckboxChange = (value) => {
    setWarrantySelected(value);
    // You can add more logic here if needed
  };

  // console.log(selectedImages, "selected images");

  const { getcategory, getmaterial, imagePost, getcollections, productsPost } =
    useHooks();
  const navigate = useNavigate();
  useEffect(() => {
    const getCategoryData = async () => {
      try {
        const response = await getcategory();
        const activeCategories = response.data.filter(
          (category) => category.isActive === true
        );
        setCategoryData(activeCategories);
        if (activeCategories.length > 0) {
          setSelectedCategoryId(activeCategories[0].id);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCategoryData();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async (categoryId) => {
      try {
        const res = await getcategory({ parentId: categoryId });
        const activeSubCategories = res.data.filter(
          (subcategory) => subcategory.isActive === true
        );
        setSubCategoryData(activeSubCategories);
        return activeSubCategories;
      } catch (error) {
        console.error(error);
        setSubCategoryData([]);
      }
    };
    if (selectedCategoryId !== null) {
      fetchSubCategories(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    const fetchMaterialData = async () => {
      const page = 1;
      const perPage = 40;
      try {
        const params = { page, perPage };
        const res = await getmaterial(params);
        if (res.status === true) {
          setMaterial(res.data);
          const ids = res.data.map((material) => material.id);
          setMaterialId(ids);
        }
      } catch (error) {
        console.error("Error fetching material data:", error);
      }
    };

    fetchMaterialData();
  }, []);

  const handleMaterialSelect = (event) => {
    const selectedId = event.target.value;
    // console.log("Selected Material ID:", selectedId);
  };

  useEffect(() => {
    const getcollectionsData = async () => {
      const userId = localStorage.getItem("id");
      const response = await getcollections({ userId });
      try {
        if (response.status === true) {
          setCollectionsData(response.data.results);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getcollectionsData();
  }, []);

  useEffect(() => {
    const ids = collectionsData.map((collection) => collection.id);
    setCollectionIds(ids);
  }, [collectionsData]);

  // const handleCategoryChange = (event) => {
  //   setSelectedCategoryId(parseInt(event.target.value));
  // };

  // const handleSubCategoryChange = (event) => {
  //   setSubCategoryValue(parseInt(event.target.value));
  // };

  // const handleSelectCollection = (event) => {
  //   const selectedId = event.target.value;
  //   setSelectedCollectionId(selectedId);
  // };

  const handleImagePickerClick = () => {
    console.log("Image picker clicked");
    document.getElementById("imageUpload").click();
  };

  // const handleImageChange = async (e) => {
  //   console.log("Files selected:", e.target.files);
  //   const files = Array.from(e.target.files);
  //   console.log("Files selected:", files);
  //   try {
  //     const imagePaths = await Promise.all(
  //       files.map((file) => imagePost(file))
  //     );
  //     if (imagePaths) {
  //       const newImages = imagePaths.map((path) => `${path}`);
  //       setSelectedImages((prevImages) => [...prevImages, ...newImages]);
  //       setValue("images", [...selectedImages, ...newImages], {
  //         shouldValidate: true,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error in handleImageChange: ", error);
  //     alert("An error occurred during image upload");
  //   }
  // };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);

    // Validation for file types and size
    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    try {
      const validatedFiles = files.filter((file) => {
        setLoading(true);
        // Check file type
        if (!allowedTypes.includes(file.type)) {
          console.log(`File ${file.name} is not allowed (type: ${file.type})`);
          return false;
        }

        // Check file size
        if (file.size > maxSize) {
          console.log(
            `File ${file.name} exceeds the size limit (max: ${maxSize} bytes)`
          );
          return false;
        }

        return true;
      });

      if (validatedFiles.length !== files.length) {
        toastr.warning(
          "Some files were not uploaded. Please check file types and sizes."
        );
      }

      const imagePaths = await Promise.all(
        validatedFiles.map((file) => imagePost(file))
      );

      if (imagePaths) {
        const newImages = imagePaths.map((path) => `${path}`);
        setSelectedImages((prevImages) => [...prevImages, ...newImages]);
        setValue("images", [...selectedImages, ...newImages], {
          shouldValidate: true,
        });
      }
    } catch (error) {
      console.error("Error in handleImageChange: ", error);
      alert("An error occurred during image upload");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
    setValue("images", updatedImages, { shouldValidate: true });
  };

  const handleSaleChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setSalePrice(value);
    }
  };

  const handleLength = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setLength(value);
    }
  };

  const handleWidth = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setWidth(value);
    }
  };

  const handleHeight = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setHeight(value);
    }
  };

  // const handleMetaKeywords = (Keywords) => {
  //   setMetas(Keywords);
  // };

  const handlechange = (value) => {
    setSelected(value);
  };

  // const handleColorChange = (e) => {
  //   const newValue = e.target.value;
  //   setColor(newValue);
  // };
  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const handleCancel = () => {
    setShowPicker(false);
  };

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleMaterialTagsChange = (selectedMaterials) => {
    setMaterial(selectedMaterials);
  };
  // const handleCheckboxChange = (value) => {
  //   setSelected(value);
  // };

  const handleImageUpload = async (e, colorId) => {
    const file = e.target.files[0]; // Assuming single file upload per click

    // Validation for file types and size
    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    try {
      setLoading(true);
      // Check file type
      if (!allowedTypes.includes(file.type)) {
        console.log(`File ${file.name} is not allowed (type: ${file.type})`);
        toastr.warning("Only JPEG, PNG, and WebP files are allowed.");
        return;
      }

      // Check file size
      if (file.size > maxSize) {
        console.log(
          `File ${file.name} exceeds the size limit (max: ${maxSize} bytes)`
        );
        toastr.warning("File size exceeds 2 MB limit.");
        return;
      }

      // Upload the file and get its path asynchronously
      const imagePath = await imagePost(file);

      if (imagePath) {
        // Format image path with base URL
        const imageUrl = `${imagePath}`;

        // Update colorData with new image for the specific color
        const updatedColorData = colorData.map((color) => {
          if (color.id === colorId) {
            // Create a new object for the specific color to avoid mutation
            return {
              ...color,
              image: imageUrl, // Update with the new image URL
            };
          }
          return color; // Return unchanged color objects
        });

        // Update state and react-hook-form field value
        setColorData(updatedColorData); // Ensure state update triggers re-render
        setValue("colors", updatedColorData, { shouldValidate: true }); // Update form value
      }
    } catch (error) {
      console.error("Error in handleImageUpload: ", error);
      alert("An error occurred during image upload");
    } finally {
      setLoading(false);
    }
  };

  // Handle Key Press to Add Color
  const handleApply = () => {
    const newColor = {
      id: Date.now(),
      colorName: color,
      colorCode: color,
      image: "",
    };

    const updatedColorData = [...colorData, newColor];
    setColorData(updatedColorData);
    setColor(""); // Reset color input after adding
    setShowPicker(false); // Close the color picker

    setValue("colors", updatedColorData, { shouldValidate: true }); // Update form value
  };
  const filterColorData = (data) => {
    console.log("Original Data:", data);
    const filtered = data.map(({ id, ...rest }) => rest);
    console.log("Filtered Data:", filtered);
    return filtered;
  };

  // Remove Color
  const removeColor = (colorId) => {
    const updatedColorData = colorData.filter((color) => color.id !== colorId);
    setColorData(updatedColorData);
    setValue("colors", updatedColorData, { shouldValidate: true });
  };

  ///    Validations

  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      warrantySelected: "no", // Initial state: "No" selected
      warrenty: [0], // Assuming initial state for warranty years
    },
  });

  const handleNameChange = (e) => {
    const newValue = e.target.value;
    setName(newValue);
    setValue("name", newValue, { shouldValidate: true });
  };

  const handleDescriptionChange = (e) => {
    const newValue = e.target.value;
    setDescription(newValue);
    setValue("description", newValue, { shouldValidate: true }); // Update react-hook-form state and trigger validation
  };

  const handleMetaKeywords = useCallback(
    (keywords) => {
      setMetas(keywords); // Update local state
      setValue("metas", keywords, { shouldValidate: true }); // Update react-hook-form state
    },
    [setValue]
  );

  const handlebarcodeChange = (e) => {
    const newValue = e.target.value;
    setBarcode(newValue);
    setValue("barcode", newValue, { shouldValidate: true });
  };
  // const handleCategoryChange = (event) => {
  //   const categoryId = parseInt(event.target.value);
  //   setSelectedCategoryId(categoryId);
  //   setValue("categoryId", categoryId, { shouldValidate: true }); // Update react-hook-form state
  // };
  // const handleCategoryChange = (event) => {
  //   const categoryId = parseInt(event.target.value);
  //   setSelectedCategoryId(categoryId);

  //   const finalCategoryId = subCategoryValue
  //     ? parseInt(subCategoryValue)
  //     : categoryId;

  //   setValue("categoryId", finalCategoryId, { shouldValidate: true }); // Update react-hook-form state
  // };
  const handleCategoryChange = (event) => {
    const categoryId = parseInt(event.target.value);
    setSelectedCategoryId(categoryId);

    // Reset subCategoryValue when category changes
    setSubCategoryValue(null); // Assuming you have a state for subCategoryValue
    setValue("subCategoryValue", null); // Update react-hook-form state

    setValue("categoryId", categoryId, { shouldValidate: true }); // Update react-hook-form state
  };

  const handleSubCategoryChange = (event) => {
    const subCategoryId = parseInt(event.target.value);
    setSubCategoryValue(subCategoryId);

    const finalCategoryId = subCategoryId ? subCategoryId : selectedCategoryId;
    setValue("categoryId", finalCategoryId, { shouldValidate: true }); // Update react-hook-form state
  };

  const watchedProductPrice = watch("productPrice");
  const watchedSalePrice = watch("salePrice");

  // Custom validation function
  const validatePrices = (value) => {
    const salePrice = parseFloat(watchedSalePrice) || 0;
    const productPrice = parseFloat(watchedProductPrice) || 0;

    if (salePrice >= productPrice) {
      return "Sale price must be less than product price";
    }
    return true;
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      const parsedValue = parseFloat(value) || 0; // Use 0 if NaN
      setProductprice(parsedValue); // Update local state
      setValue("productPrice", parsedValue, { shouldValidate: true }); // Update react-hook-form state
    } else {
      setProductprice(0); // Set to 0 if invalid
      setValue("productPrice", 0, { shouldValidate: true }); // Clear react-hook-form state if invalid input
    }
  };

  const handleSalePriceChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      const parsedValue = parseFloat(value) || 0; // Use 0 if NaN
      setSalePrice(parsedValue); // Update local state
      setValue("salePrice", parsedValue, { shouldValidate: true }); // Update react-hook-form state
    } else {
      setSalePrice(0); // Set to 0 if invalid
      setValue("salePrice", 0, { shouldValidate: true }); // Clear react-hook-form state if invalid input
    }
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      const parsedValue = parseInt(value, 10); // Parse input as integer
      setQuantity(parsedValue); // Update local state
      setValue("qnty", parsedValue, { shouldValidate: true }); // Update react-hook-form state
    }
  };
  const handleLengthChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      const parsedValue = parseFloat(value); // Parse input as float
      setLength(parsedValue); // Update local state
      setValue("length", parsedValue, { shouldValidate: true }); // Update react-hook-form state
    }
  };
  const handlewidthChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      const parsedValue = parseFloat(value); // Parse input as float
      setWidth(parsedValue); // Update local state
      setValue("width", parsedValue, { shouldValidate: true }); // Update react-hook-form state
    }
  };
  const handleheightChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      const parsedValue = parseFloat(value); // Parse input as float
      setHeight(parsedValue); // Update local state
      setValue("height", parsedValue, { shouldValidate: true }); // Update react-hook-form state
    }
  };
  const handleUnitChange = (event) => {
    const value = event.target.value;
    setUnit(value); // Update local state
    setValue("unit", value, { shouldValidate: true }); // Update react-hook-form state
  };
  // const handleSelectCollection = (event) => {
  //   const { value } = event.target;
  //   setSelectedCollectionId(value);
  //   setValue("collection", value);
  //   trigger("collection");
  // };
  const handleSelectCollection = (event) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
      setSelectedCollectionId([parsedValue]);
      setValue("collectionIds", [parsedValue]);
      trigger("collectionIds");
    }
  };
  // const handleSelectCollection = (event) => {
  //   const { value } = event.target;
  //   const parsedValue = parseInt(value, 10);
  //   if (!isNaN(parsedValue)) {
  //     setCollectionIds([parsedValue]);
  //     setValue("collectionIds", [parsedValue]);
  //     trigger("collectionIds");
  //   }
  // };
  useEffect(() => {
    register("name", {
      required: "Product name is required",
    });
    register("description", {
      required: "Product description is required",
    });
    register("images", { required: "Product images are required" });
    register("metas", {
      required: "metaKeywords is required",
    });
    register("barcode", {
      required: "Product barcode is required",
    });
    register("categoryId", {
      required: "Please select a category",
    });
    register("productPrice", {
      required: "Product price is required",
      pattern: {
        value: /^\d+(\.\d{1,2})?$/, // Validates for numeric input with up to 2 decimal places
        message: "Invalid price format",
      },
    });
    register("salePrice", {
      required: "salePrice is required",
      pattern: {
        value: /^\d+(\.\d{1,2})?$/, // Validates for numeric input with up to 2 decimal places
        message: "Invalid price format",
      },
    });
    register("qnty", {
      required: "Quantity is required",
      pattern: {
        value: /^\d+$/, // Validates for numeric input
        message: "Invalid quantity format. Must be a number.",
      },
    });
    register("length", {
      required: "Length is required",
      pattern: {
        value: /^\d+(\.\d{1,2})?$/, // Validates for numeric input with up to 2 decimal places
        message: "Invalid length format. Must be a number.",
      },
    });
    register("width", {
      required: "Width is required",
      pattern: {
        value: /^\d+(\.\d{1,2})?$/, // Validates for numeric input with up to 2 decimal places
        message: "Invalid width format. Must be a number.",
      },
    });
    register("height", {
      required: "Height is required",
      pattern: {
        value: /^\d+(\.\d{1,2})?$/, // Validates for numeric input with up to 2 decimal places
        message: "Invalid height format. Must be a number.",
      },
    });
    register("unit", {
      required: "Unit selection is required",
    });
    register("colors", {
      validate: (value) => {
        if (!value || value.length === 0) {
          return "At least one color is required";
        }
        return true;
      },
    });
    register("materials", {
      validate: (value) => {
        if (!value || value.length === 0) {
          return "Please select at least one material.";
        }
        return true;
      },
    });
    register("collectionIds", {
      validate: (value) => {
        if (!value || value.length === 0) {
          return "Please select at least one collection.";
        }
        return true;
      },
    });
  }, [register]);
  // useEffect(() => {
  //   setValue("colors", colorData);
  // }, [colorData, setValue]);
  const onSubmit = async (data) => {
    const filteredData = {
      ...data,
      productPrice: parseFloat(data.productPrice),
      salePrice: parseFloat(data.salePrice),
      colors: filterColorData(data.colors), // Remove ids from colors
    };
    console.log(filteredData);
    try {
      // Perform your form submission logic here
      const response = await productsPost(filteredData);
      if (response.status) {
        toastr.success("Product added successfully");
        navigate("/products");
        // Reset form after successful submission
        // reset();
      } else {
        alert("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      alert("An error occurred while adding the product");
    }
  };
  // console.log(name);
  return (
    <Main>
      <div className="container-fluid">
        <div className="heading-flex">
          <p className="m-0 heading">Products</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card border-0">
            <div className="card-padding">
              <p className="m-0 info">Product Info</p>
              <p className="m-0 bottom-border"></p>

              <div className="padding mt-5">
                <div className="inputs-flex">
                  <label className="label">Product Title/Name</label>
                  <div>
                    <input
                      type="text"
                      placeholder="Product Title/Name"
                      className="inputs-width"
                      value={name}
                      onChange={handleNameChange}
                    />

                    {errors.name && (
                      <p className="validation-color">{errors.name.message}</p>
                    )}
                  </div>
                </div>
                <div className="inputs-flex mt-4">
                  <h5 className="label">Product Description</h5>
                  <div>
                    <textarea
                      id="description"
                      className="inputs-description"
                      cols={5}
                      rows={5}
                      placeholder="Product Description"
                      value={description}
                      onChange={handleDescriptionChange}
                    ></textarea>
                    {errors.description && (
                      <p className="validation-color">
                        {errors.description.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Product Images</h5>
                  <div>
                    <div
                      onClick={handleImagePickerClick}
                      className="upload-img"
                      style={{ position: "relative", cursor: "pointer" }}
                    >
                      <input
                        type="file"
                        id="imageUpload"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        multiple
                      />
                      <div>
                        <p className="m-0 drag">Upload your images here</p>
                        <p className="m-0 accepted-only">
                          (Only *.jpeg, *.webp and *.png images will be
                          accepted)
                        </p>
                      </div>
                    </div>
                    {loading ? (
                      <PuffLoaderComponent />
                    ) : (
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          width: "91%",
                        }}
                      >
                        {selectedImages.map((image, index) => (
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${image}`}
                              alt={`Selected ${index}`}
                              style={{
                                width: "73px",
                                height: "73px",
                                borderRadius: "10px",
                              }}
                            />
                            <RxCross2
                              onClick={() => handleRemoveImage(index)}
                              className="Cross-btn"
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    {errors.images && (
                      <p className="validation-color">
                        {errors.images.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="inputs-flexs mt-4">
                  <h5 className="label">Meta Keywords</h5>
                  <div>
                    <ProductsInputs onChange={handleMetaKeywords} />
                    {errors.metas && (
                      <p className="validation-color">{errors.metas.message}</p>
                    )}
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Barcode</h5>
                  <div>
                    <input
                      type="text"
                      className="inputs-width"
                      placeholder="Product Barcode"
                      value={barcode}
                      onChange={handlebarcodeChange}
                    />
                    {errors.barcode && (
                      <p className="validation-color">
                        {errors.barcode.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Select Category</h5>
                  <div>
                    <select
                      className="inputs-width"
                      onChange={handleCategoryChange}
                    >
                      <option value="" hidden>
                        Select category
                      </option>
                      {categoryData.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                    {errors.categoryId && (
                      <p className="validation-color">
                        {errors.categoryId.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Select Sub Category</h5>
                  <div>
                    <select
                      className="inputs-width"
                      placeholder="Sub Category"
                      onClick={handleSubCategoryChange}
                    >
                      <option value="" hidden>
                        Select sub-category
                      </option>
                      {subCategoryData.length > 0 ? (
                        subCategoryData.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))
                      ) : (
                        <option>No subcategories available</option>
                      )}
                    </select>
                  </div>
                </div>

                {/* <div className="inputs-flex mt-4">
                  <h5 className="label">Product Price</h5>
                  <div>
                    <div className="position-relative">
                      <span className="img-dollar">
                        <img src={Dollar} alt="Dollar Sign" />
                      </span>
                      <input
                        type="number"
                        className="inputs-price"
                        name="productPrice"
                        value={productprice}
                        onChange={handlePriceChange}
                      />
                      {errors.productPrice && (
                        <p className="validation-color">
                          {errors.productPrice.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Sale Price</h5>
                  <div>
                    <div className="position-relative">
                      <span className="img-dollar">
                        <img src={Dollar} alt="Dollar Sign" />
                      </span>
                      <input
                        type="number"
                        className="inputs-price"
                        name="salePrice"
                        value={saleprice}
                        onChange={handlesalePriceChange}
                      />
                      {errors.salePrice && (
                        <p className="validation-color">
                          {errors.salePrice.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="inputs-flex mt-4">
                  <h5 className="label">Product Price</h5>
                  <div>
                    <div className="position-relative">
                      <span className="img-dollar">
                        <img src={Dollar} alt="Dollar Sign" />
                      </span>
                      <input
                        type="number"
                        className="inputs-price"
                        {...register("productPrice", {
                          required: "Product price is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/, // Validates for numeric input with up to 2 decimal places
                            message: "Invalid price format",
                          },
                        })}
                        value={productprice}
                        onChange={handlePriceChange}
                      />
                      {errors.productPrice && (
                        <p className="validation-color">
                          {errors.productPrice.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Sale Price</h5>
                  <div>
                    <div className="position-relative">
                      <span className="img-dollar">
                        <img src={Dollar} alt="Dollar Sign" />
                      </span>
                      <input
                        type="number"
                        className="inputs-price"
                        {...register("salePrice", {
                          required: "Sale price is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/, // Validates for numeric input with up to 2 decimal places
                            message: "Invalid price format",
                          },
                          validate: validatePrices, // Add custom validation
                        })}
                        value={saleprice}
                        onChange={handleSalePriceChange}
                      />
                      {errors.salePrice && (
                        <p className="validation-color">
                          {errors.salePrice.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Product Quantity</h5>
                  <div>
                    <div>
                      <input
                        type="number"
                        className="inputs-width"
                        placeholder="0"
                        name="quantity"
                        value={quantity}
                        onChange={handleQuantityChange}
                      />
                    </div>
                    {errors.qnty && (
                      <p className="validation-color">{errors.qnty.message}</p>
                    )}
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Dimension</h5>
                  <div>
                    <input
                      type="number"
                      className="inputs-height"
                      placeholder="Length"
                      value={length}
                      onChange={handleLengthChange}
                    />
                    &nbsp;
                    <RxCross2 />
                    &nbsp;
                    <input
                      type="number"
                      className="inputs-height"
                      placeholder="Width"
                      value={width}
                      onChange={handlewidthChange}
                    />
                    &nbsp;
                    <RxCross2 />
                    &nbsp;
                    <input
                      type="number"
                      className="inputs-height"
                      placeholder="Height"
                      value={height}
                      onChange={handleheightChange}
                    />
                    &nbsp;
                    <select
                      className="inputs-height"
                      value={unit}
                      onChange={handleUnitChange}
                    >
                      <option value="0">Select Unit</option>
                      <option value="Cm">Centimeter</option>
                      <option value="Inch">Inches</option>
                    </select>
                    {errors.length && (
                      <p className="validation-color">
                        {errors.length.message}
                      </p>
                    )}
                    {errors.width && (
                      <p className="validation-color">{errors.width.message}</p>
                    )}
                    {errors.height && (
                      <p className="validation-color">
                        {errors.height.message}
                      </p>
                    )}
                    {errors.unit && (
                      <p className="validation-color">{errors.unit.message}</p>
                    )}
                  </div>
                </div>

                {/* <div className="inputs-flex mt-4">
                  <label htmlFor="colorSelect" className="label">
                    Product Color
                  </label>
                  <div className="position-relative">
                    <div>
                      <div className="inputs-width">
                        <input
                          type="color"
                          value={color}
                          onChange={handleColorChange}
                          onKeyDown={handleKeyPress}
                        />
                        <p
                          style={{
                            position: "absolute",
                            top: "16px",
                            right: "0px",
                            padding: "0px 10px",
                          }}
                        >
                          {color}
                        </p>
                      </div>
                      {loading ? (
                        <PuffLoaderComponent />
                      ) : (
                        <div className="select-color">
                          {colorData.map((color, index) => (
                            <div key={color.id}>
                              <div
                                style={{
                                  backgroundColor: color.colorCode,
                                  display: "inline-block",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  margin: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span className="picked-color">
                                  {color.colorName}
                                </span>
                                <RxCross2
                                  onClick={() => removeColor(color.id)}
                                  size={20}
                                  color="white"
                                  className="cross-border"
                                />
                              </div>
                              <div style={{ position: "relative" }}>
                                <input
                                  type="file"
                                  id={`fileInput_${color.id}`}
                                  onChange={(e) =>
                                    handleImageUpload(e, color.id)
                                  }
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor={`fileInput_${color.id}`}
                                  className="color-upload-img"
                                  style={{ cursor: "pointer" }}
                                >
                                  {!color.image && (
                                    <div>
                                      <p className="m-0 drag">
                                        Click to upload images
                                      </p>
                                    </div>
                                  )}
                                </label>

                                {color.image && (
                                  <div className="mt-3">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}/${color.image}`}
                                      alt={`Selected ${color.colorName}`}
                                      className="color-img"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {errors.colors && (
                      <p className="validation-color">
                        {errors.colors.message}
                      </p>
                    )}
                  </div>
                </div> */}
                <div className="inputs-flex mt-4">
                  <label htmlFor="colorSelect" className="label">
                    Product Color
                  </label>
                  <div className="position-relative">
                    <div>
                      <div className="inputs-width">
                        <div
                          onClick={togglePicker}
                          style={{
                            width: "68px",
                            height: "28px",
                            backgroundColor: color,
                            border: "1px solid #ccc",
                            cursor: "pointer",
                          }}
                        ></div>
                        <p
                          style={{
                            position: "absolute",
                            top: "16px",
                            right: "0px",
                            padding: "0px 10px",
                          }}
                        >
                          {color}
                        </p>
                      </div>
                      {showPicker && (
                        <div style={{ position: "absolute", zIndex: 2 }}>
                          <SketchPicker
                            color={color}
                            onChange={handleColorChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              background: "white",
                            }}
                          >
                            <button onClick={handleApply} className="apply-btn">
                              Apply
                            </button>
                            <button
                              onClick={handleCancel}
                              className="cancel-btn"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                      {loading ? (
                        <PuffLoaderComponent />
                      ) : (
                        <div className="select-color">
                          {colorData.map((color, index) => (
                            <div key={color.id}>
                              <div
                                style={{
                                  backgroundColor: color.colorCode,
                                  display: "inline-block",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  margin: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span className="picked-color">
                                  {color.colorName}
                                </span>
                                <RxCross2
                                  onClick={() => removeColor(color.id)}
                                  size={20}
                                  color="white"
                                  className="cross-border"
                                />
                              </div>
                              <div style={{ position: "relative" }}>
                                <input
                                  type="file"
                                  id={`fileInput_${color.id}`}
                                  onChange={(e) =>
                                    handleImageUpload(e, color.id)
                                  }
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor={`fileInput_${color.id}`}
                                  className="color-upload-img"
                                  style={{ cursor: "pointer" }}
                                >
                                  {!color.image && (
                                    <div>
                                      <p className="m-0 drag">
                                        Click to upload images
                                      </p>
                                    </div>
                                  )}
                                </label>

                                {color.image && (
                                  <div className="mt-3">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL}/${color.image}`}
                                      alt={`Selected ${color.colorName}`}
                                      className="color-img"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {errors.colors && (
                      <p className="validation-color">
                        {errors.colors.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Product Material</h5>
                  <div>
                    <MaterialTags
                      materials={material}
                      materialId={materialId}
                      setSelectedIds={setSelectedIds}
                      onChangetags={handleMaterialSelect}
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      trigger={trigger}
                    />
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Select Collection</h5>
                  <div>
                    <select
                      className="inputs-width"
                      placeholder="Category"
                      onChange={handleSelectCollection}
                      value={selectedCollectionId}
                    >
                      <option value="" hidden>
                        Select Collections
                      </option>
                      {collectionsData.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {errors.collectionIds && (
                      <p className="validation-color">
                        {errors.collectionIds.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="inputs-flex mt-4">
                  <h5 className="label">Warranty</h5>
                  <div className="span-select-flex">
                    <div className="span-flex">
                      <span className="span-space">
                        <input
                          type="radio"
                          {...register("warrantySelected")}
                          value="no"
                          style={{ width: "18px", cursor: "pointer" }}
                        />
                        No
                      </span>
                      &nbsp;
                      <span className="span-space">
                        <input
                          type="radio"
                          {...register("warrantySelected")}
                          value="yes"
                          style={{ width: "18px", cursor: "pointer" }}
                        />
                        Yes
                      </span>
                    </div>
                    <div>
                      {/* {errors.warrantySelected && (
                        <p className="validation-color">
                          {errors.warrantySelected.message}
                        </p>
                      )} */}
                      {watch("warrantySelected") === "yes" && (
                        <Controller
                          name="warrenty"
                          control={control}
                          rules={{
                            required: "Please select warranty years.",
                            validate: (value) =>
                              value !== "0" || "Please select warranty years.",
                          }}
                          render={({ field }) => (
                            <select
                              className="warranty-input"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            >
                              <option value="0">Select Warranty years</option>
                              <option value="1">1 Year</option>
                              <option value="2">2 Year</option>
                              <option value="3">3 Year</option>
                              <option value="4">4 Year</option>
                              <option value="5">5 Year</option>
                              <option value="6">6 Year</option>
                              <option value="7">7 Year</option>
                              <option value="8">8 Year</option>
                              <option value="9">9 Year</option>
                              <option value="10">10 Year</option>
                            </select>
                          )}
                        />
                      )}
                      {errors.warrenty && (
                        <p className="validation-color">
                          {errors.warrenty.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="buttons-flex">
                  <button type="button" className="Cancel">
                    Cancel
                  </button>
                  <button type="submit" className="Add">
                    Add Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Main>
  );
};

export default AddProducts;

// import React, { useState, useEffect } from "react";
// import { Main } from "./style";
// import { RxCross2 } from "react-icons/rx";
// import Dollar from "../../../assets/img/add-products/$.png";
// import ProductsInputs from "components/products-inputs";
// import MaterialTags from "components/product-material-tags";
// import useHooks from "./useHook";
// import toastr from "toastr";
// import Spiner from "components/spinner";
// import { Navigate, useNavigate } from "react-router-dom";
// const AddProducts = () => {
//   const [name, setName] = useState("");
//   const [description, setDescription] = useState("");
//   const [metas, setMetas] = useState([]);
//   const [barcode, setBarcode] = useState("");
//   const [productprice, setProductprice] = useState();
//   const [saleprice, setSalePrice] = useState();
//   const [quantity, setQuantity] = useState();
//   const [length, setLength] = useState();
//   const [width, setWidth] = useState();
//   const [height, setHeight] = useState();
//   const [unit, setUnit] = useState("");
//   const [warrenty, setWarrenty] = useState([]);
//   const [selected, setSelected] = useState(null);
//   const [selectedImages, setSelectedImages] = useState([]);
//   const [color, setColor] = useState("");
//   const [colorData, setColorData] = useState([]);
//   const [categoryData, setCategoryData] = useState([]);
//   const [subCategoryData, setSubCategoryData] = useState([]);
//   const [selectedCategoryId, setSelectedCategoryId] = useState([]);
//   const [material, setMaterial] = useState([]);
//   const [materialId, setMaterialId] = useState([]);
//   const [collectionsData, setCollectionsData] = useState([]);
//   const [collectionIds, setCollectionIds] = useState([]);
//   const [selectedCollectionId, setSelectedCollectionId] = useState([]);
//   const [subCategoryValue, setSubCategoryValue] = useState([]);
//   const [loading, setloading] = useState(false);
//   const [selectedIds, setSelectedIds] = useState([]);

//   console.log(selectedImages, "selected images");
//  const navigate = useNavigate()
//   const { getcategory, getmaterial, imagePost, getcollections, productsPost } =
//     useHooks();

//   useEffect(() => {
//     const getCategoryData = async () => {
//       try {
//         const response = await getcategory();
//         const activeCategories = response.data.filter(
//           (category) => category.isActive === true
//         );
//         setCategoryData(activeCategories);
//         if (activeCategories.length > 0) {
//           setSelectedCategoryId(activeCategories[0].id);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     getCategoryData();
//   }, []);

//   useEffect(() => {
//     const fetchSubCategories = async (categoryId) => {
//       try {
//         const res = await getcategory({ parentId: categoryId });
//         const activeSubCategories = res.data.filter(
//           (subcategory) => subcategory.isActive === true
//         );
//         setSubCategoryData(activeSubCategories);
//         return activeSubCategories;
//       } catch (error) {
//         console.error(error);
//         setSubCategoryData([]);
//       }
//     };
//     if (selectedCategoryId !== null) {
//       fetchSubCategories(selectedCategoryId);
//     }
//   }, [selectedCategoryId]);

//   useEffect(() => {
//     const fetchMaterialData = async () => {
//       const page = 1;
//       const perPage = 40;
//       try {
//         const params = { page, perPage };
//         const res = await getmaterial(params);
//         if (res.status === true) {
//           setMaterial(res.data);
//           const ids = res.data.map((material) => material.id);
//           setMaterialId(ids);
//         }
//       } catch (error) {
//         console.error("Error fetching material data:", error);
//       }
//     };

//     fetchMaterialData();
//   }, []);

//   const handleMaterialSelect = (event) => {
//     const selectedId = event.target.value;
//     console.log("Selected Material ID:", selectedId);
//   };

//   useEffect(() => {
//     const getcollectionsData = async () => {
//       const userId = localStorage.getItem("id");
//       const response = await getcollections({ userId });
//       try {
//         if (response.status === true) {
//           setCollectionsData(response.data.results);
//         }
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getcollectionsData();
//   }, []);

//   useEffect(() => {
//     const ids = collectionsData.map((collection) => collection.id);
//     setCollectionIds(ids);
//   }, [collectionsData]);

//   const handleCategoryChange = (event) => {
//     setSelectedCategoryId(parseInt(event.target.value));
//   };

//   const handleSubCategoryChange = (event) => {
//     setSubCategoryValue(parseInt(event.target.value));
//   };
//   const handleCheckboxChange = (value) => {
//     setSelected(value);
//   };

//   const handleSelectCollection = (event) => {
//     const selectedId = event.target.value;
//     setSelectedCollectionId(selectedId);
//   };

//   const handleImagePickerClick = () => {
//     document.getElementById("imageUpload").click();
//   };

//   const handleImageChange = async (e) => {
//     const files = Array.from(e.target.files);
//     try {
//       const imagePaths = await Promise.all(
//         files.map((file) => imagePost(file))
//       );
//       if (imagePaths) {
//         const newImages = imagePaths.map((path) => `${path}`);
//         setSelectedImages((prevImages) => [...prevImages, ...newImages]);
//       }
//     } catch (error) {
//       console.error("Error in handleImageChange: ", error);
//       alert("An error occurred during image upload");
//     }
//   };

//   const handleRemoveImage = (index) => {
//     setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
//   };

//   const handlePriceChange = (e) => {
//     const value = e.target.value;
//     if (value >= 0) {
//       setProductprice(value);
//     }
//   };

//   const handleSaleChange = (e) => {
//     const value = e.target.value;
//     if (value >= 0) {
//       setSalePrice(value);
//     }
//   };

//   const handleLength = (e) => {
//     const value = e.target.value;
//     if (value >= 0) {
//       setLength(value);
//     }
//   };

//   const handleWidth = (e) => {
//     const value = e.target.value;
//     if (value >= 0) {
//       setWidth(value);
//     }
//   };

//   const handleHeight = (e) => {
//     const value = e.target.value;
//     if (value >= 0) {
//       setHeight(value);
//     }
//   };

//   const handleMetaKeywords = (Keywords) => {
//     setMetas(Keywords);
//   };

//   const handlechange = (value) => {
//     setSelected(value);
//   };

//   const handleColorChange = (e) => {
//     setColor(e.target.value);
//   };

//   const handleMaterialTagsChange = (selectedMaterials) => {
//     setMaterial(selectedMaterials);
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       setColorData([
//         ...colorData,
//         { id: Date.now(), name: color, code: color },
//       ]);
//       setColor("");
//     }
//   };

//   const removeColor = (id) => {
//     setColorData(colorData.filter((color) => color.id !== id));
//   };

//   const handleImageUpload = async (e, colorId) => {
//     const files = Array.from(e.target.files);
//     try {
//       const imagePaths = await Promise.all(
//         files.map((file) => imagePost(file))
//       );
//       if (imagePaths) {
//         const newImages = imagePaths.map(
//           (path) => ` ${process.env.REACT_APP_BASE_URL}/${path}`
//         );
//         const updatedColorData = colorData.map((color) => {
//           if (color.id === colorId) {
//             return {
//               ...color,
//               images: [...(color.images || []), ...newImages],
//             };
//           }
//           return color;
//         });
//         setColorData(updatedColorData);
//       }
//     } catch (error) {
//       console.error("Error in handleImageUpload: ", error);
//       alert("An error occurred during image upload");
//     }
//   };

//   const handleCreateProducts = async (e) => {
//     e.preventDefault();
//     const productData = {
//       name,
//       description: description,
//       images: selectedImages,
//       metas: metas,
//       barcode,
//       productPrice: parseFloat(productprice),
//       salePrice: parseFloat(saleprice),
//       qnty: parseInt(quantity, 10),
//       length: parseFloat(length),
//       width: parseFloat(width),
//       height: parseFloat(height),
//       unit,
//       warrenty: [parseInt(warrenty)],
//       colors: colorData.map((color) => ({
//         colorName: color.name,
//         colorCode: color.code,
//         image: color.images,
//       })),
//       categoryId: subCategoryValue
//         ? parseInt(subCategoryValue)
//         : selectedCategoryId,
//       materials: selectedIds,
//       collectionIds: [parseInt(collectionIds)],
//     };
//     console.log(productData.categoryId);
//     try {
//       const response = await productsPost(productData);
//       if (response.status) {
//         toastr.success("Product added successfully");
//         setName("");
//         setDescription("");
//         setMetas([]);
//         setBarcode("");
//         setProductprice("");
//         setSalePrice("");
//         setQuantity("");
//         setLength("");
//         setWidth("");
//         setHeight("");
//         setUnit([]);
//         setWarrenty("");
//         setSelected(null);
//         setSelectedImages([]);
//         setColor("");
//         setColorData([]);
//         setSelectedCategoryId(null);
//         setMaterial([]);
//         setMaterialId([]);
//         setCollectionsData([]);
//         setCollectionIds([]);
//         setSelectedCollectionId(null);
//       } else {
//         toastr.error("Please filled all the input fields");
//       }
//     } catch (error) {
//       console.error("Error adding product:", error);
//       alert("An error occurred while adding the product");
//     } finally {
//       setloading(true);
//       navigate("/products");
//     }
//   };

//   return (
//     <Main>
//       {loading ? (
//         <Spiner />
//       ) : (
//         <div className="container-fluid">
//           <div className="heading-flex">
//             <p className="m-0 heading">Products</p>
//           </div>
//           <form onSubmit={handleCreateProducts}>
//             <div className="card border-0">
//               <div className="card-padding">
//                 <p className="m-0 info">Product Info</p>
//                 <p className="m-0 bottom-border"></p>

//                 <div className="padding mt-5">
//                   <div className="inputs-flex">
//                     <h5 className="label">Product Title/Name</h5>
//                     <input
//                       type="text"
//                       name="name"
//                       value={name}
//                       placeholder="Product Title/Name"
//                       className="inputs-width"
//                       onChange={(e) => setName(e.target.value)}
//                     />
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Product Description</h5>
//                     <textarea
//                       className="inputs-description"
//                       cols={5}
//                       rows={5}
//                       placeholder="Product Description"
//                       name="description"
//                       value={description}
//                       onChange={(e) => setDescription(e.target.value)}
//                     ></textarea>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Product Images</h5>
//                     <div>
//                       <div
//                         onClick={handleImagePickerClick}
//                         className="upload-img"
//                         style={{ position: "relative", cursor: "pointer" }}
//                       >
//                         <input
//                           type="file"
//                           id="imageUpload"
//                           onChange={handleImageChange}
//                           style={{ display: "none" }}
//                           multiple
//                         />
//                         <div>
//                           <p className="m-0 drag">Drag your images here</p>
//                           <p className="m-0 accepted-only">
//                             (Only *.jpeg, *.webp and *.png images will be
//                             accepted)
//                           </p>
//                         </div>
//                       </div>
//                       <div
//                         className="mt-3"
//                         style={{
//                           display: "flex",
//                           flexWrap: "wrap",
//                           gap: "10px",
//                           width: "91%",
//                         }}
//                       >
//                         {selectedImages.map((image, index) => (
//                           <div
//                             key={index}
//                             style={{
//                               position: "relative",
//                               display: "inline-block",
//                             }}
//                           >
//                             <img
//                               src={`${process.env.REACT_APP_BASE_URL}${image}`}
//                               alt={`Selected ${index}`}
//                               style={{
//                                 width: "73px",
//                                 height: "73px",
//                                 borderRadius: "10px",
//                               }}
//                             />
//                             <RxCross2
//                               onClick={() => handleRemoveImage(index)}
//                               className="Cross-btn"
//                             />
//                           </div>
//                         ))}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Meta Keywords</h5>
//                     <ProductsInputs onChange={handleMetaKeywords} />
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Barcode</h5>
//                     <div>
//                       <input
//                         type="text"
//                         className="inputs-width"
//                         placeholder="Product Barcode"
//                         name="barcode"
//                         value={barcode}
//                         onChange={(e) => setBarcode(e.target.value)}
//                       />
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Select Category</h5>
//                     <div>
//                       <select
//                         className="inputs-width"
//                         onChange={handleCategoryChange}
//                       >
//                         <option value="" hidden>
//                           Select category
//                         </option>
//                         {categoryData.map((category) => (
//                           <option key={category.id} value={category.id}>
//                             {category.name}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Select Sub Category</h5>
//                     <div>
//                       <select
//                         className="inputs-width"
//                         placeholder="Sub Category"
//                         onClick={handleSubCategoryChange}
//                       >
//                         <option value="" hidden>
//                           Select sub-category
//                         </option>
//                         {subCategoryData.length > 0 ? (
//                           subCategoryData.map((item, index) => (
//                             <option key={index} value={item.id}>
//                               {item.name}
//                             </option>
//                           ))
//                         ) : (
//                           <option>No subcategories available</option>
//                         )}
//                       </select>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Product Price</h5>
//                     <div>
//                       <div className="position-relative">
//                         <span className="img-dollar">
//                           <img src={Dollar} alt="Dollar Sign" />
//                         </span>
//                         <input
//                           type="number"
//                           className="inputs-price"
//                           name="productPrice"
//                           value={productprice}
//                           onChange={handlePriceChange}
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Sale Price</h5>
//                     <div>
//                       <div className="position-relative">
//                         <span className="img-dollar">
//                           <img src={Dollar} alt="Dollar Sign" />
//                         </span>
//                         <input
//                           type="number"
//                           className="inputs-price"
//                           name="salePrice"
//                           value={saleprice}
//                           onChange={handleSaleChange}
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Product Quantity</h5>
//                     <div>
//                       <input
//                         type="number"
//                         className="inputs-width"
//                         placeholder="0"
//                         name="quantity"
//                         value={quantity}
//                         onChange={(e) => setQuantity(e.target.value)}
//                       />
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Dimension</h5>
//                     <div>
//                       <input
//                         type="number"
//                         className="inputs-height"
//                         placeholder="Length"
//                         value={length}
//                         onChange={handleLength}
//                       />
//                       &nbsp;
//                       <RxCross2 />
//                       &nbsp;
//                       <input
//                         type="number"
//                         className="inputs-height"
//                         placeholder="Width"
//                         value={width}
//                         onChange={handleWidth}
//                       />
//                       &nbsp;
//                       <RxCross2 />
//                       &nbsp;
//                       <input
//                         type="number"
//                         className="inputs-height"
//                         placeholder="Height"
//                         value={height}
//                         onChange={handleHeight}
//                       />
//                       &nbsp;
//                       <select
//                         className="inputs-height"
//                         value={unit}
//                         onChange={(e) => setUnit(e.target.value)}
//                       >
//                         <option value={0}>Select Unit</option>
//                         <option value={2}>Centimeter</option>
//                         <option value={3}>Inches</option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <label htmlFor="colorSelect" className="label">
//                       Product Color
//                     </label>
//                     <div className="position-relative">
//                       <div>
//                         <div className="inputs-width">
//                           <input
//                             type="color"
//                             value={color}
//                             onChange={handleColorChange}
//                             onKeyDown={handleKeyPress}
//                           />
//                           <p
//                             style={{
//                               position: "absolute",
//                               top: "16px",
//                               right: "0px",
//                               padding: "0px 10px",
//                             }}
//                           >
//                             {color}
//                           </p>
//                         </div>
//                         <div className="select-color">
//                           {colorData.map((color) => (
//                             <div key={color.id}>
//                               <div
//                                 style={{
//                                   backgroundColor: color.code,
//                                   display: "inline-block",
//                                   padding: "5px",
//                                   borderRadius: "5px",
//                                   margin: "5px",
//                                   position: "relative",
//                                   display: "flex",
//                                   justifyContent: "center",
//                                   alignItems: "center",
//                                 }}
//                               >
//                                 <span className="picked-color">
//                                   {color.name}
//                                 </span>
//                                 <RxCross2
//                                   onClick={() => removeColor(color.id)}
//                                   size={20}
//                                   color="white"
//                                   className="cross-border"
//                                 />
//                               </div>
//                               <div style={{ position: "relative" }}>
//                                 <input
//                                   type="file"
//                                   id={`fileInput_${color.id}`}
//                                   onChange={(e) =>
//                                     handleImageUpload(e, color.id)
//                                   }
//                                   style={{ display: "none" }}
//                                   multiple
//                                 />
//                                 <div
//                                   className="color-upload-img"
//                                   onClick={() =>
//                                     document
//                                       .getElementById(`fileInput_${color.id}`)
//                                       .click()
//                                   }
//                                   style={{
//                                     position: "relative",
//                                     cursor: "pointer",
//                                   }}
//                                 >
//                                   <div>
//                                     <p className="m-0 drag">
//                                       Click to upload images
//                                     </p>
//                                   </div>
//                                 </div>
//                                 <div
//                                   className="mt-3"
//                                   style={{
//                                     display: "flex",
//                                     flexWrap: "wrap",
//                                     gap: "10px",
//                                   }}
//                                 >
//                                   {color.images &&
//                                     color.images.map((image, index) => (
//                                       <div key={index}>
//                                         <img
//                                           src={image}
//                                           alt={`Selected ${index}`}
//                                           className="color-img"
//                                         />
//                                         <div
//                                           style={{
//                                             position: "absolute",
//                                             top: "0",
//                                             right: "0",
//                                             cursor: "pointer",
//                                           }}
//                                         ></div>
//                                       </div>
//                                     ))}
//                                 </div>
//                               </div>
//                             </div>
//                           ))}
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Product Material</h5>
//                     <div>
//                       <MaterialTags
//                         materials={material}
//                         materialId={materialId}
//                         setSelectedIds={setSelectedIds}
//                         onChangetags={handleMaterialSelect}
//                       />
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Select Collection</h5>
//                     <div>
//                       <select
//                         className="inputs-width"
//                         placeholder="Category"
//                         onChange={handleSelectCollection}
//                       >
//                         <option value="" hidden>
//                           Select Collections
//                         </option>
//                         {collectionsData.map((item) => (
//                           <option key={item.id} value={item.id}>
//                             {item.name}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>

//                   <div className="inputs-flex mt-4">
//                     <h5 className="label">Warranty</h5>
//                     <div className="span-select-flex">
//                       <div className="span-flex">
//                         <span className="span-space">
//                           <input
//                             type="checkbox"
//                             checked={selected === "no"}
//                             onChange={() => handleCheckboxChange("no")}
//                             style={{ width: "18px", cursor: "pointer" }}
//                           />
//                           No
//                         </span>
//                         &nbsp;
//                         <span className="span-space">
//                           <input
//                             type="checkbox"
//                             checked={selected === "yes"}
//                             onChange={() => handleCheckboxChange("yes")}
//                             style={{ width: "18px", cursor: "pointer" }}
//                           />
//                           Yes
//                         </span>
//                       </div>
//                       <div>
//                         <select
//                           className="warranty-input"
//                           value={warrenty}
//                           onChange={(e) => setWarrenty(e.target.value)}
//                         >
//                           <option value="0" hidden>
//                             Select Warranty years
//                           </option>
//                           <option value="1">1 Year</option>
//                           <option value="2">2 Year</option>
//                         </select>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="text-end mt-4 pb-4">
//                     <button type="submit" className="Cancel">
//                       Cancel
//                     </button>
//                     &nbsp;
//                     <button type="submit" className="Add">
//                       Add Product
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       )}
//     </Main>
//   );
// };

// export default AddProducts;
