import React from 'react';
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from '@mui/material/Button';
import { Main } from './style';
import { IoCloseSharp } from "react-icons/io5";
import { Padding } from '@mui/icons-material';
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    height: 250,
    bgcolor: "background.paper",
    border: "0px",
    boxShadow: 24,
    borderRadius: "12px",
    "@media (max-width: 425px)": {
      width: "90%",
      height: "auto",
      padding: "0px 0px 15px 0px",
    },
  };

const DeleteModal = ({ open, handleClose, handleDelete }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Main>
        <Box sx={style}>
        <div className='d-flex flex-row justify-content-between align-items-center heading'>
        <Typography variant="h6" >
          </Typography>
        <Typography variant="h6" style={{fontSize:"20px",fontWeight:"bold"}}>
            Confirm Delete
          </Typography>
          <IoCloseSharp style={{fontSize:"30px",cursor:"pointer"}} onClick={handleClose} />
        </div>
          <Box className='text-container' >
          <Typography variant="body1" style={{fontSize:"20px"}} gutterBottom>
            Are you sure you want to delete this item?
          </Typography>
          <div className='btn-flex'>
            <button variant="contained" className='filter' onClick={handleDelete}>
              Yes
            </button>
            <button variant="contained" className="reset" onClick={handleClose}>
              No
            </button>
          </div>
          </Box>
        </Box>
      </Main>
    </Modal>
  );
};

export default DeleteModal;
