import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { MdClose } from "react-icons/md";
import plus from "../../../assets/img/vendor-profile-edit/Plus.svg";
import Arrow from "../../../assets/img/vendor-profile-edit/Upload Big Arrow.svg";
import Lotus from "../../../assets/img/vendor-profile-edit/Group.png";
import { Main } from "./style";
import { useForm } from "react-hook-form";
import usehook from "./usehook";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
const TabsWrapper = styled.div`
  display: flex;
  margin-bottom: 17px;
  justify-content: center;
  gap: 10%;
`;
const Tab = styled.div`
  font-family: inter;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  color: ${({ active }) => (active ? "#1C1C27" : "#D9D9D9")};
  border-bottom: ${({ active }) => (active ? "2px solid #808D9E" : "none")};
  &:hover {
    color: #1c1c27;
  }
`;
const TabContent = styled.div`
  padding: 20px;
`;
const NextButton = styled.button`
  margin-top: 20px;
  padding: 10px 40px;
  background-color: #156a3d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;
const CreateVendorButton = styled.button`
  margin-top: 20px;
  padding: 10px 40px;
  background-color: #156a3d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;
const CheckIcon = styled(FaCheck)`
  margin-left: 10px;
  color: white;
  background-color: black;
  padding: 3px;
  border-radius: 12px;
`;
const CustomTabs = () => {
  const { BussinessInfo, imageUpload, AdditionalInformation, getProfile } =
    usehook();
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();
  //  Validations
  // #==================================================

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm();
  const onSubmit = async (data) => {
    const apiPayload = {
      businessLogo: bussinesslogo, // State variable
      businessBannerImage: backgroundimg, // State variable
      dba: data.Dba, // From form input
      businessType: data.Bussiness, // From form input
      businessLicenseNo: data.Licence, // From form input
      businessSsin: data.SSN, // From form input
      businessStartDate: data.Date, // From form input
      businessLicenseImage: businessLicense, // You would handle this similar to the logo and banner image
    };
  };

  // #=========================  PostBusinessInfo  ===================================
  const postBusinessInfo = async (apiPayload) => {
    try {
      const response = await BussinessInfo(apiPayload);
      // Handle the response if needed
      return response;
    } catch (error) {
      console.log("Error posting business info:", error);
      throw error; // Re-throw the error to handle it in onSubmit if needed
    }
  };

  // #=========================  getprofile  ===================================
  // const getprofile = async () => {
  //   try {
  //     const response = await getProfile(); // Assuming getProfile is a function that fetches the profile data
  //     setProfile(response); // Ensure setProfile matches the state setter's name
  //     console.log("Profile Response:", response);

  //     // Check if businessSsin exists and is not empty
  //     const businessSsin = response.data?.businessInfo?.businessSsin;
  //     const description = response.data?.businessInfo?.description;
  //     if (businessSsin) {
  //       console.log("Business SSN found:", businessSsin);
  //       setCompletedTabs([...completedTabs, activeTab]);
  //       setActiveTab(activeTab + 1);
  //     }
  //     if (description) {
  //       console.log("Description found:", description);
  //       setCompletedTabs([...completedTabs, activeTab]);
  //       toastr.success("Additional info saved successfully.");

  //       navigate("/blank-page");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching profile:", error);
  //     throw error; // Re-throw the error to handle it elsewhere if needed
  //   }
  // };

  const getprofile = async () => {
    try {
      const response = await getProfile(); // Assuming getProfile is a function that fetches the profile data
      setProfile(response); // Ensure setProfile matches the state setter's name
      console.log("Profile Response:", response);

      // Check if businessSsin exists and is not empty
      const businessSsin = response.data?.businessInfo?.businessSsin;
      const description = response.data?.businessInfo?.description;

      if (businessSsin) {
        setCompletedTabs([...completedTabs, activeTab]);
        setActiveTab(activeTab + 1);
        // toastr.success("Business SSN found. Proceeding to the next tab...");
      }

      if (description) {
        console.log("Description found:", description);
        toastr.success("Additional info saved successfully.");
        navigate("/blank-page");
      }
    } catch (error) {
      console.log("Error fetching profile:", error);
      toastr.error("Failed to fetch profile information. Please try again.");
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  };

  useEffect(() => {
    getprofile(); // Call the getprofile function inside useEffect
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const [completedTabs, setCompletedTabs] = useState([]);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);

  // const handleNext = async () => {
  //   // Trigger validation for the current tab
  //   const isValid = await trigger();
  //   console.log("Validation status:", isValid);

  //   if (isValid) {
  //     const apiPayload = {
  //       businessLogo: bussinesslogo,
  //       businessBannerImage: backgroundimg,
  //       dba: watch("Dba"),
  //       businessType: watch("Bussiness"),
  //       businessLicenseNo: watch("Licence"),
  //       businessSsin: watch("SSN"),
  //       businessStartDate: watch("Date"),
  //       businessLicenseImage: businessLicense,
  //     };

  //     console.log("API Payload:", apiPayload);

  //     try {
  //       const response = await postBusinessInfo(apiPayload);
  //       console.log("API Response:", response);

  //       if (response?.status === true) {
  //         getprofile();
  //         const { businessSsin } = response.data.businessInfo;
  //         console.log("Business SSN:", businessSsin);

  //         if (businessSsin) {
  //           console.log("Proceeding to the next tab...");
  //           setCompletedTabs((prev) => [...prev, activeTab]);
  //           setActiveTab((prev) => prev + 1);
  //           setApiCallSuccess(true);
  //         } else {
  //           toastr.error("Business SSN not found. Cannot proceed.");
  //         }
  //       } else {
  //         toastr.error("Business info already saved!");
  //       }
  //     } catch (error) {
  //       console.error("Error in API call:", error);
  //       toastr.error("Failed to save business info. Please try again.");
  //     }
  //   } else {
  //     toastr.error("Validation failed. Please check your input.");
  //   }
  // };

  const handleNext = async () => {
    // Trigger validation for the current tab
    const isValid = await trigger();
    console.log("Validation status:", isValid);

    if (isValid) {
      const apiPayload = {
        businessLogo: bussinesslogo,
        businessBannerImage: backgroundimg,
        dba: watch("Dba"),
        businessType: watch("Bussiness"),
        businessLicenseNo: watch("Licence"),
        businessSsin: watch("SSN"),
        businessStartDate: watch("Date"),
        businessLicenseImage: businessLicense,
        businessName: watch("bussinessname"),
      };

      console.log("API Payload:", apiPayload);

      try {
        const response = await postBusinessInfo(apiPayload);
        console.log("API Response:", response);

        if (response?.status === true) {
          // Call getprofile to check if SSN is saved and handle tab navigation
          await getprofile();
        } else {
          toastr.error("Business info already saved!");
        }
      } catch (error) {
        console.error("Error in API call:", error);
        toastr.error("Failed to save business info. Please try again.");
      }
    } else {
      toastr.error("Validation failed. Please check your input.");
    }
  };

  const tabs = ["Business Information", "Additional Information"];
  const handleTabClick = (index) => {
    if (index <= activeTab || !apiCallSuccess) {
      // Allow navigation if it's the current or a completed tab
      setActiveTab(index);
    }
  };
  const handleCreateVendor = async () => {
    const isValid = await trigger();
    if (isValid) {
      const apiPayload = {
        description: watch("Description"), // From form input
        aboutShop: watch("shop"), // From form input
        addressLine1: watch("PhysicalAddress1"), // From form input
        addressLine2: watch("PhysicalAddress2"), // From form input
        state: watch("State"), // From form input
        city: watch("City"), // You would handle this similar to the logo and banner image
        zip: watch("ZipCode"), // You would handle this similar to the logo and banner image
      };

      const response = await AdditionalInformation(apiPayload);

      if (response.status === true) {
        // Handle successful response
        console.log(response);
        setCompletedTabs([...completedTabs, activeTab]);
        setApiCallSuccess(true);
        toastr.success("Additional info saved successfully.");
        navigate("/blank-page");
      } else {
        toastr.error("An error occur");
        // Display all error messages
      }
    }
  };

  //   //  for upload img
  //   // #===========================================================
  //   const [profileImage, setProfileImage] = useState(null);

  //   const handleImagePickerClick = () => {
  //     document.getElementById("profileImageInput").click();
  //   };

  //   const handleImageChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //       console.log("file is ", file);
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         setProfileImage(reader.result); // This should trigger a re-render
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   };
  //   // #===========================================================

  // for bussinees logo
  // #===========================================================

  const [bussinesslogo, setbussinesslogo] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlelogoPickerClick = () => {
    document.getElementById("profilelogoInput").click();
  };

  const handlelogoChange = async (event) => {
    const file = event.target.files[0];

    // Validation for file type and size
    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    try {
      setLoading(true);

      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        console.log(`File ${file.name} is not allowed (type: ${file.type})`);
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        console.log(
          `File ${file.name} exceeds the size limit (max: ${maxSize} bytes)`
        );
        return;
      }

      // Pass the file directly to the imageUpload function
      const uploadedImagePath = await imageUpload(file);
      if (uploadedImagePath) {
        console.log("Image uploaded successfully:", uploadedImagePath);
        setbussinesslogo(uploadedImagePath); // Update the state with the uploaded image path
      }
    } catch (error) {
      console.error("Error in handlelogoChange: ", error);
      alert("An error occurred during image upload");
    } finally {
      setLoading(false);
    }
  };

  // #===========================================================
  // for background img
  const [backgroundimg, setbackgroundimg] = useState(null);

  const handlebackimgPickerClick = () => {
    document.getElementById("profilebackimgInput").click();
  };

  const handlebackimgChange = async (event) => {
    const file = event.target.files[0];

    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxSize = 2 * 1024 * 1024;

    try {
      setLoading(true);

      if (!allowedTypes.includes(file.type)) {
        console.log(`File ${file.name} is not allowed (type: ${file.type})`);
        return;
      }

      if (file.size > maxSize) {
        console.log(
          `File ${file.name} exceeds the size limit (max: ${maxSize} bytes)`
        );
        return;
      }

      const uploadedImagePath = await imageUpload(file);
      if (uploadedImagePath) {
        console.log(
          "Background image uploaded successfully:",
          uploadedImagePath
        );
        setbackgroundimg(uploadedImagePath);
      }
    } catch (error) {
      console.error("Error in handlebackimgChange: ", error);
      alert("An error occurred during background image upload");
    } finally {
      setLoading(false);
    }
  };
  // #===========================================================

  // Bussiness License
  // #===========================================================

  const [businessLicense, setBusinessLicense] = useState(null);

  const handleLicensePickerClick = () => {
    document.getElementById("businessLicenseInput").click();
  };

  const handleLicenseChange = async (event) => {
    const file = event.target.files[0];

    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    const maxSize = 5 * 1024 * 1024; // 5 MB

    try {
      setLoading(true);

      if (!allowedTypes.includes(file.type)) {
        console.log(`File ${file.name} is not allowed (type: ${file.type})`);
        return;
      }

      if (file.size > maxSize) {
        console.log(
          `File ${file.name} exceeds the size limit (max: ${maxSize} bytes)`
        );
        return;
      }

      const uploadedFilePath = await imageUpload(file);
      if (uploadedFilePath) {
        console.log(
          "Business license uploaded successfully:",
          uploadedFilePath
        );
        setBusinessLicense(uploadedFilePath);
      }
    } catch (error) {
      console.error("Error in handleLicenseChange: ", error);
      alert("An error occurred during business license upload");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="card border-0"
        style={{
          position: "absolute",
          // top: "50%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          // padding: "40px 0px 0px 0px",
          flexDirection: "column",

          width: "100%",
          height: "auto",
          backgroundColor: "#F9F9F9",
        }}
      >
        <div className="card-body">
          <TabsWrapper>
            {/* {tabs.map((tab, index) => (
              <Tab
                key={index}
                active={activeTab === index}
                onClick={() => setActiveTab(index)}
              >
                {tab}
                {completedTabs.includes(index) && <CheckIcon />}
              </Tab>
            ))} */}
            {/* {tabs.map((tab, index) => (
              <Tab
                key={index}
                active={activeTab === index}
                onClick={() => handleTabClick(index)}
                disabled={index > activeTab && !completedTabs.includes(index)} // Disable future tabs if not completed
              >
                {tab}
                {completedTabs.includes(index) && <CheckIcon />}
              </Tab>
            ))} */}
          </TabsWrapper>
          <TabContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* {activeTab === 0 && (
                <>
                  <div className="Vendor-Information-parent-1 ">

                    <div className="Vendor-Information-child-1">
                      <label className="main-label">Profile Picture</label>
                      <div>
                        <div
                          className="Vendor-Information-border profile-pic"
                          onClick={handleImagePickerClick}
                        >
                          {console.log("hahahahha",profileImage)}
                          {profileImage ? (
                            <img
                              src={profileImage}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <>
                              <img
                                src={Lotus}
                                alt=""
                                style={{ width: "100%" }}
                              />
                              <div>
                                <img
                                  src={plus}
                                  alt="Add"
                                  className="plus-background"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <input
                        type="file"
                        id="profileImageInput"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageChange}
                        {...register("Image", {
                          required: "Image is required",
                        })}
                      />
                      {errors.Image && (
                        <p style={{ color: "red" }}>{errors.Image.message}</p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-2">
                      <label className="main-label">First Name</label>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter your Name"
                          className="input-width"
                          {...register("firstName", {
                            required: "First Name is required",
                          })}
                        />
                      </div>
                      {errors.firstName && (
                        <p style={{ color: "red" }}>
                          {errors.firstName.message}
                        </p>
                      )}
                      <label className="main-label">Last Name</label>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter your Last Name"
                          className="input-width"
                          {...register("lastname", {
                            required: "Last Name is required",
                          })}
                        />
                      </div>
                      {errors.lastname && (
                        <p style={{ color: "red" }}>
                          {errors.lastname.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">Email</label>
                      <div>
                        <input
                          type="email"
                          className="input-width"
                          placeholder="Enter your Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: "Enter a valid email address",
                            },
                          })}
                        />
                      </div>
                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email.message}</p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">Gender</label>
                      <div>
                        <select
                          className="input-width"
                          {...register("gender", {
                            required: "Gender is required",
                          })}
                        >
                          <option value="" disabled selected>
                            Select Gender
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                      {errors.gender && (
                        <p style={{ color: "red" }}>{errors.gender.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">Phone Number</label>
                      <div>
                        <input
                          type="number"
                          className="input-width"
                          placeholder="Enter your Phone Number"
                          {...register("phone", {
                            required: "Phone number is required",
                            pattern: {
                              value: /^[0-9]{10,14}$/,
                              message:
                                "Enter a valid phone number (10-14 digits)",
                            },
                          })}
                        />
                      </div>
                      {errors.phone && (
                        <p style={{ color: "red" }}>{errors.phone.message}</p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">Date of Birth</label>
                      <div>
                        <input
                          type="date"
                          className="input-width"
                          {...register("date", {
                            required: "date is required",
                          })}
                        />
                      </div>
                      {errors.date && (
                        <p style={{ color: "red" }}>{errors.date.message}</p>
                      )}
                    </div>
                  </div>
                </>
              )} */}
              {activeTab === 0 && (
                <div>
                  <div className="Vendor-Information-parent-1 ">
                    <div className="Vendor-Information-child-1">
                      <label className="main-label">Business Logo</label>
                      <div>
                        <div
                          className="Vendor-Information-border profile-pic"
                          onClick={handlelogoPickerClick}
                        >
                          {bussinesslogo ? (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${bussinesslogo}`}
                              alt=""
                              style={{
                                width: "100%",
                                height: "153px",
                                borderRadius: "8px",
                              }}
                            />
                          ) : (
                            <>
                              <img
                                src={Lotus}
                                alt=""
                                style={{ width: "50%" }}
                              />
                              <div>
                                <img
                                  src={plus}
                                  alt="Add"
                                  className="plus-background"
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <input
                          type="file"
                          id="profilelogoInput"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handlelogoChange}
                        />
                      </div>
                    </div>
                    <div className="Vendor-Information-child-2">
                      <label className="main-label">
                        Business Banner Image
                      </label>
                      <div
                        className="bussiness-information-border profile-pic"
                        onClick={handlebackimgPickerClick}
                      >
                        {backgroundimg ? (
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}${backgroundimg}`}
                            alt=""
                            className="backgound-img"
                          />
                        ) : (
                          <>
                            <div>
                              <img src={Arrow} alt="" className="arrow-width" />
                            </div>
                            <div>
                              <p
                                style={{
                                  fontFamily: "inter",
                                  fontWeight: "400",
                                  fontSize: "18px",
                                  color: "#000000",
                                }}
                              >
                                Upload images here
                              </p>
                              <p
                                style={{
                                  fontFamily: "inter",
                                  fontWeight: "400",
                                  fontSize: "18px",
                                  color: "#94A3B8",
                                }}
                              >
                                (Only *.jpeg, *.webp and *.png images will be
                                accepted)
                              </p>
                            </div>
                          </>
                        )}

                        <input
                          type="file"
                          id="profilebackimgInput"
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={handlebackimgChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">DBA (if applicable)</label>
                      <div>
                        <input
                          type="text"
                          className="input-width"
                          placeholder="Enter DBA"
                          {...register("Dba")}
                        />
                      </div>
                      {/* {errors.Dba && (
                        <p style={{ color: "red" }}>{errors.Dba.message}</p>
                      )} */}
                    </div>
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">Type of Business</label>
                      <div>
                        <select
                          className="input-width"
                          {...register("Bussiness", {
                            required: "Bussiness is required",
                          })}
                        >
                          <option value="" disabled selected>
                            Select a Type
                          </option>
                          <option value="Cannabis Related Business">
                            Cannabis Related Business
                          </option>
                          <option value="Psilocybin Related Business">
                            Psilocybin Related Business
                          </option>
                          <option value="Other Licensed Business">
                            Other Licensed Business
                          </option>
                          <option value="Non Licensed Required Business">
                            Non Licensed Required Business
                          </option>
                        </select>
                      </div>
                      {errors.Bussiness && (
                        <p style={{ color: "red" }}>
                          {errors.Bussiness.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">
                        Date Business Started
                      </label>
                      <div>
                        <input
                          type="date"
                          className="input-width"
                          placeholder="Select Date"
                          {...register("Date", {
                            required: "Date is required",
                          })}
                        />
                      </div>
                      {errors.Date && (
                        <p style={{ color: "red" }}>{errors.Date.message}</p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">Business Licence</label>
                      <div>
                        <input
                          type="text"
                          className="input-width"
                          placeholder="Enter your Business Licence"
                          {...register("Licence", {
                            required: "Licence is required",
                          })}
                        />
                      </div>
                      {errors.Licence && (
                        <p style={{ color: "red" }}>{errors.Licence.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">EIN/SSN</label>
                      <div>
                        <input
                          type="text" // Change to text to allow formatting like ###-##-####
                          className="input-width"
                          placeholder="Enter your EIN/SSN"
                          {...register("SSN", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d{3}-?\d{2}-?\d{4}$/, // SSN format with optional dashes
                              message:
                                "Invalid SSN format. Use ###-##-#### or #########",
                            },
                            maxLength: {
                              value: 11, // Accounting for SSN with dashes (9 digits + 2 dashes)
                              message: "SSN should not exceed 11 characters",
                            },
                          })}
                        />
                      </div>
                      {errors.SSN && (
                        <p style={{ color: "red" }}>{errors.SSN.message}</p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">Bussiness Name</label>
                      <div>
                        <input
                          type="text"
                          className="input-width"
                          placeholder="Enter your Bussiness Name"
                          {...register("bussinessname", {
                            required: "This  field is required",
                          })}
                        />
                      </div>
                      {errors.bussinessname && (
                        <p style={{ color: "red" }}>
                          {errors.bussinessname.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">
                        Upload Business Licence
                      </label>
                      <div>
                        <button
                          type="button"
                          className="upload-btn"
                          onClick={handleLicensePickerClick}
                        >
                          Upload file
                        </button>
                        <input
                          type="file"
                          id="businessLicenseInput"
                          style={{ display: "none" }}
                          onChange={handleLicenseChange}
                        />
                        <div>
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}${businessLicense}`}
                            alt="license-Image"
                            style={{
                              width: "240px",
                              marginTop: "10px",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 1 && (
                <div>
                  <p className="main-label">Additional Business Information</p>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">Description</label>
                      <div>
                        <textarea
                          className="description"
                          placeholder="Enter Description"
                          {...register("Description", {
                            required: "  Description is required",
                          })}
                        ></textarea>
                      </div>
                      {errors.Description && (
                        <p style={{ color: "red" }}>
                          {errors.Description.message}
                        </p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">About Shop</label>
                      <div>
                        <textarea
                          className="description"
                          placeholder="Enter Description"
                          {...register("shop", {
                            required: "  Description is required",
                          })}
                        ></textarea>
                      </div>
                      {errors.Description && (
                        <p style={{ color: "red" }}>
                          {errors.Description.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">
                        Physical Address (Address Line 1)
                      </label>
                      <div>
                        <input
                          type="text"
                          className="input-width"
                          placeholder="Enter Physical Address"
                          {...register("PhysicalAddress1", {
                            required: "  Physical Address is required",
                          })}
                        />
                      </div>
                      {errors.PhysicalAddress1 && (
                        <p style={{ color: "red" }}>
                          {errors.PhysicalAddress1.message}
                        </p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">
                        Physical Address (Address Line 2)
                      </label>
                      <div>
                        <input
                          type="text"
                          className="input-width"
                          placeholder="Apartment, unit etc"
                          {...register("PhysicalAddress2")}
                        />
                      </div>
                      {/* {errors.PhysicalAddress2 && (
                        <p style={{ color: "red" }}>
                          {errors.PhysicalAddress2.message}
                        </p>
                      )} */}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">City</label>
                      <div>
                        <input
                          type="text"
                          className="input-width"
                          placeholder="Enter City"
                          {...register("City", {
                            required: "City is required",
                          })}
                        />
                      </div>
                      {errors.City && (
                        <p style={{ color: "red" }}>{errors.City.message}</p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-4">
                      <label className="main-label">State</label>
                      <div>
                        <select
                          className="input-width"
                          {...register("State", {
                            required: "State is required",
                          })}
                        >
                          <option value="" disabled selected>
                            Select State
                          </option>
                          <option value="Florida">Florida</option>
                          <option value="Utah">Utah</option>
                          <option value="North Carolina">North Carolina</option>
                          <option value="Texas">Texas</option>
                        </select>
                      </div>
                      {errors.State && (
                        <p style={{ color: "red" }}>{errors.State.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="Vendor-Information-parent-2 ">
                    <div className="Vendor-Information-child-3">
                      <label className="main-label">Zip Code</label>
                      <div>
                        <input
                          type="text"
                          className="input-width"
                          placeholder="Enter Zip Code"
                          {...register("ZipCode", {
                            required: "Zip Code is required",
                          })}
                        />
                      </div>
                      {errors.ZipCode && (
                        <p style={{ color: "red" }}>{errors.ZipCode.message}</p>
                      )}
                    </div>
                    <div className="Vendor-Information-child-4"></div>
                  </div>
                </div>
              )}
              <div style={{ textAlign: "end" }}>
                {activeTab < tabs.length - 1 ? (
                  <NextButton type="submit" onClick={handleNext}>
                    Next
                  </NextButton>
                ) : (
                  <CreateVendorButton
                    type="submit"
                    onClick={handleCreateVendor}
                  >
                    Update
                  </CreateVendorButton>
                )}
              </div>
            </form>
          </TabContent>
        </div>
      </div>
    </>
  );
};
const VendorInformation = () => {
  return (
    <Main>
      <div className="container-fluid">
        {/* <div className="heading-container">
          <Typography variant="h6" component="h2"></Typography>
          <div className="heading-flex">
            <p className="heading">Vendor Information</p>
          </div>
        </div> */}
        <CustomTabs />
      </div>
    </Main>
  );
};
export default VendorInformation;
