// import React, { useState } from 'react';
// import { Main, PasswordField, PasswordInputContainer, ToggleButton } from './style';
// import closeEyeIcon from '../../../assets/img/vendor-setting/closeEyeIcon.svg';

// const VendorSetting = () => {
//     const [checked, setChecked] = useState(false);
//     const [showCurrentPassword, setShowCurrentPassword] = useState(false);
//     const [showNewPassword, setShowNewPassword] = useState(false);
//     const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//     const [currentPassword, setCurrentPassword] = useState('MyStrongPassword*%!@');
//     const [newPassword, setNewPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState('');

//     const handleToggleCurrentPassword = () => {
//         setShowCurrentPassword(prevState => !prevState);
//     };

//     const handleToggleNewPassword = () => {
//         setShowNewPassword(prevState => !prevState);
//     };

//     const handleToggleConfirmPassword = () => {
//         setShowConfirmPassword(prevState => !prevState);
//     };

//     const handleSubmitForm = (e) => {
//         e.preventDefault();
//         if (newPassword === confirmPassword) {
//             setError('');
//             console.log('Password Matched!!!');

//         } else {
//             setError("Your Passwords do not match.");
//         }
//     };

//     // Toggle Button Function
//     const handleToggle = () => {
//         setChecked(prevState => !prevState);
//     };

//     return (
//         <Main>
//             <div className='container-fluid'>
//                 <div>
//                     <h1 className='heading'>Setting</h1>
//                 </div>
//                 <form onSubmit={handleSubmitForm} className='profile-setting-section'>
//                     <p className='profile-setting-title'>Setting</p>
//                     <div className='change-password-section'>
//                         <div className='profile-setting-body'>
//                             <div className='change-password-body'>
//                                 <label className='input-label-changePassword'>Current Password</label>
//                                 <PasswordInputContainer>
//                                     <PasswordField
//                                         type={showCurrentPassword ? 'text' : 'password'}
//                                         placeholder="******************"
//                                         value={currentPassword}
//                                         readOnly
//                                     />
//                                     <ToggleButton onClick={handleToggleCurrentPassword}>
//                                         {showCurrentPassword ? '🙈' : <img src={closeEyeIcon} alt='Hide Password' />}
//                                     </ToggleButton>
//                                 </PasswordInputContainer>
//                             </div>
//                             <div className='notification-section'>
//                                 <p className='notification-title'>Notifications</p>
//                                 <div className='notificatiion-enable'>
//                                     <p className='enable-title'>Enabled</p>

//                                     <label className="switch">
//                                         <input type="checkbox" checked={checked} onChange={handleToggle} />
//                                         <span
//                                             className="slider round"
//                                             style={{ backgroundColor: checked ? '#F4B733' : '' }}
//                                         ></span>
//                                     </label>

//                                 </div>
//                             </div>
//                         </div>
//                         <div className='create-password-title'>
//                             <p>Create new password</p>
//                         </div>
//                         <div className='change-password-body'>
//                             <label className='input-label-changePassword'>New Password</label>
//                             <PasswordInputContainer>
//                                 <PasswordField
//                                     type={showNewPassword ? 'text' : 'password'}
//                                     placeholder="******************"
//                                     onChange={(e) => setNewPassword(e.target.value)}
//                                 />
//                                 <ToggleButton onClick={handleToggleNewPassword}
//                                     type='button'>
//                                     {showNewPassword ? '🙈' : <img src={closeEyeIcon} alt='Hide Password' />}
//                                 </ToggleButton>
//                             </PasswordInputContainer>
//                         </div>
//                         <div className='change-password-body'>
//                             <label className='input-label-changePassword'>Confirm Password</label>
//                             <PasswordInputContainer>
//                                 <PasswordField
//                                     type={showConfirmPassword ? 'text' : 'password'}
//                                     placeholder="******************"
//                                     onChange={(e) => setConfirmPassword(e.target.value)}
//                                 />
//                                 <ToggleButton onClick={handleToggleConfirmPassword}
//                                     type='button'>
//                                     {showConfirmPassword ? '🙈' : <img src={closeEyeIcon} alt='Hide Password' />}
//                                 </ToggleButton>
//                             </PasswordInputContainer>
//                             {error && <p className='error'>{error}</p>}

//                         </div>
//                     </div>
//                     <div className='text-end mx-5'>
//                         <button type='submit' className='CreateVendor'>Update</button>
//                     </div>
//                 </form>
//             </div>
//         </Main>
//     );
// };

// export default VendorSetting;

// import { useEffect, useState } from "react";
// import {
//   Main,
//   PasswordField,
//   PasswordInputContainer,
//   ToggleButton,
// } from "./style";
// import closeEyeIcon from "../../../assets/img/vendor-setting/closeEyeIcon.svg";
// import useHooks from "./useHook"; // Update the path as needed
// import { FiEye } from "react-icons/fi";
// import { FiEyeOff } from "react-icons/fi";

// const MyComponent = () => {
//   const [checked, setChecked] = useState("");
//   const [showCurrentPassword, setShowCurrentPassword] = useState(false);
//   const [showNewPassword, setShowNewPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [currentPassword, setCurrentPassword] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [error, setError] = useState("");

//   const { login, notification, getProfile } = useHooks();

//   const handleToggleCurrentPassword = () => {
//     setShowCurrentPassword((prevState) => !prevState);
//   };

//   const handleToggleNewPassword = () => {
//     setShowNewPassword((prevState) => !prevState);
//   };

//   const handleToggleConfirmPassword = () => {
//     setShowConfirmPassword((prevState) => !prevState);
//   };

//   const handleSubmitForm = async (e) => {
//     e.preventDefault();
//     if (newPassword === confirmPassword) {
//       setError("");
//       const body = {
//         oldPassword: currentPassword,
//         password: newPassword,
//       };
//       try {
//         const response = await login(body);
//         // console.log("Password Matched!!!", response);
//         // handle successful response
//         setCurrentPassword("");
//         setNewPassword("");
//       } catch (error) {
//         console.error("Error updating password:", error);
//         setError("Error updating password");
//       }
//     } else {
//       setError("Your Passwords do not match.");
//     }
//   };

//   //   const handleToggle = () => {
//   //     setChecked((prevState) => !prevState);
//   //   };

//   const [profileData, setProfileData] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         const data = await getProfile(); // Assuming getProfile fetches profile data from API
//         setProfileData(data);
//         setChecked(data.isNotificationsEnabled); // Initialize checked state based on fetched profile data
//       } catch (error) {
//         console.error("Error fetching profile data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProfile();
//   }, []);

//   const handleToggle = async () => {
//     const newCheckedState = !checked;
//     setChecked(newCheckedState);

//     // Update profileData with new isNotificationsEnabled state
//     const updatedProfileData = {
//       ...profileData,
//       isNotificationsEnabled: newCheckedState,
//     };
//     setProfileData(updatedProfileData); // Update locally for immediate UI response

//     try {
//       const response = await notification(updatedProfileData); // Update backend via API call
//     //   console.log("Notification Toggle Response:", response);
//     } catch (error) {
//       console.error("Error toggling notifications:", error);
//       // Rollback state on error (optional)
//       setChecked(!newCheckedState);
//       setProfileData({
//         ...profileData,
//         isNotificationsEnabled: !newCheckedState,
//       });
//     }
//   };

// //   console.log("profileData", profileData);
//   return (
//     <Main>
//       <div className="container-fluid">
//         <div>
//           <h1 className="heading">Setting</h1>
//         </div>
//         <form onSubmit={handleSubmitForm} className="profile-setting-section">
//           <p className="profile-setting-title">Setting</p>
//           <div className="change-password-section">
//             <div className="profile-setting-body">
//               <div className="change-password-body">
//                 <label className="input-label-changePassword">
//                   Current Password
//                 </label>
//                 <PasswordInputContainer>
//                   <PasswordField
//                     type={showCurrentPassword ? "text" : "password"}
//                     placeholder="******************"
//                     onChange={(e) => setCurrentPassword(e.target.value)}
//                   />
//                   <ToggleButton
//                     onClick={handleToggleCurrentPassword}
//                     type="button"
//                   >
//                     {showCurrentPassword ? (
//                       <FiEyeOff color="#64748B" size={20} />
//                     ) : (
//                       <FiEye color="#64748B" size={20} />
//                     )}
//                   </ToggleButton>
//                 </PasswordInputContainer>
//               </div>
//               <div className="notification-section">
//                 <p className="notification-title">Notifications</p>
//                 <div className="notificatiion-enable">
//                   <p className="enable-title">Enabled</p>
//                   <label className="switch">
//                     <input
//                       type="checkbox"
//                       checked={checked}
//                       onChange={handleToggle}
//                     />
//                     <span
//                       className="slider round"
//                       style={{ backgroundColor: checked ? "#F4B733" : "" }}
//                     ></span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//             <div className="create-password-title">
//               <p>Create new password</p>
//             </div>
//             <div className="change-password-body">
//               <label className="input-label-changePassword">New Password</label>
//               <PasswordInputContainer>
//                 <PasswordField
//                   type={showNewPassword ? "text" : "password"}
//                   placeholder="******************"
//                   onChange={(e) => setNewPassword(e.target.value)}
//                 />
//                 <ToggleButton onClick={handleToggleNewPassword} type="button">
//                   {showNewPassword ? (
//                     <FiEyeOff color="#64748B" size={20} />
//                   ) : (
//                     <FiEye color="#64748B" size={20} />
//                   )}
//                 </ToggleButton>
//               </PasswordInputContainer>
//             </div>
//             <div className="change-password-body">
//               <label className="input-label-changePassword">
//                 Confirm Password
//               </label>
//               <PasswordInputContainer>
//                 <PasswordField
//                   type={showConfirmPassword ? "text" : "password"}
//                   placeholder="******************"
//                   onChange={(e) => setConfirmPassword(e.target.value)}
//                 />
//                 <ToggleButton
//                   onClick={handleToggleConfirmPassword}
//                   type="button"
//                 >
//                   {showConfirmPassword ? (
//                     <FiEyeOff color="#64748B" size={20} />
//                   ) : (
//                     <FiEye color="#64748B" size={20} />
//                   )}
//                 </ToggleButton>
//               </PasswordInputContainer>
//               {error && <p className="error">{error}</p>}
//             </div>
//           </div>
//           <div className="text-end mx-5">
//             <button type="submit" className="CreateVendor">
//               Update
//             </button>
//           </div>
//         </form>
//       </div>
//     </Main>
//   );
// };

// export default MyComponent;

import { useEffect, useState } from "react";
import {
  Main,
  PasswordField,
  PasswordInputContainer,
  ToggleButton,
} from "./style";
import useHooks from "./useHook"; // Update the path as needed
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";

const MyComponent = () => {
  const [checked, setChecked] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const { login, notification, getProfile } = useHooks();

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const validateForm = () => {
    const errors = {};

    if (!currentPassword) {
      errors.currentPassword = "Current password is required.";
    }
    if (!newPassword) {
      errors.newPassword = "New password is required.";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your new password.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const body = {
      oldPassword: currentPassword,
      password: newPassword,
    };

    try {
      const response = await login(body);
      // handle successful response
      console.log(response, "settings");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setError("");
    } catch (error) {
      console.error("Error updating password:", error);
      setError("Error updating password");
    }
  };

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(); // Assuming getProfile fetches profile data from API
        setProfileData(data);
        setChecked(data.isNotificationsEnabled); // Initialize checked state based on fetched profile data
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleToggle = async () => {
    const newCheckedState = !checked;
    setChecked(newCheckedState);

    // Update profileData with new isNotificationsEnabled state
    const updatedProfileData = {
      ...profileData,
      isNotificationsEnabled: newCheckedState,
    };
    setProfileData(updatedProfileData); // Update locally for immediate UI response

    try {
      const response = await notification(updatedProfileData); // Update backend via API call
      // console.log("Notification Toggle Response:", response);
    } catch (error) {
      console.error("Error toggling notifications:", error);
      // Rollback state on error (optional)
      setChecked(!newCheckedState);
      setProfileData({
        ...profileData,
        isNotificationsEnabled: !newCheckedState,
      });
    }
  };

  return (
    <Main>
      <div className="container-fluid">
        <div>
          <h1 className="heading">Setting</h1>
        </div>
        <form onSubmit={handleSubmitForm} className="profile-setting-section">
          <p className="profile-setting-title">Setting</p>
          <div className="change-password-section">
            <div className="profile-setting-body">
              <div className="change-password-body">
                <label className="input-label-changePassword">
                  Current Password
                </label>
                <PasswordInputContainer>
                  <PasswordField
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="******************"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    value={currentPassword}
                  />
                  <ToggleButton
                    onClick={handleToggleCurrentPassword}
                    type="button"
                  >
                    {showCurrentPassword ? (
                      <FiEyeOff color="#64748B" size={20} />
                    ) : (
                      <FiEye color="#64748B" size={20} />
                    )}
                  </ToggleButton>
                </PasswordInputContainer>
                {formErrors.currentPassword && (
                  <p className="error">{formErrors.currentPassword}</p>
                )}
              </div>
              <div className="notification-section">
                <p className="notification-title">Notifications</p>
                <div className="notificatiion-enable">
                  <p className="enable-title">Enabled</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleToggle}
                    />
                    <span
                      className="slider round"
                      style={{ backgroundColor: checked ? "#F4B733" : "" }}
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="create-password-title">
              <p>Create new password</p>
            </div>
            <div className="change-password-body">
              <label className="input-label-changePassword">New Password</label>
              <PasswordInputContainer>
                <PasswordField
                  type={showNewPassword ? "text" : "password"}
                  placeholder="******************"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                />
                <ToggleButton onClick={handleToggleNewPassword} type="button">
                  {showNewPassword ? (
                    <FiEyeOff color="#64748B" size={20} />
                  ) : (
                    <FiEye color="#64748B" size={20} />
                  )}
                </ToggleButton>
              </PasswordInputContainer>
              {formErrors.newPassword && (
                <p className="error">{formErrors.newPassword}</p>
              )}
            </div>
            <div className="change-password-body">
              <label className="input-label-changePassword">
                Confirm Password
              </label>
              <PasswordInputContainer>
                <PasswordField
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="******************"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
                <ToggleButton
                  onClick={handleToggleConfirmPassword}
                  type="button"
                >
                  {showConfirmPassword ? (
                    <FiEyeOff color="#64748B" size={20} />
                  ) : (
                    <FiEye color="#64748B" size={20} />
                  )}
                </ToggleButton>
              </PasswordInputContainer>
              {formErrors.confirmPassword && (
                <p className="error">{formErrors.confirmPassword}</p>
              )}
              {error && <p className="error">{error}</p>}
            </div>
          </div>
          <div className="text-end mx-5">
            <button type="submit" className="CreateVendor">
              Update
            </button>
          </div>
        </form>
      </div>
    </Main>
  );
};

export default MyComponent;
