import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";

const useHooks = () => {
  const collectionPost = async (formData) => {
    try {
      const response = await AuthAPI.collectionPost(formData);
      // console.log(formData)
      return response;
    } catch (error) {
      console.error("Error in collectionPost:", error);
      throw error;
    }
  };
  const imagePost = async (file) => {
    try {
      if (!file) {
        throw new Error("No file provided");
      }
      const reader = new FileReader();
      const fileAsDataURL = new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
      reader.readAsDataURL(file);
      await fileAsDataURL;  // Wait for the file to be read as data URL
      const response = await AuthAPI.imagepost(
        { image: file },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("Image upload response", response);
      if (response?.path) {
        toastr.success("Image uploaded successfully");
        console.log("response path", response.path)
        return response.path;
      } else {
        alert("Image upload failed");
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };
  const putcollection = async (id,params) => {
    try {
      const response = await AuthAPI.putcollectonSingle(id,params)
      return response;
    } catch (error) {
      console.error('error is ', error)
    }
  }
  // const singleCollectionGet = async (id) => { 
  //   try {
  //     const response = await AuthAPI.getSingleCollection(id);
  //     // console.log(formData)
  //     return response;
  //   } catch (error) {
  //     console.error("Error in single collection Get:", error);
  //     throw error;
  //   }
  // };

  return { collectionPost, imagePost, putcollection };
};

export default useHooks;


