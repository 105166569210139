import React, { useEffect, useState } from "react";
import { Main, Product } from "./style";
import myCollection1 from "../../../assets/img/collection/collection1.jpeg";
import myCollection2 from "../../../assets/img/collection/collection2.jpeg";
import myCollection3 from "../../../assets/img/collection/collection3.jpeg";
import myCollection4 from "../../../assets/img/collection/collection4.jpeg";
import myCollection5 from "../../../assets/img/collection/collection5.jpeg";

import View from "../../../assets/img/collection/view.svg";
import Edit from "../../../assets/img/collection/edit.svg";
import Delete from "../../../assets/img/collection/delete.svg";
// import Star from "../../../assets/img/products/star.png";
import PaginationRounded from "components/Pagination";
// import TransitionsModal from "components/modal";
import IosButton from "components/ios-button";
import Star from "../../../assets/img/products/star.png";
import CollectionsModal from "components/modal/collection-modal";
import useHooks from "./useHook";
import axios from "axios";
import DeleteModal from "components/modal/delete";
import { useNavigate } from "react-router-dom";

import Spiner from "components/spinner";
const Collection = () => {
  const [cardVisible, setCardVisible] = useState(true);
  const navigate = useNavigate();

  const handleView = (id) => {
    // /my-collection-detail/:id
    navigate(`/my-collection-detail/${id}`);
  };
  ///////////////   API    /////////////////////
  const { getcollections, collectionDelete, singleCollectionGet } = useHooks();

  const [collectionsData, setCollectionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  // Error State

  // All Collection Get
  const getcollectionsData = async () => {
    const userId = localStorage.getItem("id");
    const formData = {
      userId: userId,
      page: currentPage,
      perPage: 8,
    };
    const response = await getcollections(formData);
    console.log("All my-collection data", response);
    setTotalPage(Math.ceil(response.data.counts / 8));
    try {
      if (response.status == true) {
        //   console.log(response.data,'data')
        setCollectionsData(response.data.results);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after the API call is complete
    }
  };
  useEffect(() => {
    getcollectionsData();
  }, []);
  useEffect(() => {
    if (!modalOpen) {
      getcollectionsData();
    }
  }, [modalOpen, currentPage]);
  const handlePageChange = (e, value) => {
    setCurrentPage(value);
  };

  // const [modalOpen,setModalOpen]=useState(false)
  const handleModalOpen = (e) => {
    e.preventDefault();
    setModalOpen(true);
    setimageid("");
  };
  // Single Collection Api
  const [collectionSingle, setcollectionSingle] = useState("");
  const [imageid, setimageid] = useState("");
  const FetchSingleCollection = async (id) => {
    setimageid(id);
    try {
      const response = await singleCollectionGet(id);
      setcollectionSingle(response);
      console.log("single Collection ", response);
    } catch (error) {
      console.log("Error single collection get", error);
    }
  };

  useEffect(() => {
    if (!modalOpen) {
      getcollectionsData();
    }
  }, [modalOpen, currentPage]);

  const handleEdit = (id) => {
    FetchSingleCollection(id);
    setModalOpen(true);
  };
  // Delete Modal
  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = async () => {
    if (deleteID !== null) {
      try {
        await collectionDelete(deleteID);
        const newdata = collectionsData.filter(
          (catalog) => catalog.id !== deleteID
        );
        setCollectionsData(newdata);
        setDeleteModalOpen(false);
        console.log(deleteModalOpen, "id of sata");
      } catch (error) {
        console.error("Error deleting catalog:", error);
      }
    }
  };
  useEffect(() => {
    if (!deleteModalOpen) {
      getcollectionsData();
    }
  }, [deleteModalOpen, currentPage]);
  return (
    <Main>
      <div className="container-fluid">
        {loading ? (
          <Spiner />
        ) : (
          <>
            {cardVisible ? (
              <>
                <div className="heading-flex">
                  <p className="heading">My Collection</p>
                  <button
                    type="submit"
                    className="category-btn me-2"
                    onClick={handleModalOpen}
                  >
                    Create New Collection
                  </button>
                </div>
                <CollectionsModal
                  open={modalOpen}
                  onClose={() => setModalOpen(false)}
                  collectionSingle={collectionSingle}
                  imageid={imageid}
                  setimageid={setimageid}
                />
                <DeleteModal
                  open={deleteModalOpen}
                  handleClose={handleCloseDeleteModal}
                  handleDelete={handleConfirmDelete}
                />
              </>
            ) : (
              <div className="d-none"></div>
            )}
            <div className="collection-container">
              <div className="card-flex">
                {collectionsData.length > 0 ? (
                  collectionsData.map((items, index) => (
                    <div className="card card-width" key={items.id}>
                      <div className="card-body">
                        <div>
                          <img
                            src={
                              process.env.REACT_APP_BASE_URL + "/" + items.image
                            }
                            alt="Products Images"
                            className="img"
                          />
                          <div className="img-flex">
                            <div className="mt-3">
                              <p className="m-0 mainHeading dot-property">
                                {items.name}
                              </p>
                              <p className="m-0 sub-heading">
                                {items.productCount} Products
                              </p>
                            </div>
                            <div className="m-0 img-flex-2">
                              <img
                                src={View}
                                alt="view"
                                onClick={() => handleView(items.id)}
                                className="view-icon"
                              />
                              <img
                                src={Edit}
                                alt="edit"
                                onClick={() => handleEdit(items.id)}
                                className="edit-icon"
                              />
                              <img
                                src={Delete}
                                alt="delete"
                                onClick={() => handleOpenDeleteModal(items.id)}
                                className="delete-icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-record">No Record Found</div>
                )}
              </div>
              <div>
                <PaginationRounded
                  count={totalPage}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Main>
  );
};
export default Collection;
