// import React, { useEffect, useState } from "react";
// import { Main } from "./style";
// import Export from "../../../assets/img/products/Group 6971.png";
// import waleed from "../../../assets/img/products/product-img.png";
// import Arrow from "../../../assets/img/products/Arrow.png";
// import Star from "../../../assets/img/products/star.png";
// import View from "../../../assets/img/products/view.png";
// import Edit from "../../../assets/img/products/edit.png";
// import Delete from "../../../assets/img/products/delete.png";
// import IosButton from "components/ios-button";
// import PaginationRounded from "components/Pagination";
// import { useNavigate } from "react-router-dom";
// import useHooks from "./useHook";
// import { options } from "toastr";
// import DeleteModal from "components/modal/delete";
// import Spiner from "components/spinner";
// import { CSVLink } from "react-csv";

// const Products = () => {
//   const category = [
//     { id: 1, heading: "Category" },
//     { id: 2, heading: "Furniture" },
//     { id: 3, heading: "Table" },
//     { id: 4, heading: "Outdoor" },
//     { id: 5, heading: "Bedroom" },
//     { id: 6, heading: "Kitchen" },
//     { id: 7, heading: "Decor" },
//     { id: 7, heading: "Floor Tile" },
//     { id: 8, heading: "Carpet" },
//     { id: 9, heading: "Bathroom" },
//     { id: 10, heading: "Wardrobes" },
//   ];
//   const price = [
//     { id: 1, heading: "ASC" },
//     { id: 2, heading: "DESC" },
//   ];
//   const Status = [
//     { id: 1, heading: "published" },
//     { id: 2, heading: "un-published" },
//   ];

//   const navigate = useNavigate();
//   const AddProducts = (state) => {
//     navigate("/products/add", { state });
//   };
//   const UpdateProducts = (id) => {
//     navigate(`/products/update/${id}`);
//   };

//   const ProductsDetails = (id) => {
//     // console.log("this is my id ", id);
//     navigate(`/products/details/${id}`);
//   };

//   ///////////     API       //////////////
//   const { getProducts, putPublishProducts, getcategory, deleteProducts } =
//     useHooks();
//   const [productData, setProductData] = useState([]);
//   const [switches, setswitches] = useState({});
//   const [productid, setProductId] = useState("");
//   const [searchProducts, setSearchProducts] = useState("");
//   const [searchCategory, setSearchCategory] = useState("");
//   const [searchPrice, setSearchPrice] = useState("");
//   const [isPublished, setisPublished] = useState(undefined);
//   const [categoryData, setCategoryData] = useState([]);
//   const [selectedCategoryId, setSelectedCategoryId] = useState([]);
//   const [noDataFound, setNoDataFound] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
//   const [deleteID, setDeleteID] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const handleCategoryChange = (event) => {
//     setSearchCategory(event.target.value);
//   };

//   const handlePriceChange = (event) => {
//     setSearchPrice(event.target.value);
//   };

//   const handleStatusChange = (event) => {
//     setisPublished(event.target.value);
//   };

//   console.log(searchPrice);
//   const exportData = productData.map((product, index) => ({
//     sr: index,
//     name: product.name,
//     category: product.category.parent?.name,
//     subCategory: product.category.name,
//     price: product.productPrice,
//     salePrice: product.salePrice,
//     quantity: product.qnty,
//     published: product.isPublished,
//   }));
//   const headers = [
//     { label: "SR. No", key: "sr" },
//     { label: "Name", key: "name" },
//     { label: "Category", key: "category" },
//     { label: "SubCategory", key: "subCategory" },
//     { label: "Price", key: "price" },
//     { label: "SalePrice", key: "salePrice" },
//     { label: "Quantity", key: "quantity" },
//     { label: "Published", key: "published" },
//   ];
//   const getProductsData = async () => {
//     let params = `?search=${encodeURIComponent(
//       searchProducts
//     )}&categoryId=${encodeURIComponent(
//       searchCategory
//     )}&price=${encodeURIComponent(searchPrice)}&page=${currentPage}&perPage=10`;

//     if (isPublished !== undefined) {
//       params += `&isPublished=${encodeURIComponent(isPublished)}`;
//     }

//     try {
//       const response = await getProducts(params);
//       if (response.status === true || "") {
//         setProductData(response.data.results);
//         const productsPerPage = 10;
//         setTotalPages(Math.ceil(response.data.counts / productsPerPage)); // assuming `total` is the total number of products
//         const publishedList = response.data.results.reduce((acc, item) => {
//           acc[item.id] = item.isPublished;
//           return acc;
//         }, {});
//         setswitches(publishedList);
//         setNoDataFound(response.data.results.length === 0);
//       } else {
//         setProductData([]);
//         setNoDataFound(true);
//         setTotalPages(1);
//       }
//     } catch (error) {
//       console.log(error);
//       setNoDataFound(true);
//       setTotalPages(1);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleReset = (e) => {
//     // Reset the fields
//     setSearchProducts("");
//     setSearchCategory("");
//     setSearchPrice("");
//     setisPublished(""); // This can stay as an empty string, depending on how you're handling the state
//     setCurrentPage(1);

//     // Optional: Clear other states if needed
//     // setCategoryData([]);

//     // Call the API to refresh the data after resetting the fields
//     getProductsData();
//   };

//   useEffect(() => {
//     getProductsData();
//   }, []);

//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };
//   // function getId(id) {
//   //   setProductId(id);
//   // }
//   // console.log(productid);
//   // useEffect(() => {
//   // console.log("Selected Product ID:", productid);
//   // }, []);

//   const handlePublished = async (id) => {
//     // console.log(`Clicked item ID: ${id}`);
//     const newIsPublished = !switches[id];
//     setswitches((prevSwitches) => ({
//       ...prevSwitches,
//       [id]: newIsPublished,
//     }));

//     try {
//       const response = await putPublishProducts(id, newIsPublished);
//       if (!response) {
//         // Revert switch state in case of an error
//         setswitches((prevSwitches) => ({
//           ...prevSwitches,
//           [id]: !newIsPublished,
//         }));
//         alert("Failed to update publish status");
//       }
//     } catch (error) {
//       // Revert switch state in case of an error
//       setswitches((prevSwitches) => ({
//         ...prevSwitches,
//         [id]: !newIsPublished,
//       }));
//       console.error(error);
//       alert("Error occurred while updating publish status");
//     }
//   };

//   /// get category

//   useEffect(() => {
//     const getCategoryData = async () => {
//       try {
//         const response = await getcategory();
//         const activeCategories = response.data.filter(
//           (category) => category.isActive === true
//         );
//         setCategoryData(activeCategories);
//         if (activeCategories.length > 0) {
//           setSelectedCategoryId(activeCategories[0].id);
//         }
//       } catch (error) {
//         console.error(error);
//       }
//     };
//     getCategoryData();
//   }, []);

//   const handleConfirmDelete = async () => {
//     if (deleteID !== null) {
//       try {
//         await deleteProducts(deleteID);
//         const newdata = productData.filter(
//           (product) => product.id !== deleteID
//         );
//         setProductData(newdata);
//         handleCloseDeleteModal();
//       } catch (error) {
//         console.error("Error deleting catalog:", error);
//       }
//     }
//   };
//   useEffect(() => {
//     if (!deleteModalOpen) {
//       getProductsData();
//     }
//   }, [deleteModalOpen, currentPage]);
//   const handleCloseDeleteModal = () => {
//     setDeleteID(null);
//     setDeleteModalOpen(false);
//   };
//   const handleOpenDeleteModal = (id) => {
//     setDeleteID(id);
//     setDeleteModalOpen(true);
//   };
//   return (
//     <Main>
//       {loading ? (
//         <Spiner />
//       ) : (
//         <>
//           <DeleteModal
//             open={deleteModalOpen}
//             handleClose={handleCloseDeleteModal}
//             handleDelete={handleConfirmDelete}
//           />
//           <div className="container-fluid">
//             <div className="heading-flex">
//               <p className="m-0 heading">Products Overview</p>
//             </div>

//             <div className="card border-0">
//               <div class="card-body">
//                 <div className="products-flex">
//                   <div className="export-text">
//                     <CSVLink
//                       data={exportData}
//                       className="export-text"
//                       headers={headers}
//                       target="_blank"
//                       filename={"customer.csv"}
//                     >
//                       <img src={Export} alt="" className="export-width" />
//                       <p>Export</p>
//                     </CSVLink>
//                   </div>
//                   <button
//                     type="submit"
//                     className="products-button"
//                     onClick={() => AddProducts()}
//                   >
//                     + Add Product
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <div className="card border-0 mt-3">
//               <div class="card-body">
//                 <div className="total-flex">
//                   <input
//                     type="search"
//                     name="searchProducts"
//                     value={searchProducts}
//                     onChange={(e) => setSearchProducts(e.target.value)}
//                     id=""
//                     className="search-input"
//                     placeholder="Search Product"
//                   />

//                   <select
//                     name=""
//                     id=""
//                     value={searchCategory}
//                     className="category-width"
//                     onChange={(e) => setSearchCategory(e.target.value)}
//                   >
//                     <option value="">Select Category</option>
//                     {categoryData.map((category, index) => (
//                       <>
//                         <option key={index} value={category.id}>
//                           {category.name}
//                         </option>
//                       </>
//                     ))}
//                   </select>

//                   {/* <select
//                     name=""
//                     id=""
//                     value={searchPrice}
//                     className="price-width"
//                     onChange={(e) => setSearchPrice(e.target.value)}
//                   >
//                     <option value="">Prices</option>
//                     {price.map((items, index) => (
//                       <>
//                         <option key={index} value={items.heading}>
//                           {items.heading}
//                         </option>
//                       </>
//                     ))}
//                   </select> */}
//                   <select
//                     name=""
//                     id=""
//                     value={isPublished}
//                     className="status-width"
//                     onChange={(e) => setisPublished(e.target.value)}
//                   >
//                     <option value="">Status</option>
//                     {/* {Status.map((items, index) => ( */}
//                     {/* <> */}
//                     <option value="true">Published</option>
//                     <option value="false">un-Published</option>
//                     {/* </> */}
//                     {/* ))} */}
//                   </select>
//                 </div>
//                 <div className="filter-butons">
//                   <div>
//                     <button
//                       type="submit"
//                       className="filter-button"
//                       onClick={getProductsData}
//                     >
//                       Filter
//                     </button>
//                   </div>
//                   &nbsp;
//                   <div>
//                     <button
//                       type="submit"
//                       className="Reset-button"
//                       onClick={handleReset}
//                     >
//                       Reset
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="card mt-3 border-0">
//               <div className="card-body">
//                 {noDataFound ? (
//                   <div className="no-record">No Record Found</div>
//                 ) : (
//                   <>
//                     <table className="w-100">
//                       <thead className="table-head">
//                         <tr className="table-head">
//                           <th>Product ID</th>
//                           <th>Image</th>
//                           <th>Product Name</th>
//                           <th>Ratings</th>
//                           <th>Category</th>
//                           <th>Sub Category</th>
//                           <th>Price</th>
//                           <th>Sale Price</th>
//                           <th>Stock</th>
//                           <th>Status</th>
//                           <th>Published</th>
//                           <th>Actions</th>
//                         </tr>
//                       </thead>

//                       {productData &&
//                         productData.length > 0 &&
//                         productData.map((items, index) => (
//                           <tbody className="table-body" key={index}>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {index + 1}
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {items.images.length > 0 && (
//                                 <img
//                                   src={`${process.env.REACT_APP_BASE_URL}${items.images[0].url}`} // Accessing the URL of the first image
//                                   alt="Product Image"
//                                   style={{
//                                     width: "50px",
//                                     height: "50px",
//                                     marginRight: "5px",
//                                     borderRadius: "10px",
//                                   }}
//                                 />
//                               )}
//                             </td>
//                             <td
//                               className="dot-property"
//                               style={{ paddingBlock: "15px" }}
//                             >
//                               {items.name}
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {" "}
//                               <span className="rating">
//                                 <img src={Star} alt="Rating Stars" /> &nbsp;
//                                 {items.totalRatting}
//                               </span>
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {items.category.parent?.name || "--"}
//                             </td>
//                             <td
//                               style={{
//                                 paddingBlock: "15px",
//                               }}
//                             >
//                               {items.category.name}
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {items.productPrice}
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {items.salePrice}
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {items.qnty}
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               <p
//                                 className={`status-${
//                                   items.qnty <= 0 ? "sold" : "stock"
//                                 } m-0`}
//                               >
//                                 {items.qnty <= 0 ? "Sold out" : "Stock In"}
//                               </p>
//                             </td>
//                             <td style={{ paddingBlock: "15px" }}>
//                               {/* <IosButton
//                         checked={switches[items.id]}
//                         onChange={() => handlePublished(items.id)}
//                       /> */}
//                               <IosButton
//                                 checked={switches[items.id]}
//                                 onChange={() => handlePublished(items.id)}
//                               />
//                             </td>
//                             <td
//                               style={{ paddingBlock: "27px" }}
//                               className="icon-flex"
//                             >
//                               <img
//                                 src={View}
//                                 alt="View Icon"
//                                 onClick={() => ProductsDetails(items.id)}
//                                 style={{ cursor: "pointer" }}
//                               />
//                               <img
//                                 src={Edit}
//                                 alt="Edit Icon"
//                                 onClick={() => {
//                                   // setProductId(items.id);
//                                   UpdateProducts(items.id);
//                                 }}
//                                 style={{ cursor: "pointer" }}
//                               />
//                               <img
//                                 src={Delete}
//                                 alt="Delete Icon"
//                                 onClick={() => handleOpenDeleteModal(items.id)}
//                                 style={{ cursor: "pointer" }}
//                                 // onClick={handleDelete(items.id)}
//                               />
//                             </td>
//                           </tbody>
//                         ))}
//                     </table>

//                     <div>
//                       <PaginationRounded
//                         count={totalPages}
//                         page={currentPage}
//                         onChange={handlePageChange}
//                       />
//                     </div>
//                   </>
//                 )}
//               </div>
//             </div>
//           </div>
//         </>
//       )}
//     </Main>
//   );
// };

// export default Products;

import React, { useEffect, useState } from "react";
import { Main } from "./style";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import useHooks from "./useHook";
import IosButton from "components/ios-button";
import PaginationRounded from "components/Pagination";
import DeleteModal from "components/modal/delete";
import Spiner from "components/spinner";
import Export from "../../../assets/img/products/Group 6971.png";
import View from "../../../assets/img/products/view.svg";
import Edit from "../../../assets/img/products/edit.svg";
import Delete from "../../../assets/img/products/delete.svg";
import Star from "../../../assets/img/products/star.png";

const Products = () => {
  const navigate = useNavigate();
  const { getProducts, putPublishProducts, getcategory, deleteProducts } =
    useHooks();

  const [productData, setProductData] = useState([]);
  const [switches, setswitches] = useState({});
  const [searchProducts, setSearchProducts] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchPrice, setSearchPrice] = useState("");
  const [isPublished, setIsPublished] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const getProductsData = async (params = "") => {
    setLoading(true);
    try {
      const response = await getProducts(params);
      if (response.status === true || "") {
        setProductData(response.data.results);
        setTotalPages(Math.ceil(response.data.counts / 10));
        const publishedList = response.data.results.reduce((acc, item) => {
          acc[item.id] = item.isPublished;
          return acc;
        }, {});
        setswitches(publishedList);
        setNoDataFound(response.data.results.length === 0);
      } else {
        setProductData([]);
        setNoDataFound(true);
        setTotalPages(1);
      }
    } catch (error) {
      console.log(error);
      setNoDataFound(true);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = () => {
    let params = `?page=${currentPage}&perPage=10`;

    if (searchProducts) {
      params += `&search=${encodeURIComponent(searchProducts)}`;
    }
    if (selectedSubCategoryId) {
      params += `&categoryId=${encodeURIComponent(selectedSubCategoryId)}`;
    }
    if (searchPrice) {
      params += `&price=${encodeURIComponent(searchPrice)}`;
    }
    if (isPublished !== "") {
      params += `&isPublished=${encodeURIComponent(isPublished)}`;
    }

    // Call the getProductsData function with the built params
    getProductsData(params);
  };

  const handleReset = () => {
    setSearchProducts("");
    setSearchCategory("");
    setSearchPrice("");
    setSelectedSubCategoryId("");
    setSearchSubCategory("");
    setIsPublished("");
    setCurrentPage(1);
    getProductsData(`?page=1&perPage=10`); // Fetch all data with default page and perPage
  };

  useEffect(() => {
    getProductsData(`?page=${currentPage}&perPage=10`);
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePublished = async (id) => {
    const newIsPublished = !switches[id];
    setswitches((prevSwitches) => ({
      ...prevSwitches,
      [id]: newIsPublished,
    }));

    try {
      const response = await putPublishProducts(id, newIsPublished);
      if (!response) {
        setswitches((prevSwitches) => ({
          ...prevSwitches,
          [id]: !newIsPublished,
        }));
        alert("Failed to update publish status");
      }
    } catch (error) {
      setswitches((prevSwitches) => ({
        ...prevSwitches,
        [id]: !newIsPublished,
      }));
      console.error(error);
      alert("Error occurred while updating publish status");
    }
  };

  //    GET CATEGORY AND SUB_CATEGORY
  useEffect(() => {
    const getCategoryData = async () => {
      try {
        const response = await getcategory();
        const activeCategories = response.data.filter(
          (category) => category.isActive === true
        );
        setCategoryData(activeCategories);
      } catch (error) {
        console.error(error);
      }
    };
    getCategoryData();
  }, []);

  const handleCategoryChange = async (e) => {
    const selectedCategoryId = e.target.value;
    setSearchCategory(selectedCategoryId);

    if (selectedCategoryId) {
      try {
        const response = await getcategory({
          parentId: Number(selectedCategoryId),
        });
        const activeSubCategories = response.data.filter(
          (subcategory) => subcategory.isActive === true
        );
        setSubCategoryData(activeSubCategories);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSubCategoryData([]);
    }
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;
    setSearchSubCategory(selectedSubCategoryId);

    const selectedSubCategory = subCategoryData.find(
      (subcategory) => subcategory.id === Number(selectedSubCategoryId)
    );

    if (selectedSubCategory) {
      console.log("Selected Sub-Category:", selectedSubCategory);
      setSelectedSubCategoryId(selectedSubCategory.id); // Save the sub-category ID in state
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteID !== null) {
      try {
        await deleteProducts(deleteID);
        const newData = productData.filter(
          (product) => product.id !== deleteID
        );
        setProductData(newData);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Error deleting catalog:", error);
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const exportData = productData.map((product, index) => ({
    sr: index + 1,
    name: product.name,
    category: product.category.parent?.name,
    subCategory: product.category.name,
    price: product.productPrice,
    salePrice: product.salePrice,
    quantity: product.qnty,
    published: product.isPublished,
  }));

  const headers = [
    { label: "SR. No", key: "sr" },
    { label: "Name", key: "name" },
    { label: "Category", key: "category" },
    { label: "SubCategory", key: "subCategory" },
    { label: "Price", key: "price" },
    { label: "SalePrice", key: "salePrice" },
    { label: "Quantity", key: "quantity" },
    { label: "Published", key: "published" },
  ];

  const ProductsDetails = (id) => {
    navigate(`/products/details/${id}`);
  };
  const UpdateProducts = (id) => {
    navigate(`/products/update/${id}`);
  };
  const AddProducts = (state) => {
    navigate("/products/add", { state });
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <>
          <DeleteModal
            open={deleteModalOpen}
            handleClose={handleCloseDeleteModal}
            handleDelete={handleConfirmDelete}
          />
          <div className="container-fluid">
            <div className="heading-flex">
              <p className="m-0 heading">Products Overview</p>
            </div>

            <div className="card border-0">
              <div class="card-body">
                <div className="products-flex">
                  <div className="export-text">
                    <CSVLink
                      data={exportData}
                      className="export-text nav-link"
                      headers={headers}
                      target="_blank"
                      filename={"customer.csv"}
                    >
                      <img src={Export} alt="" className="export-width" />
                      <p>Export</p>
                    </CSVLink>
                  </div>
                  <button
                    type="submit"
                    className="products-button"
                    onClick={() => AddProducts()}
                  >
                    + Add Product
                  </button>
                </div>
              </div>
            </div>
            <div className="card border-0 mt-3">
              <div class="card-body">
                <div className="total-flex">
                  <input
                    type="search"
                    name="searchProducts"
                    value={searchProducts}
                    onChange={(e) => setSearchProducts(e.target.value)}
                    id=""
                    className="search-input"
                    placeholder="Search Product"
                  />

                  <select
                    name="category"
                    id="category"
                    value={searchCategory}
                    className="category-width"
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select Category</option>
                    {categoryData.map((category, index) => (
                      <option key={index} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  <select
                    name="subcategory"
                    id="subcategory"
                    value={searchSubCategory}
                    className="category-width"
                    onChange={handleSubCategoryChange}
                  >
                    <option value="">Select Sub Category</option>
                    {subCategoryData.map((subcategory, index) => (
                      <option key={index} value={subcategory.id}>
                        {subcategory.name}
                      </option>
                    ))}
                  </select>

                  {/* <select
                    name=""
                    id=""
                    value={searchPrice}
                    className="price-width"
                    onChange={(e) => setSearchPrice(e.target.value)}
                  >
                    <option value="">Prices</option>
                    {price.map((items, index) => (
                      <>
                        <option key={index} value={items.heading}>
                          {items.heading}
                        </option>
                      </>
                    ))}
                  </select> */}
                  <select
                    name=""
                    id=""
                    value={isPublished}
                    className="status-width"
                    onChange={(e) => setIsPublished(e.target.value)}
                  >
                    <option value="">Status</option>
                    {/* {Status.map((items, index) => ( */}
                    {/* <> */}
                    <option value="true">Published</option>
                    <option value="false">Un Published</option>
                    {/* </> */}
                    {/* ))} */}
                  </select>
                </div>
                <div className="filter-butons">
                  <button
                    type="submit"
                    className="filter-button"
                    onClick={handleFilter}
                  >
                    Filter
                  </button>
                  &nbsp;
                  <button
                    type="submit"
                    className="Reset-button"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <div className="card mt-3 border-0">
              <div className="card-body">
                {noDataFound ? (
                  <div className="no-record">No Record Found</div>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table-width">
                        <thead className="table-head">
                          <tr className="table-head">
                            <th>Sr No.</th>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Ratings</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Price</th>
                            <th>Sale Price</th>
                            <th>Stock</th>
                            <th>Status</th>
                            <th>Published</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        {productData &&
                          productData.length > 0 &&
                          productData.map((items, index) => (
                            <tbody className="table-body" key={index}>
                              <td style={{ paddingBlock: "15px" }}>
                                {index + 1}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.images.length > 0 && (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${items.images[0].url}`} // Accessing the URL of the first image
                                    alt="Product Image"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      marginRight: "5px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                              </td>
                              <td
                                className="dot-property"
                                style={{ paddingBlock: "15px" }}
                              >
                                {items.name}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {" "}
                                <span className="rating">
                                  <img src={Star} alt="Rating Stars" /> &nbsp;
                                  {items.totalRatting.toString().slice(0, 3)}
                                </span>
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.category.parent?.name || "--"}
                              </td>
                              <td
                                style={{
                                  paddingBlock: "15px",
                                }}
                              >
                                {items.category.name}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.productPrice}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.salePrice}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.qnty}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                <p
                                  className={`status-${
                                    items.qnty <= 0 ? "sold" : "stock"
                                  } m-0`}
                                >
                                  {items.qnty <= 0 ? "Sold out" : "Stock In"}
                                </p>
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {/* <IosButton
                        checked={switches[items.id]}
                        onChange={() => handlePublished(items.id)}
                      /> */}
                                <IosButton
                                  checked={switches[items.id]}
                                  onChange={() => handlePublished(items.id)}
                                />
                              </td>
                              <td
                                style={{ paddingBlock: "27px" }}
                                className="icon-flex"
                              >
                                <img
                                  src={View}
                                  alt="View Icon"
                                  onClick={() => ProductsDetails(items.id)}
                                  className="view-icon"
                                />
                                <img
                                  src={Edit}
                                  alt="Edit Icon"
                                  onClick={() => {
                                    // setProductId(items.id);
                                    UpdateProducts(items.id);
                                  }}
                                  className="edit-icon"
                                />
                                <img
                                  src={Delete}
                                  alt="Delete Icon"
                                  onClick={() =>
                                    handleOpenDeleteModal(items.id)
                                  }
                                  className="delete-icon"
                                  // onClick={handleDelete(items.id)}
                                />
                              </td>
                            </tbody>
                          ))}
                      </table>
                    </div>
                    <div>
                      <PaginationRounded
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Main>
  );
};
export default Products;
