// import { Main } from "./style";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import { Link, useNavigate } from "react-router-dom";
// import { yupToFormErrors } from "formik";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import useHooks from "../useHooks";
// import toastr from "toastr";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// // import { ToastContainer } from "react-toastify";

// const Login = () => {
//   const { login } = useHooks();

//   const SignupSchema = Yup.object().shape({
//     firstName: Yup.string()
//       .required("Required")
//       .min(6, "Too Short!")
//       .max(11, "Too Long!"),
//     email: Yup.string().email("Invalid email").required("Required"),
//   });

//   const Dashboard = useNavigate();

//   const handleSubmit = (values) => {
//     if (!values.email || !values.firstName) {
//       console.log("Invalid fields. Show validation errors.");
//     } else {
//       console.log("Fields are filled. Route to forget.");
//       Dashboard("/");
//     }
//   };

//   const navigate = useNavigate();
//   const Forget = (state) => {
//     navigate("/auth/forget", { state });
//   };

//   const data = { email: "", password: "" };

//   const [inputdata, setInputdata] = useState(data);
//   const handleData = (e) => {
//     setInputdata({ ...inputdata, [e.target.name]: e.target.value });
//   };

//   const handleSubmited = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await login(inputdata);
//       console.log(response, "respo");

//       if (response.status === true) {
//         const { user } = response.data;

//         // Always clear login status and token before navigating
//         localStorage.removeItem("login");
//         localStorage.removeItem("token");
//         localStorage.removeItem("requireVerification");

//         // Save token and verification requirement in localStorage
//         localStorage.setItem("token", response.data.token);
//         localStorage.setItem("id", user.id);

//         const requireVerification =
//           user.forcePasswordChange === true && user.isVerified === false;

//         localStorage.setItem("requireVerification", requireVerification);

//         // Check if the user needs to change their password or is not verified
//         if (requireVerification) {
//           navigate("/auth/change-password");
//         } else if (user.isVerified === true) {
//           // If the user is verified, navigate to the home page
//           localStorage.setItem("login", true);
//           navigate("/");
//           setTimeout(() => {
//             toastr.success("Login successful");
//           }, 400);
//         } else if (user.isVerified === false) {
//           // If the user is verified, navigate to the home page
//           navigate("/blank-page");
//         } else if (user.isProfileComplete === false) {
//           navigate("/vendor-information");
//         }
//       } else {
//         // Handle errors based on the API response
//         const errorMessage = response.errors || "Login failed";
//         toastr.error(errorMessage);
//       }
//     } catch (error) {
//       localStorage.setItem("login", false);
//       toastr.error("Login failed");
//     }
//   };

//   const [showPassword, setShowPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };
//   return (
//     <>
//       {/* <ToastContainer /> */}
//       <Main>
//         <div>
//           <p className="yellow_border"></p>
//         </div>
//         <div className="">
//           <div className="margin">
//             <h4 className="heading">Sign In to your Account</h4>
//             <p className="welcome_Content">
//               Welcome back! please enter your detail
//             </p>
//           </div>

//           <Formik
//             initialValues={{
//               firstName: "",
//               email: "",
//             }}
//             validationSchema={SignupSchema}
//             onSubmit={handleSubmit}
//           >
//             {({ errors, touched }) => (
//               <Form>
//                 <div className="text-center">
//                   <Field
//                     type="email"
//                     name="email"
//                     value={inputdata.email}
//                     onChange={handleData}
//                     className="input my-3"
//                     placeholder="Email"
//                   />
//                   {errors.email && touched.email ? (
//                     <div
//                       style={{
//                         color: "red",
//                         marginTop: "-14px",
//                         textAlign: "start",
//                         marginLeft: "20%",
//                       }}
//                     >
//                       {errors.email}
//                     </div>
//                   ) : null}
//                   <div style={{ position: "relative" }}>
//                     <Field
//                       type={showPassword ? "text" : "password"}
//                       name="password"
//                       value={inputdata.password}
//                       onChange={handleData}
//                       className="input-password"
//                       placeholder="Password"
//                     />
//                     <span
//                       className="eye-icon"
//                       onClick={togglePasswordVisibility}
//                     >
//                       {showPassword ? (
//                         <FaRegEyeSlash size={20} color="#64748b" />
//                       ) : (
//                         <FaRegEye size={20} color="#64748b" />
//                       )}
//                     </span>
//                     {errors.firstName && touched.firstName ? (
//                       <div
//                         style={{
//                           color: "red",
//                           textAlign: "start",
//                           marginLeft: "20%",
//                         }}
//                       >
//                         {errors.firstName}
//                       </div>
//                     ) : null}
//                   </div>
//                 </div>

//                 <div className="remember">
//                   <span className="remember_me">
//                     <input type="checkbox" /> Remember me
//                   </span>
//                   <span
//                     className="Forgot_Password"
//                     style={{ cursor: "pointer" }}
//                     onClick={() => Forget()}
//                   >
//                     Forgot Password?
//                   </span>
//                 </div>
//                 <div className="text-center">
//                   <button
//                     type="submit"
//                     className="btn SignIn_Button"
//                     onClick={handleSubmited}
//                   >
//                     Sign In
//                   </button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </div>
//       </Main>
//     </>
//   );
// };

// export default Login;

import { Main } from "./style";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import useHooks from "../useHooks";
import toastr from "toastr";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const Login = () => {
  const { login } = useHooks();
  const navigate = useNavigate();

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"), // Added validation for password
  });

  const handleSubmit = async (values) => {
    try {
      const response = await login(values);
      console.log(response, "respo");

      if (response.status === true) {
        const { user } = response.data;

        // Always clear login status and token before navigating
        localStorage.removeItem("login");
        localStorage.removeItem("token");
        localStorage.removeItem("requireVerification");

        // Save token and verification requirement in localStorage
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("id", user.id);

        const requireVerification =
          user.forcePasswordChange === true && user.isVerified === false;

        localStorage.setItem("requireVerification", requireVerification);

        // Check if the user needs to change their password or is not verified
        if (requireVerification) {
          navigate("/auth/change-password");
        } else if (user.isVerified === true) {
          // If the user is verified, navigate to the home page
          localStorage.setItem("login", true);
          navigate("/");
          setTimeout(() => {
            toastr.success("Login successful");
          }, 400);
        } else if (user.isVerified === false) {
          navigate("/blank-page");
        } else if (user.isProfileComplete === false) {
          navigate("/vendor-information");
        }
      } else {
        // Handle errors based on the API response
        const errorMessage = response.errors || "Login failed";
        toastr.error(errorMessage);
      }
    } catch (error) {
      localStorage.setItem("login", false);
      toastr.error("Login failed");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Main>
        <div>
          <p className="yellow_border"></p>
        </div>
        <div className="">
          <div className="margin">
            <h4 className="heading">Sign In to your Account</h4>
            <p className="welcome_Content">
              Welcome back! please enter your detail
            </p>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="text-center">
                  <Field
                    type="email"
                    name="email"
                    className="input my-3"
                    placeholder="Email"
                  />
                  {errors.email && touched.email ? (
                    <div
                      style={{
                        color: "red",
                        marginTop: "-14px",
                        textAlign: "start",
                        marginLeft: "20%",
                      }}
                    >
                      {errors.email}
                    </div>
                  ) : null}
                  <div style={{ position: "relative" }}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="input-password"
                      placeholder="Password"
                    />
                    <span
                      className="eye-icon"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FaRegEyeSlash size={20} color="#64748b" />
                      ) : (
                        <FaRegEye size={20} color="#64748b" />
                      )}
                    </span>
                    {errors.password && touched.password ? (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                          marginLeft: "20%",
                        }}
                      >
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="remember">
                  <span className="remember_me">
                    <input type="checkbox" /> Remember me
                  </span>
                  <span
                    className="Forgot_Password"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/auth/forget")}
                  >
                    Forgot Password?
                  </span>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn SignIn_Button">
                    Sign In
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Main>
    </>
  );
};

export default Login;

// import { Main } from "./style";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// import { useState } from "react";
// import useHooks from "../useHooks";
// import toastr from "toastr";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

// const Login = () => {
//   const { login } = useHooks();
//   const navigate = useNavigate();

//   const SignupSchema = Yup.object().shape({
//     email: Yup.string().email("Invalid email").required("Required"),
//     password: Yup.string().required("Required"),
//   });

//   const handleSubmit = async (values, { setSubmitting }) => {
//     try {
//       // Call the API using the login function
//       const response = await login(values);
//       console.log(response, "response from API");

//       if (response.status === true) {
//         const { user } = response.data;

//         localStorage.removeItem("login");
//         localStorage.removeItem("token");
//         localStorage.removeItem("requireVerification");

//         localStorage.setItem("token", response.data.token);
//         localStorage.setItem("id", user.id);

//         const requireVerification =
//           user.forcePasswordChange === true && user.isVerified === false;

//         localStorage.setItem("requireVerification", requireVerification);

//         if (requireVerification) {
//           navigate("/auth/change-password");
//         } else if (user.isVerified === true) {
//           localStorage.setItem("login", true);
//           navigate("/");
//           setTimeout(() => {
//             toastr.success("Login successful");
//           }, 400);
//         } else if (user.isProfileComplete === false) {
//           navigate("/vendor-information");
//         }
//       } else {
//         const errorMessage = response.errors || "Login failed";
//         toastr.error(errorMessage);
//       }
//     } catch (error) {
//       toastr.error("Login failed");
//       console.log("Error during login", error);
//     } finally {
//       // Ensure that form submission state is set to false when API call completes
//       setSubmitting(false);
//     }
//   };

//   const [showPassword, setShowPassword] = useState(false);
//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const Forget = () => {
//     navigate("/auth/forget");
//   };

//   return (
//     <>
//       <Main>
//         <div>
//           <p className="yellow_border"></p>
//         </div>
//         <div className="">
//           <div className="margin">
//             <h4 className="heading">Sign In to your Account</h4>
//             <p className="welcome_Content">
//               Welcome back! please enter your details
//             </p>
//           </div>

//           <Formik
//             initialValues={{
//               email: "",
//               password: "",
//             }}
//             validationSchema={SignupSchema}
//             onSubmit={handleSubmit} // Correctly handle form submission
//           >
//             {({ errors, touched, isSubmitting }) => (
//               <Form>
//                 <div className="text-center">
//                   <Field
//                     type="email"
//                     name="email"
//                     className="input my-3"
//                     placeholder="Email"
//                   />
//                   {errors.email && touched.email ? (
//                     <div
//                       style={{
//                         color: "red",
//                         marginTop: "-14px",
//                         textAlign: "start",
//                         marginLeft: "20%",
//                       }}
//                     >
//                       {errors.email}
//                     </div>
//                   ) : null}
//                   <div style={{ position: "relative" }}>
//                     <Field
//                       type={showPassword ? "text" : "password"}
//                       name="password"
//                       className="input-password"
//                       placeholder="Password"
//                     />
//                     <span
//                       className="eye-icon"
//                       onClick={togglePasswordVisibility}
//                     >
//                       {showPassword ? (
//                         <FaRegEyeSlash size={20} color="#64748b" />
//                       ) : (
//                         <FaRegEye size={20} color="#64748b" />
//                       )}
//                     </span>
//                     {errors.password && touched.password ? (
//                       <div
//                         style={{
//                           color: "red",
//                           textAlign: "start",
//                           marginLeft: "20%",
//                         }}
//                       >
//                         {errors.password}
//                       </div>
//                     ) : null}
//                   </div>
//                 </div>

//                 <div className="remember">
//                   <span className="remember_me">
//                     <input type="checkbox" /> Remember me
//                   </span>
//                   <span
//                     className="Forgot_Password"
//                     style={{ cursor: "pointer" }}
//                     onClick={() => Forget()}
//                   >
//                     Forgot Password?
//                   </span>
//                 </div>
//                 <div className="text-center">
//                   <button
//                     type="submit"
//                     className="btn SignIn_Button"
//                     disabled={isSubmitting} // Disable button during form submission
//                   >
//                     {isSubmitting ? "Signing In..." : "Sign In"}
//                   </button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </div>
//       </Main>
//     </>
//   );
// };

// export default Login;
