import { AuthAPI } from "libs/http-service/api/auth.api";

const usehook = () => {
  const DisputesDetails = async (id) => {
    try {
      const response = await AuthAPI.disputeDetails(id);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return { DisputesDetails };
};

export default usehook;
