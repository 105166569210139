// import * as React from "react";
// import Switch, { switchClasses } from "@mui/joy/Switch";

// export default function IosButton() {
//   const [checked, setChecked] = React.useState(true);
//   console.log(checked, 'IOS Check')
//   return (
//     <Switch
//       color={checked ? "success" : "danger"}
//       checked={checked}
//       onChange={(event) => setChecked(event.target.checked)}
//       sx={{
//         "--Switch-thumbSize": "16px",
//         "--Switch-trackWidth": "40px",
//         "--Switch-trackHeight": "24px",
//         "--Switch-trackBackground": "#EE5E52",
//         "&:hover": {
//           "--Switch-trackBackground": "#EE5E52",
//         },
//         [`&.${switchClasses.checked}`]: {
//           "--Switch-trackBackground": "#5CB176",
//           "&:hover": {
//             "--Switch-trackBackground": "#5CB176",
//           },
//         },
//       }}
     
//     />
//   );
// }
import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
const IosButton = styled((props) => (
  
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#209A42',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33CF4D',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 19,
      height: 19,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#FF0000' : '',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
export default IosButton