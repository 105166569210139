import React, { useEffect, useState } from "react";
import { Main } from "./style";
import useHooks from "./useHook";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import Spiner from "components/spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const VendorProfileEdit = () => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const [profilePic, setProfilePicture] = useState("");
  const [inputdata, setInputdata] = useState({ name: "" ,lastname:""});
  const [backgroundPreview, setBackgroundPreview] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { updateProfile, getProfile, imagePost } = useHooks();

  useEffect(() => {
    const getProfileData = async () => {
      const fields = [
        "name",
        "lastname",
        "countryCode",
        "isoCode",
        "phone",
        "age",
        "profile_pic",
        "gender",
        "background_image",
      ];
      try {
        const response = await getProfile();
        if (response?.data) {
          const data = {};
          for (const key of Object.keys(response.data)) {
            if (fields.includes(key)) {
              data[key] = response.data[key];
            }
          }
          console.log(data);
          setInputdata(data); // Set state with the fetched data
          setBackgroundPreview(data.background_image);
          setProfilePicture(data.profile_pic);
          setBackgroundImage(data.background_image);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getProfileData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", selectedImages);

    // Default values in case `inputdata` is missing them
    const countryCode = inputdata.countryCode || "US";
    const isoCode = inputdata.isoCode || ""; // Default to empty string if isoCode is not set

    const data = {
      name: inputdata.name || "",
      lastname: inputdata.lastname || "",
      countryCode: countryCode,
      phone: inputdata.phone || "",
      isoCode: isoCode,
      age: parseInt(inputdata.age, 10) || 0, // Default to 0 if age is not a valid number
      gender: inputdata.gender || "",
    };

    try {
      const updatedData = {
        ...data,
        profile_pic: profilePic,
        background_image: backgroundImage,
      };

      const response = await updateProfile(updatedData);
      toastr.success("Profile updated successfully");
      navigate("/vendor-profile");
    } catch (error) {
      toastr.error("Failed to update profile:", error.message);
    }
  };

  const handleImagePickerClick = () => {
    document.getElementById("imagePicker").click();
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    try {
      const imagePaths = await Promise.all(
        files.map((file) => imagePost(file))
      );
      if (imagePaths) {
        const newImages = imagePaths.map((path) => `${path}`);
        setSelectedImages(newImages);
        setProfilePicture(newImages[newImages.length - 1]);
      }
    } catch (error) {
      console.error("Error in handleImageUpload: ", error);
      alert("An error occurred during image upload");
    }
  };

  const handleBackgroundUpload = async (e) => {
    const file = e.target.files[0];
    try {
      const imagePath = await imagePost(file);
      if (imagePath) {
        setBackgroundImage(imagePath);
        setBackgroundPreview(imagePath);
      }
    } catch (error) {
      console.error("Error in handleBackgroundUpload: ", error);
      alert("An error occurred during background image upload");
    }
  };

  const handlePhoneChange = (phone, country) => {
    console.log("Selected phone number:", phone);
    console.log("Selected country code:", country.dialCode); // Extract country code
    console.log("Selected ISO code:", country.countryCode); // Extract ISO code

    setInputdata((prevState) => ({
      ...prevState,
      phone: phone,
      countryCode: country.dialCode, // Set the country code dynamically
      isoCode: country.countryCode || prevState.countryCode, // Set the ISO code dynamically
    }));
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setInputdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          <div>
            <h1 className="heading">My Profile</h1>
          </div>
          <div className="profile-section-body">
            <p className="profile-title">Profile</p>
            <section>
              <div className="profile-head-section">
                <div className="profile-picture-section">
                  <p className="profile-picture-title">Profile Picture</p>
                  <figure className="profile-Image-section">
                    <label htmlFor="profileUpload">
                      <div
                        onClick={handleImagePickerClick}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          id="imagePicker"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleImageUpload}
                        />
                        <img
                          src={
                            selectedImages.length > 0
                              ? `${process.env.REACT_APP_BASE_URL}${
                                  selectedImages[selectedImages.length - 1]
                                }`
                              : `${process.env.REACT_APP_BASE_URL}${inputdata.profile_pic}`
                          }
                          alt="Profile"
                          className="profile-image"
                        />
                      </div>
                    </label>
                  </figure>
                </div>
              </div>
              <div className="input-fields-section">
                <div className="input-fields">
                  <label className="input-types-label">First Name</label>
                  <input
                    type="text"
                    name="name"
                    value={inputdata.name}
                    onChange={handleData}
                    placeholder="Full Name"
                    className="input-width"
                  />
                </div>
                {/* <div className="input-fields">
                  <label className="input-types-label">Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    value={inputdata.lastname}
                    onChange={handleData}
                    placeholder="Last Name"
                    className="input-width"
                  />
                </div> */}
                <div className="input-fields">
                  <label className="input-types-label">Age</label>
                  <input
                    type="number"
                    name="age"
                    value={inputdata.age}
                    onChange={handleData}
                    placeholder="Enter Age"
                    className="input-width"
                  />
                </div>
                <div className="input-fields">
                  <label className="input-types-label">Contact Number</label>
                  <PhoneInput
                    country={"us"}
                    value={inputdata.phone}
                    onChange={handlePhoneChange}
                    className="input-width"
                  />
                </div>
                <div className="input-fields">
                  <label className="input-types-label">Gender</label>
                  <select
                    name="gender"
                    className="input-width"
                    value={inputdata.gender}
                    onChange={handleData}
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  className="CreateVendor mt-3"
                  onClick={handleSubmit}
                >
                  Update Profile
                </button>
              </div>
            </section>
          </div>
        </div>
      )}
    </Main>
  );
};

export default VendorProfileEdit;

// // import React, { useEffect, useState } from "react";
// // import { Main } from "./style";
// // import userProfileImage from "../../../assets/img/vendor-profile-edit/profilePicture.svg";
// // import userProfileBgImage from "../../../assets/img/vendor-profile-edit/bgImageUploader.svg";
// // import useHooks from "./useHook";
// // import { useNavigate } from "react-router-dom";
// // import toastr from "toastr";
// // import Spiner from "components/spinner";

// // const VendorProfileEdit = () => {
// //   const [inputdata, setInputdata] = useState({
// //     name: "",
// //     countryCode: "",
// //     phone: "",
// //     age: "",
// //     gender: "",
// //     description: "",
// //     about: "",
// //     address: "",
// //     businessName: "",
// //     profile_pic: "",
// //     background_image: "",
// //   });

// //   const [backgroundPreview, setBackgroundPreview] = useState(null);
// //   const [loading, setLoading] = useState(true);

// //   const navigate = useNavigate();
// //   const { updateProfile, getProfile, imagePost } = useHooks();

// //   const handleData = (e) => {
// //     const { name, value } = e.target;
// //     setInputdata({
// //       ...inputdata,
// //       [name]: value,
// //     });
// //   };

// //   useEffect(() => {
// //     const getProfileData = async () => {
// //       const response = await getProfile();
// //       try {
// //         if (response?.data) {
// //           setInputdata({
// //             ...inputdata,
// //             ...response.data,
// //           });
// //           setBackgroundPreview(response.data.background_image);
// //         }
// //       } catch (error) {
// //         console.log(error);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     getProfileData();
// //   }, []);

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     // Validate that required fields are filled
// //     if (
// //       !inputdata.name ||
// //       !inputdata.countryCode ||
// //       !inputdata.phone ||
// //       !inputdata.age ||
// //       !inputdata.gender
// //     ) {
// //       toastr.error("Please fill in all required fields.");
// //       return;
// //     }

// //     const formData = new FormData();
// //     for (const key in inputdata) {
// //       formData.append(key, inputdata[key]);
// //     }

// //     try {
// //       await updateProfile(formData); // Assuming updateProfile handles FormData
// //       toastr.success("Profile updated successfully");
// //       navigate("/vendor-profile");
// //     } catch (error) {
// //       console.error("Failed to update profile:", error);
// //       alert("Failed to update profile:", error);
// //     }
// //   };

// //   const handleImagePickerClick = () => {
// //     document.getElementById("imagePicker").click();
// //   };

// //   const handleImageUpload = async (e) => {
// //     const file = e.target.files[0];
// //     try {
// //       const imagePath = await imagePost(file);
// //       setInputdata({ ...inputdata, profile_pic: imagePath });
// //     } catch (error) {
// //       console.error("Error in handleImageUpload: ", error);
// //       alert("An error occurred during image upload");
// //     }
// //   };

// //   const handleBackgroundUpload = async (e) => {
// //     const file = e.target.files[0];
// //     try {
// //       const imagePath = await imagePost(file);
// //       setInputdata({ ...inputdata, background_image: imagePath });
// //       setBackgroundPreview(imagePath);
// //     } catch (error) {
// //       console.error("Error in handleBackgroundUpload: ", error);
// //       alert("An error occurred during background image upload");
// //     }
// //   };

// //   return (
// //     <Main>
// //       {loading ? (
// //         <Spiner />
// //       ) : (
// //         <div className="container-fluid">
// //           <div>
// //             <h1 className="heading">My Profile</h1>
// //           </div>
// //           <div className="profile-section-body">
// //             <p className="profile-title">Profile</p>
// //             <section>
// //               <div className="profile-head-section">
// //                 <div className="profile-picture-section">
// //                   <p className="profile-picture-title">Profile Picture</p>
// //                   <figure className="profile-Image-section">
// //                     <label htmlFor="profileUpload">
// //                       <div
// //                         onClick={handleImagePickerClick}
// //                         style={{ cursor: "pointer" }}
// //                       >
// //                         <input
// //                           type="file"
// //                           id="imagePicker"
// //                           accept="image/*"
// //                           style={{ display: "none" }}
// //                           onChange={handleImageUpload}
// //                         />
// //                         <img
// //                           src={
// //                             inputdata.profile_pic
// //                               ? `${process.env.REACT_APP_BASE_URL}${inputdata.profile_pic}`
// //                               : userProfileImage
// //                           }
// //                           alt="Profile"
// //                           className="profile-image"
// //                         />
// //                       </div>
// //                     </label>
// //                   </figure>
// //                 </div>

// //                 <form className="file-upload-section">
// //                   <p className="file-upload-title">Background Image</p>
// //                   <input
// //                     type="file"
// //                     id="backgroundUpload"
// //                     accept="image/*"
// //                     onChange={handleBackgroundUpload}
// //                   />
// //                   <label
// //                     htmlFor="backgroundUpload"
// //                     className="upload-profile-image-section"
// //                   >
// //                     {backgroundPreview ? (
// //                       <img
// //                         src={`${process.env.REACT_APP_BASE_URL}${backgroundPreview}`}
// //                         alt="Background"
// //                         className="background-img"
// //                       />
// //                     ) : (
// //                       <img src={userProfileBgImage} alt="Background" />
// //                     )}
// //                   </label>
// //                 </form>
// //               </div>
// //               <div className="input-fields-section">
// //                 {/* Input fields */}
// //                 <div className="input-fields">
// //                   <label className="input-types-label">Name</label>
// //                   <input
// //                     type="text"
// //                     name="name"
// //                     value={inputdata.name}
// //                     onChange={handleData}
// //                     placeholder="Full Name"
// //                     className="input-width"
// //                   />
// //                 </div>
// //                 <div className="input-fields">
// //                   <label className="input-types-label">Country Code</label>
// //                   <input
// //                     type="text"
// //                     name="countryCode"
// //                     value={inputdata.countryCode}
// //                     onChange={handleData}
// //                     placeholder="+1"
// //                     className="input-width"
// //                   />
// //                 </div>
// //                 <div className="input-fields">
// //                   <label className="input-types-label">Phone</label>
// //                   <input
// //                     type="text"
// //                     name="phone"
// //                     value={inputdata.phone}
// //                     onChange={handleData}
// //                     placeholder="+90000 000000000"
// //                     className="input-width"
// //                   />
// //                 </div>
// //                 <div className="input-fields">
// //                   <label className="input-types-label">Age</label>
// //                   <input
// //                     type="number"
// //                     name="age"
// //                     value={inputdata.age}
// //                     onChange={handleData}
// //                     placeholder="Age"
// //                     className="input-width"
// //                   />
// //                 </div>
// //                 <div className="input-fields">
// //                   <label className="input-types-label">Gender</label>
// //                   <select
// //                     name="gender"
// //                     value={inputdata.gender}
// //                     onChange={handleData}
// //                     className="input-width"
// //                   >
// //                     <option value="">Select Gender</option>
// //                     <option value="male">Male</option>
// //                     <option value="female">Female</option>
// //                   </select>
// //                 </div>
// //                 <div className="input-fields">
// //                   <label className="input-types-label">Address</label>
// //                   <input
// //                     type="text"
// //                     name="address"
// //                     value={inputdata.address}
// //                     onChange={handleData}
// //                     placeholder="Your Address"
// //                     className="input-width"
// //                   />
// //                 </div>

// //                 <div className="input-fields">
// //                   <label className="input-types-label">Description</label>
// //                   <textarea
// //                     rows={4}
// //                     name="description"
// //                     value={inputdata.description}
// //                     onChange={handleData}
// //                     placeholder="Describe here..."
// //                     className="textarea-width"
// //                   />
// //                 </div>

// //                 <div className="input-fields">
// //                   <label className="input-types-label">Shop</label>
// //                   <textarea
// //                     rows={5}
// //                     name="about"
// //                     value={inputdata.about}
// //                     onChange={handleData}
// //                     placeholder="About the Shop..."
// //                     className="textarea-width"
// //                   />
// //                 </div>
// //               </div>
// //               {/* Update Button */}
// //               <div className="text-end">
// //                 <button
// //                   type="submit"
// //                   className="CreateVendor"
// //                   onClick={handleSubmit}
// //                 >
// //                   Update Profile
// //                 </button>
// //               </div>
// //             </section>
// //           </div>
// //         </div>
// //       )}
// //     </Main>
// //   );
// // };

// // export default VendorProfileEdit;
