import React, { useState, useEffect, useRef } from "react";
import { Main } from "./style";
import { RxCross2 } from "react-icons/rx";
import Dollar from "../../../assets/img/add-products/$.png";
import ProductsInputs from "components/products-inputs";
import MaterialTags from "components/product-material-tags";
import useHooks from "./useHook";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Spiner from "components/spinner";
import toastr from "toastr";
import PuffLoaderComponent from "components/puff-loader";
import { useForm } from "react-hook-form";
import { SketchPicker } from "react-color";

const UpdateProducts = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [metas, setMetas] = useState([]);
  const [barcode, setBarcode] = useState("");
  const [productprice, setProductprice] = useState();
  const [saleprice, setSalePrice] = useState();
  const [quantity, setQuantity] = useState();
  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [unit, setUnit] = useState("");
  const [warrenty, setWarrenty] = useState([]);
  const [selected, setSelected] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [color, setColor] = useState("");
  const [colorData, setColorData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [material, setMaterial] = useState([]);
  const [materialId, setMaterialId] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]);
  const [collectionIds, setCollectionIds] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState([]);
  const [subCategoryValue, setSubCategoryValue] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  const { register, watch, control, setValue } = useForm();

  const {
    getcategory,
    getmaterial,
    imagePost,
    getcollections,
    productsPost,
    getsingleproduct,
    updateProduct,
  } = useHooks();

  const navigate = useNavigate();

  useEffect(() => {
    const getCategoryData = async () => {
      try {
        const response = await getcategory();
        const activeCategories = response.data.filter(
          (category) => category.isActive === true
        );
        setCategoryData(activeCategories);
        if (activeCategories.length > 0) {
          setSelectedCategoryId(activeCategories[0].id);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCategoryData();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async (categoryId) => {
      try {
        const res = await getcategory({ parentId: categoryId });
        const activeSubCategories = res.data.filter(
          (subcategory) => subcategory.isActive === true
        );
        setSubCategoryData(activeSubCategories);
        return activeSubCategories;
      } catch (error) {
        console.error(error);
        setSubCategoryData([]);
      }
    };
    if (selectedCategoryId !== null) {
      fetchSubCategories(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    const fetchMaterialData = async () => {
      const page = 1;
      const perPage = 40;
      try {
        const params = { page, perPage };
        const res = await getmaterial(params);
        if (res.status === true) {
          setMaterial(res.data);
          const ids = res.data.map((material) => material.id);
          setMaterialId(ids);
        }
      } catch (error) {
        console.error("Error fetching material data:", error);
      }
    };

    fetchMaterialData();
  }, []);

  const handleMaterialSelect = (event) => {
    const selectedId = event.target.value;
  };

  useEffect(() => {
    const getcollectionsData = async () => {
      const userId = localStorage.getItem("id");
      const response = await getcollections({ userId });
      try {
        if (response.status === true) {
          setCollectionsData(response.data.results);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getcollectionsData();
  }, []);

  useEffect(() => {
    const ids = collectionsData.map((collection) => collection.id);
    setCollectionIds(ids);
  }, []);

  // const handleCategoryChange = (event) => {
  //   setSelectedCategoryId(parseInt(event.target.value));
  // };

  // const handleSubCategoryChange = (event) => {
  //   setSubCategoryValue(parseInt(event.target.value));
  // };
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(parseInt(event.target.value));
    // Fetch and set subcategories based on selected category
    const selectedCategory = categoryData.find(
      (category) => category.id === parseInt(event.target.value)
    );
    setSubCategoryData(selectedCategory ? selectedCategory.subCategories : []);
  };

  const handleSubCategoryChange = (event) => {
    setSubCategoryValue(parseInt(event.target.value));
  };

  const handleSelectCollection = (e) => {
    setSelectedCollectionId(e.target.value);
  };

  const handleImagePickerClick = () => {
    document.getElementById("imageUpload").click();
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    try {
      setLoader(true);
      const imagePaths = await Promise.all(
        files.map((file) => imagePost(file))
      );
      if (imagePaths && imagePaths.length > 0) {
        const newImages = imagePaths.map((path) => `${path}`);
        setSelectedImages((prevImages) => [...prevImages, ...newImages]);
      }
    } catch (error) {
      console.error("Error in handleImageChange: ", error);
      alert("An error occurred during image upload");
    } finally {
      setLoader(false);
    }
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setProductprice(value);
    }
  };

  const handleSaleChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setSalePrice(value);
    }
  };

  const handleLength = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setLength(value);
    }
  };

  const handleWidth = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setWidth(value);
    }
  };

  const handleCheckboxChange = (value) => {
    setSelected(value);
    setWarrenty(value === "yes" ? warrenty : "0"); // Reset warrenty if 'no' is selected
    setValue("warrantySelected", value); // Update react-hook-form value
  };

  const handleHeight = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setHeight(value);
    }
  };

  const handleMetaKeywords = (Keywords) => {
    setMetas(Keywords);
  };

  const handlechange = (value) => {
    setSelected(value);
  };

  const handleMaterialTagsChange = (selectedMaterials) => {
    setMaterial(selectedMaterials);
  };

  // colors

  const togglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     setColorData([
  //       ...colorData,
  //       {
  //         id: Date.now(),
  //         colorCode: color,
  //         colorName: color,
  //         image: "", // Placeholder, will be updated by handleImageUpload
  //       },
  //     ]);
  //     setColor("");
  //   }
  // };
  const handleApply = () => {
    const newColor = {
      id: Date.now(),
      colorName: color,
      colorCode: color,
      image: "",
    };

    const updatedColorData = [...colorData, newColor];
    setColorData(updatedColorData);
    setColor(""); // Reset color input after adding
    setShowPicker(false); // Close the color picker
  };
  const handleCancel = () => {
    setShowPicker(false);
  };

  const removeColor = (id) => {
    setColorData(colorData.filter((color) => color.id !== id));
  };

  //remove tag

  const removeTags = (name) => {
    setMaterialNames(materialNames.filter((n) => n !== name));
  };

  const handleImageUpload = async (e, colorId) => {
    const files = Array.from(e.target.files);
    if (!files.length) {
      alert("No files selected for upload.");
      return;
    }

    try {
      setLoader(true);
      const imagePaths = await Promise.all(
        files.map((file) => imagePost(file))
      );
      if (imagePaths.length > 0) {
        const newImage = `${imagePaths[0]}`;
        const updatedColorData = colorData.map((color) => {
          if (color.id === colorId) {
            return {
              ...color,
              image: newImage,
            };
          }
          return color;
        });
        setColorData(updatedColorData);
      }
    } catch (error) {
      console.error("Error in handleImageUpload: ", error);
      alert("An error occurred during image upload");
    } finally {
      setLoader(false);
    }
  };

  // #================================================================

  //   metas tags
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const handleInputKeyPress = (event) => {
    const { key } = event;
    if (key === "Enter" || key === "," || key === " ") {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue && !metas.includes(trimmedValue)) {
        setMetas([...metas, trimmedValue]);
        setInputValue("");
      }
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const removeTag = (tagToRemove) => {
    setMetas(metas.filter((tag) => tag !== tagToRemove));
  };

  const handleInputPaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");
    const pastedTags = pasteData.split(/[,\s]+/).map((tag) => tag.trim());
    const newTags = [...new Set([...metas, ...pastedTags])];
    setMetas(newTags.filter((tag) => tag && !metas.includes(tag)));
  };

  const handleTagClick = () => {
    inputRef.current.focus();
  };

  // #================================================================

  // material Tags  #================================================================

  const [tagList, setTagList] = useState([]);
  const [selectedMaterialName, setSelectedMaterialName] = useState("");
  const inputRef2 = useRef(null);

  // Function to handle material selection
  const handleKeywordSelect = (event) => {
    const { value } = event.target;

    if (value === "") return;

    const selectedMaterial = material.find(
      (material) => material.name === value
    );

    if (selectedMaterial) {
      setTagList((prevTags) => {
        // Check if the material ID is already in the tagList
        if (!prevTags.includes(selectedMaterial.id)) {
          return [...prevTags, selectedMaterial.id];
        }
        return prevTags;
      });
    }

    setSelectedMaterialName(""); // Reset selectedMaterialName after selection
  };
  useEffect(() => {
    console.log("Selected Material Names:", selectedMaterialName);
  }, []);

  const handleTagRemoval = (tagToRemove) => {
    // Assuming tagList contains only IDs
    const updatedTags = tagList.filter((tag) => tag !== tagToRemove);
    setTagList(updatedTags);
  };

  const handleTagClick2 = () => {
    inputRef2.current.focus();
  };

  // ==================================================================
  console.log("selectedCollectionId", selectedCollectionId);
  const { id } = useParams();

  const handleCreateProducts = async (e) => {
    e.preventDefault();
    const productData = {
      name,
      description: description,
      images: selectedImages.map((image) => image),
      metas,
      barcode,
      productPrice: parseFloat(productprice),
      salePrice: parseFloat(saleprice),
      qnty: parseInt(quantity),
      length: parseFloat(length),
      width: parseFloat(width),
      height: parseFloat(height),
      unit,
      warrenty: [parseInt(warrenty)],
      colors: colorData.map((color) => ({
        image: color.image,
        colorCode: color.colorCode,
        colorName: color.colorName,
      })),

      // categoryId: subCategoryValue
      //   ? parseInt(subCategoryValue)
      //   : selectedCategoryId,
      categoryId: subCategoryValue
        ? parseInt(subCategoryValue)
        : selectedCategoryId,
      materials: tagList,
      collectionIds: [parseInt(selectedCollectionId)],
    };
    try {
      const response = await updateProduct(id, productData);
      if (response.status === true) {
        toastr.success("Product updated successfully");
        // setTagList([]);
        // setSelectedMaterialName("");
        // setName("");
        // setDescription("");
        // setMetas([]);
        // setBarcode("");
        // setProductprice("");
        // setSalePrice("");
        // setQuantity("");
        // setLength("");
        // setWidth("");
        // setHeight("");
        // setUnit([]);
        // setWarrenty("");
        // setSelected(null);
        // setSelectedImages([]);
        // setColor([]);
        // setColorData([]);
        // setSelectedCategoryId();
        // setMaterial([]);
        // setMaterialId([]);
        // setCollectionsData([]);
        // setCollectionIds([]);
        // setSelectedCollectionId(null);
        navigate("/products");
      } else {
        alert("Failed to update product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      alert("An error occurred while adding the product");
    }
  };

  // get products
  const [productData, setProductData] = useState([]);
  const [switches, setswitches] = useState({});
  const [materialNames, setMaterialNames] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await getsingleproduct({ id });

        if (response.status) {
          const product = response.data;
          setName(product.name);
          setDescription(product.description);
          setBarcode(product.barcode);
          setProductprice(product.productPrice);
          setSalePrice(product.salePrice);
          setQuantity(product.qnty);
          setLength(product.length);
          setWidth(product.width);
          setHeight(product.height);
          setUnit(product.unit);
          setMetas(product.metas.map((meta) => meta.name) || "");
          setColorData(product.colors || []);
          setSelectedImages(product.images.map((images) => images.url) || "");
          // setSubCategoryValue(product.category?.parent?.name || null);
          setSelectedCategoryId(
            product.category?.parent?.id || product.category?.id
          );
          setSubCategoryValue(product.category?.id || null);
          // setWarrenty(product.warrenty || []);
          const warrantyValue = product.warrenty || 0;
          const warrantySelected = warrantyValue > 0 ? "yes" : "no";
          setSelected(warrantySelected);
          setWarrenty(warrantyValue.toString());
          setValue("warrantySelected", warrantySelected);
          // setValue("warrantySelected", selected);
          const existingMaterials = product.materials
            .map((item) => (item.material ? item.material.id : ""))
            .filter((id) => id);
          setTagList(existingMaterials);
          if (product.collections && product.collections.length > 0) {
            setSelectedCollectionId(product.collections[0].id);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProductData();
  }, [id]);

  return (
    <Main>
      {Loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          <div className="heading-flex">
            <p className="m-0 heading">Products</p>
          </div>
          <form onSubmit={handleCreateProducts}>
            <div className="card border-0">
              <div className="card-padding">
                <p className="m-0 info">Product Info</p>
                <p className="m-0 bottom-border"></p>

                <div className="padding mt-5">
                  <div className="inputs-flex">
                    <h5 className="label">Product Title/Name</h5>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      placeholder="Product Title/Name"
                      className="inputs-width"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Product Description</h5>
                    <textarea
                      className="inputs-description"
                      cols={5}
                      rows={5}
                      placeholder="Product Description"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Product Images</h5>
                    <div>
                      <div
                        onClick={handleImagePickerClick}
                        className="upload-img"
                        style={{ position: "relative", cursor: "pointer" }}
                      >
                        <input
                          type="file"
                          id="imageUpload"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          multiple
                        />
                        <div>
                          <p className="m-0 drag">Drag your images here</p>
                          <p className="m-0 accepted-only">
                            (Only *.jpeg, *.webp and *.png images will be
                            accepted)
                          </p>
                        </div>
                      </div>
                      {loader ? (
                        <PuffLoaderComponent />
                      ) : (
                        <div
                          className="mt-3"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                            width: "91%",
                          }}
                        >
                          {selectedImages.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <img
                                src={
                                  image.url
                                    ? `${process.env.REACT_APP_BASE_URL}${image.url}`
                                    : `${process.env.REACT_APP_BASE_URL}${image}`
                                }
                                data-url={image}
                                alt={`Selected ${index}`}
                                style={{
                                  width: "73px",
                                  height: "73px",
                                  borderRadius: "10px",
                                }}
                              />
                              <RxCross2
                                onClick={() => handleRemoveImage(index)}
                                className="Cross-btn"
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Meta Keywords</h5>
                    <div className="">
                      <input
                        type="text"
                        name="metas"
                        value={inputValue} // Bind the input value
                        onChange={handleInputChange}
                        onKeyPress={handleInputKeyPress}
                        onPaste={handleInputPaste}
                        className="inputs-width"
                        placeholder="Product Keywords"
                        ref={inputRef}
                      />
                      <div className="flex">
                        {metas.map((tag, index) => (
                          <span key={index} className="tag-background">
                            {tag}
                            <RxCross2
                              onClick={() => removeTag(tag)}
                              size={16} // Adjust size as needed
                              color="white"
                              className="cross-border"
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-flex mt-4">
                    <h5 className="label">Barcode</h5>
                    <div>
                      <input
                        type="text"
                        className="inputs-width"
                        placeholder="Product Barcode"
                        name="barcode"
                        value={barcode}
                        onChange={(e) => setBarcode(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Select Category</h5>
                    <div>
                      <select
                        className="inputs-width"
                        onChange={handleCategoryChange}
                        // value={selectedCategoryId}
                        value={selectedCategoryId}
                      >
                        <option value="" hidden>
                          Select category
                        </option>
                        {categoryData && categoryData.length > 0 ? (
                          categoryData.map((category, index) => (
                            <option value={category.id} key={index}>
                              {category.name}
                            </option>
                          ))
                        ) : (
                          <option>No categories available</option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Select Sub Category</h5>
                    <div>
                      <select
                        className="inputs-width"
                        placeholder="Sub Category"
                        onChange={handleSubCategoryChange}
                        value={subCategoryValue}
                      >
                        <option value="" hidden>
                          Select sub-category
                        </option>
                        {subCategoryData && subCategoryData.length > 0 ? (
                          subCategoryData.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        ) : (
                          <option>No subcategories available</option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Product Price</h5>
                    <div>
                      <div className="position-relative">
                        <span className="img-dollar">
                          <img src={Dollar} alt="Dollar Sign" />
                        </span>
                        <input
                          type="number"
                          className="inputs-price"
                          name="productPrice"
                          value={productprice}
                          onChange={handlePriceChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Sale Price</h5>
                    <div>
                      <div className="position-relative">
                        <span className="img-dollar">
                          <img src={Dollar} alt="Dollar Sign" />
                        </span>
                        <input
                          type="number"
                          className="inputs-price"
                          name="salePrice"
                          value={saleprice}
                          onChange={handleSaleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Product Quantity</h5>
                    <div>
                      <input
                        type="number"
                        className="inputs-width"
                        placeholder="0"
                        name="quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Dimension</h5>
                    <div>
                      <input
                        type="number"
                        className="inputs-height"
                        placeholder="Length"
                        value={length}
                        onChange={handleLength}
                      />
                      &nbsp;
                      <RxCross2 />
                      &nbsp;
                      <input
                        type="number"
                        className="inputs-height"
                        placeholder="Width"
                        value={width}
                        onChange={handleWidth}
                      />
                      &nbsp;
                      <RxCross2 />
                      &nbsp;
                      <input
                        type="number"
                        className="inputs-height"
                        placeholder="Height"
                        value={height}
                        onChange={handleHeight}
                      />
                      &nbsp;
                      <select
                        className="inputs-height"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      >
                        <option value="0">Select Unit</option>
                        <option value="Cm">Centimeter</option>
                        <option value="Inch">Inches</option>
                      </select>
                    </div>
                  </div>

                  {/* <div className="inputs-flex mt-4">
                    <label htmlFor="colorSelect" className="label">
                      Product Color
                    </label>
                    <div className="position-relative">
                      <div>
                        <div className="inputs-width">
                          <input
                            type="color"
                            value={color}
                            onChange={handleColorChange}
                            onKeyDown={handleKeyPress}
                          />
                          <p
                            style={{
                              position: "absolute",
                              top: "16px",
                              right: "0px",
                              padding: "0px 10px",
                            }}
                          >
                            {color}
                          </p>
                        </div>
                        {loader ? (
                          <PuffLoaderComponent />
                        ) : (
                          <div className="select-color">
                            {colorData.map((color) => (
                              <div key={color.id}>
                                <div
                                  style={{
                                    backgroundColor:
                                      color.colorCode || color.code,
                                    display: "inline-block",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    margin: "5px",
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="picked-color">
                                    {color.colorName || color.name}
                                  </span>
                                  <RxCross2
                                    onClick={() => removeColor(color.id)}
                                    size={20}
                                    color="white"
                                    className="cross-border"
                                  />
                                </div>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="file"
                                    id={`fileInput_${color.id}`}
                                    onChange={(e) =>
                                      handleImageUpload(e, color.id)
                                    }
                                    style={{ display: "none" }}
                                    multiple
                                  />
                                  <div
                                    className="color-upload-img"
                                    onClick={() =>
                                      document
                                        .getElementById(`fileInput_${color.id}`)
                                        .click()
                                    }
                                    style={{
                                      position: "relative",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {!color.image && (
                                      <div>
                                        <p className="m-0 drag">
                                          Click to upload images
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className="mt-3"
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "10px",
                                    }}
                                  >
                                    {color.image && (
                                      <div>
                                        <img
                                          src={`${process.env.REACT_APP_BASE_URL}/${color.image}`}
                                          alt={`Color ${color.colorName}`}
                                          className="color-img"
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                            cursor: "pointer",
                                          }}
                                        ></div>
                                      </div>
                                    )}
                                    {(color.images || []).map((image) => (
                                      <div
                                        key={image}
                                        style={{ position: "relative" }}
                                      >
                                        <img
                                          src={image}
                                          alt={"selected"}
                                          className="color-img"
                                        />
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                            cursor: "pointer",
                                          }}
                                        ></div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="inputs-flex mt-4">
                    <label htmlFor="colorSelect" className="label">
                      Product Color
                    </label>
                    <div className="position-relative">
                      <div>
                        <div className="inputs-width">
                          <div
                            onClick={togglePicker}
                            style={{
                              width: "68px",
                              height: "28px",
                              backgroundColor: color,
                              border: "1px solid #ccc",
                              cursor: "pointer",
                            }}
                          ></div>
                          <p
                            style={{
                              position: "absolute",
                              top: "16px",
                              right: "0px",
                              padding: "0px 10px",
                            }}
                          >
                            {color}
                          </p>
                        </div>
                        {showPicker && (
                          <div style={{ position: "absolute", zIndex: 2 }}>
                            <SketchPicker
                              color={color}
                              onChange={handleColorChange}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "10px",
                                background: "white",
                              }}
                            >
                              <button
                                onClick={handleApply}
                                className="apply-btn"
                              >
                                Apply
                              </button>
                              <button
                                onClick={handleCancel}
                                className="cancel-btn"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}
                        {Loading ? (
                          <PuffLoaderComponent />
                        ) : (
                          <div className="select-color">
                            {colorData.map((color, index) => (
                              <div key={color.id}>
                                <div
                                  style={{
                                    backgroundColor: color.colorCode,
                                    display: "inline-block",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    margin: "5px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="picked-color">
                                    {color.colorName}
                                  </span>
                                  <RxCross2
                                    onClick={() => removeColor(color.id)}
                                    size={20}
                                    color="white"
                                    className="cross-border"
                                  />
                                </div>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="file"
                                    id={`fileInput_${color.id}`}
                                    onChange={(e) =>
                                      handleImageUpload(e, color.id)
                                    }
                                    style={{ display: "none" }}
                                  />
                                  <label
                                    htmlFor={`fileInput_${color.id}`}
                                    className="color-upload-img"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {!color.image && (
                                      <div>
                                        <p className="m-0 drag">
                                          Click to upload images
                                        </p>
                                      </div>
                                    )}
                                  </label>

                                  {color.image && (
                                    <div className="mt-3">
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}/${color.image}`}
                                        alt={`Selected ${color.colorName}`}
                                        className="color-img"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Product Material</h5>
                    <div>
                      <select
                        value={selectedMaterialName}
                        onChange={handleKeywordSelect}
                        className="inputs-width"
                        ref={inputRef2}
                      >
                        <option value="" disabled hidden>
                          Select a Product Keyword
                        </option>
                        {material.map((material) => (
                          <option key={material.id} value={material.name}>
                            {material.name}
                          </option>
                        ))}
                      </select>
                      <div className="flex">
                        {tagList.map((tag, index) => (
                          <span key={index} className="tag-background">
                            {material.find((mat) => mat.id === tag)?.name ||
                              tag}
                            <RxCross2
                              onClick={() => handleTagRemoval(tag)}
                              size={16}
                              color="white"
                              className="cross-border"
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="inputs-flex mt-4">
                    <h5 className="label">Select Collection</h5>
                    <div>
                      <select
                        className="inputs-width"
                        placeholder="Category"
                        name="collections"
                        onChange={handleSelectCollection}
                        value={selectedCollectionId}
                      >
                        <option value="" hidden>
                          Select Collections
                        </option>
                        {collectionsData.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* <div className="inputs-flex mt-4">
                    <h5 className="label">Warranty</h5>
                    <div className="span-select-flex">
                      <div className="span-flex">
                        <span className="span-space">
                          <input
                            type="radio"
                            checked={selected === "no"}
                            onChange={() => handleCheckboxChange("no")}
                            style={{ width: "18px", cursor: "pointer" }}
                          />
                          No
                        </span>
                        &nbsp;
                        <span className="span-space">
                          <input
                            type="radio"
                            checked={selected === "yes"}
                            onChange={() => handleCheckboxChange("yes")}
                            style={{ width: "18px", cursor: "pointer" }}
                          />
                          Yes
                        </span>
                      </div>
                      <div>
                        <select
                          className="warranty-input"
                          value={warrenty}
                          onChange={(e) => setWarrenty(e.target.value)}
                        >
                          <option value="0">Select Warranty years</option>
                          <option value="1">1 Year</option>
                          <option value="2">2 Year</option>
                          <option value="3">3 Year</option>
                          <option value="4">4 Year</option>
                          <option value="5">5 Year</option>
                          <option value="6">6 Year</option>
                          <option value="7">7 Year</option>
                          <option value="8">8 Year</option>
                          <option value="9">9 Year</option>
                          <option value="10">10 Year</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                  <div className="inputs-flex mt-4">
                    <h5 className="label">Warranty</h5>
                    <div className="span-select-flex">
                      <div className="span-flex">
                        <span className="span-space">
                          <input
                            type="radio"
                            {...register("warrantySelected")}
                            value="no"
                            checked={selected === "no"}
                            onChange={() => handleCheckboxChange("no")}
                            style={{ width: "18px", cursor: "pointer" }}
                          />
                          No
                        </span>
                        &nbsp;
                        <span className="span-space">
                          <input
                            type="radio"
                            {...register("warrantySelected")}
                            value="yes"
                            checked={selected === "yes"}
                            onChange={() => handleCheckboxChange("yes")}
                            style={{ width: "18px", cursor: "pointer" }}
                          />
                          Yes
                        </span>
                      </div>
                      <div>
                        {watch("warrantySelected") === "yes" && (
                          <select
                            className="warranty-input"
                            value={warrenty}
                            onChange={(e) => setWarrenty(e.target.value)}
                          >
                            <option value="0">Select Warranty years</option>
                            <option value="1">1 Year</option>
                            <option value="2">2 Year</option>
                            <option value="3">3 Year</option>
                            <option value="4">4 Year</option>
                            <option value="5">5 Year</option>
                            <option value="6">6 Year</option>
                            <option value="7">7 Year</option>
                            <option value="8">8 Year</option>
                            <option value="9">9 Year</option>
                            <option value="10">10 Year</option>
                          </select>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="buttons-flex">
                    <button type="submit" className="Cancel">
                      Cancel
                    </button>
                    <button type="submit" className="Add">
                      Update Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </Main>
  );
};

export default UpdateProducts;
