import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  height: 100vh;
  .fixed {
    /* position: fixed; */
    z-index: 999;
    /* width: 100%; */
    @media (max-width: 660px) {
      /* top: 20px; */
      /* left: 10px; */
    }
  }
  .width1 {
    width: 18%;

    @media (max-width: 769px) {
      width: 0%;
    }
  }
  .width2 {
    width: 81%;
    margin-left: 250px;
    @media (max-width: 769px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  .dashboard-routes {
    height: 100vh;
  }
  .cont {
    margin-left: 240px;
    padding: 0px;
    @media (max-width: 769px) {
      margin-left: 0px;
      padding: 0;
    }
  }

  .header {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure it appears above other content */
    background-color: white; /* Optional: set a background color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visibility */
  }
`;
