import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Form, Title } from "./style";
import closeIcon from "../../../assets/img/report-modal/closeIcon.svg";
import clip from "../../../assets/img/chat/modal-clip.png";
import { RiDeleteBin6Fill } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "406px",
  height: "auto",
  bgcolor: "#FFFFFF",
  p: "25px",
  borderRadius: "30px",
  outline: "none",
  overflowY: "auto",
};
const styles = {
  position: "fixed",
  zIndex: " 1300",
  right: "0,",
  bottom: "0",
  top: "0",
  left: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  outline: "none",
};
const ChatReportModal = ({ onOpen, onClose }) => {
  const handleSubmit = () => {
    console.log("Report Comments Submitted");
  };
  const handleModalClose = () => {
      onClose(false);
  };

  const Data = [
    "User engaged in unacceptable behavior, including the use of derogatory language.",
    "Encountered verbal abuse from user in comments.",
    "User comment contains offensive or disrespectful language",
    "Received inappropriate language or abusive behavior from user.",
    "User comment includes harassing or threatening language.",
    "Other",
  ];
  const [selectedImages, setSelectedImages] = React.useState([]);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/webp"
    );

    if (validFiles.length !== files.length) {
      alert("Only *.jpeg, *.webp and *.png images are accepted");
    }

    const newImages = validFiles.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    });

    Promise.all(newImages).then((images) => {
      setSelectedImages((prevImages) => [...prevImages, ...images]);
    });
  };
  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const triggerFileInput = () => {
    document.getElementById("imageUpload").click();
  };
  return (
    <>
      <Modal
        open={onOpen}
        onClose={handleModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
        sx={styles}
      >
        <Fade in={onOpen}>
          <Box sx={style}>
            <Title className="modal-head">
              <p className="modal-title">Report Comment</p>
              <img
                src={closeIcon}
                alt="CloseButton"
                onClick={onClose}
                className="close"
              />
            </Title>
            <Form>
              {Data.map((items) => (
                <div className="report-section-background">
                  <div className="flex-checkbox-report-text">
                    <input type="checkbox" />
                    <p className="report-text">{items}</p>
                  </div>
                </div>
              ))}

              <textarea
                className="report-section-textarea"
                placeholder="Write Your issue here..."
              />

              <div>
                
                <div
                  className="upload-img"
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={triggerFileInput}
                >
                   
                  <input
                    type="file"
                    id="imageUpload"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    multiple
                  />
                  <div>
                  <div className="upload-files">
                    <img src={clip} alt="clip" className="clip-image" />
                    <p>Attach Layout</p>
                  </div>
                  </div>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      width: "91%",
                    }}
                  >
                    {selectedImages.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={image}
                          alt={`Selected ${index}`}
                          style={{
                            width: "73px",
                            height: "73px",
                            borderRadius: "10px",
                          }}
                        />
                        <RiDeleteBin6Fill
                        size={30}
                          onClick={() => handleRemoveImage(index)}
                          className="Cross-btn"
                        />
                      </div>
                    ))}
                  </div>
                
              </div>
              <button
                type="submit"
                id="form-submitButton"
                onClick={handleSubmit}
              >
                Send
              </button>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ChatReportModal;
