import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  overflow: hidden;

  .main-label {
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
  }
  .Vendor-Information-parent-1 {
    width: 100%;
    display: flex;
    gap: 50px;
    align-items: center;
  }
  .Vendor-Information-parent-2 {
    width: 100%;
    display: flex;
    gap: 25px;
    align-items: center;
    padding-top: 45px;
  }
  .Vendor-Information-child-1 {
    width: 17%;
  }
  .Vendor-Information-child-2 {
    width: 83%;
  }
  .Vendor-Information-child-3 {
    width: 50%;
  }
  .Vendor-Information-child-4 {
    width: 50%;
  }
  .Vendor-Information-border,
  .bussiness-information-border {
    width: 100%;
    border: 1px solid #d9d9d9;
    height: 156px;
    border-radius: 10px;
    margin-top: 10px;
    position: relative;
  }
  .bussiness-information-border {
    border-style: dashed;
    text-align: center;
    padding: 30px 0px;
  }
  .profile-pic {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .plus-background {
    width: 25px;
    height: 25px;
    background-color: #0c3a2d;
    padding: 6px;
    border-radius: 19px;
    position: absolute;
    bottom: -8px;
    right: -10px;
  }
  .input-width {
    width: 100%;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 8px 16px 8px 16px;
    /* margin-top: 10px; */
    outline: none;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .description {
    width: 100%;
    height: 131px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 8px 16px 8px 16px;
    margin-top: 10px;
    outline: none;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    resize: none;
  }
  .upload-btn {
    margin-top: 10px;
    height: 56px;
    background-color: #e2e8f0;
    border: 0px;
    font-family: inter;
    border-radius: 12px;
    padding: 8px 16px 8px 16px;
    font-weight: 400;
    font-size: 16px;
    color: #1c1c27;
  }
  .backgound-img {
    width: 100%;
    height: 154px;
    border-radius: 9px;
  }
  figure {
    margin-bottom: 0px;
  }
  section {
    position: relative;
    padding: 0px 50px;
  }
  input[type="file"] {
    display: none;
  }
  .profile-section-body {
    width: 100%;
    background-color: var(--white-background);
    border-radius: 9px;
    padding: 20px 0px 25px 0px;
  }
  .profile-title {
    font-family: inter;
    font-size: 24px;
    font-weight: 500;
    color: #156a3d;
    text-align: center;
    letter-spacing: 0.2px;
    border-bottom: 3px solid #156a3d;
    width: 176px;
  }
  .profile-pictu re-section,
  .file-upload-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    overflow: hidden;
  }
  @media (max-width: 1040px) {
    .file-upload-section {
      width: 100%;
    }
  }
  @media (max-width: 1040px) {
    .upload-profile-image-section {
      width: 100% !important;
    }
  }
  .removeItem-icon {
    position: absolute;
    margin-left: 142px;
    width: 30px;
    height: 30px;
    z-index: 9;
    top: 33px;
    left: 52px;
  }

  .profile-Image-section {
    width: 166px;
    height: 156px;
    border-radius: 10px;
    border: 2px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .profile-image {
    width: 146px;
    height: 137px;
    border-radius: 10px;
    border-radius: 1px solid #d9d9d9;
    object-fit: contain;
  }
  .profile-picture-title,
  .file-upload-title {
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .background-img {
    width: 100%;
    background-size: cover;
    height: 146px;
  }

  .profile-head-section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 50px;
    flex-wrap: wrap;
  }
  .input-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .input-fields-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .input-types-label {
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    line-height: 27px;
  }

  .input-types-label::placeholder {
    color: #e2e8f0;
  }

  .upload-profile-image-section {
    height: 147.09px;
    width: 672px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    border: 1px dashed #94a3b8;
    border-radius: 12px;
    overflow: hidden;
  }

  .input-width {
    width: 672px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  @media (max-width: 370px) {
    .input-width {
      width: 100%;
    }
  }
  .textarea-width {
    width: 673px;
    /* height: 119px; */
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
    resize: none;
  }
  .CreateVendor {
    width: 170px;
    height: 50px;
    font-family: inter;
    font-family: 700;
    font-size: 16px;
    background-color: #156a3d;
    color: white;
    border: 0px;
    border-radius: 12px;
  }
  .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none;
    border: 0 !important;
  }
  .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    border-radius: 10px 0 0 10px;
    left: 0px;
    padding: 7px;
  }
`;
