import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
const useHooks = () => {
    const orderGetID = async (Id) => {
        try {
            const response = await AuthAPI.getorderID(Id);
            return response;
        }
        catch (error) {
            console.error(error);
            toastr.error('Try Again...');
            return null;
        }
    }
    


    return { orderGetID };
};

export default useHooks;
