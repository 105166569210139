
import React, { useEffect, useRef, useState } from "react";
import { Main } from "./style";
import Total from "../../../assets/img/home/total-order.png";
import Pending from "../../../assets/img/home/order-pending.png";
import Processing from "../../../assets/img/home/order-processing.png";
import Delivered from "../../../assets/img/home/order-delivered.png";
import Products from "../../../assets/img/home/total-products.png";
import Cancel from "../../../assets/img/home/order-cancel.png";
import Return from "../../../assets/img/home/order-return.png";
import ChartDasboard from "../chart";
import wallet from "../../../assets/img/home/Wallet.png";
import Layer from "../../../assets/img/home/Layers.png";
import Shoping from "../../../assets/img/home/Shopping cart.png";
import card1 from "../../../assets/img/home/Credit card.png";
import card2 from "../../../assets/img/home/Credit card (1).png";
import Support from "../../../assets/img/home/Support.png";
import BasicDateRangePicker from "../chart-calender";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useHooks from "./useHook";
import Spiner from "components/spinner";
import WalletChart from "../wallet-chart";
import useHook from "../notifications/useHook";
import { Chart } from "chart.js";
import CustomDateRangePicker from "../chart-calender";
import moment from "moment";
const Home = () => {
  const { ToptilesGet, DashboardCardsGet, GraphDataGet, GraphOrdersGet } =
    useHooks();

  const [toptilelist, setTopTileList] = useState({});
  const [cardlist, setcardlist] = useState({});
  // const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      return navigate("/auth");
    }
  }, [navigate]);

  const Order = [
    {
      index: 1,
      image: Total,
      text: "Total Order",
      number: cardlist.totalOrders,
      backgroundColor: "#FB923C1A",
    },
    {
      index: 2,
      image: Pending,
      text: "Order Pending",
      number: cardlist.pendingOrders,
      backgroundColor: "#923DFF1A",
    },
    {
      index: 3,
      image: Processing,
      text: "Orders Processing",
      number: cardlist.processingOrders,
      backgroundColor: "#40D1FF1A",
    },
    {
      index: 4,
      image: Delivered,
      text: "Orders Delivered",
      number: cardlist.deliveredOrders,
      backgroundColor: "#02B4561A",
    },
    {
      index: 5,
      image: Support,
      text: "Total Customers",
      number: cardlist.customerCounts,
      backgroundColor: "#FFBB541A",
    },
    {
      index: 6,
      image: Products,
      text: "Total Products",
      number: cardlist.totalProducts,
      backgroundColor: "#004E971A",
    },
    {
      index: 7,
      image: Cancel,
      text: "Order Cancel",
      number: cardlist.canceledOrders,
      backgroundColor: "#FF10101A",
    },
    {
      index: 8,
      image: Return,
      text: "Order Returns",
      number: cardlist.returnedOrders,
      backgroundColor: "#8B56251A",
    },
  ];

  const data = [
    {
      index: 1,
      image: Layer,
      title: "Today Orders",
      totalAmount: "$" + toptilelist.todaysOrders,
      backgroundColor: "#0D9488",
    },
    {
      index: 2,
      image: Layer,
      title: "Weekly Orders",
      totalAmount: "$" + toptilelist.weeklyOrders,
      backgroundColor: "#FB923C",
    },
    {
      index: 3,
      image: Shoping,
      title: "This Month",
      totalAmount: "$" + toptilelist.thisMonthsOrders,
      backgroundColor: "#3B82F6",
    },
    {
      index: 4,
      image: card1,
      title: "Last Month",
      totalAmount: "$" + toptilelist.lastMonthsOrders,
      backgroundColor: "#009E36",
    },
    {
      index: 5,
      image: card2,
      title: "Total Earning",
      totalAmount: "$" + toptilelist.totalEarnings,
      backgroundColor: "#B53CFF",
    },
  ];

  const fetchToptiles = async () => {
    try {
      const response = await ToptilesGet();
      setTopTileList(response);
      // console.log("top tile data", response)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after the API call is complete
    }
  };

  const fetchDashboardCards = async () => {
    try {
      const response = await DashboardCardsGet();
      setcardlist(response);
      // console.log("top tile data", response)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after the API call is complete
    }
  };
  useEffect(() => {
    fetchToptiles();
    fetchDashboardCards();
  }, []);

  const [loading, setLoading] = useState(true);

  const chartContainer = useRef(null);
  const [graphDataList, setGraphDataList] = useState([]);
  const [ordersDataList, setOrdersDataList] = useState([]);
  const [currentGraph, setCurrentGraph] = useState("sales"); // 'sales' or 'orders'

  //////////////////////////////////////////////////////

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const getOneMonthAgoDate = () => {
    const today = new Date();
    const oneMonthAgo = new Date(today.setMonth(today.getMonth() - 1));
    return oneMonthAgo.toISOString().split("T")[0];
  };

  const [dateRange, setDateRange] = useState({
    startDate: getOneMonthAgoDate(),
    endDate: getTodayDate(),
  });

  const [utcOffset, setUtcOffset] = useState(0);

  // Function to get a moment object with a dynamic offset
  const getMomentWithDynamicOffset = (offsetMinutes) => {
    const now = moment();
    return now.clone().utcOffset(offsetMinutes);
  };

  useEffect(() => {
    // Set the desired offset in minutes
    const offsetMinutes = 300;
    const momentWithOffset = getMomentWithDynamicOffset(offsetMinutes);

    // Update state with the UTC offset
    setUtcOffset(momentWithOffset.utcOffset());
  }, []); // Empty dependency array means this runs once when the component mounts

  const fetchGraphData = async () => {
    try {
      const { startDate, endDate } = dateRange;
      const formattedDateRange = `?startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;

      setGraphDataList([]); // Reset the state before fetching new data

      const response = await GraphDataGet(
        `${formattedDateRange}&offset=${utcOffset}`
      ); 

      if (response && Object.keys(response).length > 0) {
        const data = Object.entries(response).map(([date, price]) => ({
          date,
          price,
        }));
        setGraphDataList(data);
        setNoData(false);
      } else {
        setNoData(true);
      }
    } catch (error) {
      console.error("Error fetching graph data:", error);
      setNoData(true);
    }
  };

  const fetchOrdersData = async () => {
    try {
      const { startDate, endDate } = dateRange;
      const formattedDateRange = `?startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;

      const response = await GraphOrdersGet(
        `${formattedDateRange}&offset=${utcOffset}`
      ); 

      if (response && Object.keys(response).length > 0) {
        const data = Object.entries(response).map(([date, price]) => ({
          date,
          price,
        }));
        setOrdersDataList(data);
        setNoData(false);
      } else {
        setNoData(true);
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      setNoData(true);
    }
  };

  useEffect(() => {
    if (utcOffset !== undefined) {
      fetchGraphData();
      fetchOrdersData();
    }
  }, [utcOffset, dateRange]); // Run when utcOffset or dateRange changes

  useEffect(() => {
    if (chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");
      let dates = [],
        dataPoints = [],
        label = "",
        borderColor = "";

      // Check which graph to display
      if (currentGraph === "sales" && graphDataList.length > 0) {
        dates = graphDataList.map((entry) => entry.date);
        dataPoints = graphDataList.map((entry) => entry.price);
        label = "Sales";
        borderColor = "#0c3a2d";
      } else if (currentGraph === "orders" && ordersDataList.length > 0) {
        dates = ordersDataList.map((entry) => entry.date);
        dataPoints = ordersDataList.map((entry) => entry.price);
        label = "Orders";
        borderColor = "#ffbb02";
      } else {
        // No data to display
        return;
      }

      // Clear existing chart if any
      if (window.myChart) {
        window.myChart.destroy();
      }

      const myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: dates,
          datasets: [
            {
              label: label,
              data: dataPoints,
              fill: false,
              borderColor: borderColor,
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 0,
                callback: function (value) {
                  return value + "";
                },
              },
            },
            x: {
              beginAtZero: true,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          },
        },
      });

      // Store chart instance for cleanup
      window.myChart = myChart;

      // Cleanup function
      return () => {
        if (window.myChart) {
          window.myChart.destroy();
          window.myChart = null;
        }
      };
    }
  }, [currentGraph, graphDataList, ordersDataList]);

  return (
    <>
      <ToastContainer />
      <Main>
        {loading ? (
          <Spiner />
        ) : (
          <div className="background">
            <div className="container-fluid">
              {/* #=================================================== */}
              <div className="heading-flex">
                <p className="m-0 heading">Dashboard Overview </p>{" "}
              </div>
              <div className="overview-flex">
                {data.map((items,index) => (
                  <div
                    className="card overview-card "
                    style={{ backgroundColor: items.backgroundColor }}
                    key={index}
                  >
                    <div class="card-body overview-cardbody" >
                      <img src={items.image} alt="" className="overview-img" />
                      <p className="m-0 orders">{items.title}</p>
                      <p className="m-0 orders-number">{items.totalAmount}</p>
                    </div>
                  </div>
                ))}
              </div>
              {/* #=================================================== */}

              {/* #----------------------------------------------------- */}

              <div className="order-flex">
                {Order.map((items,index) => (
                  <div class="card third-card border-0"  key={index}>
                    <div class="card-body">
                      <div className="third-flex">
                        <div
                          style={{ backgroundColor: items.backgroundColor }}
                          className="background-order"
                        >
                          <img
                            src={items.image}
                            alt="img"
                            className="order-img"
                          />
                        </div>
                        <div className="margin">
                          <p className="m-0 order-para">{items.text}</p>
                          <p className="m-0 order-number">{items.number}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="card mt-3 border-0">
                <div className="card-body">
                  <div
                    className="sale-flex"
                    style={{ paddingRight: "30px" }}
                  >
                    <p className="SalesStatistics m-0">Sales Statistics</p>

                    <div className="d-flex wallet-main align-items-center">
                      <div className="walet-back">
                        <img src={wallet} alt="" />
                      </div>
                      <div className="d-flex">
                        {/* <BasicDateRangePicker /> */}
                        <CustomDateRangePicker
                          onDateChange={(dates) => setDateRange(dates)}
                          initialStartDate={dateRange.startDate}
                          initialEndDate={dateRange.endDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      type="button"
                      className="sales"
                      onClick={() => {
                        setCurrentGraph("sales");
                        fetchGraphData();
                      }}
                    >
                      Sales
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="Orders"
                      onClick={() => {
                        setCurrentGraph("orders");
                        fetchOrdersData();
                      }}
                    >
                      Orders
                    </button>
                  </div>

                  <div
                    className="chart-container"
                    style={{
                      overflowX: "auto", // Add horizontal scroll
                      paddingBottom: "10px", // Add some padding to ensure scroll visibility
                    }}
                  >
                    {(graphDataList.length > 0 ||
                      Object.keys(ordersDataList).length > 0) && (
                      <canvas
                        ref={chartContainer}
                        style={{ height: "442.46px" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Main>
    </>
  );
};

export default Home;
