import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthLayout from "../components/layouts/auth-layout";
import DasboardLayout from "components/layouts/dasboard-layout";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" Component={AuthLayout} />
        <Route path="/*" Component={DasboardLayout} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
