import { useEffect, useState } from "react";
import {
  Main,
  PasswordField,
  PasswordInputContainer,
  ToggleButton,
} from "./style";
import useHooks from "../../dashboard/vendor-setting/useHook"; // Update the path as needed
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";

const ForcePasswordChange = () => {
  const [checked, setChecked] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const { login, notification, getProfile } = useHooks();

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword((prevState) => !prevState);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword((prevState) => !prevState);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const validateForm = () => {
    const errors = {};

    if (!currentPassword) {
      errors.currentPassword = "Current password is required.";
    }
    if (!newPassword) {
      errors.newPassword = "New password is required.";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your new password.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    // Ensure form validation is successful before proceeding
    if (!validateForm()) return;

    const body = {
      oldPassword: currentPassword,
      password: newPassword,
    };

    try {
      // Send the password update request
      const response = await login(body);
      console.log(response);

      if (response?.status === true) {
        navigate("/vendor-information");
      }
    } catch (error) {
      // Log the error and set an error message to display
      console.error("Error updating password:", error);
      toastr.error("An error occurred while updating the password");
    } finally {
      // Reset form fields and clear any errors
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setError("");
    }
  };

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(); // Assuming getProfile fetches profile data from API
        setProfileData(data);
        setChecked(data.isNotificationsEnabled); // Initialize checked state based on fetched profile data
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleToggle = async () => {
    const newCheckedState = !checked;
    setChecked(newCheckedState);

    // Update profileData with new isNotificationsEnabled state
    const updatedProfileData = {
      ...profileData,
      isNotificationsEnabled: newCheckedState,
    };
    setProfileData(updatedProfileData); // Update locally for immediate UI response

    try {
      const response = await notification(updatedProfileData); // Update backend via API call
      // console.log("Notification Toggle Response:", response);
    } catch (error) {
      console.error("Error toggling notifications:", error);
      // Rollback state on error (optional)
      setChecked(!newCheckedState);
      setProfileData({
        ...profileData,
        isNotificationsEnabled: !newCheckedState,
      });
    }
  };

  return (
    <Main>
      <div className="container-fluid">
        {/* <div>
          <h1 className="heading">Update Password</h1>
        </div> */}
        <form
          onSubmit={handleSubmitForm}
          className="profile-setting-section"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <p className="profile-setting-title">Update Password</p>
          <div className="change-password-section">
            <div className="profile-setting-body">
              <div className="change-password-body">
                <label className="input-label-changePassword">
                  Current Password
                </label>
                <PasswordInputContainer>
                  <PasswordField
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="******************"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    value={currentPassword}
                  />
                  <ToggleButton
                    onClick={handleToggleCurrentPassword}
                    type="button"
                  >
                    {showCurrentPassword ? (
                      <FiEyeOff color="#64748B" size={20} />
                    ) : (
                      <FiEye color="#64748B" size={20} />
                    )}
                  </ToggleButton>
                </PasswordInputContainer>
                {formErrors.currentPassword && (
                  <p className="error">{formErrors.currentPassword}</p>
                )}
              </div>
              {/* <div className="notification-section">
                <p className="notification-title">Notifications</p>
                <div className="notificatiion-enable">
                  <p className="enable-title">Enabled</p>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleToggle}
                    />
                    <span
                      className="slider round"
                      style={{ backgroundColor: checked ? "#F4B733" : "" }}
                    ></span>
                  </label>
                </div>
              </div> */}
            </div>
            <div className="create-password-title">
              <p>Create new password</p>
            </div>
            <div className="change-password-body">
              <label className="input-label-changePassword">New Password</label>
              <PasswordInputContainer>
                <PasswordField
                  type={showNewPassword ? "text" : "password"}
                  placeholder="******************"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                />
                <ToggleButton onClick={handleToggleNewPassword} type="button">
                  {showNewPassword ? (
                    <FiEyeOff color="#64748B" size={20} />
                  ) : (
                    <FiEye color="#64748B" size={20} />
                  )}
                </ToggleButton>
              </PasswordInputContainer>
              {formErrors.newPassword && (
                <p className="error">{formErrors.newPassword}</p>
              )}
            </div>
            <div className="change-password-body">
              <label className="input-label-changePassword">
                Confirm Password
              </label>
              <PasswordInputContainer>
                <PasswordField
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="******************"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
                <ToggleButton
                  onClick={handleToggleConfirmPassword}
                  type="button"
                >
                  {showConfirmPassword ? (
                    <FiEyeOff color="#64748B" size={20} />
                  ) : (
                    <FiEye color="#64748B" size={20} />
                  )}
                </ToggleButton>
              </PasswordInputContainer>
              {formErrors.confirmPassword && (
                <p className="error">{formErrors.confirmPassword}</p>
              )}
              {error && <p className="error">{error}</p>}
            </div>
          </div>
          <div className="text-end mx-5">
            <button type="submit" className="CreateVendor">
              Update
            </button>
          </div>
        </form>
      </div>
    </Main>
  );
};

export default ForcePasswordChange;
