import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };
const useHooks = () => {
    const imagePost = async (file) => {
      try {
          if (!file) {
              throw new Error("No file provided");
          }
          const reader = new FileReader();
          const fileAsDataURL = new Promise((resolve, reject) => {
              reader.onload = () => resolve(reader.result);
              reader.onerror = reject;
          });
          reader.readAsDataURL(file);
          await fileAsDataURL;  // Wait for the file to be read as data URL
          const response = await AuthAPI.imagepost(
              { image: file },
              { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log("Image upload response", response);
          if (response?.path) {
              // toastr.success("Image uploaded successfully");
              toastr.success('Success')
              console.log("response path",response.path)
              return response.path;
          } else {
            toastr.error("Image upload failed");
              return null;
          }
      } catch (error) {
          console.error("Error uploading image:", error);
          toastr.error("An error occurred during image upload");
          return null;
      }
  };
  const Subcatalogspost = async (body) => { 
    try {
      const response = await AuthAPI.postSubCatalog(body);
      // console.log(formData)
      // toastr.success("subcatalog post successfully!!")
      return response;
    } catch (error) {
      toastr.error("Error in collectionPost:");
      console.error("Error in collectionPost:", error);
      throw error;
    }
  };
  const getSubcatalogs = async (body)=> { 
    try {
      const response = await AuthAPI.getSubCatalog(body);  
      console.log('response is ',response)
      // toastr.success("subctalog Get Successfully")
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("subcatalog get error.Try Again later");
      return null;
    }
  }; 
  const putSubCatalog = async (id, params) => {
    try {
      const response = await AuthAPI.putSubCatalog(id,params);
      // console.log(response, 'Put Sub Catalog Posted Successfully');
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("update error.Try Again later");
      return null;
    }
  };
  const getSingleSubcatalogs = async (id)=> { 
    try {
      const response = await AuthAPI.getSingleSubCatelog(id)  
      console.log('response is ',response)
      return response;
    } catch (error) {
      console.error(error);
      toastr.error('subcatalog Get images Error');
      return null;
    }
  }; 
    
    return {imagePost,Subcatalogspost,getSubcatalogs,putSubCatalog,getSingleSubcatalogs };
  };
  
  export default useHooks;

 
  