import styled from "styled-components";

export const Main = styled.div`
  .spinner-adjust {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px); /* Apply the blur effect */
    animation: backgroundFade 2s infinite;
    z-index: 1000; /* Ensure it stays on top of other elements */
  }

  @keyframes backgroundFade {
    0% {
      background-color: rgba(240, 240, 240, 0.5); /* Light gray */
    }
    50% {
      background-color: rgba(255, 255, 255, 0.5); /* White */
    }
    100% {
      background-color: rgba(240, 240, 240, 0.5); /* Light gray */
    }
  }

  .spinner {
    position: relative;
    width: 100px;
    height: 100px;
    /* left: 40%;
    top: 270px; */
    z-index: 99;
    /* background: #000000; */
    border-radius: 47px;
  }
  .absolute {
  }

  .sofa {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%; /* Adjust size as needed */
    height: auto;
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; /* Adjust size as needed */
    height: auto;
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
