// import React, { useState, useEffect } from "react";
// import styled from "styled-components";

// // Styled component for custom date range picker
// const StyledDateRangePickerWrapper = styled.div`
//   display: flex;
//   gap: 10px;

//   input {
//     padding: 16.5px 15px;
//     font-family: inter, sans-serif;
//     font-size: 12px;
//     font-weight: 600;
//     color: #252d31;
//     border: none;
//     box-sizing: content-box;
//     background: none;
//     height: 1.4375em;
//     margin: 0;
//     width: 85px; /* Adjust width for better visibility */
//     -webkit-tap-highlight-color: transparent;
//     display: block;
//     min-width: 0;
//     outline: none;
//     box-shadow: none;
//   }

//   input[type="date"]::-webkit-calendar-picker-indicator {
//     opacity: 0; /* Hide default calendar icon */
//   }

//   .date-range-container {
//     display: flex;
//     align-items: center;
//     flex-direction: row;
//     width: 100% !important;
//   }

//   label {
//     color: white;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     font-size: 1rem;
//     line-height: 1.4375em;
//     letter-spacing: 0.00938em;
//     padding: 0;
//     position: relative;
//     display: block;
//     transform-origin: top left;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     max-width: calc(133% - 32px);
//     pointer-events: auto;
//     user-select: none;
//     position: absolute;
//     left: 0;
//     top: 0;
//     transform: translate(14px, -9px) scale(0.75);
//     transition: color 200ms cubic-bezier(0, 0, 0.2, 1),
//       transform 200ms cubic-bezier(0, 0, 0.2, 1),
//       max-width 200ms cubic-bezier(0, 0, 0.2, 1);
//   }

//   label.focused {
//     color: white;
//   }
// `;

// const Main = styled.div`
//   margin: 20px;
// `;

// export default function CustomDateRangePicker({
//   onDateChange,
//   initialStartDate,
//   initialEndDate,
// }) {
//   const [startDate, setStartDate] = useState(initialStartDate || "2022-04-17");
//   const [endDate, setEndDate] = useState(initialEndDate || "2022-04-21");

//   useEffect(() => {
//     // Update state if initial dates change
//     if (initialStartDate !== startDate || initialEndDate !== endDate) {
//       setStartDate(initialStartDate);
//       setEndDate(initialEndDate);
//     }
//   }, [initialStartDate, initialEndDate]);

//   const handleStartDateChange = (e) => {
//     const selectedDate = e.target.value;
//     setStartDate(selectedDate);
//     onDateChange({ startDate: selectedDate, endDate }); // Pass updated date range
//   };

//   const handleEndDateChange = (e) => {
//     const selectedDate = e.target.value;
//     setEndDate(selectedDate);
//     onDateChange({ startDate, endDate: selectedDate }); // Pass updated date range
//   };

//   return (
//     <StyledDateRangePickerWrapper>
//       <div className="date-range-container">
//         <input type="date" value={startDate} onChange={handleStartDateChange} />
//         <span>-</span>
//         <input type="date" value={endDate} onChange={handleEndDateChange} />
//       </div>
//     </StyledDateRangePickerWrapper>
//   );
// }

// import React, { useEffect, useState } from "react";
// import styled from "styled-components";

// // Styled component for custom date range picker
// const StyledDateRangePickerWrapper = styled.div`
//   display: flex;
//   gap: 10px;
//   align-items: center;

//   input {
//     padding: 16.5px 15px;
//     font-family: inter, sans-serif;
//     font-size: 12px;
//     font-weight: 600;
//     color: #252d31;
//     border: none;
//     box-sizing: content-box;
//     background: none;
//     height: 1.4375em;
//     margin: 0;
//     width: 85px; /* Adjust width for better visibility */
//     -webkit-tap-highlight-color: transparent;
//     display: block;
//     min-width: 0;
//     outline: none;
//     box-shadow: none;
//   }

//   input[type="date"]::-webkit-calendar-picker-indicator {
//     opacity: 0; /* Hide default calendar icon */
//   }

//   .date-range-container {
//     display: flex;
//     align-items: center;
//     flex-direction: row;
//     width: 100% !important;
//   }

//   label {
//     color: white;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     font-size: 1rem;
//     line-height: 1.4375em;
//     letter-spacing: 0.00938em;
//     padding: 0;
//     position: relative;
//     display: block;
//     transform-origin: top left;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     max-width: calc(133% - 32px);
//     pointer-events: auto;
//     user-select: none;
//     position: absolute;
//     left: 0;
//     top: 0;
//     transform: translate(14px, 12px) scale(0.75);
//   }
// `;

// const CustomDateRangePicker = ({
//   onDateChange,
//   initialStartDate,
//   initialEndDate,
// }) => {
//   const [startDate, setStartDate] = useState(initialStartDate);
//   const [endDate, setEndDate] = useState(initialEndDate);

//   useEffect(() => {
//     // Trigger onDateChange when the start or end date changes
//     onDateChange({ startDate, endDate });
//   }, [startDate, endDate]);

//   return (
//     <StyledDateRangePickerWrapper>
//       <input
//         type="date"
//         value={startDate}
//         onChange={(e) => setStartDate(e.target.value)}
//       />
//       <span>-</span>
//       <input
//         type="date"
//         value={endDate}
//         onChange={(e) => setEndDate(e.target.value)}
//       />
//     </StyledDateRangePickerWrapper>
//   );
// };

// export default CustomDateRangePicker;

import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Styled component for custom date range picker
const StyledDateRangePickerWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  input {
    padding: 16.5px 11px;
    font-family: inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #252d31;
    border: none;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    width: 85px; /* Adjust width for better visibility */
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }

  .date-range-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100% !important;
  }

  label {
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    pointer-events: auto;
    user-select: none;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(14px, 12px) scale(0.75);
  }
`;

const CustomDateRangePicker = ({
  onDateChange,
  initialStartDate,
  initialEndDate,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    // Trigger onDateChange when the start or end date changes
    onDateChange({ startDate, endDate });
  }, [startDate, endDate]);

  return (
    <StyledDateRangePickerWrapper>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <span>-</span>
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </StyledDateRangePickerWrapper>
  );
};

export default CustomDateRangePicker;
