import React, { useEffect, useState } from "react";
import { Main } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import Evidence from "../../../assets/img/orders/evidence.svg";
import man from "../../../assets/img/orders/Group 1707488230.svg";
import women from "../../../assets/img/orders/image.svg";
import OrderDisputeModal from "components/modal/order-disputes-modal";
import usehook from "./usehook";
import Spiner from "components/spinner";

const DisputesDetails = () => {
  const navigate = useNavigate();
  const OrderDispute = (state) => {
    navigate("/order-dispute", { state });
  };

  const data = [
    {
      index: 1,
      img: women,
      name: "Leslie Alexander",
      messgae:
        "Our Customer relations team reahced initail dispute determintion.",
    },
    {
      index: 2,
      img: man,
      name: "Lotus Customer Relation",
      messgae:
        "Our Customer relations team reahced initail dispute determintion.",
    },
    {
      index: 3,
      img: women,
      name: "Leslie Alexander",
      messgae:
        "Our Customer relations team reahced initail dispute determintion.",
    },
    {
      index: 4,
      img: man,
      name: "Lotus Customer Relation",
      messgae:
        "Our Customer relations team reahced initail dispute determintion.",
    },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { id } = useParams();
  const { DisputesDetails } = usehook();
  const [disputesdetails, setDisputesDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [showbutton, setShowButton] = useState(true);

  const Disputes = async () => {
    try {
      const response = await DisputesDetails(id);
      console.log(response);
      setDisputesDetails(response.data);
      console.log("kjsdkjskdjksdjskdj", disputesdetails);
      if (
        response.data.status === "resolved" ||
        response.data.status === "returned"
      ) {
        setShowButton(false); // Hide button
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Disputes();
    console.log(disputesdetails, "desputes");
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  // console.log(id);
  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <>
          <OrderDisputeModal
            open={open}
            handleClose={handleClose}
            disputesdetails={disputesdetails}
            Disputes={Disputes}
          />

          <div className="container-fluid pt-3">
            <div className="card card-border">
              <div className="card-body">
                <div>
                  <button
                    type="button"
                    className="back-button"
                    onClick={() => OrderDispute()}
                  >
                    Back
                  </button>
                </div>

                <div className="cards-parent">
                  {/* #======================   Order Details   ======================#   */}
                  <div className="first-card order-detail-card">
                    <div className="card-body">
                      <div>
                        <p className="main-heading ">Order Details</p>
                      </div>
                    </div>
                    <hr className="border-bottom-color" />
                    <div className="padding">
                      <p className="sub-heading">Invoice Number</p>
                      <p className="main-heading">
                        {disputesdetails?.order?.id}
                      </p>

                      <div className="order-details-flex">
                        <div className="order-date">
                          <p className="sub-heading">Order Date</p>
                          <p className="main-heading">
                            {disputesdetails?.order?.createdAt
                              ? formatDate(disputesdetails.order.createdAt)
                              : "N/A"}
                          </p>
                        </div>
                        <div className="order-amount">
                          <p className="sub-heading">Order Amount</p>
                          <p className="main-heading">
                            ${disputesdetails?.order?.totalAmount}
                          </p>
                        </div>
                      </div>
                      <div className="order-details-flex">
                        <div className="order-date">
                          <p className="sub-heading">Customer</p>
                          <p className="main-heading">
                            {disputesdetails?.order?.user?.name}
                          </p>
                        </div>
                        <div className="order-amount">
                          <p className="sub-heading">Vendor</p>
                          <p className="main-heading">
                            {disputesdetails?.order?.vendor?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="border-bottom-color mt-3 mb-2" />
                    <p className="main-heading" style={{ padding: "0px 15px" }}>
                      Dispute Details
                    </p>
                    <hr className="border-bottom-color  mt-2 mb-3" />
                    <div className="padding pb-3">
                      <p className="sub-heading">Dispute ID</p>
                      <p className="main-heading">{disputesdetails?.id}</p>

                      <div className="order-details-flex">
                        <div className="order-date">
                          <p className="sub-heading">Dispute Date</p>
                          <p className="main-heading">
                            {disputesdetails?.createdAt
                              ? formatDate(disputesdetails.createdAt)
                              : "N/A"}
                            {/* {disputesdetails?.createdAt} */}
                          </p>
                        </div>
                        <div className="order-amount">
                          <p className="sub-heading">Dispute Amount</p>
                          <p className="main-heading">
                            ${disputesdetails?.orderProduct?.totalPrice}
                          </p>
                        </div>
                      </div>
                      <div className="order-details-flex">
                        <div className="order-date">
                          <p className="sub-heading">Color</p>
                          <p
                            style={{
                              backgroundColor:
                                disputesdetails?.orderProduct?.orderProductColor
                                  ?.colorCode,
                              width: "20px",
                              height: "20px",
                              borderRadius: "20px",
                              marginTop: "5px",
                            }}
                          ></p>
                        </div>
                        <div className="order-amount">
                          <p className="sub-heading">Product Name</p>
                          <p className="main-heading">
                            {disputesdetails?.orderProduct?.product?.name}
                          </p>
                        </div>
                      </div>
                      <p className="sub-heading mt-3">Dispute Reason</p>
                      <p className="main-heading">{disputesdetails?.reason}</p>
                      <p className="sub-heading mt-3">Evidence Images</p>

                      <div className="evidence-img-flex">
                        <div className="evidence-img">
                          <img src={Evidence} alt="" />
                          <p className="evidence-font">Document.jpg</p>
                        </div>
                        <div className="evidence-img">
                          <img src={Evidence} alt="" />
                          <p className="evidence-font">Document.jpg</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* #======================   Resolution   ======================#   */}

                  <div className="second-card order-detail-card pb-5">
                    <div className="card-body">
                      <div>
                        <p className="main-heading ">Resolution</p>
                      </div>
                    </div>

                    <hr className="border-bottom-color" />
                    <div className="padding">
                      <div className="order-details-flex m-0">
                        <div className="order-date">
                          <p className="sub-heading">Dispute Status</p>
                          <p className="status">
                            {disputesdetails?.status}
                          </p>
                        </div>
                        {disputesdetails?.comments
                              .filter((comment) => comment.warning)
                              .sort(
                                (a, b) =>
                                  new Date(b.createdAt) - new Date(a.createdAt)
                              )
                              .slice(0, 1)
                              .map((warning) => (
                        <div className="order-amount">
                          <p className="sub-heading">Warning</p>
                          <p className="main-heading">
                          
                                <div className="warning-text">
                                  {warning.warning
                                    ? warning.warning
                                    : "no warning"}
                                </div>
                            
                          </p>
                        </div>
                          ))}
                      </div>
                    </div>
                    <hr className="border-bottom-color mt-4 mb-4" />
                    <div className="padding">
                      <div
                        className="order-details-flex m-0"
                        style={{ alignItems: "center" }}
                      >
                        <div className="order-date">
                          <p className="main-heading">Resolution History</p>
                          <p className=" sub-heading">
                            Please see below for dispute details and resolution.
                          </p>
                        </div>
                        <div className="order-amount text-end">
                          {showbutton && (
                            <button
                              type="button"
                              className="takeAction-button"
                              onClick={handleOpen}
                            >
                              Take Action
                            </button>
                          )}
                        </div>
                      </div>

                      {/* <div style={{ position: "relative" }} key={index}>
                          <div className="chat-flex">
                            <div>
                              <img src={disputesdetails?.comments.images} alt="" />
                            </div>

                            <div>
                              <p className="chat-name-heading">{item.name}</p>
                              <p className="sub-heading">Mar 4, 2024</p>
                            </div>
                          </div>
                          <p className="under-img-border">sdd</p>
                          <div class="card mt-3">
                            <div class="padding">
                              <p className="chat-text">{item.messgae}</p>
                            </div>
                          </div>
                        </div> */}
                      <div
                        style={{
                          position: "relative",
                          maxHeight: "583px",
                          overflowY: "auto",
                          marginTop: "15px",
                        }}
                      >
                        <div>
                          {disputesdetails?.comments.map((comment, index) => (
                            <React.Fragment key={comment.id}>
                              {/* Only render profile pic, name, and date if comment is not null */}
                              {comment.comment && (
                                <>
                                  <div className="chat-flex">
                                    <div>
                                      <img
                                        src={`${process.env.REACT_APP_BASE_URL}${comment.user.profile_pic}`}
                                        alt={comment.user.name}
                                        className="image-width"
                                      />
                                    </div>

                                    <div>
                                      <p className="chat-name-heading">
                                        {comment.user.name}
                                      </p>
                                      <p className="sub-heading">
                                        {new Date(
                                          comment.createdAt
                                        ).toLocaleDateString()}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="under-img-border"></div>
                                </>
                              )}

                              {comment.comment && (
                                <div className="card ">
                                  <div className="padding">
                                    <p className="chat-text">
                                      {comment.comment}
                                    </p>
                                  </div>
                                </div>
                              )}

                              {/* Show warning if comment is null and warning exists */}
                              {/* {comment.warning && !comment.comment && (
                              <div className="card mt-3">
                                <div className="padding">
                                  <p className="chat-text warning-text">
                                    {comment.warning}
                                  </p>
                                </div>
                              </div>
                            )} */}

                              {comment.images.length > 0 && (
                                <div className="comment-images">
                                  {comment.images.map((image) => (
                                    <img
                                      key={image.id}
                                      src={`${process.env.REACT_APP_BASE_URL}${image.url}`}
                                      alt="comment image"
                                      className="comment-img"
                                    />
                                  ))}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Main>
  );
};

export default DisputesDetails;
