import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };
const useHooks = () => {
    const policiesGet=async(body)=>{
        try{
            const response = await AuthAPI.policiesGet(body); 
            // toastr.success("policies created")

            return response.data
        }
        catch(error){
            console.error(error);
                  toastr.error('Error Try Again Later');
                  return null;
        }
    }
    const policiesDelete=async(body)=>{
        try{
            const response = await AuthAPI.policiesDelete(body); 
            // toastr.success("Policies deleted successfully!!!!");

            return response
        }
        catch(error){
            console.error(error);
                  toastr.error("Error. Try Agan Later")
                  return null;
        }
    }


  return { policiesGet,policiesDelete};
};

export default useHooks;
