// import React, { useState } from "react";
// import { Main, NavLinkStyled } from "./style";
// import Logo from "../../assets/img/sidebar/Logo.png";
// import { FiAlignLeft } from "react-icons/fi";
// import { useLocation } from "react-router-dom";
// import { RxCross1 } from "react-icons/rx";
// import {
//   Catalogs,
//   Collections,
//   Disputes,
//   LayoutSvg,
//   Orders,
//   Policies,
//   Products,
//   Wallet,
//   Customer,
// } from "assets/img";

// const Sidebar = () => {
//   const location = useLocation();
//   const [isOpen, setIsOpen] = useState(false);

//   const data = [
//     { id: 1, Image: LayoutSvg, name: "Dashboard", path: "/" },
//     { id: 2, Image: Customer, name: "Customers", path: "/customers" },
//     { id: 3, Image: Products, name: "Products", path: "/products" },
//     { id: 4, Image: Orders, name: "Orders", path: "/orders" },
//     { id: 5, Image: Disputes, name: "Order Disputes", path: "/order-dispute" },
//     { id: 6, Image: Catalogs, name: "My Catalogs", path: "/my-catalogs" },
//     {
//       id: 7,
//       Image: Collections,
//       name: "My Collections",
//       path: "/my-collection",
//     },
//     { id: 8, Image: Policies, name: "My Policies", path: "/my-policies" },
//     { id: 9, Image: Wallet, name: "My Wallet", path: "/my-wallet" },
//   ];

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   const additionalDashboardRoutes = [
//     "/chat-managment",
//     "/notifications",
//     "/chat-managment/chat",
//     "/vendor-profile",
//     "/edit-vendor-profile",
//     "/vendor-profile-settings",
//   ];

//   const isActive = (path) => {
//     if (path === "/") {
//       return (
//         location.pathname === "/" ||
//         additionalDashboardRoutes.includes(location.pathname)
//       );
//     }
//     if (location.pathname.startsWith(path)) {
//       return true;
//     }
//     if (!data.find((item) => item.path === location.pathname) && path === "/") {
//       return true;
//     }
//     return false;
//   };

//   return (
//     <Main isOpen={isOpen}>
//       <FiAlignLeft className="burger-icon" size={50} onClick={toggleSidebar} />
//       <div className={`sidebar-content ${isOpen ? "open" : ""}`}>
//         <div className="background-color">
//           <div className="container-fluid">
//             <div className="cross text-end">
//               <RxCross1
//                 onClick={toggleSidebar}
//                 size={30}
//                 style={{ cursor: "pointer", color: "black" }}
//               />
//             </div>
//             <div className="text-center">
//               <img src={Logo} alt="Logo" className="logo" />
//             </div>
//             <p className="bottom-border"></p>
//             <ul className="mt-2" style={{ listStyle: "none", padding: "0" }}>
//               {data.map((item) => {
//                 const Icon = item.Image;
//                 return (
//                   <NavLinkStyled
//                     className={`nav-link dashboard d-flex align-items-center mt-2 ${
//                       isActive(item.path) ? "active" : ""
//                     }`}
//                     key={item.id}
//                     to={item.path}
//                   >
//                     <div>
//                       <div className="menu">
//                         <Icon className="color" />
//                         <span className="ms-2">{item.name}</span>
//                       </div>
//                     </div>
//                   </NavLinkStyled>
//                 );
//               })}
//             </ul>
//           </div>
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default Sidebar;

import React, { useState } from "react";
import { Main, NavLinkStyled } from "./style";
import Logo from "../../assets/img/sidebar/lotuspro.svg";
import { FiAlignLeft } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import {
  Catalogs,
  Collections,
  Disputes,
  LayoutSvg,
  Orders,
  Policies,
  Products,
  Wallet,
  Customer,
} from "assets/img";

const Sidebar = ({ toggleSidebar, isOpen }) => {
  const location = useLocation();

  const data = [
    { id: 1, Image: LayoutSvg, name: "Dashboard", path: "/" },
    { id: 2, Image: Customer, name: "Customers", path: "/customers" },
    { id: 3, Image: Products, name: "Products", path: "/products" },
    { id: 4, Image: Orders, name: "Orders", path: "/orders" },
    { id: 5, Image: Disputes, name: "Order Disputes", path: "/order-dispute" },
    { id: 6, Image: Catalogs, name: "My Catalogs", path: "/my-catalogs" },
    {
      id: 7,
      Image: Collections,
      name: "My Collections",
      path: "/my-collection",
    },
    { id: 8, Image: Policies, name: "My Policies", path: "/my-policies" },
    { id: 9, Image: Wallet, name: "My Wallet", path: "/my-wallet" },
  ];

  const additionalDashboardRoutes = [
    "/chat-managment",
    "/notifications",
    "/chat-managment/chat",
    "/vendor-profile",
    "/edit-vendor-profile",
    "/vendor-profile-settings",
  ];

  const isActive = (path) => {
    if (path === "/") {
      return (
        location.pathname === "/" ||
        additionalDashboardRoutes.includes(location.pathname)
      );
    }
    if (location.pathname.startsWith(path)) {
      return true;
    }
    if (!data.find((item) => item.path === location.pathname) && path === "/") {
      toggleSidebar();
      return true;
    }
    return false;
  };

  const handleNavLinkClick = (path) => {
    // Close the sidebar when a link is clicked
    toggleSidebar();

    // Additional handling if needed
    // You can navigate to the path here if not using NavLinkStyled
  };

  return (
    <Main isOpen={isOpen}>
      <div className="burger-icon" size={50} onClick={toggleSidebar}></div>
      <div className={`sidebar-content ${isOpen ? "open" : ""}`}>
        <div className="background-color shadow-lg">
          <div className="container-fluid">
            <div className="cross text-end">
              <RxCross1
                onClick={toggleSidebar}
                size={30}
                style={{ cursor: "pointer", color: "black" }}
              />
            </div>
            <div className="logo-flex">
              <img src={Logo} alt="Logo" className="logo" />
              <p className="lotus">
                Lotus <span className="pro">Pro</span>{" "}
              </p>
            </div>
            <p className="bottom-border"></p>
            <ul className="mt-2" style={{ listStyle: "none", padding: "0" }}>
              {data.map((item) => {
                const Icon = item.Image;
                return (
                  <NavLinkStyled
                    className={`nav-link dashboard d-flex align-items-center mt-2 ${
                      isActive(item.path) ? "active" : ""
                    }`}
                    key={item.id}
                    to={item.path}
                    onClick={() => handleNavLinkClick(item.path)}
                  >
                    <div>
                      <div className="menu">
                        <Icon className="color" />
                        <span className="ms-2">{item.name}</span>
                      </div>
                    </div>
                  </NavLinkStyled>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Sidebar;
