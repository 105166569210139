import { AuthAPI } from "libs/http-service/api/auth.api";

const useHooks = () => {
  const getOrdersDisputes = async (body) => {
    try {
      const response = await AuthAPI.getDispute(body);
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  return { getOrdersDisputes };
};

export default useHooks;
