// import React, { useState, useRef } from "react";
// import { Main } from "./style";
// import { RxCross2 } from "react-icons/rx";

// const MaterialTags = ({
//   materials,
//   onChangetags,
//   setSelectedIds,
//   materialName,
//   removeTags,
// }) => {
//   const [tags, setTags] = useState([]);
//   const [selectedValue, setSelectedValue] = useState("");
//   const [material, setMaterial] = useState();
//   // const [selectedIds, setSelectedIds] = useState([]); // State to track selected material IDs
//   const inputRef = useRef();

//   const handleSelectChange = (event) => {
//     const { value } = event.target;

//     if (value === "") return;

//     const selectedMaterial = materials.find(
//       (material) => material.name === value
//     );

//     if (
//       selectedMaterial &&
//       !tags.some((tag) => tag.id === selectedMaterial.id)
//     ) {
//       setTags((prevTags) => {
//         const updatedTags = [...prevTags, selectedMaterial];
//         return updatedTags;
//       });
//       setSelectedValue("");
//       setMaterial(selectedMaterial.name);

//       // Update selectedIds state and log the array
//       setSelectedIds((prevSelectedIds) => {
//         const updatedSelectedIds = [...prevSelectedIds, selectedMaterial.id];
//         console.log("Selected Material IDs:", updatedSelectedIds);
//         return updatedSelectedIds;
//       });
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     const updatedTags = tags.filter((tag) => tag.id !== tagToRemove.id);
//     setTags(updatedTags);

//     // Update selectedIds state when a tag is removed
//     setSelectedIds((prevSelectedIds) => {
//       const updatedSelectedIds = prevSelectedIds.filter(
//         (id) => id !== tagToRemove.id
//       );
//       console.log("Updated Selected Material IDs:", updatedSelectedIds);
//       return updatedSelectedIds;
//     });
//   };

//   const handleTagClick = () => {
//     inputRef.current.focus();
//   };
//   // console.log(materialName)
//   return (
//     <Main>
//       <div className="flex-wrap">
//         <select
//           value={selectedValue}
//           onChange={handleSelectChange}
//           className="inputs-width"
//           ref={inputRef}
//         >
//           <option value="" disabled hidden>
//             Select a Product Keyword
//           </option>
//           {materials.map((material) => (
//             <option key={material.id} value={material.name}>
//               {material.name}
//             </option>
//           ))}
//         </select>
//         <div className="flex">
//           {tags.map((tag) => (
//             <span key={tag.id} className="tag-background">
//               {tag.name}
//               <RxCross2
//                 onClick={() => removeTag(tag)}
//                 size={20}
//                 color="white"
//                 className="cross-border"
//               />
//             </span>
//           ))}

//           {/* {materialName &&
//             materialName.map((name, index) => (
//               <span key={index} className="tag-background">
//                 {name}
//                 <RxCross2
//                   onClick={() => removeTags(name)}
//                   size={20}
//                   color="white"
//                   className="cross-border"
//                 />
//               </span>
//             ))} */}

//           {/* {materialName} */}
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default MaterialTags;

// import React, { useState, useRef } from "react";
// import { Main } from "./style";
// import { RxCross2 } from "react-icons/rx";
// import { useForm } from "react-hook-form";

// const MaterialTags = ({
//   materials = [],
//   onChangetags,
//   setSelectedIds,
//   materialName,
//   removeTags,
//   errorMessage,
// }) => {
//   const [tags, setTags] = useState([]);
//   const [selectedValue, setSelectedValue] = useState("");
//   const inputRef = useRef();
//   const {
//     register,
//     formState: { errors },
//   } = useForm();

//   const handleSelectChange = (event) => {
//     const { value } = event.target;

//     if (value === "") return;

//     const selectedMaterial = materials.find(
//       (material) => material.name === value
//     );

//     if (
//       selectedMaterial &&
//       !tags.some((tag) => tag.id === selectedMaterial.id)
//     ) {
//       setTags((prevTags) => [...prevTags, selectedMaterial]);
//       setSelectedValue("");

//       setSelectedIds((prevSelectedIds) => {
//         const updatedSelectedIds = [...prevSelectedIds, selectedMaterial.id];
//         console.log("Selected Material IDs:", updatedSelectedIds);
//         return updatedSelectedIds;
//       });
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     const updatedTags = tags.filter((tag) => tag.id !== tagToRemove.id);
//     setTags(updatedTags);

//     setSelectedIds((prevSelectedIds) => {
//       const updatedSelectedIds = prevSelectedIds.filter(
//         (id) => id !== tagToRemove.id
//       );
//       console.log("Updated Selected Material IDs:", updatedSelectedIds);
//       return updatedSelectedIds;
//     });
//   };

//   return (
//     <Main>
//       <div className="flex-wrap">
//         <select
//           {...register("material", {
//             required: "Material selection is required",
//           })}
//           value={selectedValue}
//           onChange={handleSelectChange}
//           className="inputs-width"
//           ref={inputRef}
//         >
//           <option value="" disabled hidden>
//             Select a Product Keyword
//           </option>
//           {/* {materials.map((material) => ( */}
//           <option>sadsadsadsd</option>
//           <option>dsadsd</option>
//           {/* ))} */}
//         </select>
//         {(errors.material || errorMessage) && (
//           <span className="error-message">
//             {errors.material ? errors.material.message : errorMessage}
//           </span>
//         )}
//         <div className="flex">
//           {tags.map((tag) => (
//             <span key={tag.id} className="tag-background">
//               {tag.name}
//               <RxCross2
//                 onClick={() => removeTag(tag)}
//                 size={20}
//                 color="white"
//                 className="cross-border"
//               />
//             </span>
//           ))}
//         </div>
//       </div>
//     </Main>
//   );
// };

// export default MaterialTags;

// import React, { useState, useRef, useEffect } from "react";
// import { Main } from "./style";
// import { RxCross2 } from "react-icons/rx";

// const MaterialTags = ({
//   materials,
//   onChangetags,
//   setSelectedIds,
//   materialName,
//   removeTags,
//   validate
// }) => {
//   const [tags, setTags] = useState([]);
//   const [selectedValue, setSelectedValue] = useState("");
//   const [material, setMaterial] = useState();
//   const inputRef = useRef();
//   const [validationError, setValidationError] = useState(false);

//   useEffect(() => {
//     // Check validation when tags change or when validate prop changes
//     if (validate) {
//       setValidationError(tags.length === 0);
//     }
//   }, [tags, validate]);

//   const handleSelectChange = (event) => {
//     const { value } = event.target;

//     if (value === "") return;

//     const selectedMaterial = materials.find(
//       (material) => material.name === value
//     );

//     if (
//       selectedMaterial &&
//       !tags.some((tag) => tag.id === selectedMaterial.id)
//     ) {
//       setTags((prevTags) => {
//         const updatedTags = [...prevTags, selectedMaterial];
//         return updatedTags;
//       });
//       setSelectedValue("");
//       setMaterial(selectedMaterial.name);

//       // Update selectedIds state and log the array
//       setSelectedIds((prevSelectedIds) => {
//         const updatedSelectedIds = [...prevSelectedIds, selectedMaterial.id];
//         console.log("Selected Material IDs:", updatedSelectedIds);
//         return updatedSelectedIds;
//       });
//     }
//   };

//   const removeTag = (tagToRemove) => {
//     const updatedTags = tags.filter((tag) => tag.id !== tagToRemove.id);
//     setTags(updatedTags);

//     // Update selectedIds state when a tag is removed
//     setSelectedIds((prevSelectedIds) => {
//       const updatedSelectedIds = prevSelectedIds.filter(
//         (id) => id !== tagToRemove.id
//       );
//       console.log("Updated Selected Material IDs:", updatedSelectedIds);
//       return updatedSelectedIds;
//     });
//   };

//   const handleTagClick = () => {
//     inputRef.current.focus();
//   };

//   return (
//     <Main>
//       <div className="flex-wrap">
//         <select
//           value={selectedValue}
//           onChange={handleSelectChange}
//           className="inputs-width"
//           ref={inputRef}
//         >
//           <option value="" disabled hidden>
//             Select a Product Keyword
//           </option>
//           {materials.map((material) => (
//             <option key={material.id} value={material.name}>
//               {material.name}
//             </option>
//           ))}
//         </select>
//         <div className="flex">
//           {tags.map((tag) => (
//             <span key={tag.id} className="tag-background">
//               {tag.name}
//               <RxCross2
//                 onClick={() => removeTag(tag)}
//                 size={20}
//                 color="white"
//                 className="cross-border"
//               />
//             </span>
//           ))}
//         </div>
//         {validationError && (
//           <div className="validation-message">Please select at least one material.</div>
//         )}
//       </div>
//     </Main>
//   );
// };

// export default MaterialTags;

import React, { useState, useRef, useEffect } from "react";
import { Main } from "./style";
import { RxCross2 } from "react-icons/rx";

const MaterialTags = ({
  materials,
  onChangetags,
  setSelectedIds,
  register,
  setValue,
  trigger,
  errors,
}) => {
  const [tags, setTags] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    const materialIds = tags.map((tag) => tag.id);
    setValue("materials", materialIds);
    trigger("materials"); // Trigger validation whenever tags change
  }, [tags, setValue, trigger]);

  const handleSelectChange = (event) => {
    const { value } = event.target;

    if (value === "") return;

    const selectedMaterial = materials.find(
      (material) => material.name === value
    );

    if (
      selectedMaterial &&
      !tags.some((tag) => tag.id === selectedMaterial.id)
    ) {
      setTags((prevTags) => {
        const updatedTags = [...prevTags, selectedMaterial];
        return updatedTags;
      });
      setSelectedValue("");

      // Update selectedIds state and log the array
      setSelectedIds((prevSelectedIds) => {
        const updatedSelectedIds = [...prevSelectedIds, selectedMaterial.id];
        console.log("Selected Material IDs:", updatedSelectedIds);
        return updatedSelectedIds;
      });
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag.id !== tagToRemove.id);
    setTags(updatedTags);

    // Update selectedIds state when a tag is removed
    setSelectedIds((prevSelectedIds) => {
      const updatedSelectedIds = prevSelectedIds.filter(
        (id) => id !== tagToRemove.id
      );
      console.log("Updated Selected Material IDs:", updatedSelectedIds);
      return updatedSelectedIds;
    });
  };

  return (
    <Main>
     
        <select
          value={selectedValue}
          onChange={handleSelectChange}
          className="inputs-width"
          ref={inputRef}
        >
          <option value="" disabled hidden>
            Select a Product Keyword
          </option>
          {materials.map((material) => (
            <option key={material.id} value={material.name}>
              {material.name}
            </option>
          ))}
        </select>
        <div className="flex">
          {tags.map((tag) => (
            <span key={tag.id} className="tag-background">
              {tag.name}
              <RxCross2
                onClick={() => removeTag(tag)}
                size={20}
                color="white"
                className="cross-border"
              />
            </span>
          ))}
        </div>
        {errors.materials && (
          <div className="validation-color">{errors.materials.message}</div>
        )}
     
    </Main>
  );
};

export default MaterialTags;
