import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHooks = () => {
  const imagePost = async (file) => {
    try {
      if (!file) {
        throw new Error("No file provided");
      }
      const reader = new FileReader();
      const fileAsDataURL = new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
      reader.readAsDataURL(file);
      await fileAsDataURL; // Wait for the file to be read as data URL
      const response = await AuthAPI.imagepost(
        { image: file },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("Image upload response", response);
      if (response?.path) {
        toastr.success("Image uploaded successfully");
        return response.path;
      } else {
        alert("Image upload failed");
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("An error occurred during image upload");
      return null;
    }
  };

  const getcategory = async (params) => {
    try {
      const response = await AuthAPI.getcategory(params);
      //   console.log(response,'Category')
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const getmaterial = async (params) => {
    try {
      const response = await AuthAPI.getmaterial(params);
      //   console.log(response,'materials')
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };

  const getcollections = async (params) => {
    try {
      const response = await AuthAPI.getcollections(params);
      console.log(response, "");
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };

  const productsPost = async (formData) => {
    try {
      const response = await AuthAPI.productsPost(formData);
      // console.log(formData)
      return response;
    } catch (error) {
      console.error("Error in collectionPost:", error);
      throw error;
    }
  };

  const getsingleproduct = async (params) => {
    try {
      const response = await AuthAPI.getsingleproduct(params);
      // console.log(response,'')
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const updateProduct = async (id, productData) => {
    try {
      const response = await AuthAPI.updateProduct(id, productData);
      // console.log(response,'')
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };

  return {
    imagePost,
    getcategory,
    getmaterial,
    getcollections,
    productsPost,
    getsingleproduct,
    updateProduct,
  };
};

export default useHooks;
