import React from "react";
import { PuffLoader } from "react-spinners";
const PuffLoaderComponent = () => {
  return (
    <div
      style={{
        width: "100px",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PuffLoader color="#0c3a2d" />
    </div>
  );
};

export default PuffLoaderComponent;
