import React, { useEffect, useState } from "react";
import { Main } from "./style";
import Export from "../../../assets/img/customers/export.png";
import Block from "../../../assets/img/customers/block.png";
import View from "../../../assets/img/customers/view.svg";
import { useNavigate } from "react-router-dom";
import PaginationRounded from "components/Pagination";
import useHooks from "./useHook";
import { CSVLink } from "react-csv";
import Spiner from "components/spinner";
import { MdBlock } from "react-icons/md";
import { CgUnblock } from "react-icons/cg";
const Customers = () => {
  const { customersGet, blockUnblockuser } = useHooks();

  const [customerslist, setcustomerlist] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [blockedlist, setblockedlist] = useState([]);
  const fetchcustomer = async () => {
    try {
      const formData = {
        page: currentPage,
        perPage: 10,
        search: search,
      };
      const response = await customersGet(formData);
      console.log("customer response", response);
      setcustomerlist(response.data.results);
      setblockedlist(response.data.results.map((item) => item.isBlocked));
      setTotalPage(Math.ceil(response.data.counts / 10));
      console.log("this is the correct address", response.data.results);
      console.log("is blocked", blockedlist);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchcustomer();
  }, [currentPage]);
  const navigate = useNavigate();
  const overview = (id, name, email, phone, profile_pic, addresses) => {
    console.log("this is overview id", id);
    console.log("myemail is :", email);
    console.log("myemail is :", profile_pic);
    const addressNames = addresses.map((address) => address.address);
    const countries = addresses.map((address) => address.country);
    const city = addresses.map((address) => address.city);
    const state = addresses.map((address) => address.state);
    const zipCode = addresses.map((address) => address.zipCode);
    navigate(`/customers/overview/${id}`, {
      state: {
        name: name,
        email: email,
        phone: phone,
        profile_pic: profile_pic,
        addresses: addressNames,
        country: countries,
        city: city,
        state: state,
        zipCode: zipCode,
      },
    });
  };
  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  const headers = [
    { label: "SR. No", key: "sr" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone No", key: "phone" },
    { label: "Address", key: "address" },
  ];
  const exportData = customerslist.map((customer, index) => ({
    sr: index,
    name: customer.name,
    email: customer.email,
    phone: customer.phone,
    address: customer.addresses.map((addr) => addr.address).join(", "),
  }));

  const blockUnblockCustomer = async (id) => {
    try {
      const response = await blockUnblockuser(id);
      console.log("block user response", response);
      fetchcustomer();
    } catch (error) {
      console.error("error", error);
    }
  };
  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          <div className="heading-flex">
            <p className="m-0  heading">Customers List</p>
          </div>

          <div className="card export-card">
            <div className="card-body">
              {/* <img src={Export} alt="" style={{ cursor: "pointer" }} className='export-width' /> */}
              <CSVLink
                data={exportData}
                className="export-text nav-link"
                headers={headers}
                target="_blank"
                filename={"customer.csv"}
              >
                <img src={Export} alt="" className="export-width" />
                <p>Export</p>
              </CSVLink>
            </div>
          </div>

          <div className="card export-card mt-3">
            <div className="card-body">
              <div className="filter-flex">
                <input
                  type="text"
                  placeholder="Search by Name/ Email"
                  className="input-width"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />{" "}
                <button
                  type="submit"
                  className="search-button"
                  onClick={fetchcustomer}
                >
                  Search
                </button>
              </div>
            </div>
          </div>

          <div className="card export-card mt-3">
            <div className="card-body">
              {customerslist.length === 0 ? (
                <div className="no-record">No Record Found</div>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table-width">
                      <thead className="table-head">
                        <tr>
                          <th>Sr. No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone No</th>
                          <th>Address</th>
                          <th style={{ width: "15%" }}>Actions</th>
                        </tr>
                      </thead>
                      {customerslist.map((items, index) => (
                        <tbody className="table-body" key={index}>
                          <tr>
                            <td style={{ display: "none" }}>
                              <img src={items.profile_pic} alt="Profile" />
                            </td>
                            <td style={{ paddingBlock: "10px" }}>
                              {index + 1}
                            </td>
                            <td style={{ paddingBlock: "10px" }}>
                              {items.name}
                            </td>
                            <td style={{ paddingBlock: "10px" }}>
                              {items.email}
                            </td>
                            <td style={{ paddingBlock: "10px" }}>
                              {items.phone}
                            </td>
                            <td style={{ paddingBlock: "10px" }}>
                              {items.addresses.map((address, addrIndex) => (
                                <div key={addrIndex}>{address.address}</div>
                              ))}
                            </td>
                            <td style={{ paddingBlock: "10px" }}>
                              {/* <div className="view-icon"> */}
                              <img
                                src={View}
                                alt="View"
                                className="view-icon"
                                onClick={() =>
                                  overview(
                                    items.id,
                                    items.name,
                                    items.email,
                                    items.phone,
                                    items.profile_pic,
                                    items.addresses
                                  )
                                }
                              />
                              {/* </div> */}
                              &nbsp;&nbsp;
                              {/* <img
                              src={Block}
                              alt="Block"
                              className="Block-width"
                              onClick={() => blockUnblockCustomer(items.id)}
                            /> */}
                              {blockedlist[index] ? (
                                <span
                                  onClick={() => blockUnblockCustomer(items.id)}
                                  style={{
                                    backgroundColor: "#252D31",
                                    color: "white",
                                    cursor: "pointer",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    fontFamily: "inter",
                                    fontSize: "12px",
                                  }}
                                >
                                  <CgUnblock size={13} /> unblock
                                </span>
                              ) : (
                                <span
                                  onClick={() => blockUnblockCustomer(items.id)}
                                  style={{
                                    backgroundColor: "#252D31",
                                    color: "white",
                                    cursor: "pointer",
                                    padding: "5px",
                                    borderRadius: "10px",
                                    fontFamily: "inter",
                                    fontSize: "12px",
                                  }}
                                >
                                  <MdBlock size={13} /> Block
                                </span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                  <PaginationRounded
                    count={totalPage}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </>
              )}
            </div>

            {/* <div className="card-body">
            <table className="w-100">
              <thead className="table-head">
                <tr>
                  <th>SR. No</th>
                  <th>NAME</th>
                  <th>EMAIL</th>
                  <th>PHONE NO</th>
                  <th>ADDRESS</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              {customerslist.map((items, index) => (
                <tbody className="table-body" key={index}>
                  <td style={{ display: "none" }}>
                    {" "}
                    <img src={items.profile_pic} />
                  </td>
                  <td style={{ paddingBlock: "10px" }}>{index}</td>
                  <td style={{ paddingBlock: "10px" }}>{items.name}</td>
                  <td style={{ paddingBlock: "10px" }}>{items.email}</td>
                  <td style={{ paddingBlock: "10px" }}>{items.phone}</td>
                  {items.addresses.map((address) => (
                    <td style={{ paddingBlock: "10px" }}>{address.address}</td>
                  ))}

                  <td style={{ paddingBlock: "10px" }}>
                    <img
                      src={View}
                      alt=""
                      className="view-width"
                      onClick={() =>
                        overview(
                          items.id,
                          items.name,
                          items.email,
                          items.phone,
                          items.profile_pic,
                          items.addresses
                        )
                      }
                    />
                    &nbsp;&nbsp;
                    <img src={Block} alt="" className="Block-width" onClick={()=>blockUnblockCustomer(items.id)} />
                  </td>
                </tbody>
              ))}
            </table>
            <PaginationRounded
              count={totalPage}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div> */}
          </div>
        </div>
      )}
    </Main>
  );
};

export default Customers;
