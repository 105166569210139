import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };
const useHooks = () => {
    const getcollections = async (params)=> { 
        try {
          const response = await AuthAPI.getcollections(params);  
          if (response) {
            // toastr.success('Collection get successfully');
            console.log(response);
          }
          console.log(response,'')
          return response;
        } catch (error) {
          console.error(error);
          toastr.error("Please try again Later");
          return null;
        }
      }; 
      const collectionDelete=async(id)=>{
        try {
          const response = await AuthAPI.deleteCollection(id);  
          // toastr.success('Collection delete successfully')
          return response;
        } catch (error) {
          console.error(error);
          toastr.error("Please try again later");
          return null;
        }
      }
      const singleCollectionGet = async (id) => { 
        try {
          const response = await AuthAPI.getSingleCollection(id);
          // console.log(formData)
          return response;
        } catch (error) {
          console.error("Error in single collection Get:", error);
          throw error;
        }
      };

  return { getcollections,collectionDelete,singleCollectionGet };
};

export default useHooks;
