import styled from "styled-components";

export const Product = styled.div`
  background-color: var(--all-background);
  height: inherit;
  .product-name {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  .product-count {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 14px;
    color: #808d9e;
  }
  .product-para {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
  }
  .styled-font {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
  }
  .status {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

  .edit {
    width: 31px;
    height: 32px;
  }
  .img {
    width: 359px;
    height: 223px;
    border-radius: 12px;
  }
  .flex-1 {
    width: 31%;
    padding: 0px 10px;
  }
  .transitional {
    padding: 0px 12px;
    width: auto;
    height: 28px;
    border-radius: 5px;
    background-color: #0c3a2d;
    font-family: Plus Jakarta Sans;
    font-weight: 500;
    font-size: 14px;
    color: white;
    border: 0px;
  }
  .flex-4 {
    display: flex;
    flex-wrap: wrap;
  }

  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #0c3a2d;
  }
  .table-body {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
  }
  .status-sold {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #ff00001a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff0000;
  }
  .status-stock {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #209a421a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #209a42;
  }
  .status-width {
    width: 328px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    outline: none;
    padding: 15px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    cursor: pointer;
  }
  .rating {
    display: flex;
    align-items: center;
  }
  .icon-flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .showing {
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #0f172a;
  }
  .single-product-description {
    display: flex;
    flex-direction: row;
  }
  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }
  .dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    width: 159px;
  }

`;
