import styled from "styled-components";

export const Main = styled.main`
  width: 100%;

  padding-bottom: 15px;
  input::placeholder {
    font-weight: 400;
    font-size: 14px;
    font-family: inter;
    line-height: 24px;
    letter-spacing: 0.5%;
    color: #252525;
  }

  .profile-setting-section {
    width: 100%;
    background-color: var(--all-background);
    border-radius: 9px;
    padding: 25px 0px;
  }
  .profile-setting-body {
    display: flex;
    align-items: start;
    gap: 70px;
  }
  @media (max-width: 1190px) {
    .profile-setting-body {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  .profile-setting-title {
    font-family: inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: #156a3d;
    text-align: center;
    letter-spacing: 0.2px;
    border-bottom: 3px solid #156a3d;
    width: 230px;
  }
  .input-label-changePassword {
    font-family: inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.5%;
    color: #252525;
  }
  .change-password-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 50px;
  }
  .change-password-body {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  @media (max-width: 863px) {
    .change-password-body {
      width: 100%;
    }
  }
  .notification-section {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .notificatiion-enable {
    display: flex;
    flex-direction: row;
    gap: 100px;
  }
  .notification-title {
    font-family: inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .enable-title {
    font-family: inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.5%;
    color: #66707a;
  }
  .create-password-title {
    font-family: inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.5%;
    color: #9ca4ab;
    margin: 20px 0px 15px 0px;
  }
  .CreateVendor {
    width: 170px;
    height: 50px;
    font-family: inter;
    font-family: 700;
    font-size: 16px;
    background-color: #156a3d;
    color: white;
    border: 0px;
    border-radius: 12px;
  }
  .error {
    color: red;
    font-family: inter;
    font-size: 12px;
  }
  /* ===============Toggle Button CSS ============= */
  .switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 31px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  /* ==================================================== */
`;
export const PasswordInputContainer = styled.div`
  position: relative;
  width: 522.55px;
  @media (max-width: 880px) {
    width: 100%;
  }
`;

export const PasswordField = styled.input`
  width: 100%;
  font-size: 15px;
  padding: 14px 20px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  outline: none;
`;

export const ToggleButton = styled.button`
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  @media (max-width: 425px) {
    right: 10px;
  }
`;
