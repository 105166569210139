// import styled from "styled-components";

// export const ConfirmButton = styled.button`
//   width: 100%;
//   height: 56px;
//   border-radius: 5px;
//   background-color: #156a3d;
//   font-family: "Inter";
//   font-weight: 700;
//   font-size: 16px;
//   color: #ffffff;
//   border: 0px;
// `;
// export const ImgInput=styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin-top: 1px;


// .select-images {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   border: 1px solid black;
//   width: 100%;
// }

// .selected-image {
//   position: relative;
//   width: 100%;
//   display: flex;
//   flex-direction:"row"; /* Ensures each image and close icon combo is on the same line */
//   /* margin-right: 10px; */
//   margin-top: 10px;
// }

// /* .selected-image img {
//   max-width: 100%;
//   max-height: 300px;
// } */

// .remove-image {
//   position: absolute;
//   color: red;
//   cursor: pointer;
//   top: 0;
//   left:30px;
//   margin-top:0px;
// }

// .select-image {
//   position: relative;
//   text-align: center;
//   margin: 10px;
// }

// .input-file {
//   display: none;
// }

// .icon {
//   font-size: 32px;
// }

// .text {
//   margin-top: 5px;
// }

// @media (max-width: 768px) {
//   .select-images {
//     flex-direction: column;
//     align-items: center;
//   }
// }

// `

import styled from "styled-components";

export const Main = styled.div`

.create-button{
    width: 218px;
    height: 56px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: 0px;
}

`
export const ImgInput= styled.div`
.add-img-input{
    width: 616px;
    height: 130px;
    border-radius: 14px;
    text-align: center;
    background-color: #F9F9F9;
    padding: 15px 0px 30px 0px;
    position: relative;
}
.name{
    width: 616px;
    height: 45px;
    border-radius: 5px;
    outline: none;
    border: 0px;
    background: #F9F9F9;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    /* color: #808D9E; */
    color: black;
    padding: 0px 15px;
}
.text-area{
    width: 616px;
    border-radius: 5px;
    outline: none;
    border: 0px;
    background: #F9F9F9;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 16px;
    /* color: #808D9E; */
    color: black;
    padding: 10px 21px;
    resize: none;
}
.create-btn{
    width:616px;
    height: 56px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: 0px;
    }
    .image-upload-container {
  text-align: center;
}

.image-upload-label {
  cursor: pointer;
}

.uploaded-image {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
}
.img-sizzing{
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0;
    height: 130px;
    width: 100%;
    border-radius: 14px;
}
.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
`
