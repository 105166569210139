    // import styled from "styled-components";

    // export const Main = styled.div`
    // width: 100%;

    // .chart{
    // width: 100% !important;
    //     /*   height: 442px !important; */
    //     /* overflow-x: scroll ; */
    // }

    // `
    import styled from "styled-components";

    export const Main = styled.div`
        width: 100%;
        padding: 20px;
        background-color: #FFFFFF;
        border-radius: 16px;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
    
        .chart {
            width: 100% !important;
            height: 400px !important;
        }
    `;
    