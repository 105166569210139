import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  height: 100vh;
`;
export const Card = styled.div`
  .card-customize {
    border: 0px;
    border-radius: 16px;
  }
  .card-border {
    border: 1px solid #d8dfe0;
    border-radius: 4px;
    padding: 20px;
    height: 730px;
    overflow-x: auto;
  }
  .main-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 420px) {
    .main-flex {
      flex-wrap: wrap;
    }
  }
  .img-text-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .profile-name {
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    letter-spacing: 1.5%;
    white-space: nowrap;
  }
  @media (max-width: 425px) {
    .profile-name {
      font-size: 15px;
      white-space: nowrap;
    }
  }
  .last-active {
    font-family: poppins;
    font-weight: 400;
    font-size: 11px;
    line-height: 16.5px;
    color: #000000cc;
    letter-spacing: 1%;
  }
  .block-button {
    width: 93px;
    height: 37px;
    border-radius: 5px;
    background-color: #252d31;
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 22.4px;
    color: white;
    border: 0;
  }
  .report-button {
    width: 93px;
    height: 37px;
    border-radius: 5px;
    background-color: #e00000;
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    line-height: 22.4px;
    color: white;
    border: 0;
  }
  .bottom-border {
    border-bottom: 1px solid #f1f5f9;
    padding-block: 7px;
  }
  .border-all {
    border: 1px solid;
  }
  .padding-left {
    display: flex;
    gap: 16px;
  }
  .our-chat,
  .other-chat {
    border-radius: 11px;
    background-color: #f9f9f9; /* Adjust this for .our-chat and .other-chat accordingly */
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #1c1c27; /* Adjust this for .our-chat and .other-chat accordingly */
    display: inline-block; /* Use inline-block for better text wrapping */
    padding: 15px;
    word-wrap: break-word; /* Ensures long words or URLs break to the next line */
    max-width: 70%; /* Adjust the max-width to control the bubble's width */
    margin-bottom: 8px; /* Adds some space between messages */
  }

  .other-chat {
    background-color: #0c3a2d;
    color: #ffffff;
  }
  .time-text-other-chat {
    font-family: poppins;
    font-weight: 400;
    font-size: 9px;
    color: #ffffff;
    line-height: 19px;
    white-space: nowrap;
    padding: 0px 15px;
  }
  .today-flex {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .today-text {
    width: 120px;
    height: 28px;
    border-radius: 11px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: poppins;
    font-size: 11px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
  .our-chat-flex {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
    flex-direction: row-reverse;
    gap: 16px;
  }
  /* .our-chat {
    border-radius: 11px;
    background-color: #f9f9f9;
    font-family: poppins;
    font-weight: 500;
    font-size: 12px;
    color: #1c1c27;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 15px 15px;
  } */
  .time-text-our-chat {
    font-family: poppins;
    font-weight: 400;
    font-size: 9px;
    color: #1c1c27;
    line-height: 19px;
    white-space: nowrap;
    padding: 0px 15px;
  }
  .share-image-other {
    padding: 0px 70px;
    padding-block: 10px;
  }
  .share-image-our {
    text-align: end;
    padding: 0px 70px;
    padding-block: 10px;
  }

  .share-image-other {
    /* Styles for images shared by the other person */
  }

  .share-image-our {
    /* Styles for images shared by self */
  }

  /* .our-chat-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
  } */

  .other-chat-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
  }

  /* .our-chat {
    background-color: #e1ffc7;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
  } */

  /* .other-chat {
      background-color: #f1f1f1;
      padding: 10px;
      border-radius: 10px;
      margin-right: 10px;
    } */

  .time-stamp {
    font-size: 0.8em;
    color: #999;
    margin-top: 5px;
  }
  .block-button-width {
    width: 50%;
    text-align: end;
  }
  @media (max-width: 425px) {
    .block-button-width {
      width: 100%;
    }
  }
`;

export const ChatInput = styled.div`
  .chat-input {
    /* width: 100%;
    height: 51px;
    border-radius: 27px;
    outline: none;
    border: 1px solid #888080d9;
    padding: 0px 20px;
    font-family: poppins;
    font-weight: 400;
    font-size: 16px;
    color: #000000; */
    width: 100%;
    border-radius: 27px;
    outline: none;
    border: 1px solid #888080d9;
    padding: 17px 49px 0px 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    resize: none;
    overflow-y: auto;
  }
  @media (max-width: 425px) {
    .chat-input {
      font-size: 10px;
    }
  }
  .chat-input::-webkit-scrollbar {
    display: none; /* Remove scrollbar */
  }
  .field-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 10px;
  }
  .absolute-position {
    position: absolute;
    right: 25px;
    top: 17px;
  }
`;
