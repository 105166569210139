import React from 'react'
import { Main, Table } from './style';
import PaginationRounded from 'components/Pagination';
import RequestPaymentImage from '../../../assets/img/my-wallet/requestPaymentImage1.png'

const WithDrawRequestTable = () => {

    const withdrawRequestDataTable = [
        { id: '#121212121212', requestedAmount: '£300', from: 'Admin', status: 'Pending', requestPaymentImage: RequestPaymentImage, date: '6-12-2023' },
        { id: '#121212121212', requestedAmount: '£250', from: 'Admin', status: 'Approved', requestPaymentImage: RequestPaymentImage, date: '6-12-2023' },
        { id: '#121212121212', requestedAmount: '£250', from: 'Admin', status: 'Pending', requestPaymentImage: RequestPaymentImage, date: '6-12-2023' },
        { id: '#121212121212', requestedAmount: '£250', from: 'Admin', status: 'Approved', requestPaymentImage: RequestPaymentImage, date: '6-12-2023' }

    ]
    return (
        <Main>
            <div className='container-fluid'>
                <div className="heading-flex">
                    <p className="heading">Withdraw Requests</p>
                </div>
                <div className='dataTable-section'>
                    <div className='dataTable-section-body'>
                        <Table>
                            <thead>
                                <tr className='table-head'>
                                    <th>Requested Amount ID</th>
                                    <th>Requested Amount</th>
                                    <th>From</th>
                                    <th>Status</th>
                                    <th>Image</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                {withdrawRequestDataTable.map((item, index) => (
                                    <tr key={index}>
                                        <td className='table-body-id'>{item.id}</td>
                                        <td>{item.requestedAmount}</td>
                                        <td>{item.from}</td>
                                        <td><span className={`status-${item.status === 'Pending' ? 'pending' : 'approved'}`}>{item.status}</span></td>
                                        <td>
                                            {item.status === 'Approved' && (
                                                <img src={item.requestPaymentImage} alt='Withdraw request payments ScreenShots here!' />
                                            )}
                                        </td>
                                        <td>{item.date}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <div className='pagination-section'>
                            <PaginationRounded />
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default WithDrawRequestTable;
