import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  overflow: hidden;

  .main-label {
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
  }
  .Vendor-Information-parent-1 {
    width: 100%;
    display: flex;
    gap: 50px;
    align-items: center;
  }
  .Vendor-Information-parent-2 {
    width: 100%;
    display: flex;
    gap: 25px;
    align-items: center;
    padding-top: 45px;
  }
  .Vendor-Information-child-1 {
    width: 17%;
  }
  .Vendor-Information-child-2 {
    width: 83%;
  }
  .Vendor-Information-child-3 {
    width: 50%;
  }
  .Vendor-Information-child-4 {
    width: 50%;
  }
  .Vendor-Information-border,
  .bussiness-information-border {
    width: 100%;
    border: 1px solid #d9d9d9;
    height: 156px;
    border-radius: 10px;
    margin-top: 10px;
    position: relative;
  }
  .bussiness-information-border {
    border-style: dashed;
    text-align: center;
    padding: 30px 0px;
  }
  .profile-pic {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .plus-background {
    width: 25px;
    height: 25px;
    background-color: #0c3a2d;
    padding: 6px;
    border-radius: 19px;
    position: absolute;
    bottom: -8px;
    right: -10px;
  }
  .input-width {
    width: 100%;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 8px 16px 8px 16px;
    margin-top: 10px;
    outline: none;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .description {
    width: 100%;
    height: 131px;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 8px 16px 8px 16px;
    margin-top: 10px;
    outline: none;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    resize: none;
  }
  .upload-btn {
    margin-top: 10px;
    height: 56px;
    background-color: #e2e8f0;
    border: 0px;
    font-family: inter;
    border-radius: 12px;
    padding: 8px 16px 8px 16px;
    font-weight: 400;
    font-size: 16px;
    color: #1c1c27;
  }
  .backgound-img {
    width: 100%;
    height: 154px;
    border-radius: 9px;
  }
`;
