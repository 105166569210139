import styled from "styled-components";

export const Main = styled.div`
  background-color: var(--all-background);
  height: -webkit-fill-available;

  .card-border {
    border-radius: 10px;
    border: 0px;
  }
  .back-button {
    width: 83px;
    height: 47px;
    border-radius: 12px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    align-items: center;
    color: #ffffff;
    border: 0px;
    cursor: pointer;
  }
  .cards-parent {
    display: flex;
    width: 100%;
    margin-top: 15px;
    gap: 20px;
  }
  .first-card {
    width: 30%;
    background-color: beige;
  }
  .second-card {
    width: 70%;
    background-color: bisque;
  }
  .main-heading {
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-top: 5px;
  }
  .sub-heading {
    font-family: inter;
    font-weight: 500;
    font-size: 12px;
    color: #808d9e;
  }
  .border-bottom-color {
    margin-top: 0px;
    color: #6a8686;
  }
  .order-detail-card {
    border: 2px solid #e3e4e4;
    background-color: #ffffff;
    border-radius: 10px;
  }
  .padding {
    padding: 0px 15px;
  }
  .order-date {
    width: 50%;
  }
  .order-amount {
    width: 50%;
  }
  .order-details-flex {
    display: flex;
    gap: 40px;
    margin-top: 25px;
    width: 100%;
  }
  .evidence-img {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 127px;
    height: 32px;
    background-color: #e2e8f0;
    justify-content: center;
    padding: 10px;
    border-radius: 16px;
  }
  .evidence-font {
    font-family: inter;
    font-weight: 500;
    font-size: 12px;
    color: #2e4d55;
  }
  .evidence-img-flex {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
    flex-wrap: wrap;
  }
  .status {
    width: 107px;
    height: 25px;
    border-radius: 30px;
    padding: 10px;
    border: 1px solid #209a42;
    color: #209a42;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px; 
  }
  .takeAction-button {
    width: 146px;
    height: 47px;
    border-radius: 12px;
    background-color: #156a3d;
    color: white;
    border: 0px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
  }
  .chat-name-heading {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    color: #333333;
  }
  .chat-flex {
    display: flex;
    gap: 20px;
    margin-top: 15px;
  }
  .under-img-border {
    border-left: 1px solid #808d9e;
    height: 22px;
    margin-left: 18px;
    color: white;
    top: 35px;
  }
  .chat-text {
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    /* margin-top: 5px; */
    padding: 10px 4px;
  }
  .image-width{
    width: 40px;
    height: 40px;
    border-radius: 24px;

  }
  .comment-img{ 
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-top: 10px;
}
.comment-images{
  display: flex;
    gap: 10px;
}
.warning-text{
  color: red;
  font-family: inter;
  font-size: 16px;
}


`;
