import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import styled from "styled-components";
import useHooks from "./usehooks";
import toastr from "toastr";

// Custom styles
const StyledModal = styled.div`
  .options {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
  }

  .modal-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .modal-header {
    background-color: #ff8f501a;
    color: #ff8f50;
    padding: 5px 10px;
    border-radius: 12px;
    text-align: center;
    margin-top: 10px;
  }

  .form-control {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn-confirm {
    margin-top: 16px;
  }
`;

const OrderDisputes = ({ itemId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(""); // State for selected status
  const { changeDisputes } = useHooks(); // Use the custom hook

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value); // Update the selected status
  };

  const handleConfirm = async () => {
    try {
      const response = await changeDisputes(itemId, { status: selectedStatus });
      if (response) {
        toastr.success("Dispute status changed successfully.");
        setModalOpen(false);
      } else {
        toastr.error("Failed to change dispute status.");
      }
    } catch (error) {
      console.error(error);
      toastr.error("An error occurred while changing the dispute status.");
    }
  };

  return (
    <div>
      <svg
        style={{ cursor: "pointer" }}
        onClick={handleModalOpen}
        className="create-button"
        fill="#00D1FF"
        viewBox="0 0 16 16"
        height="1.5em"
        width="1.5em"
      >
        <path d="M2 0a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V2a2 2 0 00-2-2H2zm6.5 4.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3a.5.5 0 011 0z" />
      </svg>
      <Backdrop
        sx={{
          color: "#1C1C27",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          boxShadow:
            "inset 0 0 0 1px rgba(255, 255, 255, 0.2), 0 5px 10px rgba(0, 0, 0, 0.2)",
        }}
        open={modalOpen}
        onClick={handleModalClose}
      >
        <StyledModal>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              //   border: "2px solid #000",
              boxShadow: 24,
              p: 3,
            }}
            onClick={(event) => event.stopPropagation()}
          >
            <div className="modal-title">
              <Typography id="modal-modal-title" variant="h5" component="h5">
                Order Dispute
              </Typography>
              <IoClose
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={handleModalClose}
              />
            </div>

            <Typography id="modal-modal-description" className="modal-header">
              Item ID: {itemId}
            </Typography>

            {/* Status Selector */}
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <InputLabel id="status-select-label">Select Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={selectedStatus}
                onChange={handleStatusChange}
                label="Select Status"
              >
                <MenuItem value="admin_review" className="options">
                  admin_review
                </MenuItem>
                <MenuItem value="resolved" className="options">
                  resolved
                </MenuItem>
                <MenuItem value="returned" className="options">
                  returned
                </MenuItem>
              </Select>

              <Button
                variant="contained"
                style={{ marginTop: "16px" }}
                onClick={handleConfirm} // Update the button to call handleConfirm
              >
                Confirm
              </Button>
            </FormControl>
          </Box>
        </StyledModal>
      </Backdrop>
    </div>
  );
};

export default OrderDisputes;
