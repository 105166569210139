import React, { useEffect, useState } from "react";
import { Main } from "./style";
import { Card, Product } from "./style";
import Home1 from "../../../assets/img/catalogs/home1.png";
import Home2 from "../../../assets/img/catalogs/home2.png";
import Home3 from "../../../assets/img/catalogs/home3.png";
import Home4 from "../../../assets/img/catalogs/home4.png";
import Home5 from "../../../assets/img/catalogs/home5.png";
import Home6 from "../../../assets/img/catalogs/home6.png";
import Home7 from "../../../assets/img/catalogs/home7.png";
import View from "../../../assets/img/catalogs/view.svg";
import Copy from "../../../assets/img/catalogs/edit.svg";
import Delete from "../../../assets/img/catalogs/delete.svg";
import PaginationRounded from "components/Pagination";
import TransitionsModal from "components/modal";
import AddImgModal from "../add-img-modal";
import useHooks from "./useHook";
import { useNavigate } from "react-router-dom";
import DeleteModal from "components/modal/delete";
import Spiner from "components/spinner";
const Catalogs = () => {
  const { getcatalogs, getSingleCatalog, deleteCatalog } = useHooks();

  const navigate = useNavigate();

  const [cardVisible, setCardVisible] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [catalogData, setCatalogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [editID, setEditID] = useState("");

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [loading, setLoading] = useState(true);
  const vendorId = localStorage.getItem("id");

  const getcatalogsData = async () => {
    const formData = {
      page: currentPage,
      perPage: 8,
      userId: vendorId,
    };
    try {
      const res = await getcatalogs(formData);
      console.log("catalog Data", res);
      if (res.status === true) {
        setCatalogData(res.data.results);
        setTotalPage(Math.ceil(res.data.counts / 8));
      }
    } catch (error) {
      console.error("Error fetching material data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getcatalogsData();
  }, []);
  useEffect(() => {
    if (!modalOpen) {
      getcatalogsData();
    }
  }, [modalOpen, currentPage]); // Also depend on currentPage

  const fetchSingleData = async (id) => {
    try {
      const response = await getSingleCatalog(id);
      navigate(`/my-catalogs-detail/${id}`, {
        state: {
          response: response,
        },
      });
    } catch (error) {
      alert("fetch error");
    }
  };

  const handlePageChange = (e, value) => {
    setCurrentPage(value);
  };

  // const handleDeleteCatalog = async (id) => {
  //   try {
  //     await deleteCatalog(id);
  //     const newdata = catalogData.filter((catalog) => catalog.id !== id);
  //     setCatalogData(newdata);
  //   } catch (error) {
  //     console.error("Error deleting catalog:", error);
  //   }
  // };

  const handleModalOpen = (e) => {
    e.preventDefault();
    setEditID("");
    setModalOpen(true);
  };
  // delete modal open
  const handleEdit = (id) => {
    setEditID(id);
    setModalOpen(true);
  };
  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteID !== null) {
      try {
        await deleteCatalog(deleteID);
        const newdata = catalogData.filter(
          (catalog) => catalog.id !== deleteID
        );
        setCatalogData(newdata);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Error deleting catalog:", error);
      }
    }
  };
  useEffect(() => {
    if (!deleteModalOpen) {
      getcatalogsData();
    }
  }, [deleteModalOpen, currentPage]);
  return (
    <Main>
      {loading ? (
        <Spiner />
      ) : (
        <div className="container-fluid">
          {cardVisible ? (
            <div className="heading-flex">
              <p className="heading ">My Catalogs</p>
              <button
                type="submit"
                className="category-btn me-2"
                onClick={handleModalOpen}
              >
                Create New Catalogs
              </button>
            </div>
          ) : (
            <div className="d-none"></div>
          )}
          <div className="catalog-container">
            <TransitionsModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              editID={editID}
              getcatalogsData={getcatalogsData}
            />
            <DeleteModal
              open={deleteModalOpen}
              handleClose={handleCloseDeleteModal}
              handleDelete={handleConfirmDelete}
            />
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home3-tab"
                tabIndex="0"
              >
                <div className="card-flex">
                  {catalogData.length > 0 ? (
                    catalogData.map((items, index) => (
                      <div className="card card-width" key={index}>
                        <div className="card-body">
                          <div>
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                items.image
                              }
                              alt=""
                              className="img"
                            />
                            <div className="img-flex">
                              <div className="mt-3">
                                <p className="m-0 heading-card dot-property">
                                  {items.name}
                                </p>
                                <p className="m-0 sub-heading">
                                  {items.catalogImagesCount} Photos
                                </p>
                              </div>
                              <div className="m-0 img-flex-2">
                                <img
                                  src={View}
                                  alt="view"
                                  onClick={() => fetchSingleData(items.id)}
                                  className="view-icon"
                                />
                                <img
                                  src={Copy}
                                  alt="copy"
                                  onClick={() => handleEdit(items.id)}
                                  className="edit-icon"
                                />
                                <img
                                  src={Delete}
                                  alt="delete"
                                  onClick={() =>
                                    handleOpenDeleteModal(items.id)
                                  }
                                  className="delete-icon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-record">No Record Found</div>
                  )}
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <PaginationRounded
                className="catalog-pagination"
                count={totalPage}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      )}
    </Main>
  );
};

export default Catalogs;
