import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHooks = () => {
  const updateProfile = async (profileData) => {
    try {
      const response = await AuthAPI.updateProfile(profileData);
      // console.log(response);
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const getProfile = async () => {
    try {
      const response = await AuthAPI.getProfile();
      console.log(response, "media");
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const imagePost = async (file) => {
    try {
      if (!file) {
        throw new Error("No file provided");
      }
      const reader = new FileReader();
      const fileAsDataURL = new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
      reader.readAsDataURL(file);
      await fileAsDataURL; // Wait for the file to be read as data URL
      const response = await AuthAPI.imagepost(
        { image: file },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      // toastr.success("Image upload response");
      if (response?.path) {
        // toastr.success("Image uploaded successfully");
        toastr.success("Image upload successfull");
        // toastr.error("response path", response.path);
        return response.path;
      } else {
        alert("Image upload failed");
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("An error occurred during image upload");
      return null;
    }
  };

  return { updateProfile, getProfile, imagePost };
};

export default useHooks;
