import { AuthAPI } from "libs/http-service/api/auth.api";
const useHooks = () => {
  const getproductsdetails = async (id) => {
    try {
      const response = await AuthAPI.getproductsdetails(id);
      console.log(response, "hello");
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const fetchproductsreviews = async (params) => {
    try {
      const response = await AuthAPI.getproductsReviews(params);
      // console.log(response, "hello");
      return response;
    } catch (error) {
      console.error(error);
      // alert(error.message);
      return null;
    }
  };
  return { getproductsdetails, fetchproductsreviews };
};
export default useHooks;
